import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { useState, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import networkClient from '../../networks/networkClient';
import { methodTypes, networkEndpoints } from '../../networks/networkStrings';
import endpointParamsSetter from '../../utils/endpointParamsSetter';
import {
  addEmployeeStrings,
  employeeDetailsPageStrings,
} from '../../utils/strings';
import CustomDrawer from '../CustomDrawer';
import BasicInformationComponent from '../EmployeeActions/BasicInformationComponent';
import makeFirstLetterCapital from '../../utils/makeFirstLetterCapital';
import { detailsCardPadding } from '../../utils/dimensions/paddings';
import { handle403Error } from '../../utils/errorHandler';
import { snackbarContext } from '../../utils/context';
import getLoggedInUser from '../../utils/getLoggedInUser';

function EditEmployeeBasicDetails({
  title,
  successButtonText,
  toggleDrawer,
  setToggleDrawer,
  failureButtonText,
  data,
  setEmployeeDetails,
  setHistoryData,
}) {
  const { showSnackbar } = useContext(snackbarContext);
  const [isUpdateUserError, setIsUpdateUserError] = useState(false);
  const currLocation = useLocation();
  const navigate = useNavigate();
  const location = useLocation();
  const user = getLoggedInUser(navigate, location);
  const basicDetailsInitialState = {
    ...Object.keys(data)
      .map((key) => {
        const newKey = key === 'id' ? undefined : key;

        if (newKey === 'personal_email') {
          return {
            personal_email: {
              value: data[key],
              error: false,
              errorMessage: addEmployeeStrings.errorMessages.required,
            },
          };
        }
        if (newKey === 'company_email') {
          return {
            company_email: {
              value: data[key],
              error: false,
              errorMessage: addEmployeeStrings.errorMessages.invalidEmail,
            },
          };
        }

        return newKey
          ? {
              [newKey]: {
                value: data[key],
                error: false,
                errorMessage: addEmployeeStrings.errorMessages.required,
              },
            }
          : null;
      })
      .filter((obj) => obj !== null)
      .reduce((acc, obj) => ({ ...acc, ...obj }), {}),
  };

  const [basicDetails, setBasicDetails] = useState(basicDetailsInitialState);

  const updateUser = async () => {
    const updatedData = {
      first_name: basicDetails.first_name.value,
      last_name: basicDetails.last_name.value,
      country_code: basicDetails.country_code.value,
      phone_number: basicDetails.phone_number.value,
      personal_email: basicDetails.personal_email.value,
      company_email:
        basicDetails.company_email.value === ''
          ? null
          : basicDetails.company_email.value,
      address: basicDetails.address.value,
      city: basicDetails.city.value,
      state: basicDetails.state.value,
      zipcode: basicDetails.zipcode.value,
    };
    if (basicDetails.first_name.value !== data.first_name) {
      setHistoryData((prev) => [
        {
          description: `First Name has been changed from ${data.first_name} to ${basicDetails.first_name.value} by ${user.name}`,
          timestamp: Date.now(),
        },
        ...prev,
      ]);
    }
    if (basicDetails.last_name.value !== data.last_name) {
      setHistoryData((prev) => [
        {
          description: `Last Name has been changed from ${data.last_name} to ${basicDetails.last_name.value} by ${user.name}`,
          timestamp: Date.now(),
        },
        ...prev,
      ]);
    }
    if (basicDetails.country_code.value !== data.country_code) {
      setHistoryData((prev) => [
        {
          description: `Country Code has been changed from ${data.country_code} to ${basicDetails.country_code.value} by ${user.name}`,
          timestamp: Date.now(),
        },
        ...prev,
      ]);
    }
    if (basicDetails.phone_number.value !== data.phone_number) {
      setHistoryData((prev) => [
        {
          description: `Phone Number has been changed from ${data.phone_number} to ${basicDetails.phone_number.value} by ${user.name}`,
          timestamp: Date.now(),
        },
        ...prev,
      ]);
    }
    if (basicDetails.personal_email.value !== data.personal_email) {
      setHistoryData((prev) => [
        {
          description: `Personal Email has been changed from ${data.personal_email} to ${basicDetails.personal_email.value} by ${user.name}`,
          timestamp: Date.now(),
        },
        ...prev,
      ]);
    }
    if (
      basicDetails.address.value !== data.address ||
      basicDetails.city.value !== data.city ||
      basicDetails.state.value !== data.state ||
      basicDetails.zipcode.value !== data.zipcode
    ) {
      setHistoryData((prev) => [
        {
          description: `Address has been updated by ${user.name}`,
          timestamp: Date.now(),
        },
        ...prev,
      ]);
    }
    setIsUpdateUserError(null);
    const response = await networkClient({
      method: methodTypes.patch,
      endpoint: endpointParamsSetter({
        path: networkEndpoints.user.users,
        params: data.id,
      }),
      requestBody: updatedData,
    });
    if (response.error) {
      if (response.errors[0].code === 400) {
        setIsUpdateUserError(false);
        response.errors.forEach((err) => {
          setBasicDetails((prevBasicDetails) => ({
            ...prevBasicDetails,
            [err.field]: {
              ...prevBasicDetails[err.field],
              error: true,
              errorMessage: err.message,
            },
          }));
        });
      } else {
        setIsUpdateUserError(true);
      }
      if (response.errors[0].code === 403) {
        handle403Error(navigate, currLocation);
      }
    } else {
      response.data.status = makeFirstLetterCapital(response.data.status);
      response.data = {
        ...response.data,
      };
      showSnackbar(
        employeeDetailsPageStrings.responseMessage.userUpdateSuccess
      );
      setEmployeeDetails(response.data);
      setIsUpdateUserError(false);
      setToggleDrawer(false);
    }
  };
  function joiningDataHasError() {
    const updatedFields = Object.keys(basicDetails).filter(
      (key) => basicDetails[key].value !== basicDetailsInitialState[key].value
    );
    if (
      basicDetails.company_email.error ||
      basicDetails.first_name.error ||
      basicDetails.last_name.error ||
      basicDetails.country_code.error ||
      basicDetails.phone_number.error ||
      basicDetails.personal_email.error ||
      basicDetails.first_name.value === '' ||
      basicDetails.last_name.value === '' ||
      basicDetails.country_code.value === '' ||
      basicDetails.phone_number.value === '' ||
      basicDetails.personal_email.value === '' ||
      basicDetails.city.error ||
      basicDetails.zipcode.error ||
      basicDetails.state.error ||
      basicDetails.address.error ||
      updatedFields.length === 0
    ) {
      return true;
    }
    return false;
  }

  return (
    <CustomDrawer
      error={isUpdateUserError}
      successButtonLoading={isUpdateUserError === null}
      title={title}
      open={toggleDrawer}
      onFailureButtonClick={() => {
        setBasicDetails(basicDetailsInitialState);
        setToggleDrawer(false);
      }}
      onSuccessButtonClick={updateUser}
      successButtonDisabled={joiningDataHasError()}
      successButtonText={successButtonText}
      failureButtonText={failureButtonText}>
      <Box paddingTop={detailsCardPadding.editDrawerContentPadding}>
        <BasicInformationComponent
          basicDetails={basicDetails}
          setBasicDetails={setBasicDetails}
        />
      </Box>
    </CustomDrawer>
  );
}

EditEmployeeBasicDetails.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number.isRequired,
    first_name: PropTypes.string.isRequired,
    last_name: PropTypes.string.isRequired,
    country_code: PropTypes.string.isRequired,
    phone_number: PropTypes.string.isRequired,
    personal_email: PropTypes.string.isRequired,
    address: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    zipcode: PropTypes.string.isRequired,
    company_email: PropTypes.string,
  }),
  title: PropTypes.string.isRequired,
  successButtonText: PropTypes.string.isRequired,
  failureButtonText: PropTypes.string.isRequired,
  toggleDrawer: PropTypes.bool.isRequired,
  setToggleDrawer: PropTypes.func.isRequired,
  setEmployeeDetails: PropTypes.func.isRequired,
  setHistoryData: PropTypes.func.isRequired,
};
EditEmployeeBasicDetails.defaultProps = {
  data: {
    company_email: '',
  },
};
export default EditEmployeeBasicDetails;
