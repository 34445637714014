import moment from 'moment';
import {
  momentStrings,
  snackbarStrings,
  softwareStatusStrings,
} from './strings';
import networkClient from '../networks/networkClient';
import { methodTypes, networkEndpoints } from '../networks/networkStrings';
import { handle403Error } from './errorHandler';

export default async function resendMailClient({
  userSoftware,
  showSnackbar,
  navigate,
  location,
}) {
  const timegGapInHour = process.env.REACT_APP_TIME_BETWEEN_MAILS || 24;

  if (Array.isArray(userSoftware)) {
    let isError = false;
    const resendMailResponse = await networkClient({
      method: methodTypes.post,
      endpoint: networkEndpoints.userSoftware.sendMultipleRevokeEmail,
      requestBody: { userSoftwareIds: userSoftware },
    });
    if (resendMailResponse.error) {
      if (resendMailResponse.errors[0].code === 403) {
        handle403Error(navigate, location);
      }
      // handle errors
      else {
        isError = true;
      }
    }
    showSnackbar(
      isError
        ? snackbarStrings.messages.error.somethingWentWrong
        : snackbarStrings.messages.success.allRevokeRequestInitiated,
      isError ? snackbarStrings.variant.error : snackbarStrings.variant.success
    );
  } else {
    // check if previous mail sent within past 24 hours
    // if not send a new email
    // eslint-disable-next-line
    if (
      !userSoftware.lastEmailDate ||
      moment().diff(
        userSoftware.lastEmailDate,
        momentStrings.timeUnits.hours
      ) >= timegGapInHour
    ) {
      const resendMailResponse = await networkClient({
        method: methodTypes.post,
        endpoint:
          userSoftware.status === softwareStatusStrings.active
            ? networkEndpoints.userSoftware.revokeEmail
            : networkEndpoints.userSoftware.assignEmail,
        requestBody: { userSoftwareIds: [userSoftware.userSoftwareId] },
      });
      if (!resendMailResponse.error) {
        // set last email date if mail sent successfully
        if (resendMailResponse.data.success.length) {
          // eslint-disable-next-line
          userSoftware.lastEmailDate =
            resendMailResponse.data.success[0].last_email_date;

          showSnackbar(
            snackbarStrings.messages.success.mailSentSuccessfully,
            snackbarStrings.variant.success
          );
        }
        // if mail has already been sent within past 24 hours then give warning message
        else if (resendMailResponse.data.warning.length) {
          showSnackbar(
            resendMailResponse.data.warning[0].message,
            snackbarStrings.variant.warning
          );
        }
        // error while sending mail
        else {
          showSnackbar(
            snackbarStrings.messages.error.somethingWentWrong,
            snackbarStrings.variant.error
          );
        }
      }
      // handle 403 error
      else if (resendMailResponse.errors[0].code === 403) {
        handle403Error(navigate, location);
      }
      // handle errors
      else {
        showSnackbar(
          snackbarStrings.messages.error.somethingWentWrong,
          snackbarStrings.variant.error
        );
      }
    }
    // if mail has been already sent within past 24 hours
    // then give warning with remaining time
    else {
      const tryAfter = moment(userSoftware.lastEmailDate)
        .add(timegGapInHour, momentStrings.timeUnits.hours)
        .diff(moment(), momentStrings.timeUnits.hours);

      showSnackbar(
        snackbarStrings.messages.warning.tryAgainAfter.replace(
          /{([^}]+)}/g,
          tryAfter
        ),
        snackbarStrings.variant.warning
      );
    }
  }
}
