// headCells has data for Table Head data.
const headCells = [
  {
    id: 'image',
    dataType: 'image',
    label: '',
    flex: 1,
    sortingNeeded: false,
  },
  {
    id: 'name',
    dataType: 'name',
    label: 'Name',
    flex: 6,
  },
  {
    id: 'status',
    dataType: 'status',
    label: 'Status',
    flex: 3,
  },
];

export default headCells;
