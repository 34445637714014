import { customTableStrings } from './strings';

function descendingComparator(a, b, sortOrderBy) {
  if (
    sortOrderBy === customTableStrings.tableCellDataTypes.employeeId &&
    a.id === null
  ) {
    return -1;
  }
  if (
    sortOrderBy === customTableStrings.tableCellDataTypes.employeeId &&
    b.id === null
  ) {
    return 1;
  }
  if (
    sortOrderBy === customTableStrings.tableCellDataTypes.employeeId &&
    a?.id !== null &&
    b?.id !== null
  ) {
    const lastSlashIndexA = a?.id?.lastIndexOf('/');
    const lastSlashIndexB = b?.id?.lastIndexOf('/');

    const textPartA = a?.id?.slice(0, lastSlashIndexA + 1);
    const textPartB = b?.id?.slice(0, lastSlashIndexB + 1);

    const textComparison = textPartA?.localeCompare(textPartB);

    if (textComparison !== 0) {
      return textComparison === -1 ? 1 : -1;
    }
    const numberPartA = parseInt(a?.id?.slice(lastSlashIndexA + 1), 10);
    const numberPartB = parseInt(b?.id?.slice(lastSlashIndexB + 1), 10);

    return numberPartB - numberPartA;
  }
  if (
    sortOrderBy === customTableStrings.tableCellDataTypes.employeeName ||
    sortOrderBy === customTableStrings.tableCellDataTypes.nameWithManager ||
    sortOrderBy === customTableStrings.tableCellDataTypes.name ||
    sortOrderBy === customTableStrings.tableCellDataTypes.client ||
    sortOrderBy === customTableStrings.tableCellDataTypes.role ||
    sortOrderBy === customTableStrings.tableCellDataTypes.allocation
  ) {
    if (b < a) {
      return -1;
    }
    if (b > a) {
      return 1;
    }
    return 0;
  }
  if (sortOrderBy === customTableStrings.tableCellDataTypes.skills) {
    if (b[sortOrderBy].length < a[sortOrderBy].length) {
      return -1;
    }
    if (b[sortOrderBy].length > a[sortOrderBy].length) {
      return 1;
    }
    return 0;
  }
  if (sortOrderBy === customTableStrings.tableCellDataTypes.date) {
    if (!b[sortOrderBy]) {
      return -1;
    }
    if (!a[sortOrderBy]) {
      return 1;
    }
  }
  if (b[sortOrderBy] < a[sortOrderBy]) {
    return -1;
  }
  if (b[sortOrderBy] > a[sortOrderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(sortOrder, sortOrderBy) {
  return sortOrder === customTableStrings.sortOrder.decending
    ? (a, b) => descendingComparator(a, b, sortOrderBy)
    : (a, b) => -descendingComparator(a, b, sortOrderBy);
}

function stableSort(array, comparator, sortOrderBy) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    let order;
    if (sortOrderBy === customTableStrings.tableCellDataTypes.employeeName) {
      order = comparator(
        a[0].nameData.name.toLowerCase(),
        b[0].nameData.name.toLowerCase()
      );
    } else if (
      sortOrderBy === customTableStrings.tableCellDataTypes.nameWithManager
    ) {
      order = comparator(a[0].name.softwareName, b[0].name.softwareName);
    } else if (sortOrderBy === customTableStrings.tableCellDataTypes.role) {
      order = comparator(a[0].role.roleName, b[0].role.roleName);
    } else if (sortOrderBy === customTableStrings.tableCellDataTypes.name) {
      order = comparator(a[0].name.toLowerCase(), b[0].name.toLowerCase());
    } else if (sortOrderBy === customTableStrings.tableCellDataTypes.client) {
      order = comparator(a[0].client.toLowerCase(), b[0].client.toLowerCase());
    } else if (
      sortOrderBy === customTableStrings.tableCellDataTypes.allocation
    ) {
      order = comparator(
        parseInt(a[0].allocation, 10),
        parseInt(b[0].allocation, 10)
      );
    } else {
      order = comparator(a[0], b[0]);
    }
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });

  return stabilizedThis.map((el) => el[0]);
}

export { getComparator, stableSort, descendingComparator };
