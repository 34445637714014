import { Stack, styled } from '@mui/material';
import { navBarSize } from '../../utils/dimensions/size';

const NavBarStack = styled(Stack)(() => ({
  height: navBarSize.navBarHeight,
  alignItems: 'end',
  flexDirection: 'row',
}));

export default NavBarStack;
