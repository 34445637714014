import { useNavigate } from 'react-router-dom';
import { Box, Container, Stack, Typography, useTheme } from '@mui/material';
import CustomFilledIconButton from '../../components/CustomFilledIconButton';
import { ReactComponent as PageNotFoundSVG } from '../../utils/assets/PageNotFoundSVG.svg';
import { pageNotFoundPageSize } from '../../utils/dimensions/size';
import { pageNotFoundStrings } from '../../utils/strings';
import redirectPaths from '../../Router/RedirectPaths';

function PageNotFoundPage() {
  const navigate = useNavigate();
  const theme = useTheme();

  return (
    <Container
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: pageNotFoundPageSize.pageHeight,
      }}>
      <Stack justifyContent='center' alignItems='center'>
        <PageNotFoundSVG />
        <Box height={pageNotFoundPageSize.spaceBetweenObjects} />
        <Typography variant='h6' color={theme.palette.grey[1100]}>
          {pageNotFoundStrings.pageSubtitle}
        </Typography>
        <Box height={pageNotFoundPageSize.spaceBetweenObjects} />
        <CustomFilledIconButton
          text={pageNotFoundStrings.buttonText}
          onClick={() => {
            navigate(redirectPaths.homePath.to);
          }}
        />
      </Stack>
    </Container>
  );
}

export default PageNotFoundPage;
