import { InputAdornment, MenuItem, TextField, useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import { customTextFieldMargins } from '../../utils/dimensions/margins';
import { customTextFieldStrings } from '../../utils/strings';

function CustomTextField({
  textColor,
  disabled,
  value = '',
  varient,
  placeHolder,
  type,
  error,
  multiline,
  rows,
  select,
  endAdornment,
  onChange,
  onBlur,
  menuItem,
  disableDropdownIcon,
  flex,
  helperText,
  children,
  maxLength,
  SelectProps,
}) {
  const theme = useTheme();
  return (
    <TextField
      disabled={disabled}
      value={value}
      varient={varient}
      label={placeHolder}
      onBlur={onBlur}
      error={error}
      helperText={helperText}
      sx={{
        input: {
          color: textColor,
          cursor: disabled ? 'not-allowed' : 'text',
        },
        flex,
        margin: customTextFieldMargins.margin,
        '& .MuiOutlinedInput-root': {
          borderRadius: theme.shape.textFieldBorderRadius,
          '&.Mui-focused fieldset': {
            border: `solid 1px ${
              error ? theme.palette.error.main : theme.palette.info.main
            }`,
          },
        },
        '& .MuiInputLabel-root.Mui-focused': {
          color: error ? theme.palette.error.main : theme.palette.info.main,
        },
        '& .MuiSelect-icon': {
          display: disableDropdownIcon ? 'none' : 'inline-block',
        },
      }}
      type={type}
      select={select}
      multiline={multiline}
      rows={rows}
      InputProps={{
        endAdornment: (
          <InputAdornment
            sx={{
              fontFamily: theme.typography.body1Medium.fontFamily,
              fontWeight: theme.typography.body1Medium.fontWeight,
              fontSize: theme.typography.body1Medium.fontSize,
              color: theme.palette.grey[400],
            }}
            position='end'>
            {endAdornment}
          </InputAdornment>
        ),
      }}
      onChange={(newValue) => {
        if (newValue.target.value.length) {
          if (newValue.target.value.length <= maxLength) {
            onChange(newValue);
          }
        } else {
          onChange(newValue);
        }
      }}
      SelectProps={SelectProps}>
      {select &&
        menuItem.map((menu) => (
          <MenuItem key={menu} value={menu}>
            {menu}
          </MenuItem>
        ))}
      {children}
    </TextField>
  );
}
// seting default props if props is not provided
CustomTextField.defaultProps = {
  varient: customTextFieldStrings.variant,
  placeHolder: customTextFieldStrings.placeHolder,
  type: customTextFieldStrings.type,
  multiline: false,
  disabled: false,
  endAdornment: '',
  maxLength: 254,
  rows: 1,
  value: '',
  select: false,
  menuItem: [],
  flex: '1',
  disableDropdownIcon: false,
  textColor: 'default',
  onBlur: null,
  error: false,
  helperText: null,
  children: null,
  SelectProps: {},
};

CustomTextField.propTypes = {
  textColor: PropTypes.string,
  placeHolder: PropTypes.string,
  varient: PropTypes.string,
  type: PropTypes.string,
  endAdornment: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.bool,
  ]),
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  multiline: PropTypes.bool,
  disabled: PropTypes.bool,
  rows: PropTypes.number,
  maxLength: PropTypes.number,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({}),
    PropTypes.number,
  ]),
  helperText: PropTypes.string,
  SelectProps: PropTypes.shape({}),
  error: PropTypes.bool,
  flex: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  select: PropTypes.bool,
  menuItem: PropTypes.arrayOf(PropTypes.string),
  disableDropdownIcon: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.arrayOf(PropTypes.element),
        PropTypes.bool,
      ])
    ),
    PropTypes.element,
  ]),
};

export default CustomTextField;
