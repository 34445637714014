import { useLocation, useNavigate } from 'react-router-dom';
import { EditOutlined } from '@mui/icons-material';
import { Box, Stack, Typography, useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { employeeDetailsPageSize } from '../../utils/dimensions/size';
import CustomTextButton from '../../components/CustomTextButton';
import CustomDetailsBox from '../../components/CustomDetailsBox';
import ShowDetailsColumn from '../../components/ShowDetailsColumn';

import {
  employeeDetailsPageStrings,
  joiningInformationStrings,
  tableCellStrings,
} from '../../utils/strings';
import { employeeDetailsPagePaddings } from '../../utils/dimensions/paddings';
import EditEmployeeJobDetails from '../../components/EmployeeProfileActions/EditEmployeeJobDetails';
import getLoggedInUser from '../../utils/getLoggedInUser';
import makeFirstLetterCapital from '../../utils/makeFirstLetterCapital';

function JobDetailsCard({
  title,
  employeeJobDetails,
  setEmployeeDetails,
  setHistoryData,
}) {
  const [toggleDrawer, setToggleDrawer] = useState(false);
  const [user, setUser] = useState(null);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    setUser(getLoggedInUser(navigate, location));
  }, [navigate, setUser, location]);

  let role;
  if (user) {
    role = user.role;
  }
  const offset = moment().utcOffset();
  const localJoinDate = moment
    .utc(employeeJobDetails.join_date)
    .utcOffset(offset)
    .format('MMMM D, YYYY');
  const localEndDate = moment
    .utc(employeeJobDetails.end_date)
    .utcOffset(offset)
    .format('MMMM D, YYYY');
  const theme = useTheme();

  return (
    <div>
      <CustomDetailsBox
        title={title}
        titleTailElement={
          <CustomTextButton
            disabled={role === 4 || role === 5}
            icon={
              <EditOutlined
                fontSize={employeeDetailsPageSize.asideIconSize}
                color={theme.palette.grey[1100]}
              />
            }
            onClick={() => {
              setToggleDrawer(true);
            }}>
            {employeeDetailsPageStrings.profileTab.editButtonText}
          </CustomTextButton>
        }>
        <Stack
          padding={employeeDetailsPagePaddings.rowDetailsPadding}
          display='flex'
          flexDirection='row'>
          <ShowDetailsColumn
            flex='1'
            label={employeeDetailsPageStrings.profileTab.idLabel}>
            {employeeJobDetails.employeeId || tableCellStrings.dash}
          </ShowDetailsColumn>
          <ShowDetailsColumn
            flex='1'
            label={
              employeeDetailsPageStrings.employeeDetailsCard.locationLabel
            }>
            {employeeJobDetails.branch?.name || tableCellStrings.dash}
          </ShowDetailsColumn>
          <ShowDetailsColumn
            flex='1'
            label={employeeDetailsPageStrings.profileTab.jobTitleLabel}>
            {employeeJobDetails.job_title || tableCellStrings.dash}
          </ShowDetailsColumn>
        </Stack>
        <Box height={employeeDetailsPageSize.verticalCommonSpace} />
        <Stack
          padding={employeeDetailsPagePaddings.rowDetailsPadding}
          display='flex'
          flexDirection='row'>
          <ShowDetailsColumn
            flex='1'
            label={employeeDetailsPageStrings.profileTab.typeLabel}>
            {makeFirstLetterCapital(employeeJobDetails.employment_type)}
          </ShowDetailsColumn>
          <ShowDetailsColumn
            flex='1'
            label={employeeDetailsPageStrings.profileTab.agencyNameLabel}>
            {employeeJobDetails.agencyName}
          </ShowDetailsColumn>
          <ShowDetailsColumn
            flex='1'
            label={employeeDetailsPageStrings.profileTab.agencyEmailLabel}>
            {employeeJobDetails.agencyEmail}
          </ShowDetailsColumn>
        </Stack>
        <Box height={employeeDetailsPageSize.verticalCommonSpace} />
        <Stack
          padding={employeeDetailsPagePaddings.rowDetailsPadding}
          display='flex'
          flexDirection='row'>
          <ShowDetailsColumn
            flex='1'
            label={
              employeeDetailsPageStrings.profileTab.agencyPhoneNumberLabel
            }>
            {employeeJobDetails.agencyPhoneNumber}
          </ShowDetailsColumn>
          <ShowDetailsColumn
            flex='1'
            label={employeeDetailsPageStrings.profileTab.capacityLabel}>
            <Stack direction='row' alignItems='center'>
              {employeeJobDetails.capacity !== null ? (
                <>
                  <Typography variant='body2SemiBold'>
                    {`${employeeJobDetails.capacity} ${joiningInformationStrings.capacityInHours}`}
                  </Typography>
                  <Typography variant='body2'>
                    &nbsp;{joiningInformationStrings.capacityInWeek}
                  </Typography>
                </>
              ) : (
                <Typography variant='body2'>{tableCellStrings.dash}</Typography>
              )}
            </Stack>
          </ShowDetailsColumn>
          <ShowDetailsColumn
            flex='1'
            label={employeeDetailsPageStrings.profileTab.startDateLabel}>
            {localJoinDate}
          </ShowDetailsColumn>
        </Stack>
        <Box height={employeeDetailsPageSize.verticalCommonSpace} />
        <Stack
          flexDirection='row'
          display='flex'
          padding={employeeDetailsPagePaddings.rowDetailsPadding}>
          <ShowDetailsColumn
            flex='1'
            label={employeeDetailsPageStrings.profileTab.endDateLabel}>
            {employeeJobDetails.end_date !== null
              ? localEndDate
              : tableCellStrings.dash}
          </ShowDetailsColumn>
          <ShowDetailsColumn
            flex='2'
            label={employeeDetailsPageStrings.profileTab.jobDescription}>
            {employeeJobDetails.note || '-'}
          </ShowDetailsColumn>
        </Stack>
      </CustomDetailsBox>
      <EditEmployeeJobDetails
        title={employeeDetailsPageStrings.employeeDetailsCard.successButtonText}
        toggleDrawer={toggleDrawer}
        setToggleDrawer={setToggleDrawer}
        data={employeeJobDetails}
        setEmployeeDetails={setEmployeeDetails}
        successButtonText={
          employeeDetailsPageStrings.employeeDetailsCard.successButtonText
        }
        failureButtonText={
          employeeDetailsPageStrings.employeeDetailsCard.failureButtonText
        }
        setHistoryData={setHistoryData}
      />
    </div>
  );
}

JobDetailsCard.propTypes = {
  title: PropTypes.string.isRequired,
  employeeJobDetails: PropTypes.shape({
    id: PropTypes.number,
    employeeId: PropTypes.string,
    job_title: PropTypes.string,
    location: PropTypes.string,
    note: PropTypes.string,
    capacity: PropTypes.number,
    end_date: PropTypes.string,
    join_date: PropTypes.string,
    agencyEmail: PropTypes.string,
    agencyName: PropTypes.string,
    agencyPhoneNumber: PropTypes.string,
    employment_type: PropTypes.string,
    branch_id: PropTypes.number,
    branch: PropTypes.shape({
      name: PropTypes.string,
    }),
    company_email: PropTypes.string,
  }).isRequired,
  setEmployeeDetails: PropTypes.func.isRequired,
  setHistoryData: PropTypes.func.isRequired,
};

export default JobDetailsCard;
