import PropTypes from 'prop-types';
import { Box, Stack, Typography, useTheme } from '@mui/material';
import { useContext, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import CustomDrawer from '../CustomDrawer';
import CustomTextField from '../Inputs/CustomTextField';
import { completeAssignSoftwareMargins } from '../../utils/dimensions/margins';
import { employeeSoftwareCompleteAssignmentSize } from '../../utils/dimensions/size';
import { completeAssignmentSoftwareDrawerStrings } from '../../utils/strings';
import { completeAssignmentSoftwareDetailsPadding } from '../../utils/dimensions/paddings';
import networkClient from '../../networks/networkClient';
import { methodTypes, networkEndpoints } from '../../networks/networkStrings';
import endpointParamsSetter from '../../utils/endpointParamsSetter';
import { handle403Error } from '../../utils/errorHandler';
import { snackbarContext } from '../../utils/context';
import DefaultSoftwareFavicon from '../../utils/assets/DefaultSoftwareFavicon.svg';
import makeFirstLetterCapital from '../../utils/makeFirstLetterCapital';

export default function CompleteSoftwareAssignDrawer({
  toggleCompleteAssignDrawer,
  setCompleteAssignDrawer,
  assignSoftwareData,
  setTableData,
  setActionButton,
  title,
  user,
  setHistoryData,
}) {
  const theme = useTheme();
  const { showSnackbar } = useContext(snackbarContext);
  const formDataInitialState = {
    username: {
      value: '',
      error: false,
      errorMessage:
        completeAssignmentSoftwareDrawerStrings.errorMessage.required.username,
    },
    notes: {
      value: '',
    },
  };
  const [formData, setFormData] = useState(formDataInitialState);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const setData = ({ key, error, data, errorMessage }) => {
    setFormData({
      ...formData,
      [key]: {
        ...formData[key],
        error,
        value: data === undefined ? formData[key].value : data,
        errorMessage: errorMessage || formData[key].errorMessage,
      },
    });
  };
  const assignSoftware = async () => {
    setIsLoading(true);
    const assignSoftwareResponse = await networkClient({
      method: methodTypes.patch,
      endpoint: endpointParamsSetter({
        path: networkEndpoints.userSoftware.userSoftwareById,
        params: assignSoftwareData.userSoftwareId,
      }),
      requestBody: {
        username: formData.username.value.trim(),
        status: completeAssignmentSoftwareDrawerStrings.active,
        note: formData.notes.value.trim(),
      },
    });

    if (!assignSoftwareResponse.error) {
      setFormData(formDataInitialState);
      showSnackbar(completeAssignmentSoftwareDrawerStrings.successmessage);
      setIsError(false);
      setIsLoading(false);
      setTableData((oldTableData) => {
        const tempSoftwareIndex = oldTableData.findIndex(
          (obj) =>
            obj.actionButton.data.userSoftwareId ===
            assignSoftwareData.userSoftwareId
        );

        oldTableData.splice(tempSoftwareIndex, 1, {
          ...oldTableData[tempSoftwareIndex],
          assignDate: assignSoftwareResponse.data.assign_date,
          username: assignSoftwareResponse.data.username,
          name: {
            ...oldTableData[tempSoftwareIndex].name,
            actionBy: user.given_name,
            fullName: user.name,
            status: makeFirstLetterCapital(assignSoftwareResponse.data.status),
          },
          status: makeFirstLetterCapital(assignSoftwareResponse.data.status),
          actionButton: setActionButton({
            status: makeFirstLetterCapital(assignSoftwareResponse.data.status),
            userSoftwareId: assignSoftwareResponse.data.id,
            managers: [user.userId],
            imageUrl: oldTableData[tempSoftwareIndex].imageUrl,
            name: oldTableData[tempSoftwareIndex].name.softwareName,
            lastEmailDate: assignSoftwareResponse.data.last_email_date,
          }),
        });
        setHistoryData((prev) => [
          {
            description: `${user.name} has assigned ${assignSoftwareData?.name} software`,
            timestamp: Date.now(),
          },
          ...prev,
        ]);
        return oldTableData;
      });
      setCompleteAssignDrawer(false);
    } else {
      setIsError(true);
      setTimeout(() => {
        setIsError(false);
      }, 10000);
      if (assignSoftwareResponse.errors[0].code === 403) {
        handle403Error(navigate, location);
      }
    }
  };

  const isValidationError = () => {
    if (formData.username.error || !formData.username.value) {
      return true;
    }
    return false;
  };

  return (
    <div>
      <CustomDrawer
        error={isError}
        successButtonLoading={isLoading}
        open={toggleCompleteAssignDrawer}
        title={title}
        onFailureButtonClick={() => {
          setFormData(formDataInitialState);
          setCompleteAssignDrawer(false);
        }}
        onSuccessButtonClick={() => {
          assignSoftware();
        }}
        successButtonDisabled={isValidationError()}
        onClose={() => {
          setCompleteAssignDrawer(false);
        }}
        headerTailElement={
          <Stack direction='row' alignItems='center' spacing={1}>
            {assignSoftwareData.imageUrl ? (
              <Box
                sx={{
                  padding: completeAssignmentSoftwareDetailsPadding.iconPadding,
                  border: `1px solid ${theme.palette.grey[100]}`,
                }}>
                <img
                  src={assignSoftwareData.imageUrl}
                  alt={assignSoftwareData?.name?.softwareName}
                  width={employeeSoftwareCompleteAssignmentSize.imageWidth}
                  height={employeeSoftwareCompleteAssignmentSize.imageHeight}
                  onError={(e) => {
                    e.target.src = DefaultSoftwareFavicon;
                    e.onerror = null;
                  }}
                />
              </Box>
            ) : (
              <img
                width={employeeSoftwareCompleteAssignmentSize.imageWidth}
                height={employeeSoftwareCompleteAssignmentSize.imageHeight}
                src={DefaultSoftwareFavicon}
                style={{ objectFit: 'contain' }}
                alt={assignSoftwareData?.name?.softwareName}
              />
            )}
            <Typography variant='h5SemiBold' color={theme.palette.grey[1100]}>
              {assignSoftwareData?.name}
            </Typography>
          </Stack>
        }
        successButtonText={
          completeAssignmentSoftwareDrawerStrings.AssignButtonText
        }
        failureButtonText={
          completeAssignmentSoftwareDrawerStrings.cancelButtonText
        }>
        <Box marginX={completeAssignSoftwareMargins.marginX} paddingTop='15px'>
          <Stack>
            <CustomTextField
              onChange={(e) => {
                setData({
                  key: completeAssignmentSoftwareDrawerStrings.keys.username,
                  data: e.target.value,
                  error: e.target.value.trim().length <= 0,
                });
              }}
              onBlur={(e) => {
                setData({
                  key: completeAssignmentSoftwareDrawerStrings.keys.username,
                  error: e.target.value.trim() === '',
                  data: e.target.value,
                });
              }}
              value={formData.username.value}
              error={formData.username.error}
              helperText={
                formData.username.error ? formData.username.errorMessage : null
              }
              placeHolder={
                completeAssignmentSoftwareDrawerStrings.placeHolder.username
              }
              width={employeeSoftwareCompleteAssignmentSize.widthOfTextBox}
            />
          </Stack>
          <Stack>
            <CustomTextField
              onChange={(e) => {
                setData({
                  key: completeAssignmentSoftwareDrawerStrings.keys.notes,
                  data: e.target.value,
                });
              }}
              value={formData.notes.value}
              placeHolder={
                completeAssignmentSoftwareDrawerStrings.placeHolder.notes
              }
              width={employeeSoftwareCompleteAssignmentSize.widthOfTextBox}
              multiline
              rows={3}
            />
          </Stack>
        </Box>
      </CustomDrawer>
    </div>
  );
}

CompleteSoftwareAssignDrawer.propTypes = {
  toggleCompleteAssignDrawer: PropTypes.bool.isRequired,
  setCompleteAssignDrawer: PropTypes.func.isRequired,
  assignSoftwareData: PropTypes.shape({
    imageUrl: PropTypes.string,
    name: PropTypes.string,
    userSoftwareId: PropTypes.number,
    status: PropTypes.string,
  }),
  setTableData: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  user: PropTypes.shape({
    name: PropTypes.string,
    userId: PropTypes.number,
    given_name: PropTypes.string,
    role: PropTypes.number,
  }).isRequired,
  setHistoryData: PropTypes.func.isRequired,
  setActionButton: PropTypes.func.isRequired,
};

CompleteSoftwareAssignDrawer.defaultProps = {
  assignSoftwareData: PropTypes.shape({
    imageUrl: '',
    name: '',
    userSoftwareId: '',
  }),
};
