import { Button, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { CustomOutlinedIconButtonString } from '../utils/strings';
import { CustomOutlinedIconButtonSize } from '../utils/dimensions/size';
import theme from '../styles/theme';
import CircularProgressIndicator from './CircularProgressIndicator';

export default function CustomOutlinedIconButton({
  onClick,
  icon,
  children,
  textColor,
  borderColor,
  isLoading,
}) {
  return isLoading ? (
    <CircularProgressIndicator />
  ) : (
    <div>
      <Button
        variant={CustomOutlinedIconButtonString.variant}
        disableElevation
        onClick={onClick}
        startIcon={icon}
        sx={{
          borderColor,
          backgroundColor: theme.palette.background.paper,
          color: textColor,
          borderRadius: theme.shape.borderRadius,
          height: CustomOutlinedIconButtonSize.buttonHeight,
        }}>
        <Typography variant='buttonLarge'>{children}</Typography>
      </Button>
    </div>
  );
}
CustomOutlinedIconButton.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string])
    .isRequired,
  onClick: PropTypes.func.isRequired,
  icon: PropTypes.element,
  textColor: PropTypes.string,
  borderColor: PropTypes.string,
  isLoading: PropTypes.bool,
};

CustomOutlinedIconButton.defaultProps = {
  icon: null,
  textColor: theme.palette.secondary.main,
  borderColor: theme.palette.secondary.states.outlinedRestingBorder,
  isLoading: false,
};
