// headCells has data for Table Head data.
const headCells = [
  {
    id: 'employeeName',
    dataType: 'employeeName',
    label: 'Name',
    flex: 7,
    sorting: true,
  },
  {
    id: 'assignDate',
    dataType: 'assignDate',
    label: 'Assign Date',
    flex: 4,
    sorting: true,
  },
  {
    id: 'status',
    dataType: 'status',
    label: 'Status',
    flex: 4,
    sorting: true,
  },
  {
    id: 'note',
    dataType: 'notesLink',
    label: 'Note',
    flex: 4,
  },
];

export default headCells;
