import { createTheme } from '@mui/material';
import themeToken from './themeToken.json';
import '@fontsource/ibm-plex-sans';

const getShadowString = (shadowObj) =>
  `${shadowObj[0].x}px ${shadowObj[0].y}px ${shadowObj[0].blur}px ${shadowObj[0].spread}px ${shadowObj[0].color},${shadowObj[1].x}px ${shadowObj[1].y}px ${shadowObj[1].blur}px ${shadowObj[1].spread}px ${shadowObj[1].color},${shadowObj[2].x}px ${shadowObj[2].y}px ${shadowObj[2].blur}px ${shadowObj[2].spread}px ${shadowObj[2].color}`;

const getFontWeight = (weight) => {
  if (weight === themeToken.global.fontWeights['ibm-plex-sans-0'].value) {
    return 300;
  }
  if (weight === themeToken.global.fontWeights['ibm-plex-sans-1'].value) {
    return 400;
  }
  if (weight === themeToken.global.fontWeights['ibm-plex-sans-2'].value) {
    return 600;
  }
  if (weight === themeToken.global.fontWeights['ibm-plex-sans-3'].value) {
    return 500;
  }
  return 500;
};

const getStyleValue = (styleValue) => {
  const startIndex = styleValue.lastIndexOf('.') + 1;
  const endIndex = styleValue.length - 1;
  return styleValue.substring(startIndex, endIndex);
};

const getTypographyStyles = (styles) => ({
  fontFamily:
    themeToken.global.fontFamilies[getStyleValue(styles.fontFamily)].value,
  fontWeight: getFontWeight(
    themeToken.global.fontWeights[getStyleValue(styles.fontWeight)].value
  ),
  // lineHeight: themeToken.global.lineHeights[1].value,
  fontSize: `${
    themeToken.global.fontSize[getStyleValue(styles.fontSize)].value
  }px`,
  textTransform:
    themeToken.global.textCase[getStyleValue(styles.textCase)].value,
  letterSpacing: `${
    themeToken.global.letterSpacing[getStyleValue(styles.letterSpacing)].value
  }px`,
});

// theme object contains global theme created for our application.
const theme = createTheme({
  shape: {
    borderRadius: 1,
    textFieldBorderRadius: '4px',
  },
  palette: {
    contrastThreshold: 4.5,
    divider: themeToken.global.Other.Divider.value,
    text: {
      primary: themeToken.global.Text.Primary.value,
      secondary: themeToken.global.Text.Secondary.value,
      disabled: themeToken.global.Text.Disabled.value,
    },
    primary: {
      main: themeToken.global.Primary.Main.value,
      light: themeToken.global.Primary.Light.value,
      dark: themeToken.global.Primary.Dark.value,
      contrastText: themeToken.global.Primary.Contrast.value,
      states: {
        outlinedRestingBorder:
          themeToken.global.Primary.States['Outlined Resting Border'].value,
        outlinedHoverBackground:
          themeToken.global.Primary.States['Outlined Hover Background'].value,
      },
    },
    secondary: {
      main: themeToken.global.Secondary.Main.value,
      light: themeToken.global.Secondary.Light.value,
      dark: themeToken.global.Secondary.Dark.value,
      contrastText: themeToken.global.Secondary.Contrast.value,
      states: {
        outlinedRestingBorder:
          themeToken.global.Secondary.States['Outlined Resting Border'].value,
        outlinedHoverBackground:
          themeToken.global.Secondary.States['Outlined Hover Background'].value,
      },
    },
    common: {
      white: themeToken.global.Common.White.value,
      black: themeToken.global.Common.Black.value,
    },
    grey: {
      25: themeToken.global['RC-Gray']['Gray 25'].value,
      50: themeToken.global['RC-Gray']['Gray 50'].value,
      100: themeToken.global['RC-Gray']['Gray 100'].value,
      200: themeToken.global['RC-Gray']['Gray 200'].value,
      300: themeToken.global['RC-Gray']['Gray 300'].value,
      400: themeToken.global['RC-Gray']['Gray 400'].value,
      500: themeToken.global['RC-Gray']['Gray 500'].value,
      600: themeToken.global['RC-Gray']['Gray 600'].value,
      700: themeToken.global['RC-Gray']['Gray 700'].value,
      800: themeToken.global['RC-Gray']['Gray 800'].value,
      900: themeToken.global['RC-Gray']['Gray 900'].value,
      1000: themeToken.global['RC-Gray']['Gray 1000'].value,
      1100: themeToken.global['RC-Gray']['Gray 1100'].value,
    },
    action: {
      active: themeToken.global.Action.Active.value,
      hover: themeToken.global.Action.Hover.value,
      selected: themeToken.global.Action.Selected.value,
      disabled: themeToken.global.Action.Disabled.value,
      disabledBackground: themeToken.global.Action['Disabled Background'].value,
      focus: themeToken.global.Action.Focus.value,
    },
    error: {
      main: themeToken.global.Error.Main.value,
      light: themeToken.global.Error.Light.value,
      lighter: '#FEF1F1',
      dark: themeToken.global.Error.Dark.value,
      darker: '#633330',
      contrastText: themeToken.global.Error.Contrast.value,
      states: {
        outlinedRestingBorder:
          themeToken.global.Error.States['Outlined Resting Border'].value,
        outlinedHoverBackground:
          themeToken.global.Error.States['Outlined Hover Background'].value,
      },
    },
    warning: {
      main: themeToken.global.Warning.Main.value,
      light: themeToken.global.Warning.Light.value,
      lighter: '#FFF7EC',
      dark: themeToken.global.Warning.Dark.value,
      darker: '#66481C',
      contrastText: themeToken.global.Warning.Contrast.value,
      states: {
        outlinedRestingBorder:
          themeToken.global.Warning.States['Outlined Resting Border'].value,
        outlinedHoverBackground:
          themeToken.global.Warning.States['Outlined Hover Background'].value,
      },
    },
    info: {
      main: themeToken.global.Info.Main.value,
      light: themeToken.global.Info.Light.value,
      dark: themeToken.global.Info.Dark.value,
      contrastText: themeToken.global.Info.Contrast.value,
      states: {
        outlinedRestingBorder:
          themeToken.global.Info.States['Outlined Resting Border'].value,
        outlinedHoverBackground:
          themeToken.global.Info.States['Outlined Hover Background'].value,
      },
    },
    success: {
      main: themeToken.global.Success.Main.value,
      light: themeToken.global.Success.Light.value,
      lighter: '#F1F9F2',
      dark: themeToken.global.Success.Dark.value,
      darker: '#314F32',
      contrastText: themeToken.global.Success.Contrast.value,
      states: {
        outlinedRestingBorder:
          themeToken.global.Success.States['Outlined Resting Border'].value,
        outlinedHoverBackground:
          themeToken.global.Success.States['Outlined Hover Background'].value,
      },
    },
    background: {
      paper: themeToken.global.Background.Paper.value,
      default: themeToken.global.Background.Default.value,
    },
  },

  shadows: [
    'none',
    getShadowString(themeToken.global.Elevation[1].value),
    getShadowString(themeToken.global.Elevation[2].value),
    getShadowString(themeToken.global.Elevation[3].value),
    getShadowString(themeToken.global.Elevation[4].value),
    getShadowString(themeToken.global.Elevation[5].value),
    getShadowString(themeToken.global.Elevation[6].value),
    getShadowString(themeToken.global.Elevation[7].value),
    getShadowString(themeToken.global.Elevation[8].value),
    getShadowString(themeToken.global.Elevation[9].value),
    getShadowString(themeToken.global.Elevation[10].value),
    getShadowString(themeToken.global.Elevation[11].value),
    getShadowString(themeToken.global.Elevation[12].value),
    getShadowString(themeToken.global.Elevation[13].value),
    getShadowString(themeToken.global.Elevation[14].value),
    getShadowString(themeToken.global.Elevation[15].value),
    getShadowString(themeToken.global.Elevation[16].value),
    getShadowString(themeToken.global.Elevation[17].value),
    getShadowString(themeToken.global.Elevation[18].value),
    getShadowString(themeToken.global.Elevation[19].value),
    getShadowString(themeToken.global.Elevation[20].value),
    getShadowString(themeToken.global.Elevation[21].value),
    getShadowString(themeToken.global.Elevation[22].value),
    getShadowString(themeToken.global.Elevation[23].value),
    getShadowString(themeToken.global.Elevation[24].value),
  ],

  typography: {
    fontFamily: themeToken.global.fontFamilies['ibm-plex-sans'].value,
    fontWeightLight: themeToken.global.fontWeights['ibm-plex-sans-0'].value,
    fontWeightRegular: themeToken.global.fontWeights['ibm-plex-sans-1'].value,
    fontWeightSemiBold: themeToken.global.fontWeights['ibm-plex-sans-2'].value,
    fontWeightMedium: themeToken.global.fontWeights['ibm-plex-sans-3'].value,
    fontSizes: {
      0: themeToken.global.fontSize[0].value,
      1: themeToken.global.fontSize[1].value,
      2: themeToken.global.fontSize[2].value,
      3: themeToken.global.fontSize[3].value,
      4: themeToken.global.fontSize[4].value,
      5: themeToken.global.fontSize[5].value,
      6: themeToken.global.fontSize[6].value,
      7: themeToken.global.fontSize[7].value,
      8: themeToken.global.fontSize[8].value,
      9: themeToken.global.fontSize[9].value,
      10: themeToken.global.fontSize[10].value,
      11: themeToken.global.fontSize[11].value,
    },
    lineHeight: {
      0: themeToken.global.lineHeights[0].value,
      1: themeToken.global.lineHeights[1].value,
      2: themeToken.global.lineHeights[2].value,
      3: themeToken.global.lineHeights[3].value,
      4: themeToken.global.lineHeights[4].value,
      5: themeToken.global.lineHeights[5].value,
      6: themeToken.global.lineHeights[6].value,
      7: themeToken.global.lineHeights[7].value,
      8: themeToken.global.lineHeights[8].value,
      9: themeToken.global.lineHeights[9].value,
      10: themeToken.global.lineHeights[10].value,
      11: themeToken.global.lineHeights[11].value,
      12: themeToken.global.lineHeights[12].value,
      13: themeToken.global.lineHeights[13].value,
      14: themeToken.global.lineHeights[14].value,
      15: themeToken.global.lineHeights[15].value,
      16: themeToken.global.lineHeights[16].value,
      17: themeToken.global.lineHeights[17].value,
      18: themeToken.global.lineHeights[18].value,
    },
    letterSpacing: {
      0: themeToken.global.letterSpacing[0].value,
      1: themeToken.global.letterSpacing[1].value,
      2: themeToken.global.letterSpacing[2].value,
      3: themeToken.global.letterSpacing[3].value,
      4: themeToken.global.letterSpacing[4].value,
      5: themeToken.global.letterSpacing[5].value,
      6: themeToken.global.letterSpacing[6].value,
      7: themeToken.global.letterSpacing[7].value,
      8: themeToken.global.letterSpacing[8].value,
      9: themeToken.global.letterSpacing[9].value,
      10: themeToken.global.letterSpacing[10].value,
      11: themeToken.global.letterSpacing[11].value,
    },
    paragraphSpacing: {
      0: themeToken.global.paragraphSpacing[0].value,
    },
    textCases: {
      none: themeToken.global.textCase.none.value,
      uppercase: themeToken.global.textCase.uppercase.value,
    },
    textDecoration: {
      none: themeToken.global.textDecoration.none.value,
    },
    h1: getTypographyStyles(themeToken.global.Typography.H1.value),

    h2: getTypographyStyles(themeToken.global.Typography.H2.value),
    h3: getTypographyStyles(themeToken.global.Typography.H3.value),
    h4: getTypographyStyles(themeToken.global.Typography.H4.value),
    h5: getTypographyStyles(themeToken.global.Typography.H5.value),
    h5SemiBold: getTypographyStyles(
      themeToken.global.Typography['H5 - Semi - Bold'].value
    ),
    h6: getTypographyStyles(themeToken.global.Typography.H6.value),
    body1: getTypographyStyles(themeToken.global.Typography['Body 1'].value),
    body1Medium: getTypographyStyles(
      themeToken.global.Typography['Body 1 - Medium'].value
    ),
    body2SemiBold: getTypographyStyles(
      themeToken.global.Typography['Body2 Semi-Bold'].value
    ),
    body2: getTypographyStyles(themeToken.global.Typography['Body 2'].value),
    subtitle1: getTypographyStyles(
      themeToken.global.Typography['Subtitle 1'].value
    ),
    subtitle2: getTypographyStyles(
      themeToken.global.Typography['Subtitle 2'].value
    ),
    overline: getTypographyStyles(themeToken.global.Typography.Overline.value),
    caption: getTypographyStyles(themeToken.global.Typography.Caption.value),
    buttonLarge: getTypographyStyles(
      themeToken.global.Components['Button Large'].value
    ),
    buttonMedium: getTypographyStyles(
      themeToken.global.Components['Button Medium'].value
    ),
    buttonSmall: getTypographyStyles(
      themeToken.global.Components['Button Medium'].value
    ),
    inputLabel: getTypographyStyles(
      themeToken.global.Components['Input Label'].value
    ),
    helperText: getTypographyStyles(
      themeToken.global.Components['Helper Text'].value
    ),
    inputText: getTypographyStyles(
      themeToken.global.Components['Input Text'].value
    ),
    avatarInitials: getTypographyStyles(
      themeToken.global.Components['Avatar Initials'].value
    ),
    chip: getTypographyStyles(themeToken.global.Components.Chip.value),
    tooltip: getTypographyStyles(themeToken.global.Components.Tooltip.value),
    alertTitle: getTypographyStyles(
      themeToken.global.Components['Alert Title'].value
    ),
    tableHeader: getTypographyStyles(
      themeToken.global.Components['Table Header'].value
    ),
    badgeLabel: getTypographyStyles(
      themeToken.global.Components['Badge Label'].value
    ),
  },
  components: {
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: themeToken.global.Other['Backdrop Overlay'].value,
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.variant === 'outlined' && {
            '& .MuiOutlinedInput-root': {
              fontFamily:
                themeToken.global.fontFamilies[
                  getStyleValue(
                    themeToken.global.Components['Input Text'].value.fontFamily
                  )
                ].value,
              fontWeight: getFontWeight(
                themeToken.global.fontWeights[
                  getStyleValue(
                    themeToken.global.Components['Input Text'].value.fontWeight
                  )
                ].value
              ),
              fontSize: `${
                themeToken.global.fontSize[
                  getStyleValue(
                    themeToken.global.Components['Input Text'].value.fontSize
                  )
                ].value
              }px`,
              textTransform:
                themeToken.global.textCase[
                  getStyleValue(
                    themeToken.global.Components['Input Text'].value.textCase
                  )
                ].value,
              letterSpacing: `${
                themeToken.global.letterSpacing[
                  getStyleValue(
                    themeToken.global.Components['Input Text'].value
                      .letterSpacing
                  )
                ].value
              }px`,
              '&.Mui-focused fieldset': {
                border: `solid 1px ${themeToken.global.Info.Main.value}`,
              },
            },
            '& .MuiInputLabel-root.Mui-focused': {
              color: themeToken.global.Info.Main.value,
              fontFamily:
                themeToken.global.fontFamilies[
                  getStyleValue(
                    themeToken.global.Components['Input Label'].value.fontFamily
                  )
                ].value,
              fontWeight: getFontWeight(
                themeToken.global.fontWeights[
                  getStyleValue(
                    themeToken.global.Components['Input Label'].value.fontWeight
                  )
                ].value
              ),

              textTransform:
                themeToken.global.textCase[
                  getStyleValue(
                    themeToken.global.Components['Input Label'].value.textCase
                  )
                ].value,
              letterSpacing: `${
                themeToken.global.letterSpacing[
                  getStyleValue(
                    themeToken.global.Components['Input Label'].value
                      .letterSpacing
                  )
                ].value
              }px`,
            },
          }),
          ...(ownerState.variant === 'filled' && {
            backgroundColor:
              themeToken.global.Other['Filled Input Background'].value,
          }),
          ...(ownerState.variant === 'standard' && {
            '& .MuiInput-root:after': {
              borderBottom: `2px solid ${themeToken.global.Other['Standard Input Line'].value}`,
            },
          }),
        }),
      },
    },
    MuiSnackbar: {
      styleOverrides: {
        root: {
          '& .MuiSnackbarContent-root': {
            backgroundColor: themeToken.global.Other.Snackbar.value,
          },
        },
      },
    },
    MuiRating: {
      styleOverrides: {
        root: {
          color: themeToken.global.Other['Rating Active'].value,
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.variant === 'filled' && {
            backgroundColor:
              themeToken.global.Other['Filled Input Background'].value,
          }),
          ...(ownerState.variant === 'outlined' && {
            boxShadow: `${themeToken.global.Elevation.Outlined.value.x}px ${themeToken.global.Elevation.Outlined.value.y}px ${themeToken.global.Elevation.Outlined.value.blur}px ${themeToken.global.Elevation.Outlined.value.spread}px ${themeToken.global.Elevation.Outlined.value.color}`,

            border: `1px solid ${themeToken.global.Other['Outlined Border'].value}`,
          }),
        }),
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          '& .MuiSelect-outlined': {
            boxShadow: `${themeToken.global.Elevation.Outlined.value.x}px ${themeToken.global.Elevation.Outlined.value.y}px ${themeToken.global.Elevation.Outlined.value.blur}px ${themeToken.global.Elevation.Outlined.value.spread}px ${themeToken.global.Elevation.Outlined.value.color}`,
            border: `solid 1px ${themeToken.global.Other['Outlined Border'].value}`,
            '&.Mui-focused fieldset': {
              border: `solid 1px ${themeToken.global.Other['Outlined Border'].value}`,
            },
          },
          '& .MuiSelect-filled': {
            backgroundColor:
              themeToken.global.Other['Filled Input Background'].value,
          },
        },
      },
    },
  },

  // breakpoints defined
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      mobile: 0,
      tablet: 640,
      laptop: 1024,
      desktop: 1200,
    },
  },
});

export default theme;
