import PropTypes from 'prop-types';
import { Box, Stack, Typography, useTheme, Button } from '@mui/material';
import moment from 'moment/moment';
import { useState, useEffect } from 'react';
import { offBoardingDaysToGoSize } from '../../utils/dimensions/size';
import { offBoardingDaysToGoPadding } from '../../utils/dimensions/paddings';
import {
  daysToGoString,
  employeeStatusStrings,
  employeesPageStrings,
} from '../../utils/strings';

function EmployeeOffBoardingDaysToGo({
  setOpenDiscontinuePopup,
  lastDate,
  status,
}) {
  const theme = useTheme();
  const [daysToGo, setDaysToGo] = useState(0);
  const [message, setMessage] = useState('');
  let datToGoSectionBGcolor;
  if (status === employeeStatusStrings.OffBoarded) {
    datToGoSectionBGcolor =
      theme.palette.secondary.states.outlinedRestingBorder;
  } else {
    datToGoSectionBGcolor = theme.palette.primary.light;
  }

  useEffect(() => {
    if (!lastDate) {
      setMessage(daysToGoString.noDateSelectedMessage);
    } else {
      const now = moment().startOf('day');
      const lastDateObj = moment(lastDate);
      const diffInDays = lastDateObj.diff(now, 'day');
      let diffInHours;
      if (diffInDays === 0) {
        let date = lastDate;
        if (!date.includes('T')) {
          date = moment.utc(`${lastDate}T00:00:00.000Z`).toISOString();
        }
        diffInHours = moment()
          .hour(23)
          .minute(59)
          .seconds(59)
          .diff(moment(), 'hours');
      }
      if (diffInDays >= 365) {
        const yearsToGo = Math.floor(diffInDays / 365);
        setDaysToGo(yearsToGo);
        setMessage(
          `${daysToGoString.lastDateMessage[0]} ${moment(lastDate).format(
            'MMM DD, YYYY'
          )} - ${yearsToGo} ${daysToGoString.lastDateMessage[6]}`
        );
      } else {
        setDaysToGo(diffInDays);
        if (lastDateObj) {
          if (status === employeeStatusStrings.OffBoarded) {
            setMessage(`${daysToGoString.lastDateMessage[8]}`);
          } else if (daysToGo > 0) {
            setMessage(
              `${daysToGoString.lastDateMessage[0]} ${moment(lastDate).format(
                'MMM DD, YYYY'
              )} - ${daysToGo} ${daysToGoString.lastDateMessage[1]}`
            );
          } else if (diffInHours > 0) {
            setMessage(
              `${daysToGoString.lastDateMessage[0]} ${moment(lastDate).format(
                'MMM DD, YYYY'
              )} - ${diffInHours}  ${daysToGoString.lastDateMessage[5]}`
            );
          } else {
            setMessage(
              `${daysToGoString.lastDateMessage[2]}  ${moment(lastDate).format(
                'MMM DD, YYYY'
              )}`
            );
          }
        } else {
          setMessage(`${daysToGoString.lastDateMessage[3]}`);
        }
      }
    }
  }, [lastDate, daysToGo, status]);

  return (
    <>
      {(status.toLowerCase() === employeesPageStrings.offBoardedStatus ||
        status.toLowerCase() === employeesPageStrings.offBoardingStatus) && (
        <Box
          sx={{
            textAlign: 'center',
            width: offBoardingDaysToGoSize.DaysToGoBoxWidth,
            height: offBoardingDaysToGoSize.DaysToGoBoxHeight,
            background: datToGoSectionBGcolor,
            display: 'flex',
          }}>
          <Stack
            direction='row'
            spacing={2}
            sx={{
              alignItems: 'center',
              justifyContent: 'center',
              flex: 'auto',
              color: theme.palette.primary.contrastText,
            }}>
            <Typography>{message}</Typography>
            {status !== employeeStatusStrings.OffBoarded && (
              <Button
                onClick={() => {
                  setOpenDiscontinuePopup(true);
                }}>
                <Typography
                  sx={{
                    border: `${offBoardingDaysToGoPadding.editButtonBorder} ${theme.palette.primary.contrastText}`,
                    padding: offBoardingDaysToGoPadding.editButtonPadding,
                    color: theme.palette.primary.contrastText,
                    fontSize: offBoardingDaysToGoSize.EditButtonFontSize,
                  }}>
                  {daysToGoString.lastDateMessage[4]}
                </Typography>
              </Button>
            )}
          </Stack>
        </Box>
      )}
      <div> </div>
    </>
  );
}

EmployeeOffBoardingDaysToGo.propTypes = {
  setOpenDiscontinuePopup: PropTypes.func.isRequired,
  lastDate: PropTypes.string,
  status: PropTypes.string,
};
EmployeeOffBoardingDaysToGo.defaultProps = {
  lastDate: null,
  status: '',
};

export default EmployeeOffBoardingDaysToGo;
