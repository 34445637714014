import { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  ListItemButton,
  ListItemText,
  Collapse,
  Stack,
  Chip,
  Typography,
} from '@mui/material';
import { ExpandMore, ExpandLess } from '@mui/icons-material';
import HardwareSoftwareTable from './HardwareSoftwareTable';
import { resourceSelectMargins } from '../../utils/dimensions/margins';
import { resourceSelectSize } from '../../utils/dimensions/size';
import ApiErrorComponent from '../ApiErrorComponent';
import theme from '../../styles/theme';
import {
  basicInformationStrings,
  offBoardEmployeeStrings,
  branches,
} from '../../utils/strings';

function ResourceSelectComponent({
  softwareDetails,
  softwareFetchError,
  fetchSoftwares,
  handleSelections,
  applyLocationFilter,
  location,
}) {
  const [selectedClientId, setSelectedClientId] = useState(1);

  // count of selected software for each client
  const selectedSoftwareCounts = {};
  const uniqueClients = [
    ...new Map(
      softwareDetails.map((item) => [item.Client.id, item.Client])
    ).values(),
  ];

  uniqueClients.forEach((client) => {
    const clientId = client.id;
    const selectedSoftwareCount = softwareDetails.filter(
      (obj) =>
        location &&
        obj.isSelected &&
        obj.Client.id === clientId &&
        (location.includes(branches.remote) ||
          (location.includes(branches.india) &&
            obj.name.toLowerCase() !==
              basicInformationStrings.preSelectedSoftwares[0].toLowerCase()) ||
          (!location.includes(branches.india) &&
            obj.name.toLowerCase() !==
              basicInformationStrings.preSelectedSoftwares[1].toLowerCase()))
    ).length;
    selectedSoftwareCounts[clientId] = selectedSoftwareCount;
  });
  return (
    <Box
      marginX={resourceSelectMargins.marginX}
      sx={{
        overflow: 'auto',
      }}
      width={resourceSelectSize.width}>
      <Stack>
        {uniqueClients
          .sort((a, b) => {
            if (
              a.name === offBoardEmployeeStrings.clientNameTechholding &&
              b.name !== offBoardEmployeeStrings.clientNameTechholding
            ) {
              return -1; // a should come before b
            }
            if (
              a.name !== offBoardEmployeeStrings.clientNameTechholding &&
              b.name === offBoardEmployeeStrings.clientNameTechholding
            ) {
              return 1; // b should come before a
            }
            return 0;
          })
          .map((client) => {
            const clientId = client.id;
            const isSelected = selectedClientId === clientId;
            const selectedSoftwareCount = selectedSoftwareCounts[clientId];
            const tableData = softwareDetails.filter(
              (software) =>
                software.Client.id === clientId &&
                (!applyLocationFilter ||
                  software.Client.name !==
                    offBoardEmployeeStrings.clientNameTechholding ||
                  (location.includes(branches.india) &&
                    software.name.toLowerCase() !==
                      basicInformationStrings.preSelectedSoftwares[0].toLowerCase()) ||
                  (!location.includes(branches.india) &&
                    software.name.toLowerCase() !==
                      basicInformationStrings.preSelectedSoftwares[1].toLowerCase()) ||
                  location.includes(branches.remote))
            );
            return (
              <Box key={clientId} sx={{ marginBottom: 2 }}>
                <ListItemButton
                  onClick={() =>
                    setSelectedClientId(
                      clientId === selectedClientId ? null : clientId
                    )
                  }
                  sx={{
                    justifyContent: 'space-between',
                    bgcolor: theme.palette.grey[50],
                  }}>
                  <ListItemText
                    primary={client.name}
                    secondary={`${tableData.length} Software`}
                    primaryTypographyProps={{
                      ...theme.typography.body2SemiBold,
                    }}
                    secondaryTypographyProps={{
                      ...theme.typography.caption,
                    }}
                  />

                  {selectedSoftwareCount ? (
                    <Chip
                      label={
                        <Typography
                          color={theme.palette.grey[1000]}
                          variant='chip'>
                          {`${selectedSoftwareCount} Selected`}
                        </Typography>
                      }
                      sx={{ marginRight: '30px' }}
                    />
                  ) : null}
                  {isSelected ? (
                    <ExpandLess sx={{ marginRight: '5px' }} />
                  ) : (
                    <ExpandMore sx={{ marginRight: '5px' }} />
                  )}
                </ListItemButton>
                <Collapse in={isSelected} timeout='auto' unmountOnExit>
                  <HardwareSoftwareTable
                    showHeader={false}
                    onCheckBoxClick={handleSelections}
                    tableData={tableData}
                  />
                </Collapse>
              </Box>
            );
          })}
        {softwareFetchError && (
          <Box paddingTop='200px'>
            <ApiErrorComponent onRefreshClick={fetchSoftwares} />
          </Box>
        )}
      </Stack>
    </Box>
  );
}

ResourceSelectComponent.propTypes = {
  softwareDetails: PropTypes.arrayOf(
    PropTypes.shape({ isSelected: PropTypes.bool })
  ),
  fetchSoftwares: PropTypes.func,

  softwareFetchError: PropTypes.bool,
  handleSelections: PropTypes.func.isRequired,
  applyLocationFilter: PropTypes.bool.isRequired,
  location: PropTypes.string,
};
ResourceSelectComponent.defaultProps = {
  fetchSoftwares: () => {},
  softwareFetchError: false,
  location: '',
  softwareDetails: [],
};

export default ResourceSelectComponent;
