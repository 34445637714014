import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Box, Stack, TextField, Typography, useTheme } from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import CustomModal from '../CustomModal';
import { ReactComponent as DiscontinuePopupIcon } from '../../utils/assets/DiscontinuePopupIcon.svg';
import {
  customDrawerStrings,
  employeeDetailsPageStrings,
  offBoardEmployeePopupString,
} from '../../utils/strings';
import { offBoardEmployeePopupSizes } from '../../utils/dimensions/size';
import { offBoardEmployeePopupMargins } from '../../utils/dimensions/margins';
import getLoggedInUser from '../../utils/getLoggedInUser';

const moment = require('moment');

function EmployeeOffBoardingPopup({
  openDiscontinuePopup,
  setOpenDiscontinuePopup,
  lastDate,
  setEmployeeDetails,
  employeeDetails,
  updateUserStatus,
  setHistoryData,
}) {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const endDateInitialState = lastDate
    ? new Date(lastDate).toISOString()
    : new Date().toISOString();
  const [endDate, setEndDate] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isCorrectDate, setIsCorrectDate] = useState(true);
  const user = getLoggedInUser(navigate, location);
  const [updateStatusError, setUpdateStatusError] = useState(false);
  const [isNewDateSameAsOldDate, setIsNewDateSameAsOldDate] = useState(
    Boolean(lastDate)
  );
  const [errorMessage, setErrorMessage] = useState(
    customDrawerStrings.defaultErrorMessage
  );

  // updates the endDate state variable whenever the lastDate prop value changes,
  useEffect(() => {
    setEndDate(
      lastDate ? new Date(lastDate).toISOString() : new Date().toISOString()
    );
    setIsNewDateSameAsOldDate(Boolean(lastDate));
  }, [lastDate]);

  const handleDateChange = (newValue) => {
    const currentDate = new Date();
    if (newValue) {
      const newDate = newValue.$d;
      // selected date will have the same time as the current time ,using current time from currentdate
      newDate.setHours(currentDate.getHours());
      newDate.setMinutes(currentDate.getMinutes());
      newDate.setSeconds(currentDate.getSeconds());
      setIsCorrectDate(Date.parse(newDate));
      setIsNewDateSameAsOldDate(
        lastDate &&
          new Date(newValue.$d).getDate() ===
            new Date(endDateInitialState).getDate() &&
          new Date(newValue.$d).getMonth() ===
            new Date(endDateInitialState).getMonth() &&
          new Date(newValue.$d).getFullYear() ===
            new Date(endDateInitialState).getFullYear()
      );
      setEndDate(newDate);
    } else {
      setIsCorrectDate(null);
      setEndDate(null);
    }
  };

  const onSuccessButtonClick = async () => {
    setIsLoading(true);
    const response = await updateUserStatus({
      status:
        endDate === null
          ? employeeDetailsPageStrings.employeeStatus.active.toLowerCase()
          : employeeDetailsPageStrings.employeeStatus.OffBoarding.toLowerCase(),
      endDate: endDate
        ? moment(endDate).utc().format('YYYY-MM-DD HH:mm:ssZZ')
        : null,
    });

    setUpdateStatusError(null);
    if (response.error) {
      setUpdateStatusError(true);
      setErrorMessage(customDrawerStrings.defaultErrorMessage);
    } else {
      setUpdateStatusError(false);

      setHistoryData((prev) => [
        {
          description:
            employeeDetails.status ===
            employeeDetailsPageStrings.employeeStatus.active
              ? `Off-boarding started for ${employeeDetails.first_name} ${employeeDetails.last_name} by ${user.name}`
              : `Last date updated to ${moment(endDate).format('MMM Do YYYY')}
           from ${moment(endDateInitialState).format('MMM Do YYYY')}
           for ${employeeDetails.first_name} ${employeeDetails.last_name} by ${
                  user.name
                }`,
          timestamp: Date.now(),
        },
        ...prev,
      ]);

      if (employeeDetails.end_date === null) {
        setHistoryData((prev) => [
          {
            description: `Last date  ${moment(endDate).format(
              'MMM Do YYYY'
            )} added for ${employeeDetails.first_name} ${
              employeeDetails.last_name
            } by ${user.name}`,
            timestamp: Date.now(),
          },
          ...prev,
        ]);
      }
      setEmployeeDetails({
        ...employeeDetails,
        status:
          endDate === null
            ? employeeDetailsPageStrings.employeeStatus.active
            : employeeDetailsPageStrings.employeeStatus.OffBoarding,
        end_date: moment(endDate).utc().format('YYYY-MM-DD HH:mm:ssZZ'),
      });
      setOpenDiscontinuePopup(false);
    }
    setIsLoading(false);
  };

  return (
    <CustomModal
      errorMessage={errorMessage}
      setErrorMessage={setErrorMessage}
      open={openDiscontinuePopup}
      successButtonLoading={updateStatusError === null || isLoading}
      successButtonDisabled={
        endDate === null ||
        isNewDateSameAsOldDate ||
        Number.isNaN(isCorrectDate)
      }
      error={updateStatusError}
      successButtonText={offBoardEmployeePopupString.successButtonText}
      failureButtonText={offBoardEmployeePopupString.failureButtonText}
      onFailureButtonClick={() => {
        setIsNewDateSameAsOldDate(Boolean(lastDate));
        setIsCorrectDate(true);
        setOpenDiscontinuePopup(false);
        setEndDate(endDateInitialState);
      }}
      onSuccessButtonClick={() => {
        onSuccessButtonClick();
      }}>
      <Box
        height={offBoardEmployeePopupSizes.contentBoxHeight}
        width={offBoardEmployeePopupSizes.contentBoxWidth}>
        <Stack display='flex'>
          <Box textAlign='center'>
            <DiscontinuePopupIcon />
          </Box>
          <Box
            height={offBoardEmployeePopupSizes.contentBoxTextPaddingHeight}
          />
          <Box textAlign='center'>
            <Typography variant='h5'>
              {offBoardEmployeePopupString.popupMessage[0]}
              <Typography
                variant='h5SemiBold'
                sx={{
                  display: 'inline',
                }}>
                {`${employeeDetails.first_name} ${employeeDetails.last_name}`}
              </Typography>
              {offBoardEmployeePopupString.popupMessage[1]}
            </Typography>
          </Box>
        </Stack>
        <Stack>
          <Box
            height={offBoardEmployeePopupSizes.contentBoxDatePaddingHeight}
          />
          <Box sx={{ display: 'grid' }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                label={offBoardEmployeePopupString.DesktopDatePickerLabel}
                value={endDate}
                InputProps={{ readOnly: true }}
                initialFocusedDate={new Date()}
                onChange={handleDateChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    helperText={isCorrectDate ? '' : 'Invalid Date'}
                    sx={{
                      marginTop:
                        offBoardEmployeePopupMargins.textFieldDateTopMargin,
                      marginRight:
                        offBoardEmployeePopupMargins.textFieldDateRightMargin,
                      '& .MuiOutlinedInput-root': {
                        borderRadius: theme.shape.textFieldBorderRadius,
                        '&.Mui-focused fieldset': {
                          border: `solid 1px ${
                            !isCorrectDate
                              ? theme.palette.error.main
                              : theme.palette.info.main
                          }`,
                        },
                      },
                      '& .MuiInputLabel-root.Mui-focused': {
                        color: !isCorrectDate
                          ? theme.palette.error.main
                          : theme.palette.info.main,
                      },
                    }}
                  />
                )}
              />
            </LocalizationProvider>
          </Box>
        </Stack>
      </Box>
    </CustomModal>
  );
}

EmployeeOffBoardingPopup.propTypes = {
  openDiscontinuePopup: PropTypes.bool.isRequired,
  setOpenDiscontinuePopup: PropTypes.func.isRequired,
  setEmployeeDetails: PropTypes.func.isRequired,
  lastDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  employeeDetails: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    status: PropTypes.string,
    end_date: PropTypes.string,
  }),
  updateUserStatus: PropTypes.func.isRequired,
  setHistoryData: PropTypes.func.isRequired,
};
EmployeeOffBoardingPopup.defaultProps = {
  employeeDetails: {
    first_name: '',
    last_name: '',
  },
  lastDate: '',
};
export default EmployeeOffBoardingPopup;
