import pathStrings from '../utils/pathStrings';

const redirectPaths = {
  homePath: {
    from: pathStrings.homePagePath,
    to: pathStrings.employeePagePath,
  },
  // page not found page still remaining ro develop.
  pageNotFoundPath: {
    from: pathStrings.notDefinedRoutePath,
    to: pathStrings.employeePagePath,
  },

  signinPath: {
    to: pathStrings.signInPagePath,
  },
  ApproveEmployeePath: {
    to: pathStrings.ApproveEmployeePagePath,
  },
  employeeDetailsPath: {
    to: pathStrings.employeeDetailsPageRedirectPath,
  },
};

export default redirectPaths;
