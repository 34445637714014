import { Box, Typography, useTheme } from '@mui/material';
import PropTypes from 'prop-types';

import { deleteHardwareMargins } from '../../utils/dimensions/margins';
import { deleteHardwareSize } from '../../utils/dimensions/size';
import { deleteHardwareStrings } from '../../utils/strings';
import CustomDrawer from '../CustomDrawer';
import CustomTextField from '../Inputs/CustomTextField';

function DeleteHardwareDrawer({ toggleDrawer, setToggleDrawer }) {
  const theme = useTheme();
  return (
    <Box>
      <CustomDrawer
        title={deleteHardwareStrings.title}
        open={toggleDrawer}
        onFailureButtonClick={() => {
          setToggleDrawer(false);
        }}
        onSuccessButtonClick={() => {}}
        onClose={() => {
          setToggleDrawer(false);
        }}
        successButtonText={deleteHardwareStrings.successButtonText}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            margin: deleteHardwareMargins.margin,
          }}>
          <Typography variant='h6' color={theme.palette.text.primary}>
            {deleteHardwareStrings.name}
          </Typography>
          <Box height={deleteHardwareSize.commonVerticalSpace} />
          <Typography variant='body1' color={theme.palette.grey[900]}>
            {deleteHardwareStrings.serialNumberLabel}
            {deleteHardwareStrings.serailNumber}
          </Typography>
          <Box height={deleteHardwareSize.commonVerticalSpace} />
          <CustomTextField
            multiline
            onChange={() => {}}
            rows={2}
            placeHolder={deleteHardwareStrings.notes}
          />
        </Box>
      </CustomDrawer>
    </Box>
  );
}

DeleteHardwareDrawer.propTypes = {
  toggleDrawer: PropTypes.bool.isRequired,
  setToggleDrawer: PropTypes.func.isRequired,
};

export default DeleteHardwareDrawer;
