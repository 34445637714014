import { useCallback, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { Stack, Typography, Box, Container } from '@mui/material';
import PropTypes from 'prop-types';
import CustomDrawer from '../CustomDrawer';
import CustomListExpandContainer from '../CustomListExpandContainer';
import headCells from './OffBoardSoftwareTable';
import {
  employeeDetailsPageStrings,
  momentStrings,
  offBoardEmployeeStrings,
  snackbarStrings,
  softwareStatusStrings,
} from '../../utils/strings';
import { offBoardEmployeeDrawerMargins } from '../../utils/dimensions/margins';
import networkClient from '../../networks/networkClient';
import { methodTypes, networkEndpoints } from '../../networks/networkStrings';
import makeFirstLetterCapital from '../../utils/makeFirstLetterCapital';
import { offBoardEmployeeDrawerSizes } from '../../utils/dimensions/size';
import { handle403Error } from '../../utils/errorHandler';
import CustomFilledIconButton from '../CustomFilledIconButton';
import resendMailClient from '../../utils/resendEmailClient';
import { snackbarContext } from '../../utils/context';
import getLoggedInUser from '../../utils/getLoggedInUser';
import CircularProgressIndicator from '../CircularProgressIndicator';

function OffBoardEmployeeDrawer({
  toggleDrawer,
  setToggleDrawer,
  updateUserStatus,
  userId,
  setOpenSuccesModal,
  setHistoryData,
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const { showSnackbar } = useContext(snackbarContext);
  const tableDataInitialState = null;
  const [updateStatusError, setUpdateStatusError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [revokeButtonLoading, setRevokeButtonLoading] = useState(false);
  const { userId: currentUserId, role: currentUserRole } = getLoggedInUser(
    navigate,
    location
  );

  const [disableOffBoarding, setDisableOffBoarding] = useState(true);
  const [tableData, setTableData] = useState(tableDataInitialState);
  const [isSoftwaresLoading, setIsSoftwaresLoading] = useState(false);

  const timegGapInHour = process.env.REACT_APP_TIME_BETWEEN_MAILS || 24;

  const handleAllrevokeRequest = async () => {
    setRevokeButtonLoading(true);
    // find userSoftwares for which we need to send all revoke mail
    const userSoftwareIds = tableData
      .filter((userSoftware) => {
        if (
          userSoftware.status === softwareStatusStrings.active &&
          !userSoftware.manager.includes(userId) &&
          (!userSoftware.lastEmailDate ||
            moment().diff(
              userSoftware.lastEmailDate,
              momentStrings.timeUnits.hours
            ) >= timegGapInHour)
        ) {
          // eslint-disable-next-line
          userSoftware.lastEmailDate = moment();
          return true;
        }
        return false;
      })
      .map((userSoftware) => userSoftware.id);

    if (userSoftwareIds.length) {
      await resendMailClient({
        userSoftware: userSoftwareIds,
        location,
        navigate,
        showSnackbar,
      });
    } else {
      showSnackbar(
        snackbarStrings.messages.warning.alreadySent,
        snackbarStrings.variant.warning
      );
    }
    setRevokeButtonLoading(false);
  };

  // method for fetch all softwares.
  const fetchAllSoftwares = useCallback(async () => {
    setIsSoftwaresLoading(true);
    const softwareData = await networkClient({
      method: methodTypes.get,
      endpoint: `${networkEndpoints.user.user}${userId}${networkEndpoints.software.getSoftwares}`,
    });
    if (softwareData.error) {
      setIsSoftwaresLoading(false);
      setTableData(null);
      if (softwareData.errors[0].code === 403) {
        handle403Error(navigate, location);
      }
    } else {
      // set format of software response.
      const softwares = softwareData.data.map((data) => ({
        id: data.id,
        image: data.Software.icon,
        name: data.Software.name,
        client: data.Software.Client.name,
        status: makeFirstLetterCapital(data.status),
        manager: data.Software.managed_by,
        lastEmailDate: data.last_email_date,
      }));

      // sort data based on client name. tech holding client gets renedered first.
      softwares.sort((a, b) => {
        if (
          a.client === offBoardEmployeeStrings.clientNameTechholding &&
          b.client !== offBoardEmployeeStrings.clientNameTechholding
        ) {
          return -1;
        }
        if (
          a.client !== offBoardEmployeeStrings.clientNameTechholding &&
          b.client === offBoardEmployeeStrings.clientNameTechholding
        ) {
          return 1;
        }
        if (a.client === b.client) {
          return 0;
        }
        // Sort other clients dictonarry wise  using string comparison
        return a.client < b.client ? -1 : 1;
      });

      // managing status for each softwares
      setTableData(softwares);
      setIsSoftwaresLoading(false);
    }
  }, [userId, navigate, location]);

  // method for success button click event.
  const onSuccessButtonClick = async () => {
    setLoading(true);
    const response = await updateUserStatus({
      status:
        employeeDetailsPageStrings.employeeStatus.OffBoarded.toLowerCase(),
    });
    setUpdateStatusError(null);

    if (response.error) {
      setUpdateStatusError(true);
      setTimeout(() => {
        setUpdateStatusError(false);
      }, 3000);
    } else {
      setUpdateStatusError(false);
      setOpenSuccesModal(true);
      setToggleDrawer(false);
    }
    setLoading(false);
  };

  // method for close event.
  const onCloseEvent = () => {
    setToggleDrawer(false);
    setTableData(tableDataInitialState);
  };
  // fetch softwares when drawer opens.
  useEffect(() => {
    if (toggleDrawer) fetchAllSoftwares();
  }, [toggleDrawer, fetchAllSoftwares]);

  // reset complete offboarding button when tabledata changes.
  useEffect(() => {
    if (tableData) {
      // set complete offboard button enable or disable
      setDisableOffBoarding(
        tableData.some((obj) => obj.status === softwareStatusStrings.active)
      );
    }
  }, [tableData]);

  return (
    <CustomDrawer
      open={toggleDrawer}
      error={updateStatusError}
      title={offBoardEmployeeStrings.addTitle}
      successButtonLoading={updateStatusError === null || loading}
      successButtonDisabled={currentUserRole > 2 || disableOffBoarding}
      onSuccessButtonClick={onSuccessButtonClick}
      successButtonText={offBoardEmployeeStrings.submitButtonText}
      failureButtonText={offBoardEmployeeStrings.backButtonText}
      onFailureButtonClick={onCloseEvent}
      onClose={onCloseEvent}>
      <Container disableGutters>
        <Box
          marginX={offBoardEmployeeDrawerMargins.marginX}
          marginTop={offBoardEmployeeDrawerMargins.marginTop}
          display='flex'
          flexDirection='column'>
          <Stack
            flexDirection='row'
            justifyContent='space-between'
            marginBottom={offBoardEmployeeDrawerMargins.marginBottom}>
            <Typography variant='h6'>
              {offBoardEmployeeStrings.subTitle}
            </Typography>
            <Box alignItems='flex-end'>
              {revokeButtonLoading ? (
                <Box display='flex' alignItems='center' marginRight={8}>
                  <CircularProgressIndicator />
                </Box>
              ) : (
                <CustomFilledIconButton
                  disabled={
                    revokeButtonLoading ||
                    !tableData ||
                    tableData.filter(
                      (userSoftware) =>
                        userSoftware.status === softwareStatusStrings.active &&
                        !userSoftware.manager.includes(currentUserId)
                    ).length <= 0
                  }
                  text={offBoardEmployeeStrings.allRevokeRequestButtonText}
                  height={
                    offBoardEmployeeDrawerSizes.allRevokeRequestButtonHeight
                  }
                  onClick={handleAllrevokeRequest}
                />
              )}
            </Box>
          </Stack>
          <CustomListExpandContainer
            tableData={tableData}
            tableHeadData={headCells}
            setIsError={setUpdateStatusError}
            setTableData={setTableData}
            isSoftwaresLoading={isSoftwaresLoading}
            fetchAllSoftwares={fetchAllSoftwares}
            setHistoryData={setHistoryData}
          />
        </Box>
      </Container>
    </CustomDrawer>
  );
}
OffBoardEmployeeDrawer.propTypes = {
  userId: PropTypes.string.isRequired,
  toggleDrawer: PropTypes.bool.isRequired,
  setToggleDrawer: PropTypes.func.isRequired,
  updateUserStatus: PropTypes.func.isRequired,
  setOpenSuccesModal: PropTypes.func,
  setHistoryData: PropTypes.func.isRequired,
};
OffBoardEmployeeDrawer.defaultProps = {
  setOpenSuccesModal: true,
};

export default OffBoardEmployeeDrawer;
