import { Edit } from '@mui/icons-material';
import { Box, Stack, Typography, useTheme } from '@mui/material';
import { useCallback, useContext, useEffect, useState } from 'react';
import moment from 'moment';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import CustomOutlinedIconButton from '../../components/CustomOutlinedIconButton';
import DetailsCard from '../../components/DetailsCard';
import AddSoftwareDrawer from '../../components/SoftwareActions/AddSoftwareDrawer';
import DeleteSoftwareDrawer from '../../components/SoftwareActions/DeleteSoftwareDrawer';
import SoftwareNotesDrawer from '../../components/SoftwareActions/SoftwareNotesDrawer';
import CustomTable from '../../components/Table/CustomTable';
import {
  commonPagePaddings,
  softwareDetailsPadding,
} from '../../utils/dimensions/paddings';
import {
  detailsPageSize,
  softwareDetailsPageSize,
} from '../../utils/dimensions/size';
import {
  addSoftwareStrings,
  customTableStrings,
  paginationStrings,
  softwareDetailsPageStrings,
  softwareDetailsPageTopStrings,
} from '../../utils/strings';
import SoftwareDetailContent from './SoftwareDetailContent';
import headCells from './SoftwareDetailsTableData';
import CustomTableRow from '../../components/Table/CustomTableRow';
import { getComparator, stableSort } from '../../utils/sortingHelpers';
import networkClient from '../../networks/networkClient';
import CustomModal from '../../components/CustomModal';
import { methodTypes, networkEndpoints } from '../../networks/networkStrings';
import endpointParamsSetter from '../../utils/endpointParamsSetter';
import CircularProgressIndicator from '../../components/CircularProgressIndicator';
import ApiErrorComponent from '../../components/ApiErrorComponent';
import { handle403Error } from '../../utils/errorHandler';
import formatTime from '../../utils/formatDate';
import { softwareDetailsMargins } from '../../utils/dimensions/margins';
import makeFirstLetterCapital from '../../utils/makeFirstLetterCapital';
import { snackbarContext, paginationContext } from '../../utils/context';
import DefaultSoftwareFavicon from '../../utils/assets/DefaultSoftwareFavicon.svg';
import ErrorMessagePage from '../ErrorMessageScreen/ErrorMessagePage';
import pathStrings from '../../utils/pathStrings';

function SoftwareDetailsPage() {
  const theme = useTheme();
  const params = useParams();
  const { softwareId } = params;
  const location = useLocation();
  const navigate = useNavigate();
  const { getRowsPerPage, updateRowsPerPage, getPageNo, updatePageNo } =
    useContext(paginationContext);

  const rowsPerPage = getRowsPerPage(paginationStrings.userSoftwareListTable);
  const setRowsPerPage = (value) => {
    updateRowsPerPage(paginationStrings.userSoftwareListTable, value);
  };

  const page = getPageNo(paginationStrings.userSoftwareListTable);
  const setPage = (value) => {
    updatePageNo(paginationStrings.userSoftwareListTable, value);
  };

  const [toggleEditDrawer, setToggleEditDrawer] = useState(false);
  const [toggleDeleteDrawer, setToggleDeleteDrawer] = useState(false);
  const [notesToggleDrawer, setNotesToggleDrawer] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isSoftwareFetchError, setSoftwareFetchError] = useState(false);
  const [isUserFetchError, setIsUserFetchError] = useState(false);
  const { showSnackbar } = useContext(snackbarContext);
  const [conformationModal, setConformationModal] = useState(false);
  const [sortOrderBy, setSortOrderBy] = useState(headCells[2].id);
  const [sortOrder, setSortOrder] = useState(
    customTableStrings.sortOrder.ascending
  );
  const [softwareDetailData, setSoftwareDetailData] = useState(null);
  const [tableData, setTableData] = useState(null);
  const [softwareDrawerNotesData, setSoftwareDrawerNotesData] = useState(null);
  const [is404Error, setIs404Error] = useState(false);

  const onViewNoteClick = useCallback(
    (data) => {
      setNotesToggleDrawer(true);
      setSoftwareDrawerNotesData({
        ...data,
        imageUrl: softwareDetailData?.icon,
        name: softwareDetailData?.name,
      });
    },
    [
      setNotesToggleDrawer,
      setSoftwareDrawerNotesData,
      softwareDetailData?.icon,
      softwareDetailData?.name,
    ]
  );

  const fetchUserData = useCallback(async () => {
    setIsUserFetchError(null);
    const softwareUsersResponse = await networkClient({
      method: methodTypes.get,
      endpoint: endpointParamsSetter({
        path: networkEndpoints.userSoftware.usersFormSoftwareId,
        params: softwareId,
      }),
    });
    if (softwareUsersResponse.error) {
      setIsUserFetchError(true);
      if (softwareUsersResponse.error) {
        if (softwareUsersResponse.errors[0].code === 403) {
          handle403Error(navigate, location);
        } else if (
          softwareUsersResponse.errors[0].code === 404 ||
          softwareUsersResponse.errors[0].code === 422
        ) {
          setIs404Error(true);
        }
      }
    } else {
      const formattedDataTable = softwareUsersResponse.data.map(
        (employeeData) => ({
          nameData: {
            userId: employeeData.User.id,
            imageUrl: '',
            name: `${employeeData.User.first_name} ${employeeData.User.last_name}`,
            position: employeeData.User.job_title,
          },
          assignDate: employeeData.assign_date,
          status: makeFirstLetterCapital(employeeData.status),

          view: {
            buttonText: 'View',
            onButtonClick: onViewNoteClick,
            data: {
              userName: `${employeeData.User.first_name} ${employeeData.User.last_name}`,
              userJobTitle: employeeData.User.job_title,
              assignDate: employeeData.assign_date
                ? moment(employeeData.assign_date).format('MMM D, YYYY')
                : '',
              notes: employeeData.note,
            },
          },
        })
      );
      const sortedData = formattedDataTable.sort((a, b) => {
        if (a.status < b.status) {
          return -1;
        }
        if (a.status > b.status) {
          return 1;
        }
        return 0;
      });

      setTableData(sortedData);

      setIsUserFetchError(false);
    }
  }, [softwareId, navigate, onViewNoteClick, location]);

  const fetchSoftwareData = useCallback(async () => {
    setSoftwareFetchError(null);

    const softwareDetailResponse = await networkClient({
      method: methodTypes.get,
      endpoint: endpointParamsSetter({
        path: networkEndpoints.software.getSoftwareDetail,
        params: softwareId,
      }),
    });
    if (softwareDetailResponse.error) {
      setSoftwareDetailData(null);
      setSoftwareFetchError(true);

      if (softwareDetailResponse.error) {
        if (softwareDetailResponse.errors[0].code === 403) {
          handle403Error(navigate, location);
        } else if (softwareDetailResponse.errors[0].code === 404) {
          setIs404Error(true);
        }
      }
    } else {
      const softwareData = softwareDetailResponse.data;
      const formattedDataSoftware = {
        url: softwareData.url,
        name: softwareData.name,
        icon: softwareData.icon,
        createdAt: softwareData.createdAt,
        updatedAt: softwareData.updatedAt,
        status: softwareData.status,
        client: softwareData.Client.name,
        clientId: softwareData.Client.id,
        description: softwareData.description,
        managers: softwareData.Managers.map((manager, index) => ({
          id: index + 1,
          value: {
            id: manager.id,
            name: `${manager.first_name} ${manager.last_name}`,
            email: manager.company_email,
            selected: true,
          },
          error: false,
          errorMessage: addSoftwareStrings.errorMessages.required,
        })),
      };
      setSoftwareDetailData(formattedDataSoftware);
      setSoftwareFetchError(false);
    }
  }, [softwareId, navigate, location]);

  const onUserTableRowClick = (index) => {
    navigate(
      endpointParamsSetter({
        path: pathStrings.employeeDetailsPagePath,
        params: index,
      })
    );
  };

  useEffect(() => {
    fetchUserData();
  }, [fetchUserData]);

  useEffect(() => {
    fetchSoftwareData();
  }, [fetchSoftwareData]);

  const handleClick = async () => {
    setIsLoading(true);

    const data = await networkClient({
      method: methodTypes.patch,
      endpoint: endpointParamsSetter({
        path: networkEndpoints.software.updateSoftware,
        params: softwareId,
      }),
      requestBody: {
        status:
          softwareDetailData.status === softwareDetailsPageStrings.activeStatus
            ? softwareDetailsPageStrings.inactiveStatus
            : softwareDetailsPageStrings.activeStatus,
      },
    });

    setIsLoading(false);
    if (data.error) {
      setIsError(true);
    } else {
      setSoftwareDetailData((prevData) => ({
        ...prevData,
        status:
          softwareDetailData.status === softwareDetailsPageStrings.activeStatus
            ? softwareDetailsPageStrings.inactiveStatus
            : softwareDetailsPageStrings.activeStatus,
        updatedAt: moment(),
      }));
      showSnackbar(softwareDetailsPageStrings.snackbarSuccessText);

      setIsError(false);
      setConformationModal(false);
    }
  };
  if (!is404Error) {
    return (
      <>
        {isSoftwareFetchError === null && (
          <Box
            display='flex'
            flex={1}
            alignItems='center'
            justifyContent='center'
            height='100vh'>
            <CircularProgressIndicator />
          </Box>
        )}
        {isSoftwareFetchError && (
          <Box
            display='flex'
            flex={1}
            alignItems='center'
            justifyContent='center'
            height='100vh'>
            <ApiErrorComponent onRefreshClick={() => fetchSoftwareData()} />
          </Box>
        )}
        {!isSoftwareFetchError && softwareDetailData && (
          <Box
            sx={{
              height: softwareDetailsPageSize.rootBoxHeight,
              display: 'flex',
              flexDirection: 'column',
            }}>
            <Stack
              direction='row'
              justifyContent='space-between'
              padding={commonPagePaddings.pagePadding}
              sx={{
                backgroundColor: theme.palette.common.white,
              }}>
              <Stack>
                <Stack direction='row' alignItems='center'>
                  {softwareDetailData?.icon ? (
                    <Box
                      height={detailsPageSize.iconSize}
                      width={detailsPageSize.iconSize}
                      component='img'
                      src={softwareDetailData?.icon}
                      onError={(e) => {
                        e.target.src = DefaultSoftwareFavicon;
                        e.onerror = null;
                      }}
                    />
                  ) : (
                    <img
                      height={detailsPageSize.iconSize}
                      width={detailsPageSize.iconSize}
                      src={DefaultSoftwareFavicon}
                      style={{ objectFit: 'contain' }}
                      alt='img'
                    />
                  )}
                  <Box width={detailsPageSize.spaceBetweenTitleAndSubtitle} />
                  <Typography variant='h4' color={theme.palette.grey[1100]}>
                    {softwareDetailData?.name}
                  </Typography>
                </Stack>
                <Box height={detailsPageSize.spaceBetweenTitleAndSubtitle} />
                <Box display='flex'>
                  <Typography
                    variant='body1'
                    marginRight={softwareDetailsMargins.timeMarginright}
                    color={theme.palette.grey[300]}>
                    {softwareDetailsPageTopStrings.addedSoftwareText}
                    {formatTime(softwareDetailData.createdAt, true)}
                  </Typography>
                  <Typography variant='body1' color={theme.palette.grey[300]}>
                    {softwareDetailsPageTopStrings.lastEditSoftwareText}
                    {moment(softwareDetailData.updatedAt).fromNow()}
                  </Typography>
                </Box>
              </Stack>
              <CustomOutlinedIconButton
                textColor={theme.palette.primary.main}
                borderColor={theme.palette.primary.states.outlinedRestingBorder}
                onClick={() => {
                  setConformationModal(true);
                }}>
                {`${softwareDetailsPageStrings.deleteButtonText} ${
                  softwareDetailData.status ===
                  softwareDetailsPageStrings.activeStatus
                    ? softwareDetailsPageStrings.inactiveStatus
                    : softwareDetailsPageStrings.activeStatus
                }`}
              </CustomOutlinedIconButton>
            </Stack>

            <Box
              maxHeight={softwareDetailsPageSize.tableBoxHeight}
              overflow='auto'
              padding={commonPagePaddings.pagePadding}
              display='flex'>
              <Box
                maxHeight='100%'
                display='flex'
                height='fit-content'
                flex={3}>
                <CustomTable
                  page={page}
                  setPage={setPage}
                  sortOrderBy={sortOrderBy}
                  setSortOrderBy={setSortOrderBy}
                  sortOrder={sortOrder}
                  setSortOrder={setSortOrder}
                  rowsPerPage={rowsPerPage}
                  setRowsPerPage={setRowsPerPage}
                  tableData={tableData || []}
                  isError={isUserFetchError}
                  isLoading={isUserFetchError === null}
                  onTryAgainClick={fetchUserData}
                  tableHeadData={headCells}>
                  {!isUserFetchError &&
                    tableData &&
                    stableSort(
                      tableData,
                      getComparator(sortOrder, sortOrderBy),
                      sortOrderBy
                    )
                      .slice(
                        rowsPerPage < 0 ? 0 : page * rowsPerPage,
                        rowsPerPage < 0
                          ? undefined
                          : page * rowsPerPage + rowsPerPage
                      )
                      .map((row) => (
                        <CustomTableRow
                          key={JSON.stringify(row)}
                          row={row}
                          tableHeadData={headCells}
                          onRowClick={() =>
                            onUserTableRowClick(row.nameData.userId)
                          }
                        />
                      ))}
                </CustomTable>
              </Box>

              <Box width={detailsPageSize.spaceBetweenCards} />
              <Box
                flex={1}
                maxWidth={softwareDetailsPageSize.detailsBoxMaxWidth}
                sx={{ overflowWrap: 'break-word' }}>
                <DetailsCard
                  icon={<Edit />}
                  title={softwareDetailsPageStrings.detailsTitleText}
                  buttonText={softwareDetailsPageStrings.editButtonText}
                  onClick={() => {
                    setToggleEditDrawer(true);
                  }}>
                  <SoftwareDetailContent softwareDetails={softwareDetailData} />
                </DetailsCard>
              </Box>
            </Box>

            <AddSoftwareDrawer
              toggleDrawer={toggleEditDrawer}
              setToggleDrawer={setToggleEditDrawer}
              isEdit
              softwareId={softwareId}
              title={addSoftwareStrings.editTitle}
              defaultData={softwareDetailData}
              setSoftwareData={setSoftwareDetailData}
              setTableData={setTableData}
            />

            <DeleteSoftwareDrawer
              toggleDeleteDrawer={toggleDeleteDrawer}
              setToggleDeleteDrawer={setToggleDeleteDrawer}
              defaultData={{
                softwareName: softwareDetailsPageStrings.pageTitleText,
                companyName: softwareDetailsPageStrings.pageCompanyName,
                softwareImageUrl: softwareDetailsPageStrings.pageTitleImageUrl,
              }}
            />

            <SoftwareNotesDrawer
              toggleDrawer={notesToggleDrawer}
              setToggleDrawer={setNotesToggleDrawer}
              data={softwareDrawerNotesData}
            />

            <CustomModal
              error={isError}
              open={conformationModal}
              successButtonLoading={isLoading}
              onClose={() => setConformationModal(false)}
              successButtonText={
                softwareDetailData.status ===
                softwareDetailsPageStrings.activeStatus
                  ? softwareDetailsPageStrings.inactiveStatus
                  : softwareDetailsPageStrings.activeStatus
              }
              failureButtonText={
                softwareDetailsPageStrings.modalFailureButtonText
              }
              onFailureButtonClick={() => setConformationModal(false)}
              onSuccessButtonClick={handleClick}
              padding={softwareDetailsPadding.modalPadding}>
              <Typography
                variant='h6'
                width={softwareDetailsPageSize.modalTextWidth}>
                {`${softwareDetailsPageStrings.confirmModalText}${
                  softwareDetailData.status ===
                  softwareDetailsPageStrings.activeStatus
                    ? softwareDetailsPageStrings.inactiveStatus
                    : softwareDetailsPageStrings.activeStatus
                } ?`}
              </Typography>
            </CustomModal>
          </Box>
        )}
      </>
    );
  }
  if (is404Error) {
    return (
      <ErrorMessagePage
        title={softwareDetailsPageStrings.error404Text.title}
        subtitle={softwareDetailsPageStrings.error404Text.subtitle}
      />
    );
  }
}
export default SoftwareDetailsPage;
