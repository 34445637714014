import { Box, Stack, Typography, useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import ShowDetailsColumn from '../../ShowDetailsColumn';
import { basicInformationStrings } from '../../../utils/strings';
import { employeeDetailsPagePaddings } from '../../../utils/dimensions/paddings';
import formatValueIfNull from '../../../utils/formatValueIfNull';

function BasicDetails({ title, data }) {
  const theme = useTheme();

  return (
    <Box padding={employeeDetailsPagePaddings.customDetailsBoxPadding}>
      <Stack display='flex' spacing={2}>
        <Typography variant='body1Medium' color={theme.palette.text.primary}>
          {title}
        </Typography>
        <Stack direction='row'>
          <ShowDetailsColumn
            flex='1'
            label={basicInformationStrings.placeHolder.first_name}>
            {formatValueIfNull(data.first_name)}
          </ShowDetailsColumn>
          <ShowDetailsColumn
            flex='1'
            label={basicInformationStrings.placeHolder.last_name}>
            {formatValueIfNull(data.last_name)}
          </ShowDetailsColumn>
          <ShowDetailsColumn
            flex='1'
            label={basicInformationStrings.placeHolder.company_email}>
            {formatValueIfNull(data.company_email)}
          </ShowDetailsColumn>
        </Stack>
        <Stack direction='row'>
          <ShowDetailsColumn
            flex='1'
            label={basicInformationStrings.placeHolder.location}>
            {formatValueIfNull(data.Branch.name)}
          </ShowDetailsColumn>

          <ShowDetailsColumn
            flex='1'
            label={basicInformationStrings.placeHolder.personal_email}>
            {formatValueIfNull(data.personal_email)}
          </ShowDetailsColumn>

          <ShowDetailsColumn
            flex='1'
            label={basicInformationStrings.placeHolder.phone_number}>
            {`${formatValueIfNull(data.country_code)}${' '}
            ${formatValueIfNull(data.phone_number)}`}
          </ShowDetailsColumn>
        </Stack>
        <Stack direction='row'>
          <ShowDetailsColumn
            flex='1'
            label={basicInformationStrings.placeHolder.city}>
            {formatValueIfNull(data.city)}
          </ShowDetailsColumn>
          <ShowDetailsColumn
            flex='1'
            label={basicInformationStrings.placeHolder.state}>
            {formatValueIfNull(data.state)}
          </ShowDetailsColumn>

          <ShowDetailsColumn
            flex='1'
            label={basicInformationStrings.placeHolder.zipcode}>
            {formatValueIfNull(data.zipcode)}
          </ShowDetailsColumn>
        </Stack>

        <ShowDetailsColumn
          flex='1'
          label={basicInformationStrings.placeHolder.address}>
          {formatValueIfNull(data.address)}
        </ShowDetailsColumn>
      </Stack>
    </Box>
  );
}
BasicDetails.propTypes = {
  title: PropTypes.string,
  data: PropTypes.shape().isRequired,
};
BasicDetails.defaultProps = {
  title: '',
};

export default BasicDetails;
