import { Add, Close } from '@mui/icons-material';
import { useEffect, useState, useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Container,
  MenuItem,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import TextField from '@mui/material/TextField';
import { useLocation, useNavigate } from 'react-router-dom';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import {
  addSoftwareStrings,
  softwareDetailsPageStrings,
  softwareStatusStrings,
  softwaresPageStrings,
} from '../../utils/strings';
import CustomDrawer from '../CustomDrawer';
import {
  addSoftwareMargins,
  customTextFieldMargins,
} from '../../utils/dimensions/margins';
import { addSoftwareSize, detailsPageSize } from '../../utils/dimensions/size';
import CustomTextField from '../Inputs/CustomTextField';
import CustomTextButton from '../CustomTextButton';
import { addSoftwareDetailsPadding } from '../../utils/dimensions/paddings';
import CustomAvatar from '../CustomAvatar';
import networkClient from '../../networks/networkClient';
import { methodTypes, networkEndpoints } from '../../networks/networkStrings';
import endpointParamsSetter from '../../utils/endpointParamsSetter';
import makeFirstLetterCapital from '../../utils/makeFirstLetterCapital';
import { handle403Error } from '../../utils/errorHandler';
import { snackbarContext } from '../../utils/context';
import CircularProgressIndicator from '../CircularProgressIndicator';
import DefaultSoftwareFavicon from '../../utils/assets/DefaultSoftwareFavicon.svg';
import ApiErrorComponent from '../ApiErrorComponent';

const filter = createFilterOptions();
export default function AddSoftwareDrawer({
  toggleDrawer,
  setToggleDrawer,
  isEdit,
  softwareId,
  defaultData,
  allSoftwareData,
  allClientsList,
  setAllClientsList,
  setSoftwareData,
  setTableData,
  setSortOrderBy,
}) {
  const theme = useTheme();
  const { showSnackbar } = useContext(snackbarContext);

  const listOfManagersInitialState = null;
  const [listOfManagers, setListOfManagers] = useState(
    listOfManagersInitialState
  );
  const location = useLocation();
  const navigate = useNavigate();
  const [isErrorPop, setIsErrorPop] = useState(false);
  const [isLoadding, setIsLoadding] = useState(true);
  const [value, setValue] = useState(defaultData.client);
  const [clientList, setClientList] = useState(allClientsList);
  const [managerError, setManagerError] = useState(false);

  let managers;
  // checking if defualt data has managers or not
  if (isEdit) {
    if (defaultData.managers.length > 0) {
      managers = defaultData.managers;
    } else {
      managers = [
        {
          id: 1,
          value: '',
          error: false,
          errorMessage: addSoftwareStrings.errorMessages.required,
        },
      ];
    }
  } else {
    managers = [
      {
        id: 1,
        value: '',
        error: false,
        errorMessage: addSoftwareStrings.errorMessages.required,
      },
    ];
  }
  // state of form data.
  const formDataInitialState = {
    url: {
      value: defaultData.url,
      error: false,
      errorMessage: addSoftwareStrings.errorMessages.required,
    },
    name: {
      value: defaultData.name,
      error: false,
      errorMessage: addSoftwareStrings.errorMessages.required,
    },
    icon: {
      value: defaultData.icon || '',
      error: false,
      errorMessage: addSoftwareStrings.errorMessages.invalidUrl,
    },
    client: {
      value: defaultData.client,
      error: false,
      errorMessage: addSoftwareStrings.errorMessages.required,
      id: defaultData.clientId,
    },
    status: {
      value: defaultData.status,
      error: false,
      errorMessage: addSoftwareStrings.errorMessages.required,
    },
    description: {
      value: defaultData.description || '',
    },
    managers: [...managers],
  };
  const [formdata, setFormdata] = useState(formDataInitialState);

  // function for rendering menuItem component of manager dropdown.
  function avatarWithName(name, imgUrl) {
    return (
      <Box display='flex' alignItems='center'>
        <CustomAvatar name={name} src={imgUrl} />
        <Box width={detailsPageSize.widthBetweenImageAndName} />
        <Typography variant='body2' color={theme.palette.grey[1100]}>
          {name}
        </Typography>
      </Box>
    );
  }

  // checks if form contains any error or empty textfields.
  function isError() {
    const updatedFields = Object.keys(formdata).filter(
      (key) =>
        key !== 'managers' &&
        formdata[key].value !== formDataInitialState[key].value
    );

    const oldManagers = formDataInitialState.managers
      .map((manager) => manager.value.id)
      .sort();

    const newManagers = formdata.managers
      .map((manager) => manager.value.id)
      .sort();

    if (
      formdata.url.error ||
      formdata.name.error ||
      formdata.icon.error ||
      formdata.status.error ||
      formdata.client.error ||
      formdata.icon.error ||
      !formdata.url.value ||
      !formdata.name.value ||
      !formdata.status.value ||
      !formdata.client.value ||
      formdata.managers.some((manager) => !manager.value.id || manager.error) ||
      (isEdit &&
        updatedFields.length === 0 &&
        JSON.stringify(newManagers) === JSON.stringify(oldManagers))
    ) {
      return true;
    }
    return false;
  }

  // this method checks if provided value is valid as per URL format.
  function isValidURL(str) {
    if (
      /^(http(s)?:\/\/.)[-a-zA-Z0-9@:%._+~#=]{0,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)$/g.test(
        str
      )
    )
      return true;
    return false;
  }

  const setData = ({ key, error, data, errorMessage }) => {
    setFormdata({
      ...formdata,
      [key]: {
        ...formdata[key],
        error,
        value: data === undefined ? formdata[key].value : data,
        errorMessage: errorMessage || formdata[key].errorMessage,
      },
    });
  };

  const fetchClients = useCallback(async () => {
    const response = await networkClient({
      method: methodTypes.get,
      endpoint: networkEndpoints.clients.getAllClients,
    });
    if (response.error) {
      if (response.errors[0].code === 403) {
        handle403Error(navigate, location);
      }
      setIsErrorPop(true);
      setTimeout(() => setIsErrorPop(false), 10000);
    } else {
      setClientList(response.data);
      if (!allClientsList) {
        setAllClientsList(response.data);
      }
    }
  }, [navigate, location, allClientsList, setAllClientsList]);

  const fetchUsers = useCallback(async () => {
    setIsLoadding(true);
    const response = await networkClient({
      method: methodTypes.get,
      endpoint: networkEndpoints.user.getUsers,
      queryParams: {
        role: softwaresPageStrings.managerRoleArray,
        status: softwaresPageStrings.status,
      },
    });
    setIsLoadding(false);
    if (!response.error) {
      setManagerError(false);
      // Map the response data to the format expected by the component
      let users;
      if (isEdit) {
        users = response.data.map((user) => {
          const managerIndex = defaultData.managers.findIndex(
            (obj) => obj.value.id === user.id
          );
          if (managerIndex < 0) {
            return {
              id: user.id,
              name: `${user.first_name} ${user.last_name}`,
              position: user.job_title,
              email: user.company_email,
              selected: false,
            };
          }
          return defaultData.managers[managerIndex].value;
        });
      } else {
        users = response.data.map((user) => ({
          id: user.id,
          name: `${user.first_name} ${user.last_name}`,
          position: user.job_title,
          email: user.company_email,
          selected: false,
        }));
      }
      setListOfManagers(users);
    } else {
      setManagerError(true);
      if (response.errors[0].code === 403) {
        handle403Error(navigate, location);
      }
      setIsErrorPop(true);
      setTimeout(() => setIsErrorPop(false), 10000);
    }
  }, [defaultData, isEdit, location, navigate]);

  useEffect(() => {
    if (toggleDrawer && clientList === null) {
      if (allClientsList) {
        setClientList(allClientsList);
      } else {
        fetchClients();
      }
    }
  }, [toggleDrawer, fetchClients, clientList, allClientsList]);

  useEffect(() => {
    if (toggleDrawer && listOfManagers === null) {
      fetchUsers();
    }
  }, [toggleDrawer, fetchUsers, listOfManagers]);

  useEffect(() => {
    setFormdata((oldFormdata) => ({
      ...oldFormdata,
      status: { ...oldFormdata.status, value: defaultData.status },
    }));
  }, [defaultData.status]);

  const setErrorMessage = ({ errors }) => {
    errors.forEach((error) => {
      if (error.field === 'client_id') {
        setFormdata((prevValues) => ({
          ...prevValues,
          name: {
            ...prevValues.name,
            error: true,
            errorMessage: addSoftwareStrings.errorMessages.duplicateSoftware,
          },
        }));
      } else if (
        Object.keys(addSoftwareStrings.placeHolder).includes(error.field)
      ) {
        setFormdata((prevValues) => ({
          ...prevValues,
          [error.field]: {
            ...prevValues[error.field],
            error: true,
            errorMessage: error.message,
          },
        }));
      }
    });
  };

  const postSoftware = async () => {
    setIsErrorPop(null);
    setSortOrderBy(null);
    const softwareData = Object.keys(formdata)
      .map((key) => ({
        [key]:
          key === 'status'
            ? formdata[key].value.toLowerCase()
            : formdata[key].value,
      }))
      .reduce(
        (accumulator, currentValue) => ({ ...accumulator, ...currentValue }),
        {}
      );
    softwareData.managed_by = formdata.managers.map(
      (manager) => manager.value.id
    );

    delete softwareData.managers;
    softwareData.client_id = formdata.client.id;
    softwareData.client_name = formdata.client.value;
    softwareData.icon = softwareData.icon !== '' ? softwareData.icon : null;
    delete softwareData.client;

    if (!isEdit) {
      const response = await networkClient({
        method: methodTypes.post,
        endpoint: networkEndpoints.software.addSoftware,
        requestBody: softwareData,
      });
      if (response.error) {
        if (response.errors[0].code === 400) {
          setIsErrorPop(false);
          setErrorMessage({ errors: response.errors });
        } else {
          setIsErrorPop(true);
        }
        if (response.errors[0].code === 403) {
          handle403Error(navigate, location);
        }
        setTimeout(() => setIsErrorPop(false), 10000);
      } else {
        allSoftwareData.unshift({
          id: response.data.id,
          url: response.data.icon,
          name: response.data.name,
          managers: response.data.Managers.map((manager) => ({
            name: `${manager.first_name} ${manager.last_name}`,
            position: manager.job_title,
            imageUrl: `${manager.first_name} ${manager.last_name}`,
          })),
          client: response.data?.Client?.name,
          clientId: response.data?.Client?.id,
          allocation: response.data?.Managers.length,
          status: makeFirstLetterCapital(response.data.status),
        });
        setListOfManagers(
          listOfManagers.map((obj) => ({ ...obj, selected: false }))
        );
        setSoftwareData([...allSoftwareData]);
        setIsErrorPop(false);
        setToggleDrawer(false);
        showSnackbar(softwaresPageStrings.softwareAdded);
        setFormdata(formDataInitialState);
        setValue(null);
      }
    } else {
      const response = await networkClient({
        method: methodTypes.patch,
        endpoint: endpointParamsSetter({
          path: networkEndpoints.software.editSoftware,
          params: softwareId,
        }),
        requestBody: softwareData,
      });
      if (response.error) {
        if (response.errors[0].code === 400) {
          setIsErrorPop(false);
          setErrorMessage({ errors: response.errors });
        } else {
          setIsErrorPop(true);
        }
        if (response.errors[0].code === 403) {
          handle403Error(navigate, location);
        }
        setTimeout(() => setIsErrorPop(false), 10000);
      } else {
        const formattedDataSoftware = {
          ...defaultData,
          url: response.data.url,
          name: response.data.name,
          status: response.data.status,
          icon: response.data.icon,
          client: response.data.Client.name,
          clientId: response.data.Client.id,
          description: response.data.description,
          managers: response.data.Managers.map((manager, index) => ({
            id: index + 1,
            value: {
              id: manager.id,
              name: `${manager.first_name} ${manager.last_name}`,
              email: manager.company_email,
              selected: true,
            },
            error: false,
            errorMessage: addSoftwareStrings.errorMessages.required,
          })),
          updatedAt: response.data.updatedAt,
        };

        const newTableData = response.data.Managers.map((manager) => ({
          nameData: {
            userId: manager.id,
            imageUrl: `${manager.first_name} ${manager.last_name}`,
            name: `${manager.first_name} ${manager.last_name}`,
            position: manager.job_title,
          },
          assignDate: Date.now(),
          status: softwareStatusStrings.active,
          view: {
            buttonText: null,
            onButtonClick: null,
            data: {
              notes: null,
            },
          },
        }));

        setTableData((prevTableData) => {
          // Create a set of existing manager names in tableData
          const existingManagerNames = new Set(
            prevTableData.map((entry) => entry.nameData.userId)
          );

          // Filter out the managers that are already present in tableData
          const uniqueManagers = newTableData.filter(
            (manager) => !existingManagerNames.has(manager.nameData.userId)
          );

          // Append the unique managers to the tableData
          const updatedTableData = [...uniqueManagers, ...prevTableData];
          return updatedTableData;
        });
        setSoftwareData(formattedDataSoftware);
        setIsErrorPop(false);
        setToggleDrawer(false);
        showSnackbar(softwareDetailsPageStrings.snackbarSuccessText);
        setValue(response.data?.Client?.name);
      }
    }
  };
  return (
    <CustomDrawer
      error={isErrorPop}
      successButtonLoading={isErrorPop === null}
      open={toggleDrawer}
      onFailureButtonClick={() => {
        setToggleDrawer(false);
        setFormdata(formDataInitialState);
        setValue(isEdit ? defaultData.client : null);
        if (isEdit) {
          setListOfManagers(
            listOfManagers?.map((obj) => {
              if (
                defaultData.managers.some(
                  (managerObj) => managerObj.value.id === obj.id
                )
              ) {
                return {
                  ...obj,
                  selected: true,
                };
              }
              return {
                ...obj,
                selected: false,
              };
            })
          );
        } else
          setListOfManagers(
            listOfManagers?.map((obj) => ({ ...obj, selected: false }))
          );
      }}
      onSuccessButtonClick={postSoftware}
      successButtonDisabled={isError()}
      title={
        isEdit ? addSoftwareStrings.editTitle : addSoftwareStrings.addTitle
      }
      failureButtonText={addSoftwareStrings.failureButtonText}
      successButtonText={
        isEdit
          ? addSoftwareStrings.editButtonText
          : addSoftwareStrings.submitButtonText
      }
      headerTailElement={
        isEdit ? (
          <Stack direction='row' alignItems='center' spacing={1}>
            {defaultData.icon ? (
              <Box
                sx={{
                  padding: addSoftwareDetailsPadding.iconPadding,
                  border: `1px solid ${theme.palette.grey[100]}`,
                }}>
                <img
                  src={defaultData.icon}
                  alt={defaultData.icon}
                  width={addSoftwareSize.softwareImageSize}
                  height={addSoftwareSize.softwareImageSize}
                  onError={(e) => {
                    e.target.src = DefaultSoftwareFavicon;
                    e.onerror = null;
                  }}
                />
              </Box>
            ) : (
              <img
                width={addSoftwareSize.softwareImageSize}
                height={addSoftwareSize.softwareImageSize}
                src={DefaultSoftwareFavicon}
                style={{ objectFit: 'contain' }}
                alt={defaultData.icon}
              />
            )}
            <Stack>
              <Typography variant='h5SemiBold' color={theme.palette.grey[1100]}>
                {defaultData.name}
              </Typography>
            </Stack>
          </Stack>
        ) : null
      }>
      <Box
        marginX={addSoftwareMargins.marginX}
        display='flex'
        flexDirection='column'>
        <Box height={addSoftwareSize.spaceBetweenTitleAndTextField} />
        <Stack>
          <CustomTextField
            textColor={theme.palette.info.dark}
            helperText={formdata.url.error ? formdata.url.errorMessage : null}
            error={formdata.url.error}
            value={formdata.url.value}
            onBlur={(e) => {
              setData({
                key: 'url',
                error: !isValidURL(e.target.value),
                errorMessage:
                  e.target.value === ''
                    ? addSoftwareStrings.errorMessages.required
                    : addSoftwareStrings.errorMessages.invalidUrl,
              });
            }}
            onChange={(e) => {
              const trimmedValue = e.target.value.replace(/\s/g, '');
              setData({
                key: 'url',
                error: !isValidURL(trimmedValue),
                data: trimmedValue,
                errorMessage:
                  trimmedValue === ''
                    ? addSoftwareStrings.errorMessages.required
                    : addSoftwareStrings.errorMessages.invalidUrl,
              });
            }}
            placeHolder={addSoftwareStrings.placeHolder.url}
          />

          <CustomTextField
            value={formdata.name.value}
            error={formdata.name.error}
            helperText={formdata.name.error ? formdata.name.errorMessage : null}
            onChange={(e) => {
              setData({
                key: 'name',
                error: e.target.value.length <= 0,
                data: e.target.value,
              });
            }}
            onBlur={(e) => {
              const trimmedValue = e.target.value.trim();

              setData({
                key: 'name',
                error: trimmedValue === '',
                data: trimmedValue,
              });
            }}
            placeHolder={addSoftwareStrings.placeHolder.name}
          />
          <CustomTextField
            textColor={theme.palette.info.dark}
            helperText={formdata.icon.error ? formdata.icon.errorMessage : null}
            error={formdata.icon.error}
            value={formdata.icon.value}
            onBlur={(e) => {
              setData({
                key: 'icon',
                error:
                  e.target.value.trim() !== '' && !isValidURL(e.target.value),
                errorMessage:
                  e.target.value !== ''
                    ? addSoftwareStrings.errorMessages.invalidUrl
                    : '',
              });
            }}
            onChange={(e) => {
              const trimmedValue = e.target.value.replace(/\s/g, '');
              setData({
                key: 'icon',
                error:
                  e.target.value.trim() !== '' && !isValidURL(e.target.value),
                data: trimmedValue,
                errorMessage:
                  trimmedValue !== ''
                    ? addSoftwareStrings.errorMessages.invalidUrl
                    : '',
              });
            }}
            placeHolder={addSoftwareStrings.placeHolder.icon}
          />
          <Stack direction='row'>
            <Autocomplete
              sx={{
                margin: customTextFieldMargins.margin,
                width: addSoftwareSize.autoCompleteWidth,
                '& .MuiInputBase-root': {
                  borderRadius: addSoftwareSize.autoCompleteRadius,
                },
              }}
              value={value || ''}
              onChange={(event, newValue) => {
                if (newValue) {
                  if (newValue.inputValue) {
                    setValue({
                      name: newValue.inputValue,
                    });
                    setClientList((oldClients) => [
                      ...oldClients,
                      { id: oldClients.length + 1, name: newValue.inputValue },
                    ]);
                    setAllClientsList((oldClients) => [
                      ...oldClients,
                      { id: oldClients.length + 1, name: newValue.inputValue },
                    ]);
                    setFormdata({
                      ...formdata,
                      client: {
                        ...formdata.client,
                        value: newValue.inputValue,
                        error: newValue.inputValue.length <= 0,
                        id: null,
                        errorMessage:
                          newValue.inputValue.length <= 0
                            ? 'Please select a client'
                            : '',
                      },
                    });
                  } else {
                    setValue(newValue);
                    setFormdata({
                      ...formdata,
                      client: {
                        ...formdata.client,
                        value: newValue.name || '', // check for null or undefined
                        id: newValue.id,
                        error: false,
                        errorMessage: '',
                      },
                    });
                  }
                } else {
                  setValue(null);
                  setFormdata({
                    ...formdata,
                    client: {
                      ...formdata.client,
                      value: '', // set to empty string when value is null or undefined
                      id: null,
                      error: false,
                      errorMessage: '',
                    },
                  });
                }
              }}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);

                const { inputValue } = params;
                // Suggest the creation of a new value
                const isExisting = options.some(
                  (option) => inputValue.trim() === option.name
                );
                if (inputValue.trim() !== '' && !isExisting) {
                  filtered.push({
                    inputValue: inputValue.trim(),
                    name: `"${inputValue.trim()}" (New Client)`,
                  });
                }

                return filtered;
              }}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              options={clientList || []}
              getOptionLabel={(option) => {
                if (typeof option === 'string') {
                  return option;
                }

                if (option.inputValue) {
                  return option.inputValue;
                }

                return option.name;
              }}
              renderOption={(props, option) => (
                <li {...props}>{option.name}</li>
              )}
              freeSolo
              renderInput={(params) => (
                <TextField
                  {...params}
                  label='Client'
                  value={formdata.client.value}
                  error={formdata.client.error}
                  helperText={
                    formdata.client.error ? formdata.client.errorMessage : null
                  }
                  onChange={(e) => {
                    setData({
                      key: 'client',
                      error: e.target.value.length <= 0,
                      data: e.target.value,
                    });
                  }}
                  onBlur={(e) => {
                    const trimmedValue = e.target.value.trim();
                    setData({
                      key: 'client',
                      error: trimmedValue === '',
                      data: trimmedValue,
                    });
                  }}
                />
              )}
            />
            <CustomTextField
              value={makeFirstLetterCapital(formdata.status.value)}
              select
              menuItem={addSoftwareStrings.menuItem.status}
              error={formdata.status.error}
              helperText={
                formdata.status.error ? formdata.status.errorMessage : null
              }
              onChange={(e) => {
                setData({
                  key: 'status',
                  error: e.target.value.length <= 0,
                  data: e.target.value,
                });
              }}
              onBlur={(e) => {
                setData({
                  key: 'status',
                  error: e.target.value === '',
                  data: e.target.value,
                });
              }}
              placeHolder={addSoftwareStrings.placeHolder.status}
            />
          </Stack>
          <CustomTextField
            value={formdata.description.value}
            onChange={(e) => {
              setData({
                key: 'description',
                error: false,
                data: e.target.value,
              });
            }}
            onBlur={(e) => {
              const trimmedValue = e.target.value.trim();

              setData({
                key: 'description',
                error: trimmedValue === '',
                data: trimmedValue,
              });
            }}
            placeHolder={addSoftwareStrings.placeHolder.description}
            multiline
            rows={3}
          />
          {formdata.managers.map((manager, index) => (
            <CustomTextField
              select
              key={JSON.stringify(manager.id)}
              id={manager.value.id}
              value={manager.value.id}
              placeHolder={`${addSoftwareStrings.placeHolder.manager} ${
                index + 1
              }`}
              error={formdata.managers[index].error}
              helperText={
                formdata.managers[index].error
                  ? formdata.managers[index].errorMessage
                  : null
              }
              disableDropdownIcon
              SelectProps={{
                MenuProps: {
                  PaperProps: {
                    sx: {
                      maxHeight: addSoftwareSize.managersDropdownMaxHeight,
                      overflow: 'auto',
                      '&::-webkit-scrollbar': {
                        width: addSoftwareSize.managersDropdownScrollbarWidth,
                      },
                      '&::-webkit-scrollbar-thumb': {
                        backgroundColor: theme.palette.grey[300],
                        borderRadius:
                          addSoftwareSize.managersDropdownScrollbarRadius,
                        opacity: 0.7,
                      },
                    },
                  },
                },
              }}
              endAdornment={
                formdata.managers.length > 1 && (
                  <Typography
                    display='flex'
                    alignItems='center'
                    sx={{
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      if (manager.value !== '')
                        listOfManagers[
                          listOfManagers.findIndex(
                            (obj) => obj.id === manager.value.id
                          )
                        ].selected = false;
                      formdata.managers.splice(index, 1);
                      setFormdata({
                        ...formdata,
                        managers: [...formdata.managers],
                      });
                    }}>
                    <Close />
                  </Typography>
                )
              }
              onBlur={(e) => {
                // reset error value.
                formdata.managers[index] = {
                  ...manager,
                  error: e.target.value === '',
                };
                setFormdata({
                  ...formdata,
                  managers: [...formdata.managers],
                });
              }}
              onChange={(e) => {
                // make previous selected manager value false.
                if (manager.value !== '')
                  listOfManagers[
                    listOfManagers.findIndex(
                      (obj) => obj.id === manager.value.id
                    )
                  ].selected = false;
                // add new manager value.
                formdata.managers[index] = {
                  ...manager,
                  value: listOfManagers.find(
                    (obj) => obj.id === e.target.value
                  ),
                  error: e.target.value === '',
                };
                // make new selected manager value true.
                listOfManagers[
                  listOfManagers.findIndex((obj) => obj.id === e.target.value)
                ].selected = true;
                // set data and rerenders values.
                setFormdata({
                  ...formdata,
                  managers: [...formdata.managers],
                });
              }}>
              {/* eslint-disable */}
              {(isLoadding || managerError) && manager.value.name ? (
                <MenuItem key={manager.value.id} value={manager.value.id}>
                  {avatarWithName(manager.value.name, manager.value.imgUrl)}
                </MenuItem>
              ) : (
                listOfManagers?.map((menu) => {
                  if (manager.value.id === menu.id || !menu.selected)
                    return (
                      <MenuItem key={menu.id} value={menu.id}>
                        {avatarWithName(menu.name, menu.imgUrl)}
                      </MenuItem>
                    );
                  return null;
                })
              )}
              {isLoadding ? (
                <Box
                  display='flex'
                  flex={1}
                  alignItems='center'
                  justifyContent='center'
                  height='10vh'>
                  <CircularProgressIndicator />
                </Box>
              ) : managerError && !isLoadding ? (
                <Box
                  display='flex'
                  flex={1}
                  alignItems='center'
                  justifyContent='center'
                  height='10vh'>
                  <ApiErrorComponent
                    errorMessage={addSoftwareStrings.managerError}
                    onRefreshClick={fetchUsers}
                  />
                </Box>
              ) : (
                <div />
              )}
            </CustomTextField>
          ))}
          <Box display='flex'>
            <CustomTextButton
              // if number od dropdowns added is same as number of managers, button gets disabled.
              disabled={
                (listOfManagers &&
                  formdata.managers.length === listOfManagers.length) ||
                isErrorPop === true
              }
              onClick={() => {
                const newManager = {
                  id: formdata.managers.length + 1,
                  value: '',
                  error: false,
                  errorMessage: addSoftwareStrings.errorMessages.required,
                };
                setFormdata({
                  ...formdata,
                  managers:
                    formdata.managers.length === 0
                      ? [newManager]
                      : [...formdata.managers, newManager],
                });
              }}>
              <Container
                disableGutters
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}>
                <Typography>
                  {addSoftwareStrings.addManagerButtonText}
                </Typography>
                <Box width={addSoftwareSize.spaceBetweenButtonAndIcon} />
                <Add fontSize={theme.typography.buttonSmall.fontSize} />
              </Container>
            </CustomTextButton>
          </Box>
        </Stack>
      </Box>
    </CustomDrawer>
  );
}

AddSoftwareDrawer.propTypes = {
  toggleDrawer: PropTypes.bool.isRequired,
  setToggleDrawer: PropTypes.func.isRequired,
  isEdit: PropTypes.bool,
  softwareId: PropTypes.string,
  defaultData: PropTypes.shape({
    client: PropTypes.string,
    status: PropTypes.string,
    description: PropTypes.string,
    name: PropTypes.string,
    icon: PropTypes.string,
    url: PropTypes.string,
    companyName: PropTypes.string,
    managers: PropTypes.arrayOf(PropTypes.shape()),
    clientId: PropTypes.number,
  }),
  allClientsList: PropTypes.arrayOf(PropTypes.shape()),
  setAllClientsList: PropTypes.func,
  setSoftwareData: PropTypes.func.isRequired,
  allSoftwareData: PropTypes.arrayOf(PropTypes.shape()),
  setTableData: PropTypes.func,
  setSortOrderBy: PropTypes.func,
  managerError: PropTypes.bool,
  setManagerError: PropTypes.func,
};

AddSoftwareDrawer.defaultProps = {
  isEdit: false,
  defaultData: {
    status: addSoftwareStrings.menuItem.status[0],
  },
  allClientsList: null,
  softwareId: null,
  setAllClientsList: () => {},
  setTableData: null,
  allSoftwareData: [{}],
  setSortOrderBy: () => {},
  managerError: false,
  setManagerError: () => {},
};
