import { Box } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useMemo, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import Navbar from '../components/Navbar/Navbar';
import navbarTabsRouterPaths from '../Router/NavbarTabsRouterPaths';
import redirectPaths from '../Router/RedirectPaths';
import pathStrings from '../utils/pathStrings';
import { snackbarContext, paginationContext } from '../utils/context';
import { customTableStrings, snackbarStrings } from '../utils/strings';

export default function AppLayout() {
  function getEndpoint(endpoint) {
    const index = endpoint.slice(1).indexOf(pathStrings.homePagePath);
    if (index !== -1) return endpoint.slice(0, index + 1);

    return endpoint;
  }
  const currentTabValue = useLocation();

  let initialTabValue = getEndpoint(currentTabValue.pathname);
  if (getEndpoint(currentTabValue.pathname) === pathStrings.homePagePath)
    initialTabValue = redirectPaths.homePath.to;

  const { enqueueSnackbar } = useSnackbar();
  const showSnackbar = useMemo(
    () => ({
      showSnackbar: (message, variant = snackbarStrings.variant.success) => {
        enqueueSnackbar(message, { variant });
      },
    }),
    [enqueueSnackbar]
  );

  const paginationInitialState = {
    rowsPerPage: {
      employeeListTable: customTableStrings.rowsPerPageOptions[3],
      softwareListTable: customTableStrings.rowsPerPageOptions[3],
      userSoftwareListTable: customTableStrings.rowsPerPageOptions[3],
      systemUsersListTable: customTableStrings.rowsPerPageOptions[3],
    },
    pageNo: {
      employeeListTable: 0,
      softwareListTable: 0,
      userSoftwareListTable: 0,
      systemUsersListTable: 0,
    },
  };
  const [rowsPerPage, setRowsPerPage] = useState(
    paginationInitialState.rowsPerPage
  );
  const [pageNo, setPageNo] = useState(paginationInitialState.pageNo);

  const paginationContextValue = useMemo(
    () => ({
      updateRowsPerPage: (key, value) => {
        setRowsPerPage((prevValues) => ({ ...prevValues, [key]: value }));
      },
      getRowsPerPage: (key) => rowsPerPage[key],
      updatePageNo: (key, value) => {
        setPageNo((prevValues) => ({ ...prevValues, [key]: value }));
      },
      getPageNo: (key) => pageNo[key],
    }),
    [rowsPerPage, pageNo]
  );

  // tabValue variable is defined which is used for tracking current opened tab.
  const [tabValue, setTabValue] = useState(initialTabValue);
  return (
    <Box>
      <Navbar
        tabValue={tabValue}
        setTabValue={setTabValue}
        tabsRouterPaths={navbarTabsRouterPaths}
      />
      <snackbarContext.Provider value={showSnackbar}>
        <paginationContext.Provider value={paginationContextValue}>
          <Outlet />
        </paginationContext.Provider>
      </snackbarContext.Provider>
    </Box>
  );
}
