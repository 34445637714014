import {
  Box,
  Stack,
  Switch,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import PropTypes from 'prop-types';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import {
  addEmployeeStrings,
  employmentTypes,
  joiningInformationStrings,
  onBoardingDrawerStrings,
} from '../../utils/strings';
import { joiningInformationMargins } from '../../utils/dimensions/margins';
import { joiningInformationSize } from '../../utils/dimensions/size';
import CustomTextField from '../Inputs/CustomTextField';
import { isOnlyNumbers, isValidEmail, isValidId } from '../../utils/validators';
import ApiErrorComponent from '../ApiErrorComponent';
import CircularProgressIndicator from '../CircularProgressIndicator';
import { addEmployeePaddings } from '../../utils/dimensions/paddings';

function JoiningDataComponent({
  jobDetails,
  setJobDetails,
  branchItems,
  branchError,
  fetchbranches,
  isBranchLoading,
}) {
  const theme = useTheme();
  const setData = ({ key, error, data, errorMessage }) => {
    setJobDetails((prevValue) => ({
      ...prevValue,
      [key]: {
        ...prevValue[key],
        error,
        value: data === undefined ? prevValue[key].value : data,
        errorMessage: errorMessage || prevValue[key].errorMessage,
      },
    }));
  };
  return (
    <Box
      marginX={joiningInformationMargins.marginX}
      width={joiningInformationSize.width}>
      <Stack>
        <CustomTextField
          select
          value={jobDetails.location.value}
          placeHolder={joiningInformationStrings.placeHolder.officeLocation}
          menuItem={branchItems?.map(
            (data) => data.name || [jobDetails.location.value]
          )}
          helperText={
            jobDetails.location.error ? jobDetails.location.errorMessage : null
          }
          error={jobDetails.location.error}
          onChange={(e) => {
            const branchData = branchItems?.find(
              (data) => data.name === e.target.value
            );
            setJobDetails({
              ...jobDetails,
              location: {
                ...jobDetails.location,
                error: e.target.value === '',
                value:
                  e.target.value === undefined
                    ? jobDetails.location.value
                    : e.target.value,
                errorMessage: jobDetails.location.errorMessage,
              },
              branch_id: {
                ...jobDetails.branch_id,
                error: e.target.value === '',
                value:
                  branchData.id === undefined
                    ? jobDetails.branch_id.value
                    : branchData.id,
                errorMessage: jobDetails.branch_id.errorMessage,
              },
              employee_id: {
                ...jobDetails.employee_id,
                error: e.target.value === '',
                value:
                  branchData.prefix === undefined
                    ? jobDetails.employee_id.value
                    : branchData.prefix,
                errorMessage: jobDetails.employee_id.errorMessage,
              },
              prefix: {
                value: branchData.prefix,
              },
            });
          }}
          onBlur={(e) => {
            if (jobDetails.employee_id.value !== '') {
              setData({
                key: 'employee_id',
                error: !isValidId(
                  jobDetails.employee_id.value,
                  jobDetails.prefix.value
                ),
              });
            }
            setData({
              key: 'location',
              error: e.target.value === '',
              data: e.target.value,
            });
          }}>
          {isBranchLoading ? (
            <Box
              sx={{
                padding: addEmployeePaddings.branchPadding,
                display: 'flex',
                justifyContent: 'center',
              }}>
              <CircularProgressIndicator />
            </Box>
          ) : null}
          {branchError && !isBranchLoading ? (
            <Box sx={{ padding: addEmployeePaddings.branchPadding }}>
              <ApiErrorComponent
                errorMessage={onBoardingDrawerStrings.branchError}
                onRefreshClick={fetchbranches}
              />
            </Box>
          ) : null}
        </CustomTextField>

        <Stack direction='row' display='flex'>
          <Stack direction='row' display='flex'>
            <CustomTextField
              placeHolder={joiningInformationStrings.placeHolder.employee_id}
              width={joiningInformationSize.idWidth}
              value={
                jobDetails.employee_id.disabled
                  ? ''
                  : jobDetails.employee_id.value.trim()
              }
              helperText={
                !jobDetails.employee_id.disabled && jobDetails.employee_id.error
                  ? jobDetails.employee_id.errorMessage
                  : null
              }
              disabled={jobDetails.employee_id.disabled}
              error={
                !jobDetails.employee_id.disabled && jobDetails.employee_id.error
              }
              onChange={(e) => {
                const { value } = e.target;
                const isValidLength = value.trim().length <= 50; // adding length to employee_id

                if (value.trim() === '') {
                  setData({
                    key: 'employee_id',
                    data: value,
                    error: false,
                  });
                } else if (isValidLength) {
                  setData({
                    key: 'employee_id',
                    data: value,
                    error:
                      jobDetails.location.value.includes('Remote') &&
                      !isValidId(value, jobDetails.prefix.value),
                  });
                }
              }}
              onBlur={(e) => {
                const { value } = e.target;
                const isValidLength = value.trim().length <= 50;

                if (value.trim() === '') {
                  setData({
                    key: 'employee_id',
                    data: value,
                    error: false,
                  });
                } else if (isValidLength) {
                  setData({
                    key: 'employee_id',
                    error:
                      jobDetails.location.value.includes('Remote') &&
                      !isValidId(value, jobDetails.prefix.value),
                  });
                }
              }}
            />
          </Stack>

          <CustomTextField
            value={jobDetails.job_title.value}
            helperText={
              jobDetails.job_title.error
                ? jobDetails.job_title.errorMessage
                : null
            }
            error={jobDetails.job_title.error}
            placeHolder={joiningInformationStrings.placeHolder.job_title}
            width={joiningInformationSize.jobTitleWidth}
            maxLength={30}
            onChange={(e) => {
              setData({
                key: 'job_title',
                data: e.target.value,
                error: e.target.value === '',
              });
            }}
            onBlur={(e) => {
              const trimmedValue = e.target.value.trim();
              setData({
                key: 'job_title',
                error: trimmedValue === '',
                data: trimmedValue,
              });
            }}
          />
        </Stack>
        <Stack direction='row' alignItems='center' display='flex'>
          <CustomTextField
            value={jobDetails.employment_type.value}
            helperText={
              jobDetails.employment_type.error
                ? jobDetails.employment_type.errorMessage
                : null
            }
            error={jobDetails.employment_type.error}
            select
            placeHolder={joiningInformationStrings.placeHolder.employment_type}
            width={joiningInformationSize.commonWidth}
            menuItem={joiningInformationStrings.menuItem.employementTypeMenu}
            onChange={(e) => {
              setData({
                key: 'employment_type',
                data: e.target.value,
                error: e.target.value === '',
              });
              setJobDetails((prevValues) => ({
                ...prevValues,
                employee_id: {
                  ...prevValues.employee_id,
                  disabled: e.target.value === employmentTypes.contractor,
                },
              }));
              if (
                e.target.value === employmentTypes.fullTime &&
                jobDetails.capacity.value === ''
              ) {
                setData({
                  key: 'capacity',
                  data: joiningInformationStrings.maxCapacity,
                });
              }
            }}
            onBlur={(e) => {
              setData({
                key: 'employment_type',
                error: e.target.value === '',
                data: e.target.value,
              });
            }}
          />
          <Switch
            value={jobDetails.isAgency.value}
            checked={jobDetails.isAgency.value}
            onChange={(e) => {
              if (e.target.value === 'false') {
                setData({
                  key: 'isAgency',
                  data: true,
                });
              } else {
                setJobDetails({
                  ...jobDetails,
                  isAgency: {
                    value: false,
                  },
                  agency_email: {
                    ...jobDetails.agency_email,
                    error: false,
                  },
                  agency_name: {
                    ...jobDetails.agency_name,
                    error: false,
                  },
                });
              }
            }}
          />
          <Typography
            variant='inputLabel'
            color={theme.palette.text.secondary}
            alignContent='center'>
            {joiningInformationStrings.contractText}
          </Typography>
        </Stack>
        {jobDetails.isAgency.value && (
          <Stack direction='column' display='flex'>
            <Stack direction='row' display='flex'>
              <CustomTextField
                value={jobDetails.agency_name.value}
                helperText={
                  jobDetails.agency_name.error
                    ? jobDetails.agency_name.errorMessage
                    : null
                }
                error={jobDetails.agency_name.error}
                onChange={(e) => {
                  const { value } = e.target;
                  // adding length
                  if (value.length <= 30) {
                    setData({
                      key: 'agency_name',
                      data: e.target.value.trim(),
                      error: false,
                    });
                  }
                }}
                onBlur={(e) => {
                  const { value } = e.target;
                  if (value.trim().length <= 30) {
                    setData({
                      key: 'agency_name',
                      error: false,
                      data: e.target.value.trim(),
                    });
                  }
                }}
                disabled={!jobDetails.isAgency.value}
                placeHolder={joiningInformationStrings.placeHolder.agency_name}
              />
              <CustomTextField
                value={jobDetails.agency_email.value}
                helperText={
                  jobDetails.agency_email.error
                    ? jobDetails.agency_email.errorMessage
                    : null
                }
                error={jobDetails.agency_email.error}
                onChange={(e) => {
                  if (e.target.value.trim() === '')
                    setData({
                      key: 'agency_email',
                      data: e.target.value.trim(),
                      error: true,
                      errorMessage: addEmployeeStrings.errorMessages.required,
                    });
                  else
                    setData({
                      key: 'agency_email',
                      data: e.target.value.trim(),
                      error: false,
                    });
                }}
                onBlur={(e) => {
                  if (e.target.value.trim() === '')
                    setData({
                      key: 'agency_email',
                      error: true,
                      errorMessage: addEmployeeStrings.errorMessages.required,
                    });
                  else
                    setData({
                      key: 'agency_email',
                      error: !isValidEmail(e.target.value.trim()),
                      errorMessage:
                        addEmployeeStrings.errorMessages.invalidEmail,
                    });
                }}
                disabled={!jobDetails.isAgency.value}
                placeHolder={joiningInformationStrings.placeHolder.agency_email}
              />
            </Stack>

            <CustomTextField
              flex={6}
              placeHolder={
                joiningInformationStrings.placeHolder.agency_phone_number
              }
              value={jobDetails.agency_phone_number.value}
              helperText={
                jobDetails.agency_phone_number.error
                  ? jobDetails.agency_phone_number.errorMessage
                  : null
              }
              error={jobDetails.agency_phone_number.error}
              disabled={!jobDetails.isAgency.value}
              maxLength={15}
              onChange={(e) => {
                if (
                  e.target.value.trim() === '' ||
                  isOnlyNumbers(e.target.value)
                )
                  setData({
                    key: 'agency_phone_number',
                    data: e.target.value.trim(),
                    error: false,
                  });
              }}
              onBlur={(e) => {
                if (e.target.value.trim() === '')
                  setData({
                    key: 'agency_phone_number',
                    error: false,
                  });
                else
                  setData({
                    key: 'agency_phone_number',
                    error: e.target.value.length < 10 || e.target.value === '',
                    errorMessage:
                      addEmployeeStrings.errorMessages.minLengthPhoneNo,
                  });
              }}
            />
          </Stack>
        )}

        <Stack direction='row' display='flex'>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              value={jobDetails.join_date.value}
              label={joiningInformationStrings.placeHolder.join_date}
              onChange={(e) => {
                setData({
                  key: 'join_date',
                  data: e?.$d,
                });
              }}
              renderInput={(params) => (
                <TextField
                  error={jobDetails.join_date.error}
                  helperText={
                    jobDetails.join_date.error
                      ? jobDetails.join_date.errorMessage
                      : null
                  }
                  sx={{
                    width: joiningInformationSize.commonWidth,
                    flex: 1,
                    marginTop: joiningInformationMargins.startDateMarginTop,
                    marginRight: joiningInformationMargins.startDateMarginRigth,
                    '& .MuiOutlinedInput-root': {
                      borderRadius: theme.shape.textFieldBorderRadius,
                      '&.Mui-focused fieldset': {
                        border: `solid 1px ${
                          jobDetails.join_date.error
                            ? theme.palette.error.main
                            : theme.palette.info.main
                        }`,
                      },
                    },
                    '& .MuiInputLabel-root.Mui-focused': {
                      color: jobDetails.join_date.error
                        ? theme.palette.error.main
                        : theme.palette.info.main,
                    },
                  }}
                  onChange={(e) => {
                    if (e.target.value.trim() === '') {
                      setData({
                        key: 'join_date',
                        data: e.target.value,
                        error: true,
                        errorMessage: addEmployeeStrings.errorMessages.required,
                      });
                    } else {
                      setData({
                        key: 'join_date',
                        error: params.error,
                        errorMessage:
                          addEmployeeStrings.errorMessages.invalidDate,
                      });
                    }
                  }}
                  onBlur={(e) => {
                    if (e.target.value.trim() === '')
                      setData({
                        key: 'join_date',
                        error: true,
                        errorMessage: addEmployeeStrings.errorMessages.required,
                      });
                    else
                      setData({
                        key: 'join_date',
                        error: params.error,
                        errorMessage:
                          addEmployeeStrings.errorMessages.invalidDate,
                      });
                  }}
                  {...params}
                />
              )}
            />
          </LocalizationProvider>
          <CustomTextField
            value={jobDetails?.capacity?.value}
            placeHolder={joiningInformationStrings.placeHolder.capacity}
            endAdornment={joiningInformationStrings.capacityText}
            onChange={(e) => {
              const inputValue = e.target.value.trim();
              const formattedValue = inputValue.replace(/^0+(?!$)/, ''); // Remove leading zeros except when the input is all zeros

              if (
                (formattedValue === '' || isOnlyNumbers(formattedValue)) &&
                formattedValue >= 0 &&
                formattedValue <= joiningInformationStrings.maxCapacity
              ) {
                setData({
                  key: 'capacity',
                  data: formattedValue, // Add leading zeros if the input is less than 2 characters
                });
              }
            }}
          />
        </Stack>
        <CustomTextField
          value={jobDetails.description.value}
          placeHolder={joiningInformationStrings.placeHolder.description}
          multiline
          rows={3}
          onChange={(e) => {
            setData({
              key: 'description',
              data: e.target.value,
            });
          }}
          onBlur={(e) => {
            const trimmedValue = e.target.value.trim();

            setData({
              key: 'description',
              error: trimmedValue === '',
              data: trimmedValue,
            });
          }}
        />
      </Stack>
    </Box>
  );
}

JoiningDataComponent.propTypes = {
  jobDetails: PropTypes.shape({
    location: PropTypes.shape({
      value: PropTypes.string,
      error: PropTypes.bool,
      errorMessage: PropTypes.string,
    }),
    branch_id: PropTypes.shape({
      value: PropTypes.number,
      error: PropTypes.bool,
      errorMessage: PropTypes.string,
    }),
    prefix: PropTypes.shape({
      value: PropTypes.string,
      error: PropTypes.bool,
      errorMessage: PropTypes.string,
    }),
    employee_id: PropTypes.shape({
      value: PropTypes.string,
      error: PropTypes.bool,
      errorMessage: PropTypes.string,
      disabled: PropTypes.bool,
    }),
    job_title: PropTypes.shape({
      value: PropTypes.string,
      error: PropTypes.bool,
      errorMessage: PropTypes.string,
    }).isRequired,
    employment_type: PropTypes.shape({
      value: PropTypes.string,
      error: PropTypes.bool,
      errorMessage: PropTypes.string,
    }).isRequired,
    isAgency: PropTypes.shape({
      value: PropTypes.bool,
    }).isRequired,
    agency_name: PropTypes.shape({
      value: PropTypes.string,
      error: PropTypes.bool,
      errorMessage: PropTypes.string,
    }).isRequired,
    agency_email: PropTypes.shape({
      value: PropTypes.string,
      error: PropTypes.bool,
      errorMessage: PropTypes.string,
    }).isRequired,
    agency_phone_number: PropTypes.shape({
      value: PropTypes.string,
      error: PropTypes.bool,
      errorMessage: PropTypes.string,
    }),
    join_date: PropTypes.shape({
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.instanceOf(Date),
      ]),
      error: PropTypes.bool,
      errorMessage: PropTypes.string,
    }).isRequired,
    capacity: PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      error: PropTypes.bool,
      errorMessage: PropTypes.string,
    }).isRequired,
    description: PropTypes.shape({
      value: PropTypes.string,
    }).isRequired,
  }),
  branchItems: PropTypes.arrayOf(
    PropTypes.shape({
      location: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      prefix: PropTypes.string.isRequired,
    }).isRequired
  ),
  setJobDetails: PropTypes.func,
  fetchbranches: PropTypes.func.isRequired,
  branchError: PropTypes.bool.isRequired,
  isBranchLoading: PropTypes.bool,
};

JoiningDataComponent.defaultProps = {
  jobDetails: {},
  setJobDetails: () => {},
  branchItems: [],
  isBranchLoading: false,
};

export default JoiningDataComponent;
