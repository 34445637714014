import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  addEmployeeStrings,
  branchString,
  employeeDetailsPageStrings,
  employmentTypes,
  joiningInformationStrings,
  locations,
} from '../../utils/strings';
import CustomDrawer from '../CustomDrawer';
import JoiningDataComponent from '../EmployeeActions/JoiningInformationComponent';
import networkClient from '../../networks/networkClient';
import { methodTypes, networkEndpoints } from '../../networks/networkStrings';
import endpointParamsSetter from '../../utils/endpointParamsSetter';
import makeFirstLetterCapital from '../../utils/makeFirstLetterCapital';
import { isValidId } from '../../utils/validators';
import { detailsCardPadding } from '../../utils/dimensions/paddings';
import { handle403Error } from '../../utils/errorHandler';
import { snackbarContext } from '../../utils/context';
import getLoggedInUser from '../../utils/getLoggedInUser';

export default function EditEmployeeJobDetails({
  title,
  successButtonText,
  toggleDrawer,
  setToggleDrawer,
  data,
  setEmployeeDetails,
  failureButtonText,
  setHistoryData,
}) {
  const { showSnackbar } = useContext(snackbarContext);
  const [isUpdateUserError, setIsUpdateUserError] = useState(false);
  const currLocation = useLocation();
  const navigate = useNavigate();
  const location = useLocation();
  const initialBranchState = null;
  const [isBranchLoading, setIsBranchLoading] = useState();
  const [branchItems, setBranchItems] = useState(initialBranchState);
  const [branchError, setBranchError] = useState(false);
  const user = getLoggedInUser(navigate, location);
  const jobDetailsInitialState = {
    location: {
      value: data.branch.name,
      error: false,
      errorMessage: addEmployeeStrings.errorMessages.required,
    },
    employee_id: {
      value: data.employeeId ? data.employeeId : '',
      error: false,
      errorMessage: addEmployeeStrings.errorMessages.invalidId,
      disabled:
        makeFirstLetterCapital(data.employment_type) ===
        employmentTypes.contractor,
    },
    job_title: {
      value: data.job_title,
      error: false,
      errorMessage: addEmployeeStrings.errorMessages.required,
    },
    employment_type: {
      value: makeFirstLetterCapital(data.employment_type),
      error: false,
      errorMessage: addEmployeeStrings.errorMessages.required,
    },
    isAgency: {
      value: data.agencyName !== '-',
    },
    agency_name: {
      value: data.agencyName === '-' ? '' : data.agencyName,
      error: false,
    },
    agency_email: {
      value: data.agencyEmail === '-' ? '' : data.agencyEmail,
      error: false,
      errorMessage: addEmployeeStrings.errorMessages.required,
    },
    agency_phone_number: {
      value: data.agencyPhoneNumber === '-' ? '' : data.agencyPhoneNumber,
      error: false,
      errorMessage: addEmployeeStrings.errorMessages.minLengthPhoneNo,
    },
    join_date: {
      value: data.join_date,
    },
    capacity: {
      value: data.capacity === null ? '' : data.capacity.toString(),
    },
    description: {
      value: data.note || '',
    },
    branch_id: {
      value: data.branch.id,
    },
    prefix: {
      value: data.branch.prefix,
    },
  };
  const [jobDetails, setJobDetails] = useState(jobDetailsInitialState);

  const updateUser = async () => {
    let updatedData = {
      branch_id: jobDetails.branch_id.value,
      employee_id:
        jobDetails.employee_id.value === '' || jobDetails.employee_id.disabled
          ? null
          : jobDetails.employee_id.value,
      job_title: jobDetails.job_title.value,
      employment_type: jobDetails.employment_type.value.toLowerCase(),
      capacity:
        jobDetails.capacity.value === ''
          ? null
          : Number(jobDetails.capacity.value),
      note:
        jobDetails.description.value === ''
          ? null
          : jobDetails.description.value,
    };
    if (jobDetails.isAgency.value) {
      updatedData.agency_name =
        jobDetails.agency_name.value === ''
          ? null
          : jobDetails.agency_name.value;
      updatedData.agency_email =
        jobDetails.agency_email.value === ''
          ? null
          : jobDetails.agency_email.value;
      updatedData.agency_phone_number =
        jobDetails.agency_phone_number.value === ''
          ? null
          : jobDetails.agency_phone_number.value;
    }
    if (jobDetails.employee_id.value !== data.employeeId) {
      setHistoryData((prev) => [
        {
          description: `Employee ID has been changed from ${data.employeeId} to ${jobDetails.employee_id.value} by ${user.name}`,
          timestamp: Date.now(),
        },
        ...prev,
      ]);
    }
    if (jobDetails.branch_id.value !== data.branch.id) {
      setHistoryData((prev) => [
        {
          description: `Branch has been changed from ${data.branch.name} to ${jobDetails.location.value} by ${user.name}`,
          timestamp: Date.now(),
        },
        ...prev,
      ]);
    }
    if (jobDetails.job_title.value !== data.job_title) {
      setHistoryData((prev) => [
        {
          description: `Job Title has been changed from ${data.job_title} to ${jobDetails.job_title.value} by ${user.name}`,
          timestamp: Date.now(),
        },
        ...prev,
      ]);
    }
    if (
      jobDetails.employment_type.value !==
      makeFirstLetterCapital(data.employment_type)
    ) {
      setHistoryData((prev) => [
        {
          description: `Employeement Type has been changed from ${makeFirstLetterCapital(
            data.employment_type
          )} to ${jobDetails.employment_type.value} by ${user.name}`,
          timestamp: Date.now(),
        },
        ...prev,
      ]);
    }

    if (jobDetails.capacity.value.toString() !== data.capacity.toString()) {
      setHistoryData((prev) => [
        {
          description: `Capacity has been changed from ${data.capacity} to ${jobDetails.capacity.value} by ${user.name}`,
          timestamp: Date.now(),
        },
        ...prev,
      ]);
    }
    if (jobDetails.join_date.value !== data.join_date) {
      updatedData = {
        ...updatedData,
        join_date: moment(jobDetails.join_date.value)
          .utc()
          .format('YYYY-MM-DD HH:mm:ssZZ'),
      };
      setHistoryData((prev) => [
        {
          description: `Join Date has been changed from ${moment(
            data.join_date
          ).format('MMM Do YYYY')} to ${moment(jobDetails.join_date.value)
            .utc()
            .format('MMM Do YYYY')} by ${user.name}`,
          timestamp: Date.now(),
        },
        ...prev,
      ]);
    }

    setIsUpdateUserError(null);
    const response = await networkClient({
      method: methodTypes.patch,
      endpoint: endpointParamsSetter({
        path: networkEndpoints.user.users,
        params: data.id,
      }),
      requestBody: updatedData,
    });
    if (response.error) {
      if (response.errors[0].code === 400) {
        setIsUpdateUserError(false);
        response.errors.forEach((err) => {
          setJobDetails((prevJobDetails) => ({
            ...prevJobDetails,
            [err.field]: {
              ...prevJobDetails[err.field],
              error: true,
              errorMessage: err.message,
            },
          }));
        });
      } else {
        setIsUpdateUserError(true);
      }
      if (response.errors[0].code === 403) {
        handle403Error(navigate, currLocation);
      }
    } else {
      response.data.status = makeFirstLetterCapital(response.data.status);
      showSnackbar(
        employeeDetailsPageStrings.responseMessage.userUpdateSuccess
      );
      if (jobDetails.employee_id.disabled) {
        setJobDetails((prevValue) => ({
          ...prevValue,
          employee_id: {
            ...prevValue.employee_id,
            value: response.data.employee_id ? response.data.employee_id : '',
          },
        }));
      }
      if (!jobDetails.isAgency.value)
        setJobDetails((oldData) => ({
          ...oldData,
          agency_name: { ...oldData.agency_name, value: '' },
          agency_email: { ...oldData.agency_email, value: '' },
          agency_phone_number: { ...oldData.agency_phone_number, value: '' },
        }));
      setEmployeeDetails(response.data);
      setIsUpdateUserError(false);
      setToggleDrawer(false);
    }
  };
  function jobDetailsHasError() {
    const updatedFields = Object.keys(jobDetails).filter(
      (key) =>
        !key.includes(joiningInformationStrings.ignoreFields) &&
        (key === 'employee_id' ? !jobDetails[key].disabled : true) &&
        jobDetails[key].value !== jobDetailsInitialState[key].value
    );

    if (
      jobDetails.location.error ||
      (!jobDetails.employee_id.disabled && jobDetails.employee_id.error) ||
      jobDetails.job_title.error ||
      jobDetails.employment_type.error ||
      jobDetails.agency_name.error ||
      jobDetails.agency_email.error ||
      jobDetails.capacity.error ||
      (!jobDetails.employee_id.disabled &&
        jobDetails.employee_id.value !== '' &&
        jobDetails.location.value !== '' &&
        jobDetails.location.value !== locations.remoteIndia &&
        jobDetails.location.value !== locations.remoteMexico &&
        jobDetails.location.value !== locations.remoteUs &&
        !isValidId(jobDetails.employee_id.value, jobDetails.prefix.value)) ||
      jobDetails.job_title.value === '' ||
      jobDetails.employment_type.value === '' ||
      (jobDetails.isAgency.value && jobDetails.agency_email.value === '') ||
      updatedFields.length === 0
    ) {
      return true;
    }
    return false;
  }

  const fetchbranches = useCallback(async () => {
    setIsBranchLoading(true);
    const response = await networkClient({
      method: 'get',
      endpoint: branchString.getAllBranch,
    });
    setIsBranchLoading(false);
    if (response.error) {
      setBranchError(true);
    } else {
      setBranchError(false);
      setBranchItems(response.data);
    }
  }, []);

  useEffect(() => {
    if (toggleDrawer && branchItems === initialBranchState) fetchbranches();
  }, [fetchbranches, branchItems, toggleDrawer]);

  return (
    <CustomDrawer
      title={title}
      open={toggleDrawer}
      error={isUpdateUserError}
      successButtonDisabled={jobDetailsHasError()}
      successButtonLoading={isUpdateUserError === null}
      onFailureButtonClick={() => {
        setJobDetails(jobDetailsInitialState);
        setToggleDrawer(false);
      }}
      onSuccessButtonClick={updateUser}
      successButtonText={successButtonText}
      failureButtonText={failureButtonText}>
      <Box padding={detailsCardPadding.editDrawerContentPadding}>
        <JoiningDataComponent
          jobDetails={jobDetails}
          setJobDetails={setJobDetails}
          branchItems={branchItems}
          branchError={branchError}
          fetchbranches={fetchbranches}
          isBranchLoading={isBranchLoading}
        />
      </Box>
    </CustomDrawer>
  );
}

EditEmployeeJobDetails.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number,
    employeeId: PropTypes.string,
    job_title: PropTypes.string,
    employment_type: PropTypes.string,
    agencyName: PropTypes.string,
    agencyEmail: PropTypes.string,
    agencyPhoneNumber: PropTypes.string,
    join_date: PropTypes.string,
    capacity: PropTypes.number,
    note: PropTypes.string,
    location: PropTypes.string,

    prefix: PropTypes.string,
    branch: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      prefix: PropTypes.string,
    }),
  }).isRequired,
  title: PropTypes.string.isRequired,
  successButtonText: PropTypes.string.isRequired,
  toggleDrawer: PropTypes.bool.isRequired,
  setToggleDrawer: PropTypes.func.isRequired,
  setEmployeeDetails: PropTypes.func.isRequired,
  failureButtonText: PropTypes.string.isRequired,
  setHistoryData: PropTypes.func.isRequired,
};
