import { Menu, List, Collapse, Typography, Stack, Box } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Error } from '@mui/icons-material';
import theme from '../../styles/theme';
import {
  SoftwareFilterSize,
  apiErrorComponentSize,
  filterDropDownSize,
  addSoftwareSize,
} from '../../utils/dimensions/size';
import CustomTextButton from '../CustomTextButton';
import CustomRadioButton from '../CustomRadioButton';
import {
  customFilterPadding,
  filterDropDownPadding,
} from '../../utils/dimensions/paddings';
import {
  customRadioButtonStrings,
  customSearchBarStrings,
  softwareFilterDropdownStrings,
} from '../../utils/strings';
import CustomListItem from './CustomListItem';
import CustomCheckBox from '../CustomCheckBox';
import CustomSearchBar from '../Inputs/CustomSearchBar';
import { filterDropDownMargins } from '../../utils/dimensions/margins';
import CircularProgressIndicator from '../CircularProgressIndicator';
import ApiErrorComponent from '../ApiErrorComponent';

function SoftwareFilter({
  anchorEl,
  onClose,
  filterState,
  setFilterState,
  initialFilterState,
  clientList,
  fetchClients,
  isClientError,
  isClientLoading,
}) {
  const [searchedClients, setSearchedClients] = useState(clientList);

  useEffect(() => {
    setSearchedClients(clientList);
  }, [clientList]);

  const [openCollapse, setOpenCollapse] = useState(null);
  const handleListItemClick = (index) => {
    if (openCollapse === index) {
      setOpenCollapse(null);
    } else {
      setOpenCollapse(index);
    }
  };
  // Handles the radio button click

  const handleSearch = (e) => {
    const searchData = clientList.filter((data) =>
      data.name.toUpperCase().includes(e.target.value.trim().toUpperCase())
    );
    setSearchedClients(searchData);
  };

  // Handles clear button
  const handleClear = () => {
    setFilterState(initialFilterState); // reset the selected radio button value
    setOpenCollapse(null);
    onClose();
  };

  const countFilter = (filterType) => {
    const filterValue = filterState[filterType];
    const selectedCount = filterValue.length;
    return selectedCount;
  };
  const handleFormControl = (value, type) => {
    if (type === customRadioButtonStrings.type[1]) {
      if (value) {
        setFilterState({
          ...filterState,
          [type]: [value],
        });
      } else {
        setFilterState({
          ...filterState,
          [type]: [],
        });
      }
      return;
    }
    if (type === customRadioButtonStrings.type[0]) {
      const selectedValue = [...filterState[type]];

      const index = selectedValue.indexOf(value);
      if (index >= 0) {
        selectedValue.splice(index, 1);
      } else {
        selectedValue.push(value);
      }
      setFilterState({
        ...filterState,
        [type]: selectedValue,
      });
    }
  };

  return (
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      MenuListProps={{ disablePadding: true }}
      slotProps={{
        backdrop: {
          style: {
            background: 'transparent',
          },
        },
      }}
      onClose={() => {
        onClose();
        setOpenCollapse(null);
      }}
      PaperProps={{
        style: {
          width: 300,
          marginTop: filterDropDownMargins.menuBoxMarginTop,
          marginLeft: filterDropDownMargins.menuBoxMarginLeft,
        },
      }}>
      <List
        sx={{
          display: 'inline',
          paddingBottom: customFilterPadding.menuListPaddingBottom,
          width: SoftwareFilterSize.listWidth,
          maxWidth: SoftwareFilterSize.maxwidth,
          bgcolor: theme.palette.background.paper,
        }}
        component='nav'
        subheader={
          <Stack
            height='55px'
            sx={{
              display: 'flex',
              bgcolor: theme.palette.grey[50],
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              pl: 2,
              pr: 2,
            }}>
            <Typography variant='h6'>
              {customRadioButtonStrings.FilterListLabels[0]}
            </Typography>
            <CustomTextButton onClick={handleClear} istextDecoration='false'>
              <Typography
                variant='buttonSmall'
                sx={{
                  fontWeight: filterDropDownSize.ListItemTextWidth,
                  color: theme.palette.primary.main,
                }}>
                {customRadioButtonStrings.FilterListLabels[1]}
              </Typography>
            </CustomTextButton>
          </Stack>
        }>
        <CustomListItem
          handleListItemClick={handleListItemClick}
          listIndex={0}
          countFilter={countFilter}
          openCollapse={openCollapse}
          itemListName={customRadioButtonStrings.MenulistText[0]}
          itemListType={customRadioButtonStrings.type[0]}
        />
        <Collapse in={openCollapse === 0} timeout='auto'>
          {clientList?.length && (
            <List
              component='div'
              sx={{
                display: 'grid',
                paddingRight: customFilterPadding.RightSpaceFromRadioButton,
                bgcolor: theme.palette.grey[25],
                maxHeight: '200px',
                overflow: 'auto',
                '&::-webkit-scrollbar': {
                  width: addSoftwareSize.managersDropdownScrollbarWidth,
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: theme.palette.grey[300],
                  borderRadius: addSoftwareSize.managersDropdownScrollbarRadius,
                  opacity: 0.7,
                },
              }}>
              <CustomSearchBar
                minWidth={SoftwareFilterSize.minWidth}
                paddingLeft={customFilterPadding.leftPaddingForSearchBar}
                onChange={handleSearch}
                placeholder={customSearchBarStrings.placeHolderForClients}
              />

              {searchedClients?.length > 0 ? (
                <CustomCheckBox
                  variant='body2'
                  type={customRadioButtonStrings.clientValue[0]}
                  checkBoxOption={searchedClients.map((obj) => obj.name)}
                  checkedValue={filterState.clients}
                  onChange={(value) =>
                    handleFormControl(value, customRadioButtonStrings.type[0])
                  }
                />
              ) : (
                <Box
                  display='flex'
                  justifyContent='center'
                  paddingTop={customFilterPadding.clientNotFoundTopPadding}
                  flexDirection='row'>
                  <Stack
                    direction='row'
                    gap={apiErrorComponentSize.gapBetweenComponents}
                    alignItems='center'>
                    <Error
                      sx={{
                        color: theme.palette.grey[500],
                      }}
                    />

                    <Typography
                      variant='body1Medium'
                      color={theme.palette.grey[500]}>
                      {softwareFilterDropdownStrings.clientNotFound}
                    </Typography>
                  </Stack>
                </Box>
              )}
            </List>
          )}

          {isClientLoading ? (
            <List
              component='div'
              sx={{
                padding: filterDropDownPadding.collapseListLeftPadding,
                bgcolor: theme.palette.grey[25],
                maxHeight: filterDropDownSize.listMaxHeight,
                overflowY: 'auto',
              }}>
              <Box display='flex' justifyContent='center'>
                <CircularProgressIndicator />
              </Box>
            </List>
          ) : null}

          {isClientError && !isClientLoading && (
            <List
              component='div'
              sx={{
                padding: filterDropDownPadding.collapseListLeftPadding,
                bgcolor: theme.palette.grey[25],
                maxHeight: filterDropDownSize.listMaxHeight,
                overflowY: 'auto',
              }}>
              <ApiErrorComponent
                errorMessage={softwareFilterDropdownStrings.clientFetchError}
                onRefreshClick={fetchClients}
              />
            </List>
          )}
        </Collapse>

        <CustomListItem
          handleListItemClick={handleListItemClick}
          listIndex={1}
          countFilter={countFilter}
          openCollapse={openCollapse}
          itemListName={customRadioButtonStrings.MenulistText[1]}
          itemListType={customRadioButtonStrings.type[1]}
        />
        <Collapse in={openCollapse === 1} timeout='auto'>
          <List
            component='div'
            sx={{
              display: 'block',
              paddingRight: customFilterPadding.RightSpaceFromRadioButton,
              bgcolor: theme.palette.grey[25],
            }}>
            <CustomRadioButton
              variant='body2'
              radioOption={customRadioButtonStrings.statusValue}
              onChange={(value) =>
                handleFormControl(value, customRadioButtonStrings.type[1])
              }
              checkedValue={filterState.status}
            />
          </List>
        </Collapse>
      </List>
    </Menu>
  );
}
SoftwareFilter.propTypes = {
  anchorEl: PropTypes.shape({}),
  onClose: PropTypes.func.isRequired,
  filterState: PropTypes.shape().isRequired,
  setFilterState: PropTypes.func.isRequired,
  initialFilterState: PropTypes.shape().isRequired,
  clientList: PropTypes.arrayOf(PropTypes.shape()),
  isClientError: PropTypes.bool,
  isClientLoading: PropTypes.bool,
  fetchClients: PropTypes.func.isRequired,
};
SoftwareFilter.defaultProps = {
  anchorEl: null,
  clientList: [],
  isClientLoading: false,
  isClientError: false,
};

export default SoftwareFilter;
