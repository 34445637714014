import moment from 'moment';
import { Box, Stack, Typography, useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import ShowDetailsColumn from '../../ShowDetailsColumn';
import {
  joiningInformationStrings,
  tableCellStrings,
} from '../../../utils/strings';
import { employeeDetailsPagePaddings } from '../../../utils/dimensions/paddings';
import formatValueIfNull from '../../../utils/formatValueIfNull';
import makeFirstLetterCapital from '../../../utils/makeFirstLetterCapital';

function JobDetails({ title, data }) {
  const theme = useTheme();
  return (
    <Box padding={employeeDetailsPagePaddings.customDetailsBoxPadding}>
      <Stack display='flex' spacing={2}>
        <Typography variant='body1Medium' color={theme.palette.text.primary}>
          {title}
        </Typography>
        <Stack direction='row'>
          <ShowDetailsColumn
            flex='1'
            label={joiningInformationStrings.placeHolder.employee_id}>
            {formatValueIfNull(data.employee_id)}
          </ShowDetailsColumn>
          <ShowDetailsColumn
            flex='1'
            label={joiningInformationStrings.placeHolder.job_title}>
            {formatValueIfNull(data.job_title)}
          </ShowDetailsColumn>
          <ShowDetailsColumn
            flex='1'
            label={joiningInformationStrings.placeHolder.employment_type}>
            {makeFirstLetterCapital(formatValueIfNull(data.employment_type))}
          </ShowDetailsColumn>
        </Stack>

        {data.agency_id && (
          <Stack direction='row'>
            <ShowDetailsColumn
              flex='1'
              label={joiningInformationStrings.placeHolder.agencyName}>
              {formatValueIfNull(data.Agency.name)}
            </ShowDetailsColumn>
            <ShowDetailsColumn
              flex='2'
              label={joiningInformationStrings.placeHolder.agencyEmail}>
              {formatValueIfNull(data.Agency.email)}
            </ShowDetailsColumn>
          </Stack>
        )}

        <Stack direction='row'>
          <ShowDetailsColumn
            flex='1'
            label={joiningInformationStrings.placeHolder.join_date}>
            {moment(data.join_date).format('MMM DD, YYYY')}
          </ShowDetailsColumn>
          <ShowDetailsColumn
            flex='1'
            label={joiningInformationStrings.placeHolder.end_date}>
            {data.end_date
              ? moment(data.end_date).format('MMM DD, YYYY')
              : tableCellStrings.dash}
          </ShowDetailsColumn>
          <ShowDetailsColumn
            flex='1'
            label={joiningInformationStrings.placeHolder.capacity}>
            <Stack direction='row' alignItems='center'>
              {data.capacity !== null ? (
                <>
                  <Typography variant='body2SemiBold'>
                    {`${data.capacity} ${joiningInformationStrings.capacityInHours}`}
                  </Typography>
                  <Typography variant='body2'>
                    &nbsp;{joiningInformationStrings.capacityInWeek}
                  </Typography>
                </>
              ) : (
                <Typography variant='body2'>{tableCellStrings.dash}</Typography>
              )}
            </Stack>
          </ShowDetailsColumn>
        </Stack>

        <ShowDetailsColumn
          flex='1'
          label={joiningInformationStrings.placeHolder.description}>
          {formatValueIfNull(data.note)}
        </ShowDetailsColumn>
      </Stack>
    </Box>
  );
}

JobDetails.propTypes = {
  title: PropTypes.string,
  data: PropTypes.shape().isRequired,
};
JobDetails.defaultProps = {
  title: '',
};
export default JobDetails;
