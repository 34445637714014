// this function return js object of parameters provided.
function createData(nameData, date, notes) {
  return { nameData, date, notes };
}

// rows has the softwares data
export const rows = [
  createData(
    {
      imageUrl:
        'https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=774&q=80',
      name: 'Robert Fox',
      postion: 'Software Engineer',
    },
    'May 18, 2022',
    'View'
  ),
  createData(
    {
      imageUrl:
        'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS_bv-bbQ5fudQZFzSsYMyDyuVmzEshkfS9-Iu2bIPnUBQFKjoqFzRLeXuQJsrLWRTxzj0&usqp=CAU',
      name: 'Darell Steward',
      postion: 'Software Engineer',
    },
    'June 8, 2021',
    'View'
  ),
  createData(
    {
      imageUrl:
        'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTxQUZDn3SdaD55Kk5N5-OQwzG6vhK58Oep8Q&usqp=CAU',
      name: 'Cody Fisher',
      postion: 'Software Engineer',
    },
    'May 3, 2020',
    'View'
  ),
  createData(
    {
      imageUrl: 'https://pbs.twimg.com/media/D8dDZukXUAAXLdY.jpg',
      name: 'Arlene McCoy',
      postion: 'Software Engineer',
    },
    'May 3, 2020',
    'View'
  ),
  createData(
    {
      imageUrl:
        'https://images.unsplash.com/photo-1492562080023-ab3db95bfbce?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1748&q=80',
      name: 'Gaurav Chopda',
      postion: 'Software Engineer',
    },
    'May 3, 2020',
    'View'
  ),
];

export const projectDetail = {
  noOfEmployees: '15 Employees',
  manager: {
    imageUrl:
      'https://hips.hearstapps.com/hmg-prod/images/gettyimages-1229892983-square.jpg',
    name: 'Sean Aslam',
  },
  lead: {
    imageUrl:
      'https://hips.hearstapps.com/hmg-prod/images/gettyimages-1229892983-square.jpg',
    name: 'Hardik Savaliya',
  },
  duration: '300 Hrs',
  type: 'Internal Project',
  description: 'This project is in-house project of tech holding.',
};

// headCells has data for Table Head data.
export const headCells = [
  {
    id: 'nameData',
    dataType: 'employeeName',
    label: 'Name',
    flex: 7,
    sortingNeeded: false,
  },
  {
    id: 'date',
    dataType: 'text',
    label: 'Assign Date',
    flex: 4,
  },
  {
    id: 'note',
    dataType: 'notesLink',
    label: 'Note',
    flex: 4,
  },
];
