import { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { assignSoftwareDrawerMargins } from '../../utils/dimensions/margins';
import { assignSoftwareDrawerStrings } from '../../utils/strings';
import CustomDrawer from '../CustomDrawer';
import networkClient from '../../networks/networkClient';
import ApiErrorComponent from '../ApiErrorComponent';
import { assignSoftwareDrawerPaddings } from '../../utils/dimensions/paddings';
import ResourceSelectComponent from '../EmployeeActions/ResourceSelectComponent';
import { methodTypes, networkEndpoints } from '../../networks/networkStrings';
import { handle403Error } from '../../utils/errorHandler';
import { snackbarContext } from '../../utils/context';
import CircularProgressIndicator from '../CircularProgressIndicator';
import makeFirstLetterCapital from '../../utils/makeFirstLetterCapital';

function AssignSoftwareDrawer({
  openAssignSoftwareDrawer,
  setOpenAssignSoftwareDrawer,
  userId,
  assignDrawerSoftware,
  isFetchSoftwaresLoading,
  fetchAssignDrawerSoftwares,
  employeeDetails,
  setHistoryData,
  setTableData,
  setActionButton,
  user,
}) {
  let loginUserId;
  if (user) {
    loginUserId = user.userId;
  }
  const selectedSoftwareInitialState = [];
  const { showSnackbar } = useContext(snackbarContext);

  const [selectedSoftwareIds, setSelectedSoftwareIds] = useState(
    selectedSoftwareInitialState
  );
  const [softwareDetails, setSoftwareDetails] = useState(assignDrawerSoftware);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    setSoftwareDetails(assignDrawerSoftware);
  }, [assignDrawerSoftware]);

  // Handles checkbox selection(selected values will map with software id associated with that software)
  const handleSelection = (id) => {
    setSoftwareDetails((prevSoftwareDetails) =>
      prevSoftwareDetails.map((software) =>
        software.id === id
          ? { ...software, isSelected: !software.isSelected }
          : software
      )
    );
    // if id is not included it will include in this will add id to selectedSoftwareIds
    if (selectedSoftwareIds.includes(id)) {
      setSelectedSoftwareIds(
        selectedSoftwareIds.filter((selId) => selId !== id)
      );
    } else {
      setSelectedSoftwareIds([...selectedSoftwareIds, id]);
    }
  };
  const [softwareRequestError, setSoftwareRequestError] = useState(false);
  // Manges Assign button click
  const handleAssignButtonClick = async () => {
    setSoftwareRequestError(null);
    const response = await networkClient({
      method: methodTypes.post,
      endpoint: `${networkEndpoints.user.user}${userId}${networkEndpoints.user.requestsoftware}`,
      requestBody: {
        softwareList: selectedSoftwareIds.map((softwareId) => ({
          software_id: softwareId,
        })),
      },
    });

    if (response.error) {
      setSoftwareRequestError(true);
      if (response.errors[0].code === 403) {
        handle403Error(navigate, location);
      }
    } else {
      setSelectedSoftwareIds(selectedSoftwareInitialState);
      const newRequestedSoftwares = response.data.success.map((softwareObj) => {
        const softwareIndex = softwareDetails.findIndex(
          (obj) => obj.id === softwareObj.software_id
        );
        setHistoryData((prev) => [
          {
            description: `${softwareDetails[softwareIndex].name} software requested for ${employeeDetails.first_name} ${employeeDetails.last_name} by ${user.name}`,
            timestamp: Date.now(),
          },
          ...prev,
        ]);

        return {
          imageUrl: softwareDetails[softwareIndex].icon,
          name: {
            softwareName: softwareDetails[softwareIndex].name,
            isManager: softwareDetails[softwareIndex].Managers.some(
              (manager) => manager.id === loginUserId
            ),
            actionBy: user.given_name,
            fullName: user.name,
            status: makeFirstLetterCapital(softwareObj.status),
          },
          assignDate: softwareObj.assign_date,
          ownership: softwareDetails[softwareIndex].Client.name,
          username: null,
          status: makeFirstLetterCapital(softwareObj.status),
          actionButton: setActionButton({
            status: makeFirstLetterCapital(softwareObj.status),
            userSoftwareId: softwareObj.id,
            managers: softwareDetails[softwareIndex].Managers.map(
              (obj) => obj.id
            ),
            imageUrl: softwareDetails[softwareIndex].icon,
            name: softwareDetails[softwareIndex].name,
            lastEmailDate: softwareObj.last_email_date,
          }),
          softwareId: softwareObj.software_id,
        };
      });

      setSoftwareDetails((oldSoftwareDetails) =>
        oldSoftwareDetails.filter(
          (softObj) => !selectedSoftwareIds.some((obj) => obj === softObj.id)
        )
      );

      setTableData((oldTableData) => [
        ...newRequestedSoftwares,
        ...oldTableData,
      ]);
      setSoftwareRequestError(false);
      setOpenAssignSoftwareDrawer(false);

      showSnackbar(assignSoftwareDrawerStrings.softwareRequestSuccessMessage);
    }
  };

  // const isLocationIndia = () => employeeDetails.Branch.name.includes('India');
  return (
    <div>
      <CustomDrawer
        error={softwareRequestError}
        successButtonLoading={softwareRequestError === null}
        successButtonDisabled={selectedSoftwareIds.length <= 0}
        open={openAssignSoftwareDrawer}
        title={assignSoftwareDrawerStrings.title}
        successButtonText={assignSoftwareDrawerStrings.successButtonText}
        failureButtonText={assignSoftwareDrawerStrings.failureButtonText}
        onFailureButtonClick={() => {
          setSelectedSoftwareIds(selectedSoftwareInitialState);
          setSoftwareDetails(softwareDetails);
          setOpenAssignSoftwareDrawer(false);
        }}
        onSuccessButtonClick={() => {
          handleAssignButtonClick();
        }}>
        <Box
          display='flex'
          flexDirection='column'
          marginX={assignSoftwareDrawerMargins.marginX}
          marginY={assignSoftwareDrawerMargins.marginY}>
          {!assignDrawerSoftware && isFetchSoftwaresLoading && (
            <Box
              paddingTop={assignSoftwareDrawerPaddings.errorBox}
              display='flex'
              alignItems='center'
              justifyContent='center'>
              <CircularProgressIndicator />
            </Box>
          )}
          {!assignDrawerSoftware && !isFetchSoftwaresLoading && (
            <Box paddingTop={assignSoftwareDrawerPaddings.errorBox}>
              <ApiErrorComponent onRefreshClick={fetchAssignDrawerSoftwares} />
            </Box>
          )}

          {softwareDetails?.length === 0 ? (
            <Box display='flex' justifyContent='center'>
              <Typography variant='h5'>
                {assignSoftwareDrawerStrings.noSoftwareFound}
              </Typography>
            </Box>
          ) : (
            <ResourceSelectComponent
              softwareDetails={softwareDetails || []}
              handleSelections={(id) => handleSelection(id)}
              applyLocationFilter={false}
            />
          )}
        </Box>
      </CustomDrawer>
    </div>
  );
}

AssignSoftwareDrawer.propTypes = {
  openAssignSoftwareDrawer: PropTypes.bool.isRequired,
  setOpenAssignSoftwareDrawer: PropTypes.func.isRequired,
  fetchAssignDrawerSoftwares: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
  isFetchSoftwaresLoading: PropTypes.bool.isRequired,
  assignDrawerSoftware: PropTypes.arrayOf(PropTypes.shape({})),
  employeeDetails: PropTypes.shape({
    Branch: PropTypes.shape({
      name: PropTypes.string,
    }),
    first_name: PropTypes.string,
    last_name: PropTypes.string,
  }).isRequired,
  user: PropTypes.shape({
    name: PropTypes.string,
    userId: PropTypes.number,
    given_name: PropTypes.string,
    role: PropTypes.number,
  }).isRequired,
  setHistoryData: PropTypes.func.isRequired,
  setTableData: PropTypes.func.isRequired,
  setActionButton: PropTypes.func.isRequired,
};

AssignSoftwareDrawer.defaultProps = {
  assignDrawerSoftware: [],
};

export default AssignSoftwareDrawer;
