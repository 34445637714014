import { useCallback, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Search } from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Box,
  Chip,
  Divider,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import {
  addSkillsModalStrings,
  customDrawerStrings,
} from '../../utils/strings';
import CustomModal from '../CustomModal';
import { addSkillsPaddings } from '../../utils/dimensions/paddings';
import {
  addSkillsModalSizes,
  addSoftwareSize,
} from '../../utils/dimensions/size';
import networkClient from '../../networks/networkClient';
import { methodTypes, networkEndpoints } from '../../networks/networkStrings';
import CircularProgressIndicator from '../CircularProgressIndicator';
import endpointParamsSetter from '../../utils/endpointParamsSetter';
import ApiErrorComponent from '../ApiErrorComponent';
import { handle403Error } from '../../utils/errorHandler';
import { snackbarContext } from '../../utils/context';
import { isOnlyNumbers } from '../../utils/validators';

function AddSkillsModal({
  openAddSkillsModal,
  setOpenAddSkillsModal,
  defaultData,
  userId,
  setUserSkills,
}) {
  const { showSnackbar } = useContext(snackbarContext);
  const theme = useTheme();
  const searchbarInitialState = '';
  const allSkillsInitialState = null;

  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(
    customDrawerStrings.defaultErrorMessage
  );

  const [searchbarText, setSearchbarText] = useState(searchbarInitialState);
  const [allSkills, setAllSkills] = useState(allSkillsInitialState);
  const [isSkillsLoading, setSkillsLoading] = useState(true);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [isSearchbarFocused, setIsSearchbarFocused] = useState(false);
  const [duplicateSkillsError, setDuplicateSkillsError] = useState(false);
  const [selectedSkills, setSelectedSkills] = useState([...defaultData]);

  const location = useLocation();
  const navigate = useNavigate();
  // set user skills provided in props as selected skills.
  useEffect(() => {
    setSelectedSkills([...defaultData]);
    setUserSkills(defaultData);
  }, [defaultData, setUserSkills]);

  // function for fetching all skills from db.
  const fetchAllSkills = useCallback(async () => {
    const allSkillsData = await networkClient({
      method: methodTypes.get,
      endpoint: networkEndpoints.user.getAllSkills,
    });
    setSkillsLoading(false);
    if (allSkillsData.error) {
      if (allSkillsData.errors[0].code === 403) {
        handle403Error(navigate, location);
      }
      setAllSkills(null);
    } else {
      const remainingSkills = allSkillsData.data.skills.map((skill) => {
        if (selectedSkills.some((obj) => obj.id === skill.id)) {
          return {
            ...skill,
            selected: true,
          };
        }
        return {
          ...skill,
          selected: false,
        };
      });
      setAllSkills(remainingSkills);
    }
  }, [selectedSkills, navigate, location]);

  const postSkills = async () => {
    setIsButtonLoading(true);
    const requestBody = {
      skills: selectedSkills
        .filter((elements) => elements.selected)
        .filter(
          (skill) => !defaultData.map((data) => data.id).includes(skill.id)
        )
        .map((elements) => elements.name),
    };
    const deleteSkills = defaultData
      .filter(
        (defaultSkill) =>
          !selectedSkills.some(
            (selectedSkill) => selectedSkill.id === defaultSkill.id
          )
      )
      .map((deletedSkill) => deletedSkill.id);

    let postSkillsResponse;
    let deleteSkillsResponse;

    if (requestBody.skills?.length) {
      postSkillsResponse = networkClient({
        method: methodTypes.post,
        endpoint: endpointParamsSetter({
          path: networkEndpoints.user.postSkills,
          params: userId,
        }),
        requestBody,
      });
    }
    if (deleteSkills.length) {
      deleteSkillsResponse = await networkClient({
        method: methodTypes.delete,
        endpoint: endpointParamsSetter({
          path: networkEndpoints.user.deleteUserSkills,
          params: userId,
        }),
        requestBody: { skills: deleteSkills },
      });
    }
    Promise.all([postSkillsResponse, deleteSkillsResponse]).then(
      (responses) => {
        const promisePostSkillsResponse = responses[0];
        const promiseDeleteSkillsResponse = responses[1];

        // Handle the responses from Post skills API.
        if (promisePostSkillsResponse?.error) {
          setIsError(true);
          if (
            promisePostSkillsResponse.errors[0].code === 409 ||
            promisePostSkillsResponse.errors[0].code === 400
          ) {
            setErrorMessage(promisePostSkillsResponse.errors[0].message);
          } else if (promisePostSkillsResponse.errors[0].code === 403) {
            handle403Error(navigate, location);
          } else {
            setErrorMessage(customDrawerStrings.defaultErrorMessage);
          }
        } else if (promisePostSkillsResponse) {
          const newUserSkills = promisePostSkillsResponse.data.skills.map(
            (skill) => skill
          );
          setUserSkills((prev) => [...prev, ...newUserSkills]);
        }

        // Handle the responses from delete skills API.
        if (promiseDeleteSkillsResponse?.error) {
          setIsError(true);
          setErrorMessage(customDrawerStrings.defaultErrorMessage);
        } else {
          setUserSkills((prev) =>
            prev.filter((skill) => !deleteSkills.includes(skill.id))
          );
        }

        if (
          !promisePostSkillsResponse?.error &&
          !promiseDeleteSkillsResponse?.error
        ) {
          setIsError(false);
          setSearchbarText(searchbarInitialState);
          showSnackbar(addSkillsModalStrings.addedSkillsSuccess);
          setOpenAddSkillsModal(false);
        }

        setIsButtonLoading(false);

        const updatedAllSkills = [...allSkills];
        selectedSkills.forEach((skill) => {
          const oldSkill = updatedAllSkills.some(
            (obj) => obj.name === skill.name
          );

          if (!oldSkill) {
            updatedAllSkills.push({
              ...skill,
            });
          }
        });
        setAllSkills(updatedAllSkills);
        setIsButtonLoading(false);
      }
    );
  };

  const onDeleteSkill = (skill, index) => {
    const skillIndex = allSkills.findIndex(
      (element) => element.name === skill.name
    );
    if (skillIndex >= 0) allSkills[skillIndex].selected = false;
    selectedSkills.splice(index, 1);
    setAllSkills([...allSkills]);
  };

  const onEnterKeyPressed = (e) => {
    if (e.key === 'Enter' && e.target.value.trim().length > 0) {
      if (
        selectedSkills.some(
          (skillObj) =>
            skillObj.name.toLowerCase() === e.target.value.toLowerCase()
        )
      ) {
        setDuplicateSkillsError(true);
      } else {
        selectedSkills.push({
          name: e.target.value,
          selected: true,
        });
        const allSkillIndex = allSkills.findIndex(
          (obj) => obj.name === e.target.value
        );
        if (allSkillIndex >= 0) allSkills[allSkillIndex].selected = true;
        setDuplicateSkillsError(false);
        setSearchbarText(searchbarInitialState);
        setSelectedSkills([...selectedSkills]);
      }
    }
  };

  const getSearchbarColor = () => {
    if (duplicateSkillsError) return theme.palette.error.main;
    if (isSearchbarFocused) return theme.palette.info.main;
    return theme.palette.grey[100];
  };

  // fetches all skills.
  useEffect(() => {
    if (openAddSkillsModal && allSkills === allSkillsInitialState) {
      fetchAllSkills();
    }
  }, [fetchAllSkills, openAddSkillsModal, allSkills]);

  // Change in skills check.
  function changeSkillsCheck() {
    return (
      defaultData.length === selectedSkills.length &&
      defaultData.every((obj) =>
        selectedSkills.some((sObj) => sObj.name === obj.name)
      )
    );
  }
  return (
    <div>
      <CustomModal
        error={isError}
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
        open={openAddSkillsModal}
        title={addSkillsModalStrings.title}
        successButtonLoading={isButtonLoading}
        successButtonDisabled={changeSkillsCheck()}
        successButtonText={addSkillsModalStrings.successButtonText}
        failureButtonText={addSkillsModalStrings.failureButtonText}
        onFailureButtonClick={() => {
          setOpenAddSkillsModal(false);
          setSearchbarText(searchbarInitialState);
          setSelectedSkills([...defaultData]);
          if (allSkills) {
            setAllSkills((prev) =>
              prev.map((skill) => {
                if (defaultData.find((item) => item.name === skill.name))
                  return {
                    ...skill,
                    selected: true,
                  };
                return {
                  ...skill,
                  selected: false,
                };
              })
            );
          }
        }}
        onSuccessButtonClick={postSkills}>
        <Box display='flex' flexDirection='column'>
          <Box
            onClick={() => setIsSearchbarFocused(true)}
            sx={{
              maxHeight: addSkillsModalSizes.searchBarMaxHeight,
              overflow: 'scroll',
              border: `solid 1px ${getSearchbarColor()}`,
              backgroundColor: theme.palette.grey[25],
              padding: addSkillsPaddings.contentPadding,
              width: addSkillsModalSizes.contentWidth,
            }}>
            <Stack direction='row'>
              <Search
                sx={{
                  padding: addSkillsPaddings.searchIconPadding,
                  color: theme.palette.grey[300],
                }}
              />
              <Box width={addSkillsModalSizes.sizeBetweenChips} />
              <Box
                sx={{
                  gap: addSkillsModalSizes.sizeBetweenChips,
                  flex: 1,
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                }}>
                {selectedSkills.map((skill, index) => (
                  <Chip
                    key={JSON.stringify(index)}
                    label={skill.name}
                    onDelete={() => onDeleteSkill(skill, index)}
                    sx={{
                      backgroundColor:
                        theme.palette.info.states.outlinedHoverBackground,
                      border: `1px solid ${theme.palette.info.states.outlinedRestingBorder}`,
                      borderRadius: addSkillsModalSizes.skillChipRadius,
                    }}
                  />
                ))}

                <Box display='flex' flex={1}>
                  <TextField
                    InputProps={{ disableUnderline: true }}
                    variant='standard'
                    fullWidth
                    placeholder={
                      selectedSkills.length <= 0
                        ? addSkillsModalStrings.searchbarPlaceholder
                        : null
                    }
                    sx={{
                      flex: 1,
                      width: searchbarText.length * 8,
                      '& .MuiInputBase-input': {
                        padding: addSkillsPaddings.searchbarPadding,
                      },
                    }}
                    onBlur={() => {
                      setIsSearchbarFocused(false);
                    }}
                    value={searchbarText}
                    onChange={(e) => {
                      const inputText = e.target.value;
                      if (
                        inputText.length < 50 &&
                        (!isOnlyNumbers(inputText) || inputText === '')
                      ) {
                        if (
                          !selectedSkills.some(
                            (skillObj) =>
                              skillObj.name.toLowerCase() ===
                              e.target.value.toLowerCase()
                          )
                        ) {
                          setDuplicateSkillsError(false);
                        }
                        setSearchbarText(e.target.value);
                      }
                    }}
                    inputRef={(input) =>
                      input && isSearchbarFocused && input.focus()
                    }
                    onKeyUp={(e) => onEnterKeyPressed(e)}
                  />
                </Box>
              </Box>
            </Stack>
          </Box>
          {duplicateSkillsError && (
            <Typography variant='caption' color={theme.palette.error.main}>
              {addSkillsModalStrings.duplicateSkillsMessage}
            </Typography>
          )}
          <Box height={addSkillsModalSizes.spaceBelowSearchbar} />
          {!isSkillsLoading &&
            allSkills !== null &&
            allSkills.filter((obj) => !obj.selected).length > 0 && (
              <Box
                height={addSkillsModalSizes.skillsListHeight}
                overflow='scroll'
                sx={{
                  '&::-webkit-scrollbar': {
                    width: addSoftwareSize.managersDropdownScrollbarWidth,
                  },
                  '&::-webkit-scrollbar-thumb': {
                    backgroundColor: theme.palette.grey[300],
                    borderRadius:
                      addSoftwareSize.managersDropdownScrollbarRadius,
                    opacity: 0.7,
                  },
                }}>
                {allSkills.map((skill, index) => {
                  if (
                    !skill.selected &&
                    skill.name
                      .toLowerCase()
                      .includes(searchbarText.trim().toLowerCase())
                  )
                    return (
                      <Box
                        key={JSON.stringify(index)}
                        sx={{
                          '&:hover': {
                            backgroundColor: theme.palette.grey[25],
                          },
                        }}
                        onClick={() => {
                          selectedSkills.push(allSkills[index]);
                          allSkills[index].selected = true;
                          setSearchbarText('');
                          setAllSkills([...allSkills]);
                        }}>
                        <Typography
                          padding={addSkillsPaddings.skillsTextPadding}
                          variant='subtitle2'
                          color={theme.palette.text.primary}>
                          {skill.name}
                        </Typography>
                        <Divider />
                      </Box>
                    );
                  return null;
                })}
              </Box>
            )}
          {!isSkillsLoading &&
            allSkills !== null &&
            allSkills.filter((obj) => !obj.selected).length <= 0 && (
              <Box
                height={addSkillsModalSizes.skillsListHeight}
                display='flex'
                justifyContent='center'>
                <Typography
                  sx={{ paddingTop: addSkillsPaddings.noSkillsFoundPadding }}
                  variant='caption'>
                  {addSkillsModalStrings.skillNotFound}
                </Typography>
              </Box>
            )}
          {isSkillsLoading && (
            <Box
              minHeight={addSkillsModalSizes.skillsListHeight}
              display='flex'
              flex={1}
              alignItems='center'
              justifyContent='center'>
              <CircularProgressIndicator />
            </Box>
          )}
          {allSkills === null && !isSkillsLoading && (
            <Box
              minHeight={addSkillsModalSizes.skillsListHeight}
              display='flex'
              flex={1}
              alignItems='center'
              justifyContent='center'>
              <ApiErrorComponent onRefreshClick={() => fetchAllSkills()} />
            </Box>
          )}
        </Box>
      </CustomModal>
    </div>
  );
}

AddSkillsModal.propTypes = {
  openAddSkillsModal: PropTypes.bool.isRequired,
  setOpenAddSkillsModal: PropTypes.func.isRequired,
  defaultData: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  userId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  setUserSkills: PropTypes.func.isRequired,
};

export default AddSkillsModal;
