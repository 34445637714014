import { Alert, Slide, Snackbar, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { snackbarStrings } from '../utils/strings';

function CustomSnackBar({
  alertMessage,
  open,
  setOpen,
  verticalAlign,
  horizontalAlign,
  severity,
}) {
  const handleSuccessToastClose = () => {
    setOpen(false);
  };
  return (
    <Snackbar
      open={open}
      onClose={handleSuccessToastClose}
      autoHideDuration={6000}
      TransitionComponent={Slide}
      TransitionProps={{ direction: 'left' }}
      anchorOrigin={{ vertical: verticalAlign, horizontal: horizontalAlign }}>
      <Alert severity={severity}>
        <Typography variant='body2SemiBold'>{alertMessage}</Typography>
      </Alert>
    </Snackbar>
  );
}

CustomSnackBar.propTypes = {
  alertMessage: PropTypes.string,
  open: PropTypes.bool,
  setOpen: PropTypes.func.isRequired,
  verticalAlign: PropTypes.string,
  horizontalAlign: PropTypes.string,
  severity: PropTypes.string,
};
CustomSnackBar.defaultProps = {
  open: false,
  alertMessage: '',
  verticalAlign: 'top',
  horizontalAlign: 'right',
  severity: snackbarStrings.variant.success,
};

export default CustomSnackBar;
