import { Avatar, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { customAvatarSizes, detailsPageSize } from '../utils/dimensions/size';
import randomColor from '../utils/randomColorGenerator';

function CustomAvatar({ name, height, width, src }) {
  const avatarProps = {
    alt: name,
    sx: {
      width,
      height,
      bgcolor: randomColor(name),
    },
  };

  if (src) {
    avatarProps.src = src;
  }
  return (
    <Avatar {...avatarProps}>
      <Typography
        variant='avatarInitials'
        fontSize={customAvatarSizes.fontSize}>
        {name
          ?.match(/(\b\S)?/g)
          .join('')
          .match(/(^\S|\S$)?/g)
          .join('')
          .toUpperCase()}
      </Typography>
    </Avatar>
  );
}

CustomAvatar.propTypes = {
  name: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
  src: PropTypes.string,
};

CustomAvatar.defaultProps = {
  name: null,
  height: detailsPageSize.profileImageSize,
  width: detailsPageSize.profileImageSize,
  src: null,
};

export default CustomAvatar;
