import {
  Alert,
  Box,
  Container,
  Divider,
  Drawer,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import PropTypes from 'prop-types';
import { customDrawerPaddings } from '../utils/dimensions/paddings';
import { customDrawerSize } from '../utils/dimensions/size';
import CustomFilledIconButton from './CustomFilledIconButton';
import CustomOutlinedIconButton from './CustomOutlinedIconButton';
import { customDrawerStrings } from '../utils/strings';
import CustomTextButton from './CustomTextButton';

export default function CustomDrawer({
  anchor,
  open,
  onClose,
  title,
  headerTailElement,
  subtitle,
  children,
  failureButtonText,
  onFailureButtonClick,
  successButtonText,
  onSuccessButtonClick,
  successButtonDisabled,
  successButtonLoading,
  error,
  errorMessage,
  minWidth,
  maxWidth,
  isCancelRequired,
  onBackButtonClick,
}) {
  const theme = useTheme();
  return (
    <div>
      {/* Drawer is a MUI component for rendering Drawer */}
      <Drawer anchor={anchor} open={open} onClose={onClose}>
        <Box
          sx={{
            height: customDrawerSize.drawerHeight,
            minWidth,
            maxWidth,
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
            backgroundColor: theme.palette.background.paper,
          }}>
          <Container
            disableGutters
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              height:
                successButtonText === '' && failureButtonText === ''
                  ? customDrawerSize.fullHeight
                  : customDrawerSize.titleAndChildrenHeight,
            }}>
            <Container disableGutters>
              <Stack
                direction='row'
                justifyContent='space-between'
                alignItems='center'>
                <Stack
                  sx={{
                    padding: customDrawerPaddings.titleSectionPadding,
                  }}>
                  <Typography variant='h5SemiBold'>{title}</Typography>

                  {subtitle !== '' && (
                    <>
                      <Box
                        height={customDrawerSize.spaceBetweenTitleAndSubTitle}
                      />
                      <Typography>{subtitle}</Typography>
                    </>
                  )}
                </Stack>
                <Box
                  sx={{
                    paddingRight: customDrawerPaddings.titleSectionPadding,
                  }}>
                  {headerTailElement}
                </Box>
              </Stack>
              <Divider />
            </Container>
            <Container disableGutters>
              {error && (
                <Alert
                  sx={{
                    backgroundColor:
                      theme.palette.error.states.outlinedHoverBackground,
                    border: `1px solid ${theme.palette.error.light}`,
                    color: theme.palette.error.dark,
                    '& .MuiAlert-icon': {
                      color: theme.palette.error.dark,
                    },
                  }}
                  severity='error'>
                  <Typography variant='body2SemiBold'>
                    {errorMessage}
                  </Typography>
                </Alert>
              )}
            </Container>
            <Container
              disableGutters
              sx={{
                overflow: 'scroll',
              }}>
              {children}
            </Container>
          </Container>
          {(successButtonText !== '' || failureButtonText !== '') && (
            <Container
              disableGutters
              sx={{
                height: customDrawerSize.buttonSectionHeight,
                padding: customDrawerPaddings.buttonSectionPadding,
                display: 'flex',
                justifyContent: isCancelRequired ? 'space-between' : 'end',
              }}>
              {isCancelRequired && (
                <Box display='flex'>
                  <CustomTextButton onClick={onBackButtonClick}>
                    {customDrawerStrings.backButtonText}
                  </CustomTextButton>
                </Box>
              )}

              <Stack direction='row'>
                {failureButtonText && (
                  <CustomOutlinedIconButton onClick={onFailureButtonClick}>
                    {failureButtonText}
                  </CustomOutlinedIconButton>
                )}
                {successButtonText && (
                  <>
                    <Box width={customDrawerSize.spaceBetweenBottomButtons} />
                    <CustomFilledIconButton
                      disabled={successButtonDisabled}
                      text={successButtonText}
                      onClick={onSuccessButtonClick}
                      isLoading={successButtonLoading}
                    />
                  </>
                )}
              </Stack>
            </Container>
          )}
        </Box>
      </Drawer>
    </div>
  );
}

CustomDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  successButtonText: PropTypes.string,
  failureButtonText: PropTypes.string,
  children: PropTypes.element.isRequired,
  onFailureButtonClick: PropTypes.func,
  onSuccessButtonClick: PropTypes.func,
  onBackButtonClick: PropTypes.func,
  onClose: PropTypes.func,
  headerTailElement: PropTypes.element,
  successButtonDisabled: PropTypes.bool,
  isCancelRequired: PropTypes.bool,
  anchor: PropTypes.oneOf(customDrawerStrings.possibleAnchorValues),
  errorMessage: PropTypes.string,
  error: PropTypes.bool,
  successButtonLoading: PropTypes.bool,
  minWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  maxWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

CustomDrawer.defaultProps = {
  subtitle: '',
  anchor: customDrawerStrings.anchor,
  headerTailElement: null,
  successButtonDisabled: false,
  isCancelRequired: false,
  successButtonText: '',
  failureButtonText: '',
  onFailureButtonClick: () => {},
  onSuccessButtonClick: () => {},
  onBackButtonClick: () => {},
  error: false,
  successButtonLoading: false,
  errorMessage: customDrawerStrings.defaultErrorMessage,
  onClose: () => {},
  minWidth: customDrawerSize.drawerMinWidth,
  maxWidth: customDrawerSize.drawerMaxWidth,
};
