import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import redirectPaths from '../Router/RedirectPaths';
import { ReactComponent as LogoWithTitle } from '../utils/assets/LogoWithTitle.svg';
import { logoSectionSize } from '../utils/dimensions/size';

function LogoSection({ setTabValue }) {
  const navigate = useNavigate();
  return (
    <Box
      onClick={() => {
        setTabValue(redirectPaths.homePath.to);
        navigate(redirectPaths.homePath.to);
      }}
      sx={{
        cursor: 'pointer',
        height: logoSectionSize.logoSectionHeight,
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'center',
      }}>
      <LogoWithTitle />
    </Box>
  );
}

LogoSection.propTypes = {
  setTabValue: PropTypes.func.isRequired,
};

export default LogoSection;
