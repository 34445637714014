import { Add, FilterAlt } from '@mui/icons-material';
import { Badge, Typography, useTheme, Box, Stack } from '@mui/material';
import { useState, useEffect, useCallback, useContext, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import CustomFilledIconButton from '../../components/CustomFilledIconButton';
import CustomOutlinedIconButton from '../../components/CustomOutlinedIconButton';
import CustomSearchBar from '../../components/Inputs/CustomSearchBar';
import CustomTable from '../../components/Table/CustomTable';
import { commonPagePaddings } from '../../utils/dimensions/paddings';
import { softwarePageSize } from '../../utils/dimensions/size';
import {
  softwaresPageStrings,
  addSoftwareStrings,
  customTableStrings,
  paginationStrings,
} from '../../utils/strings';
import headCells from './SoftwareTableData';
import AddSoftwareDrawer from '../../components/SoftwareActions/AddSoftwareDrawer';
import SoftwareFilter from '../../components/Filter/SoftwareFilter';
import CustomTableRow from '../../components/Table/CustomTableRow';
import { getComparator, stableSort } from '../../utils/sortingHelpers';
import networkClient from '../../networks/networkClient';
import { methodTypes, networkEndpoints } from '../../networks/networkStrings';
import makeFirstLetterCapital from '../../utils/makeFirstLetterCapital';
import { handle403Error } from '../../utils/errorHandler';
import endpointParamsSetter from '../../utils/endpointParamsSetter';
import pathStrings from '../../utils/pathStrings';
import { paginationContext } from '../../utils/context';
import { paramsToObject, updateURL } from '../../utils/urlParser';

export default function Softwares() {
  // tableData contains data for softwares details table.
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();

  const { getRowsPerPage, updateRowsPerPage, getPageNo, updatePageNo } =
    useContext(paginationContext);

  const rowsPerPage = getRowsPerPage(paginationStrings.softwareListTable);
  const setRowsPerPage = (value) => {
    updateRowsPerPage(paginationStrings.softwareListTable, value);
  };
  const page = getPageNo(paginationStrings.softwareListTable);
  const setPage = (value) => {
    updatePageNo(paginationStrings.softwareListTable, value);
  };

  const initialFilterData = useMemo(
    () => ({
      clients: [],
      status: [],
    }),
    []
  );

  const initialSortData = useMemo(
    () => ({
      sort: customTableStrings.sortOrder.decending,
      sortBy: headCells[5].id,
    }),
    []
  );

  const initialFilterState = useMemo(
    () => paramsToObject(location.search.slice(1), initialFilterData),
    [location, initialFilterData]
  );

  const initialSortState = useMemo(
    () => paramsToObject(location.search.slice(1), initialSortData),
    [location, initialSortData]
  );

  const initialSearchState = useMemo(
    () => paramsToObject(location.search.slice(1), { search: '' }),
    [location]
  );

  const [toggleDrawer, setToggleDrawer] = useState(false);
  const [sortOrderBy, setSortOrderBy] = useState(initialSortState.sortBy);
  const [sortOrder, setSortOrder] = useState(initialSortState.sort);
  const [filterState, setFilterState] = useState(initialFilterState);
  const [anchorElement, setAnchorElement] = useState(null);
  const [activeFilters, setActiveFiltersCount] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(true);
  const [allSoftwareData, setAllSoftwareData] = useState([]);
  const clientListInitialState = null;
  const [clientList, setClientList] = useState(clientListInitialState);
  const [filteredSoftwareData, setFilteredSoftwareData] = useState([]);
  const [isClientLoading, setIsClientLoading] = useState(false);
  const [isClientError, setIsClientError] = useState(false);
  const [searchBarText, setSearchBarText] = useState(initialSearchState.search);
  const [filterLoading, setFilterLoading] = useState(false);

  useEffect(() => {
    updateURL(
      filterState,
      sortOrder,
      sortOrderBy,
      searchBarText,
      initialSortData,
      location
    );
  }, [
    filterState,
    location,
    sortOrder,
    sortOrderBy,
    searchBarText,
    initialSortData,
  ]);

  const fetchSoftwares = useCallback(async () => {
    const softwareList = await networkClient({
      method: methodTypes.get,
      endpoint: networkEndpoints.software.getSoftwares,
    });
    if (softwareList.error) {
      setIsError(true);
      if (softwareList.errors[0].code === 403) {
        handle403Error(navigate, location);
      }
      setAllSoftwareData([]);
      setFilteredSoftwareData([]);
      setIsLoading(false);
    } else {
      const softwareData = softwareList.data.map((data) => ({
        id: data.id,
        url: data.icon,
        name: data.name,
        managers: data.Managers.map((Manager) => ({
          name: `${Manager.first_name} ${Manager.last_name}`,
          position: Manager.job_title,
          imageUrl: '',
        })),

        client: data?.Client?.name,
        clientId: data?.Client?.id,
        allocation: data.allocations,
        status: makeFirstLetterCapital(data.status),
      }));

      const sortedData = softwareData.sort((a, b) => {
        if (a.clientId < b.clientId) {
          return -1;
        }
        if (a.clientId > b.clientId) {
          return 1;
        }
        return 0;
      });

      setAllSoftwareData(sortedData);
      if (location.search.slice(1) === '') {
        setFilteredSoftwareData(sortedData);
        setIsLoading(false);
        setIsError(false);
      } else setFilterLoading(true);
    }
  }, [location, navigate]);

  useEffect(() => {
    fetchSoftwares();
  }, [fetchSoftwares]);

  const fetchClients = useCallback(async () => {
    setIsClientLoading(true);
    const response = await networkClient({
      method: methodTypes.get,
      endpoint: networkEndpoints.clients.getAllClients,
    });
    setIsClientLoading(false);
    if (response.error) {
      setIsClientError(true);
      if (response.errors[0].code === 403) {
        handle403Error(navigate, location);
      }
    } else {
      setIsClientError(false);
      setClientList(response.data);
    }
  }, [navigate, location]);

  useEffect(() => {
    if (anchorElement !== null && clientList === clientListInitialState) {
      fetchClients();
    }
  }, [fetchClients, clientList, clientListInitialState, anchorElement]);

  const filterData = useCallback(() => {
    let count = 0;
    Object.values(filterState).forEach((value) => {
      if (Array.isArray(value) && value.length > 0) {
        count += 1;
      }
    });
    setActiveFiltersCount(count);
    const filteredRows = allSoftwareData.filter((row) => {
      const { clients, status } = filterState;
      if (count !== 0) {
        if (clients.length > 0 && !clients.includes(row.client)) {
          return false;
        }

        if (status.length > 0 && !status.includes(row.status)) {
          return false;
        }
      }
      return row.name
        .toUpperCase()
        .includes(searchBarText.trim().toUpperCase());
    });
    setFilteredSoftwareData(filteredRows);
  }, [allSoftwareData, filterState, searchBarText]);

  useEffect(() => {
    if (filterLoading) {
      setFilterLoading(false);
      setIsLoading(false);
      setIsError(false);
    }
  }, [filteredSoftwareData, filterLoading]);

  useEffect(() => {
    filterData();
  }, [filterData]);

  const handleFilterButtonClick = (event) => {
    setAnchorElement(event.currentTarget);
  };
  // Handles Menu Close
  const handleMenuClose = () => {
    setAnchorElement(null);
  };

  const onRowClickFunction = (index) => {
    navigate(
      endpointParamsSetter({
        path: pathStrings.softwareDetailsPagePath,
        params: index,
      })
    );
  };
  return (
    <Box
      sx={{
        height: softwarePageSize.rootBoxHeight,
      }}>
      {(filteredSoftwareData.length !== 0 || activeFilters >= 0) && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
          }}>
          <Stack
            direction='row'
            justifyContent='space-between'
            padding={commonPagePaddings.pagePadding}
            sx={{
              backgroundColor: theme.palette.common.white,
            }}>
            <Stack>
              <Typography variant='h4' color={theme.palette.grey[1100]}>
                {softwaresPageStrings.title}
              </Typography>
              <Box height={softwarePageSize.spaceBetweenTitleAndSubtitle} />
              <Typography variant='body1' color={theme.palette.grey[300]}>
                {softwaresPageStrings.subtitle}
              </Typography>
            </Stack>
            <CustomFilledIconButton
              text={softwaresPageStrings.addButtonText}
              onClick={() => setToggleDrawer(!toggleDrawer)}
              icon={<Add />}
            />
          </Stack>

          <Box
            display='flex'
            flexDirection='column'
            maxHeight={softwarePageSize.tableBoxHeight}
            padding={commonPagePaddings.pagePadding}>
            <Stack
              marginBottom={softwarePageSize.spaceBetweenSearchbarAndTable}
              direction='row'
              justifyContent='space-between'>
              <CustomSearchBar
                initialValue={searchBarText}
                minWidth={softwarePageSize.searchbarMinWidth}
                onChange={(e) => setSearchBarText(e.target.value)}
                placeholder={softwaresPageStrings.searchBarPlaceholder}
              />
              <Badge color='primary' badgeContent={activeFilters}>
                <CustomOutlinedIconButton
                  icon={<FilterAlt />}
                  onClick={handleFilterButtonClick}
                  textColor={
                    activeFilters > 0
                      ? theme.palette.primary.main
                      : theme.palette.secondary.main
                  }>
                  {softwaresPageStrings.filterButtonText}
                </CustomOutlinedIconButton>
              </Badge>
            </Stack>
            <CustomTable
              page={page}
              setPage={setPage}
              sortOrderBy={sortOrderBy}
              setSortOrderBy={setSortOrderBy}
              sortOrder={sortOrder}
              setSortOrder={setSortOrder}
              tableData={filteredSoftwareData}
              tableHeadData={headCells}
              isLoading={isLoading}
              isError={isError}
              onTryAgainClick={fetchSoftwares}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}>
              {stableSort(
                filteredSoftwareData,
                getComparator(sortOrder, sortOrderBy),
                sortOrderBy
              )
                .slice(
                  rowsPerPage < 0 ? 0 : page * rowsPerPage,
                  rowsPerPage < 0 ? undefined : page * rowsPerPage + rowsPerPage
                )
                .map((row) => (
                  <CustomTableRow
                    key={JSON.stringify(row)}
                    row={row}
                    onRowClick={() => onRowClickFunction(row.id)}
                    tableHeadData={headCells}
                  />
                ))}
            </CustomTable>
          </Box>
        </Box>
      )}
      <AddSoftwareDrawer
        toggleDrawer={toggleDrawer}
        setToggleDrawer={setToggleDrawer}
        title={addSoftwareStrings.addTitle}
        allClientsList={clientList}
        setAllClientsList={setClientList}
        setSoftwareData={setAllSoftwareData}
        allSoftwareData={allSoftwareData}
        setSortOrderBy={setSortOrderBy}
      />
      <SoftwareFilter
        anchorEl={anchorElement}
        onClose={handleMenuClose}
        filterState={filterState}
        setFilterState={setFilterState}
        initialFilterState={initialFilterData}
        clientList={clientList}
        fetchClients={fetchClients}
        isClientError={isClientError}
        isClientLoading={isClientLoading}
      />
    </Box>
  );
}
