import PropTypes from 'prop-types';
import { Tab, Tabs, Typography, useTheme } from '@mui/material';

export default function CustomTabs({ tabValue, handleTabChange, tabs }) {
  const theme = useTheme();
  return (
    <div>
      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        sx={{
          '& .Mui-selected': {
            color: theme.palette.primary.main,
          },
        }}
        TabIndicatorProps={{}}>
        {tabs.map((tabData) => (
          <Tab
            key={tabData}
            value={tabData}
            disableRipple
            label={<Typography variant='buttonMedium'>{tabData}</Typography>}
          />
        ))}
      </Tabs>
    </div>
  );
}

CustomTabs.propTypes = {
  tabValue: PropTypes.string.isRequired,
  handleTabChange: PropTypes.func.isRequired,
  tabs: PropTypes.arrayOf(PropTypes.string).isRequired,
};
