import { Box, Stack } from '@mui/material';
import PropTypes from 'prop-types';
import {
  addEmployeeStrings,
  basicInformationStrings,
} from '../../utils/strings';
import { basicInformationMargins } from '../../utils/dimensions/margins';
import { basicInformationSize } from '../../utils/dimensions/size';
import CustomTextField from '../Inputs/CustomTextField';
import {
  isOnlyAlphaNumeric,
  isOnlyAlphabets,
  isValidEmail,
  isValidWorkEmail,
} from '../../utils/validators';

function BasicInformationComponent({ basicDetails, setBasicDetails }) {
  const setData = ({ key, error, data, errorMessage }) => {
    setBasicDetails({
      ...basicDetails,
      [key]: {
        ...basicDetails[key],
        error,
        value: data === undefined ? basicDetails[key].value : data,
        errorMessage: errorMessage || basicDetails[key].errorMessage,
      },
    });
  };

  return (
    <Box
      marginX={basicInformationMargins.marginX}
      width={basicInformationSize.width}>
      <Stack>
        <CustomTextField
          value={basicDetails.company_email.value || ''}
          placeHolder={basicInformationStrings.placeHolder.company_email}
          helperText={
            basicDetails.company_email.error
              ? basicDetails.company_email.errorMessage
              : null
          }
          error={basicDetails.company_email.error}
          onChange={(e) => {
            if (e.target.value.trim() === '')
              setData({
                key: 'company_email',
                data: e.target.value.trim(),
                error: false,
              });
            else
              setData({
                key: 'company_email',
                data: e.target.value.trim(),
                error: false,
              });
          }}
          onBlur={(e) => {
            if (e.target.value.trim() === '')
              setData({
                key: 'company_email',
                error: false,
              });
            else
              setData({
                key: 'company_email',
                error: !isValidWorkEmail(e.target.value.trim()),
              });
          }}
        />

        <Stack direction='row'>
          <CustomTextField
            value={basicDetails.first_name.value}
            placeHolder={basicInformationStrings.placeHolder.first_name}
            helperText={
              basicDetails.first_name.error
                ? basicDetails.first_name.errorMessage
                : null
            }
            maxLength={30}
            error={basicDetails.first_name.error}
            onChange={(e) => {
              if (isOnlyAlphabets(e.target.value))
                setData({
                  key: 'first_name',
                  data: e.target.value,
                  error: e.target.value === '',
                });
            }}
            onBlur={(e) => {
              setData({
                key: 'first_name',
                error: e.target.value === '',
                data: e.target.value,
              });
            }}
          />

          <CustomTextField
            value={basicDetails.last_name.value}
            placeHolder={basicInformationStrings.placeHolder.last_name}
            helperText={
              basicDetails.last_name.error
                ? basicDetails.last_name.errorMessage
                : null
            }
            maxLength={30}
            error={basicDetails.last_name.error}
            onChange={(e) => {
              if (isOnlyAlphabets(e.target.value))
                setData({
                  key: 'last_name',
                  data: e.target.value,
                  error: e.target.value === '',
                });
            }}
            onBlur={(e) => {
              setData({
                key: 'last_name',
                error: e.target.value === '',
                data: e.target.value,
              });
            }}
          />
        </Stack>

        <Stack direction='row'>
          <CustomTextField
            select
            flex={3}
            value={basicDetails.country_code.value}
            placeHolder={basicInformationStrings.placeHolder.country_code}
            helperText={
              basicDetails.country_code.error
                ? basicDetails.country_code.errorMessage
                : null
            }
            error={basicDetails.country_code.error}
            menuItem={basicInformationStrings.menuItem.MobileNumMenuItem}
            onChange={(e) => {
              setData({
                key: 'country_code',
                data: e.target.value,
                error: e.target.value === '',
              });
            }}
            onBlur={(e) => {
              setData({
                key: 'country_code',
                error: e.target.value === '',
                data: e.target.value,
              });
            }}
          />

          <CustomTextField
            value={basicDetails.phone_number.value}
            placeHolder={basicInformationStrings.placeHolder.phone_number}
            flex={6}
            helperText={
              basicDetails.phone_number.error
                ? basicDetails.phone_number.errorMessage
                : null
            }
            error={basicDetails.phone_number.error}
            maxLength={15}
            onChange={(e) => {
              setData({
                key: 'phone_number',
                data: e.target.value.trim(),
                error: e.target.value === '',
                errorMessage: addEmployeeStrings.errorMessages.required,
              });
            }}
            onBlur={(e) => {
              setData({
                key: 'phone_number',
                error: e.target.value.length < 10 || e.target.value === '',
                errorMessage:
                  e.target.value === ''
                    ? addEmployeeStrings.errorMessages.required
                    : addEmployeeStrings.errorMessages.minLengthPhoneNo,
              });
            }}
          />
        </Stack>

        <CustomTextField
          value={basicDetails.personal_email.value}
          placeHolder={basicInformationStrings.placeHolder.personal_email}
          helperText={
            basicDetails.personal_email.error
              ? basicDetails.personal_email.errorMessage
              : null
          }
          error={basicDetails.personal_email.error}
          onChange={(e) => {
            if (e.target.value.trim() === '')
              setData({
                key: 'personal_email',
                data: e.target.value.trim(),
                error: true,
                errorMessage: addEmployeeStrings.errorMessages.required,
              });
            else if (isValidWorkEmail(e.target.value)) {
              setData({
                key: 'personal_email',
                data: e.target.value.trim(),
                error: true,
                errorMessage: addEmployeeStrings.errorMessages.personal_email,
              });
            } else
              setData({
                key: 'personal_email',
                data: e.target.value.trim(),
                error: false,
              });
          }}
          onBlur={(e) => {
            if (e.target.value.trim() === '')
              setData({
                key: 'personal_email',
                error: true,
                errorMessage: addEmployeeStrings.errorMessages.required,
              });
            else if (isValidWorkEmail(e.target.value)) {
              setData({
                key: 'personal_email',
                error: true,
                errorMessage: addEmployeeStrings.errorMessages.personal_email,
              });
            } else
              setData({
                key: 'personal_email',
                error: !isValidEmail(e.target.value.trim()),
                errorMessage: addEmployeeStrings.errorMessages.invalidEmail,
              });
          }}
        />

        <CustomTextField
          value={basicDetails.address.value}
          placeHolder={basicInformationStrings.placeHolder.address}
          multiline
          rows={3}
          helperText={
            basicDetails.address.error
              ? basicDetails.address.errorMessage
              : null
          }
          error={basicDetails.address.error}
          onChange={(e) => {
            const { value } = e.target;
            // adding length to address
            if (value.length <= 255) {
              setData({
                key: 'address',
                data: value,
                error: false,
              });
            }
          }}
          onBlur={(e) => {
            const trimmedValue = e.target.value.trim();
            if (trimmedValue.trim().length <= 255) {
              setData({
                key: 'address',
                error: false,
                data: trimmedValue,
              });
            }
          }}
        />

        <Stack direction='row'>
          <CustomTextField
            value={basicDetails.city.value}
            placeHolder={basicInformationStrings.placeHolder.city}
            helperText={
              basicDetails.city.error ? basicDetails.city.errorMessage : null
            }
            error={basicDetails.city.error}
            maxLength={30}
            onChange={(e) => {
              setData({
                key: 'city',
                data: e.target.value,
                error: false,
              });
            }}
            onBlur={(e) => {
              const trimmedValue = e.target.value.trim();
              setData({
                key: 'city',
                error: false,
                data: trimmedValue,
              });
            }}
          />
          <CustomTextField
            value={basicDetails.state.value}
            placeHolder={basicInformationStrings.placeHolder.state}
            helperText={
              basicDetails.state.error ? basicDetails.state.errorMessage : null
            }
            error={basicDetails.state.error}
            maxLength={30}
            onChange={(e) => {
              setData({
                key: 'state',
                data: e.target.value,
                error: false,
              });
            }}
            onBlur={(e) => {
              const trimmedValue = e.target.value.trim();
              setData({
                key: 'state',
                error: false,
                data: trimmedValue,
              });
            }}
          />

          <CustomTextField
            value={basicDetails.zipcode.value}
            placeHolder={basicInformationStrings.placeHolder.zipcode}
            helperText={
              basicDetails.zipcode.error
                ? basicDetails.zipcode.errorMessage
                : null
            }
            maxLength={8}
            error={basicDetails.zipcode.error}
            onChange={(e) => {
              if (isOnlyAlphaNumeric(e.target.value))
                setData({
                  key: 'zipcode',
                  data: e.target.value,
                  error: false,
                });
            }}
            onBlur={(e) => {
              setData({
                key: 'zipcode',
                error: e.target.value.length < 3 && e.target.value !== '',
                data: e.target.value,
                errorMessage:
                  e.target.value !== '' &&
                  addEmployeeStrings.errorMessages.invalidZipcode,
              });
            }}
          />
        </Stack>
      </Stack>
    </Box>
  );
}
BasicInformationComponent.propTypes = {
  basicDetails: PropTypes.shape({
    company_email: PropTypes.shape({
      value: PropTypes.string,
      error: PropTypes.bool,
      errorMessage: PropTypes.string,
    }),
    first_name: PropTypes.shape({
      value: PropTypes.string,
      error: PropTypes.bool,
      errorMessage: PropTypes.string,
    }),
    last_name: PropTypes.shape({
      value: PropTypes.string,
      error: PropTypes.bool,
      errorMessage: PropTypes.string,
    }),
    country_code: PropTypes.shape({
      value: PropTypes.string,
      error: PropTypes.bool,
      errorMessage: PropTypes.string,
    }),
    city: PropTypes.shape({
      value: PropTypes.string,
      error: PropTypes.bool,
      errorMessage: PropTypes.string,
    }),
    zipcode: PropTypes.shape({
      value: PropTypes.string,
      error: PropTypes.bool,
      errorMessage: PropTypes.string,
    }),
    state: PropTypes.shape({
      value: PropTypes.string,
      error: PropTypes.bool,
      errorMessage: PropTypes.string,
    }),
    address: PropTypes.shape({
      value: PropTypes.string,
      error: PropTypes.bool,
      errorMessage: PropTypes.string,
    }),
    phone_number: PropTypes.shape({
      value: PropTypes.string,
      error: PropTypes.bool,
      errorMessage: PropTypes.string,
    }),
    personal_email: PropTypes.shape({
      value: PropTypes.string,
      error: PropTypes.bool,
      errorMessage: PropTypes.string,
    }),
    branch_id: PropTypes.number,
  }).isRequired,
  setBasicDetails: PropTypes.func.isRequired,
};

export default BasicInformationComponent;
