import PropTypes from 'prop-types';
import { Add } from '@mui/icons-material';
import { Typography, Box, useTheme } from '@mui/material';
import CustomFilledIconButton from './CustomFilledIconButton';
import { noDataEmployeeSize } from '../utils/dimensions/size';

export default function CustomNoDataComponent({
  imageComponent,
  title,
  subtitleOne,
  subtitleTwo,
  buttonText,
  onClick,
}) {
  const theme = useTheme();
  return (
    <div>
      <Box
        minHeight={noDataEmployeeSize.heightOfBox}
        justifyContent='center'
        alignItems='center'
        display='flex'
        flexDirection='column'>
        {imageComponent}
        <Typography variant='h4' color={theme.palette.grey[1100]}>
          {title}
        </Typography>
        <Typography variant='h5' color={theme.palette.secondary.dark}>
          {subtitleOne}
        </Typography>
        <Typography variant='h5' color={theme.palette.secondary.dark}>
          {subtitleTwo}
        </Typography>
        <Box height={noDataEmployeeSize.spaceBetweenSubTitleAndButton} />

        <CustomFilledIconButton
          text={buttonText}
          onClick={onClick}
          icon={<Add />}
        />
      </Box>
    </div>
  );
}

CustomNoDataComponent.propTypes = {
  imageComponent: PropTypes.element.isRequired,
  title: PropTypes.string.isRequired,
  subtitleOne: PropTypes.string.isRequired,
  subtitleTwo: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};
