import PropTypes from 'prop-types';
import {
  Box,
  Checkbox,
  Chip,
  Container,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import { resourceSelectSize } from '../../utils/dimensions/size';
import { customTablePaddings } from '../../utils/dimensions/paddings';
import { customTableStrings } from '../../utils/strings';
import DefaultSoftwareFavicon from '../../utils/assets/DefaultSoftwareFavicon.svg';

function HardwareSoftwareTable({
  headerText,
  onCheckBoxClick,
  tableData,
  showHeader,
}) {
  const theme = useTheme();
  return (
    <div>
      {showHeader && (
        <Table
          stickyHeader
          sx={{ border: `1px solid ${theme.palette.grey[50]}` }}>
          <TableHead>
            <TableRow
              sx={{ backgroundColor: theme.palette.grey[25], display: 'flex' }}>
              <TableCell sx={{ flex: 5 }}>
                <Typography
                  varient='tableHeader'
                  color={theme.palette.grey[400]}>
                  {headerText}
                </Typography>
              </TableCell>
              <TableCell sx={{ flex: 1 }}>
                <Chip
                  label={
                    <Typography
                      color={theme.palette.secondary.dark}
                      variant='chip'>
                      {`${
                        tableData.filter((obj) => obj.isSelected).length
                      } Selected`}
                    </Typography>
                  }
                />
              </TableCell>
            </TableRow>
          </TableHead>
        </Table>
      )}
      <Table
        stickyHeader
        sx={{ border: `1px solid ${theme.palette.grey[50]}` }}>
        {tableData.length <= 0 ? (
          <TableBody>
            <TableRow hover>
              <TableCell
                sx={{
                  borderBottom: 'none',
                }}>
                <Container
                  sx={{
                    justifyContent: 'center',
                    padding: customTablePaddings.noDataMessagePadding,
                  }}>
                  <Typography
                    variant='h6'
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}>
                    {customTableStrings.noDataMessage}
                  </Typography>
                </Container>
              </TableCell>
            </TableRow>
          </TableBody>
        ) : (
          <TableBody>
            {tableData.map((data) => (
              <TableRow
                key={data.id}
                sx={{ display: 'flex' }}
                onClick={() => onCheckBoxClick(data.id)}>
                <TableCell
                  sx={{
                    flex: 5,
                    display: 'flex',
                    alignItems: 'center',
                  }}>
                  <Stack direction='row' spacing={2}>
                    <Box
                      display='flex'
                      alignItems='center'
                      justifyContent='center'>
                      {data.icon ? (
                        <img
                          height={resourceSelectSize.logoHeight}
                          width={resourceSelectSize.logoWidth}
                          style={{ objectFit: 'contain' }}
                          src={data.icon}
                          alt={data.name}
                          onError={(e) => {
                            e.target.src = DefaultSoftwareFavicon;
                            e.onerror = null;
                          }}
                        />
                      ) : (
                        <img
                          height={resourceSelectSize.logoHeight}
                          width={resourceSelectSize.logoWidth}
                          src={DefaultSoftwareFavicon}
                          style={{ objectFit: 'contain' }}
                          alt={data.name}
                        />
                      )}
                    </Box>

                    <Typography variant='body2SemiBold'>{data.name}</Typography>
                  </Stack>
                </TableCell>

                <TableCell
                  sx={{
                    margin: 0,
                    flex: 1,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                  <Checkbox
                    id={`${data.id}`}
                    checked={data.isSelected || false}
                    value={data.isSelected}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        )}
      </Table>
    </div>
  );
}

HardwareSoftwareTable.propTypes = {
  showHeader: PropTypes.bool,
  headerText: PropTypes.string,
  onCheckBoxClick: PropTypes.func.isRequired,
  tableData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};
HardwareSoftwareTable.defaultProps = {
  showHeader: true,
  headerText: null,
};
export default HardwareSoftwareTable;
