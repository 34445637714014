function isValidWorkEmail(email) {
  const regex = /^[^\s@]+@techholding\.co$/;
  return regex.test(email);
}
function isValidPhoneNumber(phoneNo) {
  const regex = /^\d{10}$/;
  return regex.test(phoneNo);
}

function isOnlyNumbers(data) {
  const regex = /^\d+$/;
  return regex.test(data);
}
function isOnlyAlphabets(data) {
  const regex = /^[a-zA-Z ]*$/;
  return regex.test(data);
}
function isOnlyAlphaNumeric(data) {
  const regex = /^[a-zA-Z0-9]*$/;
  return regex.test(data);
}

function isOnlyAlphaNumericWithSpace(data) {
  const regex = /^[a-zA-Z0-9 !@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]*$/;
  return regex.test(data);
}

function isValidEmail(inputEmail) {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regex.test(inputEmail);
}

function isValidId(str, prefixPattern) {
  const regex = new RegExp(`^${prefixPattern}[0-9]+$`); // generate regular expression based on prefix pattern
  return regex.test(str); // check if the string matches the regular expression
}

export {
  isValidWorkEmail,
  isValidPhoneNumber,
  isValidEmail,
  isValidId,
  isOnlyAlphaNumeric,
  isOnlyAlphaNumericWithSpace,
  isOnlyNumbers,
  isOnlyAlphabets,
};
