import PropTypes from 'prop-types';
import { Box, Typography, useTheme } from '@mui/material';
import CustomOutlinedIconButton from './CustomOutlinedIconButton';
import { detailsCardPadding } from '../utils/dimensions/paddings';
import { detailsCardMargins } from '../utils/dimensions/margins';

function DetailsCard({ title, buttonText, onClick, children, icon }) {
  const theme = useTheme();
  return (
    <div>
      <Box>
        <Box
          sx={{
            backgroundColor: theme.palette.grey[25],
            paddingLeft: detailsCardPadding.titlePaddingLeft,
            paddingY: detailsCardPadding.titlePaddingY,
            alignItems: 'center',
          }}>
          <Typography variant='h6'>{title}</Typography>
        </Box>

        <Box
          sx={{
            backgroundColor: 'white',
            paddingX: detailsCardPadding.childrenPaddingX,
            paddingBottom: detailsCardPadding.childrenPAddingBottom,
          }}>
          {children}
          <Box sx={{ marginTop: detailsCardMargins.buttonMarginTop }}>
            <CustomOutlinedIconButton onClick={onClick} icon={icon}>
              {buttonText}
            </CustomOutlinedIconButton>
          </Box>
        </Box>
      </Box>
    </div>
  );
}

DetailsCard.propTypes = {
  title: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  children: PropTypes.element.isRequired,
  icon: PropTypes.element,
};
DetailsCard.defaultProps = { icon: null };

export default DetailsCard;
