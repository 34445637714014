import PropTypes from 'prop-types';
import { Box, Chip, Typography, Stack, useTheme } from '@mui/material';
import { addSoftwareSize, detailsPageSize } from '../../utils/dimensions/size';
import { softwareDetailsPageStrings } from '../../utils/strings';
import ShowDetailsColumn from '../../components/ShowDetailsColumn';
import CustomAvatar from '../../components/CustomAvatar';
import { getStatusColor } from '../../components/Table/CustomTableCell';
import makeFirstLetterCapital from '../../utils/makeFirstLetterCapital';

function SoftwareDetailContent({ softwareDetails }) {
  const theme = useTheme();
  return (
    <div>
      <Box>
        <Box height={detailsPageSize.spaceBetweenDetailsContent} />
        <ShowDetailsColumn
          label={softwareDetailsPageStrings.detailsCard.urlLabel}>
          <Typography
            varient='body2'
            component='a'
            href={softwareDetails.url}
            target='_blank'
            color={theme.palette.info.dark}>
            {softwareDetails.url}
          </Typography>
        </ShowDetailsColumn>
        <Box height={detailsPageSize.spaceBetweenDetailsContent} />
        <ShowDetailsColumn
          label={softwareDetailsPageStrings.detailsCard.nameLabel}>
          <Typography varient='body2' color={theme.palette.grey[1100]}>
            {softwareDetails.name}
          </Typography>
        </ShowDetailsColumn>

        <Box height={detailsPageSize.spaceBetweenDetailsContent} />
        <ShowDetailsColumn
          label={softwareDetailsPageStrings.detailsCard.managerLabel}>
          <Stack
            direction='column'
            sx={{
              maxHeight: detailsPageSize.managerListMaxHeight,
              overflow: 'auto',
              '&::-webkit-scrollbar': {
                width: addSoftwareSize.managersDropdownScrollbarWidth,
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: theme.palette.grey[300],
                borderRadius: addSoftwareSize.managersDropdownScrollbarRadius,
                opacity: 0.7,
              },
            }}>
            {!softwareDetails.managers.length > 0
              ? '-'
              : softwareDetails.managers.map((managerData) => (
                  <Box key={managerData.value.id}>
                    <Box height={detailsPageSize.spaceBetweenDetailsContent} />

                    <Stack direction='row' alignItems='center'>
                      <CustomAvatar
                        name={managerData.value.name}
                        width={detailsPageSize.profileImageSize}
                        height={detailsPageSize.profileImageSize}
                      />
                      <Typography
                        // onClick={navigate(`/employee/${managerData.value.id}`)}
                        variant='body2'
                        color={theme.palette.grey[1100]}
                        style={{ marginLeft: '8px' }}>
                        {managerData.value.name}
                      </Typography>
                    </Stack>
                  </Box>
                ))}
          </Stack>
        </ShowDetailsColumn>

        <Box height={detailsPageSize.spaceBetweenDetailsContent} />
        <ShowDetailsColumn
          label={softwareDetailsPageStrings.detailsCard.statusLabel}>
          <Box>
            <Chip
              sx={getStatusColor(
                makeFirstLetterCapital(softwareDetails.status)
              )}
              label={makeFirstLetterCapital(softwareDetails.status)}
            />
          </Box>
        </ShowDetailsColumn>
        <Box height={detailsPageSize.spaceBetweenDetailsContent} />
        <ShowDetailsColumn
          label={softwareDetailsPageStrings.detailsCard.clientLabel}>
          <Typography varient='body2' color={theme.palette.grey[1100]}>
            {softwareDetails.client}
          </Typography>
        </ShowDetailsColumn>
        <Box height={detailsPageSize.spaceBetweenDetailsContent} />
        <ShowDetailsColumn
          label={softwareDetailsPageStrings.detailsCard.descriptionLabel}>
          <Typography varient='body2' color={theme.palette.grey[1100]}>
            {softwareDetails.description ? softwareDetails.description : '-'}
          </Typography>
        </ShowDetailsColumn>
        <Box height={detailsPageSize.spaceBetweenDetailsContent} />
      </Box>
    </div>
  );
}

SoftwareDetailContent.propTypes = {
  softwareDetails: PropTypes.shape({
    url: PropTypes.string.isRequired,
    managers: PropTypes.arrayOf(PropTypes.shape).isRequired,
    name: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    client: PropTypes.string.isRequired,
    description: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.oneOf([null]),
    ]),
  }).isRequired,
};

export default SoftwareDetailContent;
