import PropTypes from 'prop-types';
import { Box, Stack, Typography, useTheme } from '@mui/material';
import { useContext, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import CustomDrawer from '../CustomDrawer';
import CustomTextField from '../Inputs/CustomTextField';
import { revokeSoftwareMargins } from '../../utils/dimensions/margins';
import {
  employeeSoftwareRevokeSize,
  revokeSoftwareDrawerSize,
} from '../../utils/dimensions/size';
import { revokeSoftwareDrawerStrings } from '../../utils/strings';
import { revokeSoftwareDetailsPadding } from '../../utils/dimensions/paddings';
import networkClient from '../../networks/networkClient';
import { methodTypes, networkEndpoints } from '../../networks/networkStrings';
import endpointParamsSetter from '../../utils/endpointParamsSetter';
import { handle403Error } from '../../utils/errorHandler';
import { snackbarContext } from '../../utils/context';
import DefaultSoftwareFavicon from '../../utils/assets/DefaultSoftwareFavicon.svg';
import makeFirstLetterCapital from '../../utils/makeFirstLetterCapital';

export default function RevokeSoftwareDrawer({
  toggleRevokeDrawer,
  setRevokeDrawer,
  revokeSoftwareData,
  setTableData,
  setActionButton,
  setHistoryData,
  user,
}) {
  const theme = useTheme();
  const { showSnackbar } = useContext(snackbarContext);
  const notesInitialState = '';
  const [notes, setNotes] = useState(notesInitialState);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const revokeSoftware = async () => {
    setIsLoading(true);
    const revokeSoftwareResponse = await networkClient({
      method: methodTypes.patch,
      endpoint: endpointParamsSetter({
        path: networkEndpoints.userSoftware.userSoftwareById,
        params: revokeSoftwareData.userSoftwareId,
      }),
      requestBody: {
        status: revokeSoftwareDrawerStrings.revoked,
        note: notes.trim(),
      },
    });
    setIsLoading(false);

    if (!revokeSoftwareResponse.error) {
      showSnackbar(revokeSoftwareDrawerStrings.successmessage);
      setIsError(false);
      setNotes(notesInitialState);
      setTableData((oldTableData) => {
        const tempSoftwareIndex = oldTableData.findIndex(
          (obj) =>
            obj.actionButton.data.userSoftwareId ===
            revokeSoftwareData.userSoftwareId
        );
        oldTableData.splice(tempSoftwareIndex, 1, {
          ...oldTableData[tempSoftwareIndex],
          name: {
            ...oldTableData[tempSoftwareIndex].name,
            actionBy: user.given_name,
            fullName: user.name,
            status: makeFirstLetterCapital(revokeSoftwareResponse.data.status),
          },
          status: makeFirstLetterCapital(revokeSoftwareResponse.data.status),
          actionButton: setActionButton({
            status: makeFirstLetterCapital(revokeSoftwareResponse.data.status),
            userSoftwareId: revokeSoftwareResponse.data.id,
            managers: oldTableData[tempSoftwareIndex].name.isManager
              ? [user.userId]
              : [],
            imageUrl: oldTableData[tempSoftwareIndex].imageUrl,
            name: oldTableData[tempSoftwareIndex].name.softwareName,
            lastEmailDate: revokeSoftwareResponse.data.last_email_date,
          }),
        });
        setHistoryData((prev) => [
          {
            description: `${user.name} has revoked ${revokeSoftwareData?.name} software`,
            timestamp: Date.now(),
          },
          ...prev,
        ]);
        return oldTableData;
      });
      setRevokeDrawer(false);
    } else {
      setIsError(true);
      setTimeout(() => {
        setIsError(false);
      }, 10000);
      if (revokeSoftwareResponse.errors[0].code === 403) {
        handle403Error(navigate, location);
      }
    }
  };

  return (
    <div>
      <CustomDrawer
        error={isError}
        successButtonLoading={isLoading}
        open={toggleRevokeDrawer}
        title={revokeSoftwareDrawerStrings.title}
        onFailureButtonClick={() => {
          setRevokeDrawer(false);
          setNotes(notesInitialState);
        }}
        onSuccessButtonClick={() => {
          revokeSoftware();
        }}
        headerTailElement={
          <Stack direction='row' alignItems='center' spacing={1}>
            {revokeSoftwareData.imageUrl ? (
              <Box
                sx={{
                  padding: revokeSoftwareDetailsPadding.iconPadding,
                  border: `1px solid ${theme.palette.grey[100]}`,
                }}>
                <img
                  src={revokeSoftwareData.imageUrl}
                  alt={revokeSoftwareData?.name}
                  width={employeeSoftwareRevokeSize.imageWidth}
                  height={employeeSoftwareRevokeSize.imageHeight}
                  onError={(e) => {
                    e.target.src = DefaultSoftwareFavicon;
                    e.onerror = null;
                  }}
                />
              </Box>
            ) : (
              <img
                width={employeeSoftwareRevokeSize.imageWidth}
                height={employeeSoftwareRevokeSize.imageHeight}
                src={DefaultSoftwareFavicon}
                style={{ objectFit: 'contain' }}
                alt={revokeSoftwareData?.name}
              />
            )}
            <Typography variant='h5SemiBold' color={theme.palette.grey[1100]}>
              {revokeSoftwareData?.name}
            </Typography>
          </Stack>
        }
        successButtonText={revokeSoftwareDrawerStrings.revokeButtonText}
        failureButtonText={revokeSoftwareDrawerStrings.cancelButtonText}>
        <Box marginX={revokeSoftwareMargins.marginX}>
          <Stack>
            <CustomTextField
              onChange={(e) => {
                setNotes(e.target.value);
              }}
              value={notes}
              placeHolder={revokeSoftwareDrawerStrings.placeHolder.notes}
              width={revokeSoftwareDrawerSize.widthOfTextBox}
              multiline
              rows={3}
            />
          </Stack>
        </Box>
      </CustomDrawer>
    </div>
  );
}

RevokeSoftwareDrawer.propTypes = {
  toggleRevokeDrawer: PropTypes.bool.isRequired,
  setRevokeDrawer: PropTypes.func.isRequired,
  revokeSoftwareData: PropTypes.shape({
    imageUrl: PropTypes.string,
    name: PropTypes.string,
    userSoftwareId: PropTypes.number,
  }),
  user: PropTypes.shape({
    name: PropTypes.string,
    userId: PropTypes.number,
    given_name: PropTypes.string,
    role: PropTypes.number,
  }).isRequired,
  setHistoryData: PropTypes.func.isRequired,
  setTableData: PropTypes.func.isRequired,
  setActionButton: PropTypes.func.isRequired,
};

RevokeSoftwareDrawer.defaultProps = {
  revokeSoftwareData: PropTypes.shape({
    imageUrl: '',
    name: '',
    userSoftwareId: 0,
  }),
};
