import { tokenStrings } from '../networks/networkStrings';
import redirectPaths from '../Router/RedirectPaths';

const handle403Error = (navigate, location) => {
  localStorage.removeItem(tokenStrings.user);
  localStorage.removeItem(tokenStrings.accessToken);
  localStorage.removeItem(tokenStrings.expiresAt);
  navigate(redirectPaths.signinPath.to, {
    state: { from: location },
    replace: true,
  });
};

const handle404Error = (navigate) => {
  navigate(redirectPaths.pageNotFoundPath.from);
};

export { handle403Error, handle404Error };
