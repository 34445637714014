import { encryptMessage } from '../utils/encyption/encryptionMethods';
import axiosInstance from './axiosInstance';
import { networkEndpoints, tokenStrings } from './networkStrings';

const axios = axiosInstance();
export default async function refreshToken() {
  const { data } = await axios.get(
    networkEndpoints.authentication.refreshToken
  );
  if (data.code === 200) {
    localStorage.setItem(
      tokenStrings.user,
      encryptMessage(JSON.stringify(data.data.user))
    );
    localStorage.setItem(tokenStrings.accessToken, data.data.accessToken);
    localStorage.setItem(
      tokenStrings.expiresAt,
      encryptMessage(data.data.expiresAt)
    );
  }
}
