import PropTypes from 'prop-types';
import { Refresh } from '@mui/icons-material';
import { Box, Stack, Typography, useTheme } from '@mui/material';
import moment from 'moment';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import CustomFilledIconButton from '../../components/CustomFilledIconButton';
import AssignSoftwareDrawer from '../../components/EmployeeSoftwareActions/AssignSoftwareDrawer';
import RevokeSoftwareDrawer from '../../components/EmployeeSoftwareActions/RevokeSoftwareDrawer';

import CustomSearchBar from '../../components/Inputs/CustomSearchBar';
import CustomTable from '../../components/Table/CustomTable';
import networkClient from '../../networks/networkClient';
import { methodTypes, networkEndpoints } from '../../networks/networkStrings';
import { employeeDetailsPagePaddings } from '../../utils/dimensions/paddings';
import { employeeDetailsPageSize } from '../../utils/dimensions/size';
import makeFirstLetterCapital from '../../utils/makeFirstLetterCapital';
import {
  actionButtonText,
  customTableStrings,
  employeeDetailsPageStrings,
  softwareStatusStrings,
  employeeStatusStrings,
  momentStrings,
  snackbarStrings,
  completeAssignDrawerStrings,
} from '../../utils/strings';
import headCells from './softwareTabTablesData';
import CustomTableRow from '../../components/Table/CustomTableRow';
import { getComparator, stableSort } from '../../utils/sortingHelpers';
import endpointParamsSetter from '../../utils/endpointParamsSetter';
import { handle403Error } from '../../utils/errorHandler';
import formatValueIfNull from '../../utils/formatValueIfNull';
import CompleteSoftwareAssignDrawer from '../../components/EmployeeSoftwareActions/CompleteSoftwareAssignDrawer';
import resendEmailClient from '../../utils/resendEmailClient';
import { snackbarContext } from '../../utils/context';
import getLoggedInUser from '../../utils/getLoggedInUser';
import pathStrings from '../../utils/pathStrings';

function SoftwareTab({
  employeeId,
  navigate,
  employeeStatus,
  employeeDetails,
  setHistoryData,
  setIs404Error,
}) {
  const theme = useTheme();
  const location = useLocation();
  const { showSnackbar } = useContext(snackbarContext);
  const user = getLoggedInUser(navigate, location);
  const { userId: loginUserId, role: loginUserRole } = user;

  const [toggleRevokeDrawer, setRevokeDrawer] = useState(false);
  const [toggleCompleteAssignDrawer, setCompleteAssignDrawer] = useState(false);
  const [revokeSoftwareData, setRevokeSoftwareData] = useState({});
  const [completeAssignSoftwareData, setCompleteAssignSoftwareData] = useState(
    {}
  );
  const [assignDrawerTitle, setAssignDrawerTitle] = useState('');
  const [openAssignSoftwareDrawer, setOpenAssignSoftwareDrawer] =
    useState(false);
  const [employeeSoftwareResponse, setEmployeeSoftwareResponse] = useState([]);
  const [employeeSoftware, setemployeeSoftware] = useState([]);
  const [tableData, setTableData] = useState(employeeSoftware);
  const [lastUpdatedAt, setlastUpdatedAt] = useState();
  const [isLoading, setisLoading] = useState(true);
  const [isError, setisError] = useState(false);
  const assignDrawerSoftwareInitialState = null;
  const [assignDrawerSoftware, setAssignDrawerSoftware] = useState(
    assignDrawerSoftwareInitialState
  );
  const [isFetchAllSoftwaresLoading, setFetchAllSoftwareLoading] =
    useState(false);

  const handleSearchBarTextChange = (e) => {
    const arr = employeeSoftware.filter((data) =>
      data.name.softwareName
        .toUpperCase()
        .includes(e.target.value.trim().toUpperCase())
    );
    setTableData(arr);
  };

  const revokeActionHandle = useCallback(
    (data) => {
      setRevokeSoftwareData(data);
      setRevokeDrawer(true);
    },
    [setRevokeSoftwareData, setRevokeDrawer]
  );

  const assignActionHandle = useCallback(
    (data) => {
      setCompleteAssignSoftwareData(data);
      setCompleteAssignDrawer(true);
      setAssignDrawerTitle('Assign Software');
    },
    [
      setCompleteAssignSoftwareData,
      setCompleteAssignDrawer,
      setAssignDrawerTitle,
    ]
  );

  const reAssignActionHandle = useCallback(
    (data) => {
      setCompleteAssignSoftwareData(data);
      setCompleteAssignDrawer(true);
      setAssignDrawerTitle(completeAssignDrawerStrings.reAssignTitle);
    },
    [
      setCompleteAssignSoftwareData,
      setCompleteAssignDrawer,
      setAssignDrawerTitle,
    ]
  );

  const resendMailHandle = useCallback(
    async (data) => {
      await resendEmailClient({
        userSoftware: data,
        showSnackbar,
        navigate,
        location,
      });
    },
    [showSnackbar, navigate, location]
  );

  const requestMailHandle = useCallback(
    async (data) => {
      const apiEndpoint = endpointParamsSetter({
        path: networkEndpoints.userSoftware.userSoftwareById,
        params: data.userSoftwareId,
      });
      const payload = {
        status: softwareStatusStrings.pending.toLocaleLowerCase(),
      };
      // update user software API call
      const requestSoftwareResponse = await networkClient({
        method: methodTypes.patch,
        endpoint: apiEndpoint,
        requestBody: payload,
      });

      const isUnauthorizedError =
        requestSoftwareResponse?.errors?.[0]?.code === 403 ||
        requestSoftwareResponse?.data?.isManagerNotified?.errors?.[0]?.code ===
          403;
      if (isUnauthorizedError) {
        handle403Error(navigate, location);
      }

      if (!requestSoftwareResponse.error) {
        const { isManagerNotified } = requestSoftwareResponse.data;

        setTableData((oldTableData) => {
          const tempSoftwareIndex = oldTableData.findIndex(
            (obj) =>
              obj.actionButton.data.userSoftwareId === data.userSoftwareId
          );
          oldTableData.splice(tempSoftwareIndex, 1, {
            ...oldTableData[tempSoftwareIndex],
            name: {
              ...oldTableData[tempSoftwareIndex].name,
              actionBy: user.given_name,
              fullName: user.name,
              status: makeFirstLetterCapital(
                requestSoftwareResponse.data.status
              ),
            },
            status: makeFirstLetterCapital(requestSoftwareResponse.data.status),
            actionButton: {
              buttonText: actionButtonText.resend,
              data,
              disabled:
                employeeStatus === employeeStatusStrings.OffBoarded ||
                employeeStatus === employeeStatusStrings.OffBoarding,
              onButtonClick: resendMailHandle,
            },
          });

          return [...oldTableData];
        });
        showSnackbar(
          snackbarStrings.messages.success.softwareRequestSuccessFully,
          snackbarStrings.variant.success
        );
        setHistoryData((prev) => [
          {
            description: `${user.name} has requested ${data.name} software`,
            timestamp: Date.now(),
          },
          ...prev,
        ]);

        if (!isManagerNotified.error.length) {
          // Set last email date if mail sent successfully
          if (isManagerNotified.success.length) {
            showSnackbar(
              snackbarStrings.messages.success.mailSentSuccessfully,
              snackbarStrings.variant.success
            );
          }
          // If mail has already been sent within past 24 hours then give warning message
          else if (isManagerNotified.warning.length) {
            showSnackbar(
              isManagerNotified.warning[0].message,
              snackbarStrings.variant.warning
            );
          }
          // Error while sending mail
          else {
            showSnackbar(
              snackbarStrings.messages.error.emailNotificationFailed,
              snackbarStrings.variant.error
            );
          }
        } else {
          // Handle errors
          showSnackbar(
            snackbarStrings.messages.error.emailNotificationFailed,
            snackbarStrings.variant.error
          );
        }
      } else {
        // handle error in updating when requesting sofware
        showSnackbar(
          snackbarStrings.messages.error.softwareRequestFail,
          snackbarStrings.variant.error
        );
      }
    },
    [
      location,
      showSnackbar,
      navigate,
      user.name,
      user.given_name,
      employeeStatus,
      resendMailHandle,
      setHistoryData,
    ]
  );
  const setActionButton = useCallback(
    ({ status, userSoftwareId, managers, name, imageUrl, lastEmailDate }) => {
      const isManager = managers.includes(loginUserId);
      if (isManager) {
        switch (status) {
          case softwareStatusStrings.active:
            return {
              buttonText: actionButtonText.revoke,
              onButtonClick: revokeActionHandle,
              data: {
                userSoftwareId,
                name,
                imageUrl,
                lastEmailDate,
              },
            };
          case softwareStatusStrings.pending:
            return {
              buttonText: actionButtonText.assign,
              data: {
                userSoftwareId,
                name,
                imageUrl,
                lastEmailDate,
              },
              disabled:
                employeeStatus === employeeStatusStrings.OffBoarded ||
                employeeStatus === employeeStatusStrings.OffBoarding,

              onButtonClick: assignActionHandle,
            };
          case softwareStatusStrings.revoked:
            return {
              buttonText: actionButtonText.reassign,
              data: {
                userSoftwareId,
                name,
                imageUrl,
                lastEmailDate,
                status,
              },
              disabled:
                employeeStatus === employeeStatusStrings.OffBoarded ||
                employeeStatus === employeeStatusStrings.OffBoarding,

              onButtonClick: reAssignActionHandle,
            };
          default:
            return null;
        }
      } else {
        switch (status) {
          case softwareStatusStrings.active:
            if (loginUserRole === 1)
              // if owner (role is 1) then give revoke access
              return {
                buttonText: actionButtonText.revoke,
                onButtonClick: revokeActionHandle,
                data: {
                  userSoftwareId,
                  name,
                  imageUrl,
                  lastEmailDate,
                },
              };
            return {
              buttonText: actionButtonText.resend,
              data: {
                userSoftwareId,
                name,
                imageUrl,
                lastEmailDate,
                status,
              },
              disabled: employeeStatus === employeeStatusStrings.OffBoarded,
              onButtonClick: resendMailHandle,
            };
          case softwareStatusStrings.pending:
            return {
              buttonText: actionButtonText.resend,
              data: {
                userSoftwareId,
                name,
                imageUrl,
                lastEmailDate,
                status,
              },
              disabled:
                employeeStatus === employeeStatusStrings.OffBoarded ||
                employeeStatus === employeeStatusStrings.OffBoarding,
              onButtonClick: resendMailHandle,
            };
          case softwareStatusStrings.revoked:
            return {
              buttonText: actionButtonText.request,
              data: {
                userSoftwareId,
                name,
                imageUrl,
                lastEmailDate,
                status,
              },
              disabled:
                employeeStatus === employeeStatusStrings.OffBoarded ||
                employeeStatus === employeeStatusStrings.OffBoarding,

              onButtonClick: requestMailHandle,
            };

          default:
            return null;
        }
      }
    },
    [
      revokeActionHandle,
      assignActionHandle,
      resendMailHandle,
      loginUserId,
      loginUserRole,
      employeeStatus,
      reAssignActionHandle,
      requestMailHandle,
    ]
  );

  const formatEmployeeSoftwareData = useCallback(() => {
    const softwares = employeeSoftwareResponse.map((software) => ({
      imageUrl: software.Software.icon,
      name: {
        softwareName: software.Software.name,
        isManager: software.Software.managed_by.includes(loginUserId),
        actionBy:
          makeFirstLetterCapital(software.status) ===
          softwareStatusStrings.pending
            ? software.RequestedBy?.first_name
            : software.UpdatedBy?.first_name,
        fullName:
          makeFirstLetterCapital(software.status) ===
          softwareStatusStrings.pending
            ? `${software.RequestedBy?.first_name} ${software.RequestedBy?.last_name}`
            : `${software.UpdatedBy?.first_name} ${software.UpdatedBy?.last_name}`,
        status: makeFirstLetterCapital(software.status),
      },

      assignDate: software.assign_date,
      ownership: software.Software.Client.name,
      username: formatValueIfNull(software.username),
      status: makeFirstLetterCapital(software.status),
      actionButton: setActionButton({
        status: makeFirstLetterCapital(software.status),
        userSoftwareId: software.id,
        managers: software.Software.managed_by,
        imageUrl: software.Software.icon,
        name: software.Software.name,
        lastEmailDate: software.last_email_date,
      }),
      softwareId: software.Software.id,
    }));

    // set employee software details
    setemployeeSoftware(softwares);
    setTableData(softwares);
  }, [employeeSoftwareResponse, loginUserId, setActionButton]);

  const onSoftwareTableRowClick = (index) => {
    navigate(
      endpointParamsSetter({
        path: pathStrings.softwareDetailsPagePath,
        params: index,
      })
    );
  };

  const fetchData = useCallback(async () => {
    setisLoading(true);
    setemployeeSoftware([]);
    setTableData([]);
    // get data
    const response = await networkClient({
      method: methodTypes.get,
      endpoint: endpointParamsSetter({
        path: networkEndpoints.user.getUserSoftwareById,
        params: employeeId,
      }),
    });
    setisLoading(false);
    if (!response.error) {
      setisError(false);
      setEmployeeSoftwareResponse(response.data);
      setlastUpdatedAt(
        moment().format(momentStrings.timeFormats.timeOnly12HoursCycle)
      );
    } else {
      setisError(true);
      if (response.errors[0].code === 403) {
        handle403Error(navigate, location);
      } else if (response.errors[0].code === 404) {
        setIs404Error(true);
      }
    }
  }, [employeeId, navigate, location, setIs404Error]);

  // Fetching list of softwares in drawer
  const fetchAssignDrawerSoftwares = useCallback(async () => {
    setFetchAllSoftwareLoading(true);
    const preSelectedSoftware = employeeSoftware
      .filter(
        (softwareObject) =>
          softwareObject.status !== softwareStatusStrings.revoked
      )
      .map((softwareObject) => softwareObject.softwareId);
    const softwaresData = await networkClient({
      method: methodTypes.get,
      endpoint: networkEndpoints.software.getActiveSoftwares,
    });
    if (softwaresData.error) {
      setFetchAllSoftwareLoading(false);
      if (softwaresData.errors[0].code === 403) {
        handle403Error(navigate, location);
      }
      setAssignDrawerSoftware(null);
      showSnackbar(
        employeeDetailsPageStrings.softwareTabStrings.softwareFetchErrorMessage,
        snackbarStrings.variant.error
      );
    } else {
      setFetchAllSoftwareLoading(false);
      const availableSoftwares = softwaresData.data.filter(
        (obj) => !preSelectedSoftware.includes(obj.id)
      );
      // Filter out the softwares that are not in tableData
      const notInTableData = availableSoftwares.filter(
        (software) =>
          !employeeSoftware.some((data) => data.softwareId === software.id)
      );
      setAssignDrawerSoftware(notInTableData);
    }
  }, [employeeSoftware, navigate, location, showSnackbar]);

  useEffect(() => {
    if (
      openAssignSoftwareDrawer &&
      assignDrawerSoftware === assignDrawerSoftwareInitialState
    )
      fetchAssignDrawerSoftwares();
  }, [
    fetchAssignDrawerSoftwares,
    assignDrawerSoftware,
    openAssignSoftwareDrawer,
  ]);

  const boxRef = useRef(null);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    formatEmployeeSoftwareData();
  }, [formatEmployeeSoftwareData, employeeSoftwareResponse]);

  const [sortOrderBy, setSortOrderBy] = useState(headCells[0].id);
  const [sortOrder, setSortOrder] = useState(
    customTableStrings.sortOrder.ascending
  );
  const [boxHeight, setBoxHeight] = useState('100%');

  if (boxHeight !== boxRef.current?.offsetHeight) {
    setBoxHeight(boxRef.current?.offsetHeight);
  }

  useEffect(() => {
    setBoxHeight(boxRef.current?.offsetHeight);
    window.addEventListener('resize', () =>
      setBoxHeight(boxRef.current?.offsetHeight)
    );
  }, [setBoxHeight, boxRef]);

  return (
    <div
      style={{
        height: '100%',
      }}>
      <Box
        ref={boxRef}
        maxHeight='100%'
        height='100%'
        bgcolor={theme.palette.background.paper}
        padding={employeeDetailsPagePaddings.customDetailsBoxPadding}
        minHeight={employeeDetailsPageSize.softwareTabMinHeight}>
        <Stack flexDirection='row' justifyContent='space-between'>
          <CustomSearchBar
            minWidth={employeeDetailsPageSize.searchbarMinWidth}
            onChange={handleSearchBarTextChange}
            placeholder={
              employeeDetailsPageStrings.softwareTabStrings.searchBarPlaceholder
            }
          />
          <Stack flexDirection='row' alignItems='center'>
            <Typography variant='caption' color={theme.palette.grey[400]}>
              {employeeDetailsPageStrings.softwareTabStrings.lastUpdateTime}
              {lastUpdatedAt}
            </Typography>
            <Box width={employeeDetailsPageSize.spaceBetweenButtons} />
            <Refresh
              onClick={() => {
                fetchData();
              }}
              sx={{
                padding:
                  employeeDetailsPagePaddings.softwareTabRefreshButtonPadding,
                border: `1px solid ${theme.palette.grey[100]}`,
                cursor: 'pointer',
              }}
            />

            <Box width={employeeDetailsPageSize.spaceBetweenButtons} />
            <CustomFilledIconButton
              disabled={
                employeeStatus === employeeStatusStrings.OffBoarded ||
                employeeStatus === employeeStatusStrings.OffBoarding ||
                employeeStatus === employeeStatusStrings.pending ||
                isLoading ||
                isError
              }
              text={
                employeeDetailsPageStrings.softwareTabStrings
                  .assignSoftwareButtonText
              }
              color={theme.palette.secondary.main}
              onClick={() => setOpenAssignSoftwareDrawer(true)}
            />
          </Stack>
        </Stack>
        <Box height={employeeDetailsPageSize.spaceBetweenCards} />
        <Box
          maxHeight={`calc(${boxHeight}px - 84px)`}
          height={`calc(${boxHeight}px - 150px)`}
          marginBottom={employeeDetailsPageSize.spaceAfterSoftwareTable}
          display='flex'>
          <CustomTable
            sortOrderBy={sortOrderBy}
            setSortOrderBy={setSortOrderBy}
            sortOrder={sortOrder}
            isError={isError}
            isLoading={isLoading}
            onTryAgainClick={fetchData}
            setSortOrder={setSortOrder}
            tableData={tableData}
            tableHeadData={headCells}
            emptyTableMessage={
              employeeDetailsPageStrings.softwareTabStrings.emptyTableMessage
            }
            maxTablebodyScreenSize={0.4}>
            {stableSort(
              tableData,
              getComparator(sortOrder, sortOrderBy),
              sortOrderBy
            ).map((row) => (
              <CustomTableRow
                key={row.actionButton.data.userSoftwareId}
                row={row}
                tableHeadData={headCells}
                onRowClick={() => onSoftwareTableRowClick(row.softwareId)}
              />
            ))}
          </CustomTable>
        </Box>
      </Box>

      <RevokeSoftwareDrawer
        toggleRevokeDrawer={toggleRevokeDrawer}
        setRevokeDrawer={setRevokeDrawer}
        revokeSoftwareData={revokeSoftwareData}
        setActionButton={setActionButton}
        setTableData={setTableData}
        setHistoryData={setHistoryData}
        user={user}
      />
      <AssignSoftwareDrawer
        openAssignSoftwareDrawer={openAssignSoftwareDrawer}
        setOpenAssignSoftwareDrawer={setOpenAssignSoftwareDrawer}
        userId={employeeId}
        assignDrawerSoftware={assignDrawerSoftware}
        fetchAssignDrawerSoftwares={fetchAssignDrawerSoftwares}
        isFetchSoftwaresLoading={isFetchAllSoftwaresLoading}
        employeeDetails={employeeDetails}
        setHistoryData={setHistoryData}
        setTableData={setTableData}
        setActionButton={setActionButton}
        user={user}
      />
      <CompleteSoftwareAssignDrawer
        toggleCompleteAssignDrawer={toggleCompleteAssignDrawer}
        setCompleteAssignDrawer={setCompleteAssignDrawer}
        assignSoftwareData={completeAssignSoftwareData}
        setActionButton={setActionButton}
        setTableData={setTableData}
        title={assignDrawerTitle}
        user={user}
        setHistoryData={setHistoryData}
      />
    </div>
  );
}

SoftwareTab.propTypes = {
  employeeId: PropTypes.string.isRequired,
  employeeStatus: PropTypes.string.isRequired,
  navigate: PropTypes.func.isRequired,
  employeeDetails: PropTypes.shape({}).isRequired,
  setHistoryData: PropTypes.func.isRequired,
  setIs404Error: PropTypes.func.isRequired,
};

export default SoftwareTab;
