import { Menu, Stack, useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';
import List from '@mui/material/List';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';
import { userSettingsFilterDropdownStrings } from '../../utils/strings';
import { filterDropDownSize } from '../../utils/dimensions/size';
import { filterDropDownPadding } from '../../utils/dimensions/paddings';
import CustomTextButton from '../CustomTextButton';
import CustomListItem from './CustomListItem';
import CustomRadioButton from '../CustomRadioButton';
import { filterDropDownMargins } from '../../utils/dimensions/margins';

function UserSettingsFilter({
  anchorElement,
  onClose,
  filterState,
  setFilterState,
  initialFilterState,
}) {
  const [openCollapse, setOpenCollapse] = useState(null);
  const theme = useTheme();

  const handleListItemClick = (index) => {
    if (openCollapse === index) {
      setOpenCollapse(null);
    } else {
      setOpenCollapse(index);
    }
  };

  const handleClear = () => {
    setFilterState(initialFilterState); // reset the selected radio button value
    setOpenCollapse(null);
    onClose();
  };

  const handleFormControl = (value, type) => {
    if (
      type === userSettingsFilterDropdownStrings.listItemType[0] ||
      type === userSettingsFilterDropdownStrings.listItemType[1]
    ) {
      if (value) {
        setFilterState({
          ...filterState,
          [type]: [value],
        });
      } else {
        setFilterState({
          ...filterState,
          [type]: [],
        });
      }
    }
  };

  const countFilter = (filterType) => {
    if (
      filterType === userSettingsFilterDropdownStrings.listItemType[0] ||
      filterType === userSettingsFilterDropdownStrings.listItemType[1]
    ) {
      return filterState[filterType].length;
    }
    return 0;
  };
  return (
    <Menu
      anchorEl={anchorElement}
      open={Boolean(anchorElement)}
      onClose={() => {
        onClose();
        setOpenCollapse(null);
      }}
      MenuListProps={{ disablePadding: true }}
      slotProps={{
        backdrop: {
          style: {
            background: 'transparent',
          },
        },
      }}
      PaperProps={{
        style: {
          width: filterDropDownSize.menuPaperPropsWidth,
          marginTop: filterDropDownMargins.menuBoxMarginTop,
          marginLeft: filterDropDownMargins.menuBoxMarginLeft,
        },
      }}>
      <List
        sx={{
          paddingBottom: filterDropDownPadding.menuListPaddingBottom,
          width: filterDropDownSize.menuListWidth,
        }}
        component='nav'
        aria-labelledby='nested-list-subheader'
        subheader={
          <Stack
            height={filterDropDownSize.menuStackHeight}
            sx={{
              display: 'flex',
              bgcolor: theme.palette.grey[50],
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              pl: filterDropDownPadding.menuStackLeftPadding,
              pr: filterDropDownPadding.menuStackRightPadding,
            }}>
            <Typography
              variant='h6'
              sx={{
                fontWeight: filterDropDownSize.ListItemTextWidth,
              }}>
              {userSettingsFilterDropdownStrings.dropDownTitle[0]}
            </Typography>
            <CustomTextButton onClick={handleClear} istextDecoration='false'>
              <Typography
                variant='buttonSmall'
                sx={{
                  fontWeight: filterDropDownSize.ListItemTextWidth,
                  color: theme.palette.primary.main,
                }}>
                {userSettingsFilterDropdownStrings.dropDownTitle[1]}
              </Typography>
            </CustomTextButton>
          </Stack>
        }>
        <CustomListItem
          handleListItemClick={handleListItemClick}
          listIndex={0}
          countFilter={countFilter}
          openCollapse={openCollapse}
          itemListName={userSettingsFilterDropdownStrings.listItemName[0]}
          itemListType={userSettingsFilterDropdownStrings.listItemType[0]}
        />

        <Collapse in={openCollapse === 0} timeout='auto' unmountOnExit>
          <List
            component='div'
            sx={{
              display: 'grid',
              paddingRight: filterDropDownPadding.collapseListRightPadding,
              bgcolor: theme.palette.grey[25],
            }}>
            <CustomRadioButton
              radioOption={userSettingsFilterDropdownStrings.StatusList}
              onChange={(value) =>
                handleFormControl(
                  value,
                  userSettingsFilterDropdownStrings.listItemType[0]
                )
              }
              checkedValue={filterState.status}
            />
          </List>
        </Collapse>
        <CustomListItem
          handleListItemClick={handleListItemClick}
          listIndex={1}
          countFilter={countFilter}
          openCollapse={openCollapse}
          itemListName={userSettingsFilterDropdownStrings.listItemName[1]}
          itemListType={userSettingsFilterDropdownStrings.listItemType[1]}
        />

        <Collapse in={openCollapse === 1} timeout='auto' unmountOnExit>
          <List
            component='div'
            sx={{
              display: 'grid',
              paddingRight: filterDropDownPadding.collapseListRightPadding,
              bgcolor: theme.palette.grey[25],
            }}>
            <CustomRadioButton
              radioOption={userSettingsFilterDropdownStrings.RoleList}
              onChange={(value) =>
                handleFormControl(
                  value,
                  userSettingsFilterDropdownStrings.listItemType[1]
                )
              }
              checkedValue={filterState.role}
            />
          </List>
        </Collapse>
      </List>
    </Menu>
  );
}

UserSettingsFilter.propTypes = {
  anchorElement: PropTypes.shape(),
  onClose: PropTypes.func.isRequired,
  filterState: PropTypes.shape().isRequired,
  setFilterState: PropTypes.func.isRequired,
  initialFilterState: PropTypes.shape({}).isRequired,
};
UserSettingsFilter.defaultProps = {
  anchorElement: false,
};
export default UserSettingsFilter;
