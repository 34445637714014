import { tableCellStrings } from './strings';

function endpointParamsSetter({ path, params }) {
  if (path.includes(tableCellStrings.colon)) {
    return path.replace(/:[^/]+(?=\/|$)/g, () => `${params}`);
  }
  return path.replace(/{([^}]+)}/g, params);
}

export default endpointParamsSetter;
