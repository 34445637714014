import PropTypes from 'prop-types';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { Typography } from '@mui/material';

export default function CustomRadioButton({
  radioOption,
  onChange,
  checkedValue,
}) {
  const handleRadioButtonClick = (event) => {
    if (checkedValue[0] === event.target.value) {
      onChange(null);
    } else {
      onChange(event.target.value);
    }
  };

  return (
    <RadioGroup name='radio-buttons-group'>
      {radioOption.map((radio) => (
        <FormControlLabel
          key={radio}
          value={radio}
          control={
            <Radio
              checked={checkedValue.includes(radio)}
              onClick={handleRadioButtonClick}
            />
          }
          label={
            <Typography variant='body2' fontWeight={400}>
              {radio}
            </Typography>
          }
          labelPlacement='start'
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        />
      ))}
    </RadioGroup>
  );
}

CustomRadioButton.propTypes = {
  radioOption: PropTypes.arrayOf(PropTypes.string).isRequired,
  checkedValue: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
};
CustomRadioButton.defaultProps = {
  checkedValue: null,
  onChange: null,
};
