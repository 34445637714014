import PropTypes from 'prop-types';
import { Box, useTheme } from '@mui/material';
import { employeeDetailsPageSize } from '../../utils/dimensions/size';
import { employeeDetailsPageStrings } from '../../utils/strings';
import BasicDetailsCard from './BasicDetailsCard';
import JobDetailsCard from './JobDetailsCard';
import CircularProgressIndicator from '../../components/CircularProgressIndicator';
import formatValueIfNull from '../../utils/formatValueIfNull';

function ProfileTab({
  employeeDetails,
  isLoading,
  setEmployeeDetails,
  setHistoryData,
}) {
  const theme = useTheme();
  if (isLoading) {
    return (
      <Box
        display='flex'
        flex={1}
        alignItems='center'
        justifyContent='center'
        height={350}>
        <CircularProgressIndicator />
      </Box>
    );
  }
  const employeeBasicDetails = {
    id: employeeDetails.id,
    first_name: employeeDetails.first_name,
    last_name: employeeDetails.last_name,
    country_code: employeeDetails.country_code,
    phone_number: employeeDetails.phone_number,
    personal_email: employeeDetails.personal_email,
    company_email: employeeDetails.company_email,
    address: employeeDetails.address,
    city: employeeDetails.city,
    state: employeeDetails.state,
    zipcode: employeeDetails.zipcode,
  };
  const employeeJobDetails = {
    id: employeeDetails.id,
    employeeId: employeeDetails.employee_id,
    job_title: employeeDetails.job_title,
    employment_type: employeeDetails.employment_type,
    company_email: employeeDetails.company_email,
    agencyName: formatValueIfNull(employeeDetails.Agency?.agency_name),
    agencyEmail: formatValueIfNull(employeeDetails.Agency?.email),
    agencyPhoneNumber: formatValueIfNull(
      employeeDetails.Agency?.agency_phone_number
    ),
    capacity: employeeDetails.capacity,
    join_date: employeeDetails.join_date,
    end_date: employeeDetails.end_date,
    note: employeeDetails.note,
    branch: employeeDetails.Branch,
  };
  return (
    <Box
      height='fit-content'
      bgcolor={theme.palette.background.paper}
      minHeight={employeeDetailsPageSize.profileTabMinHeight}>
      <BasicDetailsCard
        title={employeeDetailsPageStrings.profileTab.basicDetailsTitle}
        employeeBasicDetails={employeeBasicDetails}
        setEmployeeDetails={setEmployeeDetails}
        setHistoryData={setHistoryData}
      />
      <Box height={employeeDetailsPageSize.spaceBetweenCards} />
      <JobDetailsCard
        title={employeeDetailsPageStrings.profileTab.jobDetailstitle}
        employeeJobDetails={employeeJobDetails}
        setEmployeeDetails={setEmployeeDetails}
        setHistoryData={setHistoryData}
      />
      <Box
        marginBottom={employeeDetailsPageSize.spaceAfterProfileDetailsTable}
      />
      {/* <TeamDetailsCard
        title={employeeDetailsPageStrings.profileTab.teamDetailsTitle}
        employeeTeamDetails={employeeTeamDetails}
      /> */}
    </Box>
  );
}

ProfileTab.propTypes = {
  employeeDetails: PropTypes.shape({
    id: PropTypes.number,
    employee_id: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    country_code: PropTypes.string,
    phone_number: PropTypes.string,
    personal_email: PropTypes.string,
    company_email: PropTypes.string,
    address: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zipcode: PropTypes.string,
    location: PropTypes.string,
    Branch: PropTypes.shape({
      name: PropTypes.string,
    }),
    job_title: PropTypes.string,
    employment_type: PropTypes.string,
    capacity: PropTypes.number,
    join_date: PropTypes.string,
    end_date: PropTypes.string,
    note: PropTypes.string,
    Agency: PropTypes.shape({
      email: PropTypes.string.isRequired,
      agency_name: PropTypes.string.isRequired,
      agency_phone_number: PropTypes.string.isRequired,
    }),
  }),
  isLoading: PropTypes.bool,
  setEmployeeDetails: PropTypes.func,
  setHistoryData: PropTypes.func.isRequired,
};

ProfileTab.defaultProps = {
  employeeDetails: {
    id: 0,
    employee_id: '',
    first_name: '',
    last_name: '',
    country_code: '',
    phone_number: '',
    personal_email: '',
    company_email: '',
    address: '',
    city: '',
    state: '',
    zipcode: '',
    Branch: {
      name: '',
    },
    location: '',
    job_title: '',
    employment_type: '',
    capacity: 0,
    join_date: '',
    end_date: '',
    note: '',
  },
  setEmployeeDetails: () => {},
  isLoading: false,
};

export default ProfileTab;
