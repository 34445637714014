import { Menu, Stack, useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';
import List from '@mui/material/List';
import Collapse from '@mui/material/Collapse';
import Slider from '@mui/material/Slider';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import CustomCheckBox from '../CustomCheckBox';
import { employeeFilterDropdownStrings } from '../../utils/strings';
import { sliderMarksData } from './FilterDropDownData';
import { filterDropDownMargins } from '../../utils/dimensions/margins';
import {
  addSoftwareSize,
  filterDropDownSize,
} from '../../utils/dimensions/size';
import { filterDropDownPadding } from '../../utils/dimensions/paddings';
import CustomTextButton from '../CustomTextButton';
import CustomListItem from './CustomListItem';
import CustomRadioButton from '../CustomRadioButton';
import ApiErrorComponent from '../ApiErrorComponent';
import CircularProgressIndicator from '../CircularProgressIndicator';

function EmployeeFilter({
  anchorElement,
  onClose,
  filterState,
  setFilterState,
  initialFilterState,
  skillsData,
  fetchSkills,
  isSkillsError,
  isSkillsLoading,
  branchData,
  fetchBranches,
  isBranchesError,
  isBranchesLoading,
}) {
  const [openCollapse, setOpenCollapse] = useState(null);
  const theme = useTheme();
  const handleListItemClick = (index) => {
    if (openCollapse === index) {
      setOpenCollapse(null);
    } else {
      setOpenCollapse(index);
    }
  };
  const getStatusColor = (skill) => ({
    backgroundColor: filterState.skills.includes(skill)
      ? theme.palette.primary.main
      : undefined,
    color: filterState.skills.includes(skill)
      ? theme.palette.primary.contrastText
      : undefined,
    mr: filterDropDownMargins.skillFilterChipMarginRight,
    mt: filterDropDownMargins.skillFilterChipMarginTop,
    '&:hover': {
      background: filterState.skills.includes(skill)
        ? theme.palette.primary.main
        : undefined,
      color: filterState.skills.includes(skill)
        ? theme.palette.primary.contrastText
        : undefined,
    },
  });

  const handleClear = () => {
    setFilterState(initialFilterState); // reset the selected radio button value
    setOpenCollapse(null);
    onClose();
  };

  const handleFormControl = (value, type) => {
    if (type === employeeFilterDropdownStrings.listItemType[1]) {
      setFilterState({
        ...filterState,
        capacity: value,
      });
      return;
    }

    if (type === employeeFilterDropdownStrings.listItemType[3]) {
      if (value) {
        setFilterState({
          ...filterState,
          [type]: [value],
        });
      } else {
        setFilterState({
          ...filterState,
          [type]: [],
        });
      }
      return;
    }
    const newValue = [...filterState[type]];
    const index = newValue.indexOf(value);
    if (index >= 0) {
      newValue.splice(index, 1);
    } else {
      newValue.push(value);
    }
    setFilterState({
      ...filterState,
      [type]: newValue,
    });
  };

  const countFilter = (filterType) => {
    const filterValue = filterState[filterType];
    if (
      filterType === employeeFilterDropdownStrings.listItemType[1] &&
      filterValue !== 0
    ) {
      return 1;
    }
    if (
      filterType === employeeFilterDropdownStrings.listItemType[1] &&
      filterValue === 0
    ) {
      return 0;
    }
    const selectedCount = filterValue.length;
    return selectedCount;
  };

  return (
    <Menu
      anchorEl={anchorElement}
      open={Boolean(anchorElement)}
      onClose={() => {
        onClose();
        setOpenCollapse(null);
      }}
      MenuListProps={{ disablePadding: true }}
      slotProps={{
        backdrop: {
          style: {
            background: 'transparent',
          },
        },
      }}
      PaperProps={{
        style: {
          width: filterDropDownSize.menuPaperPropsWidth,
          marginTop: filterDropDownMargins.menuBoxMarginTop,
          marginLeft: filterDropDownMargins.menuBoxMarginLeft,
        },
      }}>
      <List
        sx={{
          paddingBottom: filterDropDownPadding.menuListPaddingBottom,
          width: filterDropDownSize.menuListWidth,
        }}
        component='nav'
        aria-labelledby='nested-list-subheader'
        subheader={
          <Stack
            height={filterDropDownSize.menuStackHeight}
            sx={{
              display: 'flex',
              bgcolor: theme.palette.grey[50],
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              pl: filterDropDownPadding.menuStackLeftPadding,
              pr: filterDropDownPadding.menuStackRightPadding,
            }}>
            <Typography
              variant='h6'
              sx={{
                fontWeight: filterDropDownSize.ListItemTextWidth,
              }}>
              {employeeFilterDropdownStrings.dropDownTitle[0]}
            </Typography>
            <CustomTextButton onClick={handleClear} istextDecoration='false'>
              <Typography
                variant='buttonSmall'
                sx={{
                  fontWeight: filterDropDownSize.ListItemTextWidth,
                  color: theme.palette.primary.main,
                }}>
                {employeeFilterDropdownStrings.dropDownTitle[1]}
              </Typography>
            </CustomTextButton>
          </Stack>
        }>
        <CustomListItem
          handleListItemClick={handleListItemClick}
          listIndex={0}
          countFilter={countFilter}
          openCollapse={openCollapse}
          itemListName={employeeFilterDropdownStrings.listItemName[0]}
          itemListType={employeeFilterDropdownStrings.listItemType[0]}
        />
        <Collapse in={openCollapse === 0} timeout='auto' unmountOnExit>
          {branchData?.length && (
            <List
              component='div'
              sx={{
                display: 'grid',
                paddingRight: filterDropDownPadding.collapseListRightPadding,
                bgcolor: theme.palette.grey[25],
              }}>
              <CustomCheckBox
                variant='body2'
                type={employeeFilterDropdownStrings.listItemName[0]}
                checkBoxOption={branchData}
                checkedValue={filterState.location}
                onChange={(value) =>
                  handleFormControl(
                    value,
                    employeeFilterDropdownStrings.listItemType[0]
                  )
                }
              />
            </List>
          )}

          {isBranchesLoading ? (
            <List
              component='div'
              sx={{
                padding: filterDropDownPadding.collapseListLeftPadding,
                bgcolor: theme.palette.grey[25],
                maxHeight: filterDropDownSize.listMaxHeight,
                overflowY: 'auto',
              }}>
              <Box display='flex' justifyContent='center'>
                <CircularProgressIndicator />
              </Box>
            </List>
          ) : null}

          {isBranchesError && !isBranchesLoading ? (
            <List
              component='div'
              sx={{
                padding: filterDropDownPadding.collapseListLeftPadding,
                bgcolor: theme.palette.grey[25],
                maxHeight: filterDropDownSize.listMaxHeight,
                overflowY: 'auto',
              }}>
              <ApiErrorComponent
                errorMessage={employeeFilterDropdownStrings.branchFetchError}
                onRefreshClick={fetchBranches}
              />
            </List>
          ) : null}
        </Collapse>

        <CustomListItem
          handleListItemClick={handleListItemClick}
          listIndex={1}
          countFilter={countFilter}
          openCollapse={openCollapse}
          itemListName={employeeFilterDropdownStrings.listItemName[1]}
          itemListType={employeeFilterDropdownStrings.listItemType[1]}
        />

        <Collapse in={openCollapse === 1} timeout='auto' unmountOnExit>
          <List
            component='div'
            sx={{
              paddingRight: filterDropDownPadding.collapseListRightPadding,
              pl: filterDropDownPadding.sliderPaddingLeft,
              bgcolor: theme.palette.grey[25],
            }}>
            <Box
              sx={{
                width: filterDropDownSize.sliderBoxWidth,
              }}>
              <Slider
                aria-label={employeeFilterDropdownStrings.listItemName[1]}
                value={filterState.capacity}
                step={5}
                min={0}
                max={40}
                marks={sliderMarksData}
                valueLabelDisplay='auto'
                onChange={(e, value) =>
                  handleFormControl(
                    value,
                    employeeFilterDropdownStrings.listItemType[1]
                  )
                }
              />
            </Box>
          </List>
        </Collapse>

        <CustomListItem
          handleListItemClick={handleListItemClick}
          listIndex={2}
          countFilter={countFilter}
          openCollapse={openCollapse}
          itemListName={employeeFilterDropdownStrings.listItemName[2]}
          itemListType={employeeFilterDropdownStrings.listItemType[2]}
        />

        <Collapse in={openCollapse === 2} timeout='auto' unmountOnExit>
          {skillsData?.length > 0 && (
            <List
              component='div'
              sx={{
                paddingRight: filterDropDownPadding.collapseListRightPadding,
                paddingLeft: filterDropDownPadding.collapseListLeftPadding,
                bgcolor: theme.palette.grey[25],
                maxHeight: filterDropDownSize.listMaxHeight,
                overflow: 'auto',
                '&::-webkit-scrollbar': {
                  width: addSoftwareSize.managersDropdownScrollbarWidth,
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: theme.palette.grey[300],
                  borderRadius: addSoftwareSize.managersDropdownScrollbarRadius,
                  opacity: 0.7,
                },
              }}>
              {skillsData?.map((skill) => (
                <Chip
                  disableRipple
                  key={skill.name}
                  label={skill.name}
                  onClick={() =>
                    handleFormControl(
                      skill.name,
                      employeeFilterDropdownStrings.listItemType[2]
                    )
                  }
                  sx={getStatusColor(skill.name)}
                />
              ))}
            </List>
          )}

          {isSkillsLoading ? (
            <List
              component='div'
              sx={{
                padding: filterDropDownPadding.collapseListLeftPadding,
                bgcolor: theme.palette.grey[25],
                maxHeight: filterDropDownSize.listMaxHeight,
                overflowY: 'auto',
              }}>
              <Box display='flex' justifyContent='center'>
                <CircularProgressIndicator />
              </Box>
            </List>
          ) : null}

          {isSkillsError && !isSkillsLoading && (
            <List
              component='div'
              sx={{
                padding: filterDropDownPadding.collapseListLeftPadding,
                bgcolor: theme.palette.grey[25],
                maxHeight: filterDropDownSize.listMaxHeight,
                overflowY: 'auto',
              }}>
              <ApiErrorComponent
                errorMessage={employeeFilterDropdownStrings.skillFetchError}
                onRefreshClick={fetchSkills}
              />
            </List>
          )}
        </Collapse>

        <CustomListItem
          handleListItemClick={handleListItemClick}
          listIndex={3}
          countFilter={countFilter}
          openCollapse={openCollapse}
          itemListName={employeeFilterDropdownStrings.listItemName[3]}
          itemListType={employeeFilterDropdownStrings.listItemType[3]}
        />

        <Collapse in={openCollapse === 3} timeout='auto' unmountOnExit>
          <List
            component='div'
            sx={{
              display: 'grid',
              paddingRight: filterDropDownPadding.collapseListRightPadding,
              bgcolor: theme.palette.grey[25],
            }}>
            <CustomRadioButton
              radioOption={employeeFilterDropdownStrings.StatusList}
              onChange={(value) =>
                handleFormControl(
                  value,
                  employeeFilterDropdownStrings.listItemType[3]
                )
              }
              checkedValue={filterState.status}
            />
          </List>
        </Collapse>
      </List>
    </Menu>
  );
}

EmployeeFilter.propTypes = {
  anchorElement: PropTypes.shape({}),
  onClose: PropTypes.func.isRequired,
  fetchSkills: PropTypes.func.isRequired,
  filterState: PropTypes.shape({
    location: PropTypes.arrayOf(PropTypes.string),
    capacity: PropTypes.number,
    skills: PropTypes.arrayOf(PropTypes.string),
    status: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  setFilterState: PropTypes.func.isRequired,
  initialFilterState: PropTypes.shape({
    location: PropTypes.arrayOf(PropTypes.string),
    capacity: PropTypes.number,
    skills: PropTypes.arrayOf(PropTypes.string),
    status: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  skillsData: PropTypes.arrayOf(PropTypes.shape()),
  isSkillsError: PropTypes.bool,
  isSkillsLoading: PropTypes.bool,
  fetchBranches: PropTypes.func.isRequired,
  branchData: PropTypes.arrayOf(PropTypes.string),
  isBranchesError: PropTypes.bool,
  isBranchesLoading: PropTypes.bool,
};
EmployeeFilter.defaultProps = {
  anchorElement: null,
  isSkillsError: false,
  isSkillsLoading: false,
  skillsData: [],
  isBranchesError: false,
  isBranchesLoading: false,
  branchData: [],
};

export default EmployeeFilter;
