import { AES, enc } from 'crypto-js';

export const encryptMessage = (message) => {
  try {
    const cipherMessage = AES.encrypt(
      message,
      process.env.REACT_APP_ENCRYPTION_SECRET_KEY
    );
    const encriptedMessage = cipherMessage.toString();

    return encriptedMessage;
  } catch (err) {
    return null;
    // log error from here.
  }
};

export const decryptMessage = (message) => {
  try {
    const bytes = AES.decrypt(
      message,
      process.env.REACT_APP_ENCRYPTION_SECRET_KEY
    );

    const decrepetedMessage = bytes.toString(enc.Utf8);

    return decrepetedMessage;
  } catch (err) {
    return null;
    // log error from here.
  }
};
