import PropTypes from 'prop-types';
import { Collapse, Switch, TableCell, TableRow } from '@mui/material';
import { useState } from 'react';
import CustomTableCell from './CustomTableCell';
import { customTableStrings } from '../../utils/strings';

function CustomTableRow({
  row,
  expandable,
  tableHeadData,
  expandSection,
  onRowClick,
}) {
  const [open, setOpen] = useState(false);
  return (
    <>
      <TableRow
        hover
        key={JSON.stringify(row)}
        sx={{
          display: 'flex',
          cursor: onRowClick != null ? 'pointer' : 'default',
        }}>
        {Object.values(row).map((cellData, cellIndex) => {
          if (tableHeadData[cellIndex]?.dataType !== 'skip')
            return (
              <TableCell
                key={JSON.stringify(cellData + cellIndex)}
                onClick={() => {
                  if (
                    onRowClick !== null &&
                    tableHeadData[cellIndex]?.dataType !==
                      customTableStrings.tableCellDataTypes.dropdown &&
                    tableHeadData[cellIndex]?.dataType !==
                      customTableStrings.tableCellDataTypes.actionButton &&
                    tableHeadData[cellIndex]?.dataType !==
                      customTableStrings.tableCellDataTypes.notesLink
                  ) {
                    onRowClick(Object.values(row)[0]);
                  }
                }}
                sx={{
                  flex: tableHeadData[cellIndex]?.flex,
                  borderBottom: open ? 'none' : 'default',
                  display:
                    tableHeadData[cellIndex]?.dataType ===
                    customTableStrings.tableCellDataTypes.multiple
                      ? 'table-cell'
                      : 'flex',
                  alignItems: 'center',
                  width: '100%',
                }}>
                <CustomTableCell
                  data={cellData}
                  dataType={tableHeadData[cellIndex]?.dataType}
                />
              </TableCell>
            );
          return null;
        })}

        {expandable && (
          <TableCell
            key='expandable'
            sx={{
              flex: 1,
              borderBottom: open ? 'none' : 'default',
              display: 'flex',
              alignItems: 'center',
            }}>
            <Switch value={open} onClick={() => setOpen(!open)} />
          </TableCell>
        )}
      </TableRow>
      {open && (
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }}>
            <Collapse in={open} timeout='auto' unmountOnExit>
              {expandSection}
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  );
}

CustomTableRow.propTypes = {
  row: PropTypes.shape({}).isRequired,
  tableHeadData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      dataType: PropTypes.string,
      label: PropTypes.string,
      flex: PropTypes.number,
      sorting: PropTypes.bool,
    })
  ).isRequired,
  expandable: PropTypes.bool,
  expandSection: PropTypes.element,
  onRowClick: PropTypes.oneOfType([PropTypes.func, PropTypes.oneOf([null])]),
};

CustomTableRow.defaultProps = {
  onRowClick: null,
  expandable: false,
  expandSection: <div />,
};

export default CustomTableRow;
