// headCells has data for Table Head data.
const headCells = [
  {
    id: 'image',
    dataType: 'image',
    label: '',
    flex: 1,
    sorting: false,
  },
  {
    id: 'nameWithManager',
    dataType: 'nameWithManager',
    label: 'Name',
    flex: 3,
    sorting: true,
  },
  {
    id: 'assignDate',
    dataType: 'assignDate',
    label: 'Assigned Date',
    flex: 4,
    sorting: true,
  },
  {
    id: 'ownership',
    dataType: 'text',
    label: 'Client',
    flex: 4,
    sorting: true,
  },
  {
    id: 'username',
    dataType: 'text',
    label: 'Username',
    flex: 3,
    sorting: true,
  },
  {
    id: 'status',
    dataType: 'status',
    label: 'Status',
    flex: 3,
    sorting: true,
  },
  {
    id: 'action',
    dataType: 'actionButton',
    label: 'Action',
    flex: 3,
  },
  {
    id: 'softwareId',
    dataType: 'skip',
    label: 'softwareId',
    flex: 1,
  },
];

export default headCells;
