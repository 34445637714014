import PropTypes from 'prop-types';
import { Box, CircularProgress, circularProgressClasses } from '@mui/material';
import theme from '../styles/theme';
import { circularProgressSize } from '../utils/dimensions/size';

function CircularProgressIndicator({ size, thickness, color, bgColor }) {
  return (
    <Box
      display='flex'
      alignItems='center'
      justifyContent='center'
      sx={{ position: 'relative' }}>
      <CircularProgress
        variant='determinate'
        sx={{
          color: bgColor,
        }}
        size={size}
        thickness={thickness}
        value={circularProgressSize.defaultBgValue}
      />
      <CircularProgress
        variant='indeterminate'
        disableShrink
        sx={{
          color,
          animationDuration: '550ms',
          position: 'absolute',
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: 'round',
          },
        }}
        size={size}
        thickness={thickness}
      />
    </Box>
  );
}
CircularProgressIndicator.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  thickness: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  color: PropTypes.string,
  bgColor: PropTypes.string,
};

CircularProgressIndicator.defaultProps = {
  size: circularProgressSize.defaultSize,
  thickness: circularProgressSize.defaultThickness,
  color: theme.palette.primary.main,
  bgColor: theme.palette.grey[200],
};
export default CircularProgressIndicator;
