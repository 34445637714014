import { useLocation, useNavigate } from 'react-router-dom';
import {
  ListItemButton,
  ListItemIcon,
  Collapse,
  Typography,
  Divider,
  Chip,
  useTheme,
  Box,
  Stack,
} from '@mui/material';
import PropTypes from 'prop-types';
import { useCallback, useContext, useState } from 'react';
import { ExpandLess, Info, ExpandMore } from '@mui/icons-material';
import CustomFilledIconButton from './CustomFilledIconButton';
import CustomTextField from './Inputs/CustomTextField';
import CustomOutlinedIconButton from './CustomOutlinedIconButton';
import { getStatusColor } from './Table/CustomTableCell';
import {
  customListExpandElementSize,
  offBoardEmployeeDrawerSizes,
} from '../utils/dimensions/size';
import { customListExpandElementPaddings } from '../utils/dimensions/paddings';
import {
  customListExpandElement,
  offBoardEmployeeDrawerMargins,
} from '../utils/dimensions/margins';
import {
  offBoardEmployeeStrings,
  softwareStatusStrings,
  employeeDetailsPageStrings,
} from '../utils/strings';
import { methodTypes, networkEndpoints } from '../networks/networkStrings';
import networkClient from '../networks/networkClient';
import { handle403Error } from '../utils/errorHandler';
import resendEmailClient from '../utils/resendEmailClient';
import { snackbarContext } from '../utils/context';
import getLoggedInUser from '../utils/getLoggedInUser';
import DefaultSoftwareFavicon from '../utils/assets/DefaultSoftwareFavicon.svg';
import CircularProgressIndicator from './CircularProgressIndicator';
import endpointParamsSetter from '../utils/endpointParamsSetter';

export default function CustomListExpandElement({
  index,
  row,
  tableData,
  setIsError,
  setTableData,
  setHistoryData,
}) {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const { showSnackbar } = useContext(snackbarContext);
  const { userId: loginUserId, name: userName } = getLoggedInUser(
    navigate,
    location
  );
  const [open, setOpen] = useState({});
  const [customTextFields, setCustomTextFields] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const toggleListState = (id) => {
    setOpen((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
    // Managing state on cancel button click
    setCustomTextFields((prevState) => ({
      ...prevState,
      [id]: '',
    }));
  };

  const handleCustomTextFieldChange = (id, value) => {
    setCustomTextFields((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const revokeSoftware = useCallback(async () => {
    setIsLoading(true);
    const revoke = await networkClient({
      method: methodTypes.patch,
      endpoint: endpointParamsSetter({
        path: networkEndpoints.userSoftware.userSoftwareById,
        params: row.id,
      }),
      requestBody: {
        status: 'revoked',
        note: customTextFields[row.id],
      },
    });

    if (revoke.code === 200) {
      let updatedTableData;
      setTableData((prev) => {
        updatedTableData = [...prev]; // Create a copy of the previous state
        updatedTableData[index] = {
          ...updatedTableData[index],
          status: softwareStatusStrings.revoked,
        };
        return updatedTableData;
      });
      setHistoryData((prev) => [
        {
          description: `${userName} has revoked ${tableData[index].name} software`,
          timestamp: Date.now(),
        },
        ...prev,
      ]);
      setIsLoading(false);
    }
    if (revoke.error) {
      setIsError(true);
      if (revoke.errors[0].code === 403) {
        handle403Error(navigate, location);
      }
      setTimeout(() => {
        setIsError(false);
      }, 3000);
    }
  }, [
    customTextFields,
    row,
    setIsError,
    index,
    location,
    navigate,
    setHistoryData,
    userName,
    setTableData,
    tableData,
  ]);

  const resendMailHandle = useCallback(async () => {
    setIsLoading(true);
    await resendEmailClient({
      userSoftware: { ...row, userSoftwareId: row.id },
      showSnackbar,
      navigate,
      location,
    });
    setIsLoading(false);
  }, [row, showSnackbar, navigate, location]);

  return (
    <>
      <ListItemButton
        sx={{
          display: 'flex',
          flex: 1,
          width: customListExpandElementSize.listButtonWidth,
          backgroundColor: open[row.id]
            ? theme.palette.grey[25]
            : theme.palette.common.white,
        }}
        onClick={() => toggleListState(row.id)}>
        <ListItemIcon>
          {row.image ? (
            <img
              src={row.image}
              alt={row.name}
              width={customListExpandElementSize.listIconWidth}
              height={customListExpandElementSize.listIconHeight}
              onError={(e) => {
                e.target.src = DefaultSoftwareFavicon;
                e.onerror = null;
              }}
            />
          ) : (
            <img
              width={customListExpandElementSize.listIconWidth}
              height={customListExpandElementSize.listIconHeight}
              src={DefaultSoftwareFavicon}
              style={{ objectFit: 'contain' }}
              alt={row.name}
            />
          )}
        </ListItemIcon>
        <Stack
          marginRight={offBoardEmployeeDrawerMargins.marginRight}
          direction='row'
          flex={1}
          justifyContent='space-between'
          alignItems='center'
          paddingRight={
            customListExpandElementPaddings.listItemTextPaddingRight
          }>
          <Stack
            marginLeft={offBoardEmployeeDrawerMargins.marginLeft}
            direction='column'
            spacing={offBoardEmployeeDrawerSizes.spaceAfterSoftware}>
            <Typography variant='body2SemiBold'>{row.name}</Typography>
            <Typography variant='body2' color={theme.palette.grey[500]}>
              {row.client}
            </Typography>
          </Stack>
          <Stack alignItems='center' justifyContent='center'>
            {row?.manager?.includes(loginUserId) && (
              <Chip
                label={
                  employeeDetailsPageStrings.employeeDetailsCard.managerLabel
                }
                sx={{
                  width: offBoardEmployeeDrawerSizes.managerChipWidth,
                  height: offBoardEmployeeDrawerSizes.managerChipHeight,
                  bgcolor: theme.palette.info.dark,
                  color: theme.palette.grey[25],
                }}
              />
            )}
          </Stack>
          <Chip sx={getStatusColor(row.status)} label={row.status} />
        </Stack>
        {row.status === softwareStatusStrings.active &&
          (open[row.id] ? <ExpandLess /> : <ExpandMore />)}
        {row.status !== softwareStatusStrings.active && (
          <ExpandMore sx={{ color: theme.palette.grey[300] }} />
        )}
      </ListItemButton>
      <Divider />
      {row.status === softwareStatusStrings.active && (
        <Collapse
          in={open[row.id]}
          unmountOnExit
          sx={{
            bgcolor: theme.palette.grey[25],
            paddingX: customListExpandElementPaddings.collapsePaddingX,
          }}>
          {row?.manager?.includes(loginUserId) ? (
            <>
              <Box
                height={customListExpandElementSize.spaceBetweenListAndCollapse}
              />
              <Stack
                alignItems='center'
                flexDirection='row'
                sx={{ display: 'flex' }}>
                <Info
                  sx={{
                    color: theme.palette.primary.dark,
                    height: customListExpandElementSize.infoIconHeight,
                    width: customListExpandElementSize.infoIconWidth,
                  }}
                />
                <Box
                  width={
                    customListExpandElementSize.spaceBetweenIconAndTypography
                  }
                />
                <Typography
                  width={customListExpandElementSize.messageWidth}
                  variant='body2'>
                  {offBoardEmployeeStrings.isManagerText}
                </Typography>
              </Stack>
              <Box
                sx={{
                  display: 'inline-flex',
                  width:
                    customListExpandElementSize.spaceBetweenTypographyAndTextfeild,
                }}>
                <CustomTextField
                  margin={customListExpandElement.marginTextFeild}
                  placeHolder='Notes'
                  multiline
                  tableDatas={2}
                  value={customTextFields[row.id] || ''}
                  onChange={(e) =>
                    handleCustomTextFieldChange(row.id, e.target.value)
                  }
                />
              </Box>

              <Box
                sx={{
                  marginBottom: customListExpandElement.boxMarginBottom,
                  marginTop: customListExpandElement.boxMarginTop,
                  marginRight: customListExpandElement.boxMarginRight,
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}>
                <CustomOutlinedIconButton
                  variant='buttonMedium'
                  onClick={() => toggleListState(row.id)}>
                  {offBoardEmployeeStrings.listCancelButtonText}
                </CustomOutlinedIconButton>
                <Box width={customListExpandElementSize.spaceBetweenButton} />
                {isLoading ? (
                  <CircularProgressIndicator />
                ) : (
                  <CustomFilledIconButton
                    variant='buttonMedium'
                    text={offBoardEmployeeStrings.revokeButtonText}
                    onClick={revokeSoftware}
                    disabled={isLoading}
                  />
                )}
              </Box>
            </>
          ) : (
            <>
              <Box
                height={customListExpandElementSize.spaceBetweenListAndCollapse}
              />
              <Stack
                alignItems='center'
                flexDirection='row'
                sx={{ display: 'flex' }}>
                <Info
                  sx={{
                    color: theme.palette.primary.dark,
                    height: customListExpandElementSize.infoIconHeight,
                    width: customListExpandElementSize.infoIconWidth,
                  }}
                />
                <Box
                  width={
                    customListExpandElementSize.spaceBetweenIconAndTypography
                  }
                />
                <Typography
                  width={customListExpandElementSize.messageWidth}
                  variant='body2'>
                  {offBoardEmployeeStrings.notManagerText}
                </Typography>
              </Stack>
              <Box
                sx={{
                  marginBottom: customListExpandElement.boxMarginBottom,
                  marginTop: customListExpandElement.boxMarginTop,
                  marginRight: customListExpandElement.boxMarginRight,
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}>
                {isLoading ? (
                  <Box display='flex' alignItems='center' marginRight={1}>
                    <CircularProgressIndicator />
                  </Box>
                ) : (
                  <CustomFilledIconButton
                    variant='buttonMedium'
                    text={offBoardEmployeeStrings.revokeRequestButtonText}
                    onClick={resendMailHandle}
                    disabled={isLoading}
                  />
                )}
              </Box>
            </>
          )}
          {open[row.id] && <Divider />}
        </Collapse>
      )}
    </>
  );
}

CustomListExpandElement.propTypes = {
  index: PropTypes.number.isRequired,
  row: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    image: PropTypes.string,
    name: PropTypes.string,
    status: PropTypes.string,
    client: PropTypes.string,
    manager: PropTypes.arrayOf(PropTypes.number),
  }).isRequired,
  tableData: PropTypes.arrayOf(
    PropTypes.shape({ status: PropTypes.string, name: PropTypes.string })
  ).isRequired,
  setIsError: PropTypes.func.isRequired,
  setTableData: PropTypes.func.isRequired,
  setHistoryData: PropTypes.func.isRequired,
};
