import Employees from '../pages/Employees/Employees';
import Softwares from '../pages/Softwares/Softwares';
import pathStrings from '../utils/pathStrings';
import { navBarStrings } from '../utils/strings';
// below code is commented for hiding hardware and projects.

// import Hardwares from '../pages/Hardwares/Hardwares';
// import Projects from '../pages/Projects/Projects';

// TabsRouterPaths has paths for tabs in navbar
const navbarTabsRouterPaths = [
  {
    name: navBarStrings.employeePageTabLabel,
    path: pathStrings.employeePagePath,
    component: <Employees />,
  },
  // {
  //   name: navBarStrings.hardwarePageTabLabel,
  //   path: pathStrings.hardwaresPagePath,
  //   component: <Hardwares />,
  // },
  {
    name: navBarStrings.softwarePageTabLabel,
    path: pathStrings.softwarePagePath,
    component: <Softwares />,
  },
  // {
  //   name: navBarStrings.projectsPageTabLabel,
  //   path: pathStrings.projectsPagePath,
  //   component: <Projects />,
  // },
];

export default navbarTabsRouterPaths;
