import PropTypes from 'prop-types';
import { Stack, Typography } from '@mui/material';
import { customTextButtonSize } from '../utils/dimensions/size';
import theme from '../styles/theme';

function CustomTextButton({
  children,
  disabled,
  onClick,
  icon,
  color,
  istextDecoration,
}) {
  return (
    <Stack
      flexDirection='row'
      gap={customTextButtonSize.spaceBetweenIconAndText}
      onClick={disabled ? null : onClick}
      alignItems='center'>
      {icon}
      <Typography
        display='flex'
        alignItems='center'
        variant='buttonSmall'
        sx={{
          cursor: disabled ? 'not-allowed' : 'pointer',
          color,
          ' :hover': {
            textDecoration: disabled || istextDecoration ? 'none' : 'underline',
          },
        }}>
        {children}
      </Typography>
    </Stack>
  );
}

CustomTextButton.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string])
    .isRequired,
  onClick: PropTypes.func.isRequired,
  icon: PropTypes.element,
  disabled: PropTypes.bool,
  istextDecoration: PropTypes.string,
  color: PropTypes.string,
};

CustomTextButton.defaultProps = {
  icon: null,
  disabled: false,
  istextDecoration: 'false',
  color: theme.palette.secondary.main,
};

export default CustomTextButton;
