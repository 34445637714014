import moment from 'moment';

const formatTime = (timestamp, isTimeRequire = false) => {
  const daysAgo = moment()
    .hour(23)
    .minute(59)
    .seconds(59)
    .diff(timestamp, 'days');

  if (daysAgo === 0) {
    // returning today for label
    if (!isTimeRequire) return 'Today';

    // returning today with time for timstamp
    const time = moment(timestamp).format('hh:mm A');
    return `Today ${time}`;
  }
  if (daysAgo === 1) {
    // returning yesterday for label
    if (!isTimeRequire) return 'Yesterday';
    // returning yesterday with time for timstamp
    const time = moment(timestamp).format('hh:mm A');
    return `Yesterday ${time}`;
  }
  if (!isTimeRequire) return moment(timestamp).format('MMM DD, YYYY');

  const date = moment(timestamp).format('MMM DD, YYYY hh:mm A');
  return date;
};

export default formatTime;
