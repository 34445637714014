import { Typography, useTheme, Box, Stack } from '@mui/material';
import { Add, FilterAlt } from '@mui/icons-material';
import Badge from '@mui/material/Badge';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import AddEmployeeDrawer from '../../components/EmployeeActions/AddEmployeeDrawer';
import CustomFilledIconButton from '../../components/CustomFilledIconButton';
import CustomOutlinedIconButton from '../../components/CustomOutlinedIconButton';
import CustomTable from '../../components/Table/CustomTable';
import { commonPagePaddings } from '../../utils/dimensions/paddings';
import { employeePageSize } from '../../utils/dimensions/size';
import {
  customTableStrings,
  employeeDetailsPageStrings,
  employeesPageStrings,
  paginationStrings,
} from '../../utils/strings';
import headCells from './EmployeeTableData';
import { ReactComponent as AddEmployeeImage } from '../../utils/assets/addEmployee.svg';
import CustomSearchBar from '../../components/Inputs/CustomSearchBar';
import CustomNoDataComponent from '../../components/CustomNoDataComponent';
import CustomTableRow from '../../components/Table/CustomTableRow';
import { getComparator, stableSort } from '../../utils/sortingHelpers';
import networkClient from '../../networks/networkClient';
import { handle403Error } from '../../utils/errorHandler';
import { methodTypes, networkEndpoints } from '../../networks/networkStrings';
import EmployeeFilter from '../../components/Filter/EmployeeFilter';
import endpointParamsSetter from '../../utils/endpointParamsSetter';
import pathStrings from '../../utils/pathStrings';
import makeFirstLetterCapital from '../../utils/makeFirstLetterCapital';
import { paginationContext } from '../../utils/context';
import { paramsToObject, updateURL } from '../../utils/urlParser';

function Employees() {
  const theme = useTheme();
  const currentLocation = useLocation();
  const navigate = useNavigate();

  const { getRowsPerPage, updateRowsPerPage, updatePageNo, getPageNo } =
    useContext(paginationContext);

  const rowsPerPage = getRowsPerPage(paginationStrings.employeeListTable);
  const setRowsPerPage = (value) => {
    updateRowsPerPage(paginationStrings.employeeListTable, value);
  };
  const page = getPageNo(paginationStrings.employeeListTable);
  const setPage = (value) => {
    updatePageNo(paginationStrings.employeeListTable, value);
  };

  const initialFilterData = useMemo(
    () => ({
      location: [],
      capacity: 0,
      skills: [],
      status: [],
    }),
    []
  );
  const initialSortData = useMemo(
    () => ({
      sort: customTableStrings.sortOrder.decending,
      sortBy: headCells[0].id,
    }),
    []
  );

  const initialFilterState = useMemo(
    () => paramsToObject(currentLocation.search.slice(1), initialFilterData),
    [currentLocation, initialFilterData]
  );

  const initialSortState = useMemo(
    () => paramsToObject(currentLocation.search.slice(1), initialSortData),
    [currentLocation, initialSortData]
  );

  const initialSearchState = useMemo(
    () => paramsToObject(currentLocation.search.slice(1), { search: '' }),
    [currentLocation]
  );

  const [toggleDrawer, setToggleDrawer] = useState(false);
  const [anchorElement, setanchorElement] = useState(null);
  const [filterState, setFilterState] = useState(initialFilterState);
  const [activeFilters, setActiveFiltersCount] = useState(0);
  const [sortOrderBy, setSortOrderBy] = useState(initialSortState.sortBy);
  const [sortOrder, setSortOrder] = useState(initialSortState.sort);
  const [isSkillsError, setIsSkillsError] = useState(false);
  const [isUserLoading, setUserLoading] = useState(true);
  const [isError, setIsError] = useState(true);
  const [allEmployeeData, setAllEmployeeData] = useState([]);
  const [filteredEmployeeData, setFilteredEmployeeData] = useState([]);
  const [skillsData, setSkillsData] = useState(null);
  const [isSkillsLoading, setIsSkillsLoading] = useState(false);
  const [branchData, setBranchData] = useState(null);
  const [isBranchesLoading, setIsBranchesLoading] = useState(false);
  const [isBranchesError, setIsBranchesError] = useState(false);
  const [searchBarText, setSearchBarText] = useState(initialSearchState.search);
  const [filterLoading, setFilterLoading] = useState(false);

  useEffect(() => {
    updateURL(
      filterState,
      sortOrder,
      sortOrderBy,
      searchBarText,
      initialSortData,
      currentLocation
    );
  }, [
    currentLocation,
    filterState,
    initialSortData,
    searchBarText,
    sortOrder,
    sortOrderBy,
  ]);

  const fetchUsers = useCallback(async () => {
    const employeeList = await networkClient({
      method: methodTypes.get,
      endpoint: networkEndpoints.user.getUsers,
    });
    if (employeeList.error) {
      if (employeeList.errors[0].code === 403) {
        handle403Error(navigate, currentLocation);
      }
      setAllEmployeeData([]);
      setFilteredEmployeeData([]);
      setUserLoading(false);
      setIsError(true);
    } else {
      const employeeData = employeeList.data.map((data) => ({
        userId: data.id,
        id: data.employee_id,
        nameData: {
          imageUrl: '',
          name: `${data.first_name} ${data.last_name}`,
          position: data.job_title,
        },
        location: data.Branch.branch_name,
        capacity: data.capacity,
        skills: data.Skills.map((skillsName) => skillsName.name),
        status: makeFirstLetterCapital(data.status),
      }));
      setAllEmployeeData(employeeData);
      if (currentLocation.search.slice(1) === '') {
        setFilteredEmployeeData(employeeData);
        setUserLoading(false);
        setIsError(false);
      } else setFilterLoading(true);
    }
  }, [navigate, currentLocation]);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  const filterData = useCallback(() => {
    let count = 0;
    Object.values(filterState).forEach((value) => {
      if (Array.isArray(value) && value.length > 0) {
        count += 1;
      } else if (typeof value === 'number' && value !== 0) {
        count += 1;
      }
    });
    setActiveFiltersCount(count);
    const filteredRows = allEmployeeData.filter((row) => {
      const { location, capacity, skills, status } = filterState;
      if (count !== 0) {
        if (location.length > 0 && !location.includes(row.location)) {
          return false;
        }
        if (capacity > 0 && row.capacity > capacity) {
          return false;
        }
        if (
          skills.length > 0 &&
          !skills.some((skill) => row.skills.includes(skill))
        ) {
          return false;
        }
        if (status.length > 0 && !status.includes(row.status)) {
          return false;
        }
      }
      return (
        (row.id &&
          row.id.toUpperCase().includes(searchBarText.trim().toUpperCase())) ||
        row.nameData.name
          .toUpperCase()
          .includes(searchBarText.trim().toUpperCase()) ||
        row.nameData.position
          ?.toUpperCase()
          .includes(searchBarText.trim().toUpperCase())
      );
    });

    setFilteredEmployeeData(filteredRows);
  }, [filterState, allEmployeeData, searchBarText]);

  useEffect(() => {
    if (filterLoading) {
      setFilterLoading(false);
      setUserLoading(false);
      setIsError(false);
    }
  }, [filteredEmployeeData, filterLoading]);

  useEffect(() => {
    filterData();
  }, [filterData]);

  const handleClick = (event) => {
    setanchorElement(event.currentTarget);
  };

  const handleMenuClose = () => {
    setanchorElement(null);
  };

  const onRowClickFunction = (index) => {
    navigate(
      endpointParamsSetter({
        path: `${pathStrings.employeeDetailsPagePath}/#${employeeDetailsPageStrings.pageTabs.profile}`,
        params: index,
      })
    );
  };

  const fetchSkills = useCallback(async () => {
    setIsSkillsLoading(true);
    const response = await networkClient({
      method: methodTypes.get,
      endpoint: networkEndpoints.user.getAllSkills,
    });
    setIsSkillsLoading(false);
    if (response.error) {
      setIsSkillsError(true);
      if (response.errors[0].code === 403) {
        handle403Error(navigate, currentLocation);
      }
    } else {
      setIsSkillsError(false);
      const skillNames = response.data.skills.map((skill) => ({
        name: skill.name,
      }));
      setSkillsData(skillNames);
    }
  }, [navigate, currentLocation]);

  const fetchBranches = useCallback(async () => {
    setIsBranchesLoading(true);
    const response = await networkClient({
      method: methodTypes.get,
      endpoint: networkEndpoints.branches.getAllBranches,
    });
    setIsBranchesLoading(false);
    if (response.error) {
      setIsBranchesError(true);
      if (response.errors[0].code === 403) {
        handle403Error(navigate, currentLocation);
      }
    } else {
      setIsBranchesError(false);
      const branchesName = response.data.map((branch) => branch.name);
      setBranchData(branchesName);
    }
  }, [navigate, currentLocation]);

  useEffect(() => {
    if (anchorElement && !skillsData) fetchSkills();
    if (anchorElement && !branchData) fetchBranches();
  }, [fetchSkills, fetchBranches, anchorElement, skillsData, branchData]);

  return (
    <Box
      sx={{
        height: employeePageSize.rootBoxHeight,
      }}>
      {allEmployeeData.length === 0 &&
        !isError &&
        activeFilters === 0 &&
        !filterLoading &&
        !isUserLoading && (
          <CustomNoDataComponent
            imageComponent={<AddEmployeeImage />}
            title={employeesPageStrings.customNoDataStrings.title}
            subtitleOne={employeesPageStrings.customNoDataStrings.subtitleOne}
            subtitleTwo={employeesPageStrings.customNoDataStrings.subtitleTwo}
            buttonText={employeesPageStrings.addButtonText}
            onClick={() => {
              setToggleDrawer(true);
            }}
          />
        )}
      {(filteredEmployeeData.length !== 0 || activeFilters >= 0) && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
          }}>
          <Stack
            direction='row'
            justifyContent='space-between'
            padding={commonPagePaddings.pagePadding}
            bgcolor={theme.palette.background.paper}>
            <Stack>
              <Typography variant='h4' color={theme.palette.grey[1100]}>
                {employeesPageStrings.title}
              </Typography>
              <Box height={employeePageSize.spaceBetweenTitleAndSubtitle} />
              <Typography variant='body1' color={theme.palette.grey[300]}>
                {employeesPageStrings.subtitle}
              </Typography>
            </Stack>
            <CustomFilledIconButton
              text={employeesPageStrings.addButtonText}
              onClick={() => setToggleDrawer(!toggleDrawer)}
              icon={<Add />}
            />
          </Stack>

          <Box
            display='flex'
            flexDirection='column'
            maxHeight={employeePageSize.tableBoxHeight}
            padding={commonPagePaddings.pagePadding}>
            <Stack
              marginBottom={employeePageSize.spaceBetweenSearchbarAndTable}
              direction='row'
              justifyContent='space-between'>
              <CustomSearchBar
                initialValue={searchBarText}
                minWidth={employeePageSize.searchbarMinWidth}
                onChange={(e) => setSearchBarText(e.target.value)}
                placeholder={employeesPageStrings.searchBarPlaceholder}
              />
              <Badge color='primary' badgeContent={activeFilters}>
                <CustomOutlinedIconButton
                  icon={<FilterAlt />}
                  onClick={handleClick}
                  textColor={
                    activeFilters > 0
                      ? theme.palette.primary.main
                      : theme.palette.secondary.main
                  }>
                  {employeesPageStrings.filterButtonText}
                </CustomOutlinedIconButton>
              </Badge>
            </Stack>

            <CustomTable
              page={page}
              setPage={setPage}
              sortOrderBy={sortOrderBy}
              setSortOrderBy={setSortOrderBy}
              sortOrder={sortOrder}
              setSortOrder={setSortOrder}
              tableData={filteredEmployeeData}
              tableHeadData={headCells}
              isLoading={isUserLoading}
              isError={isError}
              onTryAgainClick={fetchUsers}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}>
              {stableSort(
                filteredEmployeeData,
                getComparator(sortOrder, sortOrderBy),
                sortOrderBy
              )
                .slice(
                  rowsPerPage < 0 ? 0 : page * rowsPerPage,
                  rowsPerPage < 0 ? undefined : page * rowsPerPage + rowsPerPage
                )
                .map((row) => (
                  <CustomTableRow
                    key={JSON.stringify(row)}
                    row={row}
                    onRowClick={() => onRowClickFunction(row.userId)}
                    tableHeadData={headCells}
                  />
                ))}
            </CustomTable>
          </Box>
        </Box>
      )}

      <AddEmployeeDrawer
        toggleDrawer={toggleDrawer}
        setToggleDrawer={setToggleDrawer}
        allEmployeeData={allEmployeeData}
        setAllEmployeeData={setAllEmployeeData}
      />

      <EmployeeFilter
        anchorElement={anchorElement}
        onClose={handleMenuClose}
        filterState={filterState}
        setFilterState={setFilterState}
        initialFilterState={initialFilterData}
        skillsData={skillsData}
        fetchSkills={fetchSkills}
        isSkillsError={isSkillsError}
        isSkillsLoading={isSkillsLoading}
        branchData={branchData}
        fetchBranches={fetchBranches}
        isBranchesError={isBranchesError}
        isBranchesLoading={isBranchesLoading}
      />
    </Box>
  );
}

export default Employees;
