import PropTypes from 'prop-types';
import { AddCircle, Flag } from '@mui/icons-material';
import {
  Box,
  Chip,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { employeeDetailsPagePaddings } from '../../utils/dimensions/paddings';
import {
  addSoftwareSize,
  employeeDetailsPageSize,
} from '../../utils/dimensions/size';
import { employeeDetailsPageStrings } from '../../utils/strings';
import image from '../../utils/assets/emptySkillsComponent.png';
import AddSkillsModal from '../../components/EmployeeActions/AddSkillsModal';
import networkClient from '../../networks/networkClient';
import { methodTypes, networkEndpoints } from '../../networks/networkStrings';
import CircularProgressIndicator from '../../components/CircularProgressIndicator';
import endpointParamsSetter from '../../utils/endpointParamsSetter';
import ApiErrorComponent from '../../components/ApiErrorComponent';
import { handle403Error } from '../../utils/errorHandler';

function SkillsComponent({ userId, employeeStatus, setIs404Error }) {
  const theme = useTheme();
  const [isSkillsLoading, setSkillsLoading] = useState(true);
  const [isError, setIsError] = useState(true);
  const [skills, setSkills] = useState([]);
  const [openAddSkillsModal, setOpenAddSkillsModal] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  // function for fetching user skills.
  const fetchUserSkills = useCallback(async () => {
    const skillsData = await networkClient({
      method: methodTypes.get,
      endpoint: endpointParamsSetter({
        path: networkEndpoints.user.getUserSkills,
        params: userId,
      }),
    });
    setSkillsLoading(false);
    if (skillsData.error) {
      if (skillsData.errors[0].code === 403) {
        handle403Error(navigate, location);
      } else if (skillsData.errors[0].code === 404) {
        setIs404Error(true);
      }
      setSkills([]);
      setIsError(true);
    } else {
      setSkills(skillsData.data.skills);
      setIsError(false);
    }
  }, [userId, navigate, location, setIs404Error]);

  useEffect(() => {
    fetchUserSkills();
  }, [fetchUserSkills]);
  return (
    <div>
      <Box
        sx={{
          backgroundColor: theme.palette.background.paper,
          height: employeeDetailsPageSize.skillMinHeight,
          display: 'flex',
          flexDirection: 'column',
        }}>
        <Stack
          padding={employeeDetailsPagePaddings.asidePadding}
          flexDirection='row'
          justifyContent='space-between'
          bgcolor={theme.palette.grey[25]}>
          <Stack
            flexDirection='row'
            gap={employeeDetailsPageSize.gapBetweenIconAndTitle}
            alignItems='center'>
            <Flag />
            <Typography variant='h6' color={theme.palette.grey[1000]}>
              {employeeDetailsPageStrings.skillsCardTitle}
            </Typography>
          </Stack>
          <IconButton
            disabled={
              isSkillsLoading ||
              employeeStatus ===
                employeeDetailsPageStrings.employeeStatus.OffBoarded ||
              employeeStatus ===
                employeeDetailsPageStrings.employeeStatus.OffBoarding
            }
            disableRipple
            onClick={() => {
              setOpenAddSkillsModal(true);
            }}
            sx={{
              padding: 0,
              color: theme.palette.secondary.main,
            }}>
            <AddCircle fontSize='large' />
          </IconButton>
        </Stack>

        {!isSkillsLoading && !isError && (
          <Box
            padding={employeeDetailsPagePaddings.asidePadding}
            sx={{
              display: 'block',
              overflowY: 'scroll',
              maxHeight: employeeDetailsPageSize.skillBoxMaxHeight,
              '&::-webkit-scrollbar': {
                width: addSoftwareSize.managersDropdownScrollbarWidth,
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: theme.palette.grey[300],
                borderRadius: addSoftwareSize.managersDropdownScrollbarRadius,
                opacity: 0.7,
              },
            }}>
            {skills.length === 0 && (
              <Stack justifyContent='center'>
                <Box height={employeeDetailsPageSize.verticalCommonSpace} />
                <Box
                  width={employeeDetailsPageSize.skillImagwWidth}
                  component='img'
                  src={image}
                />
                <Box height={employeeDetailsPageSize.spaceBetweenCards} />
                <Typography
                  variant='body1Medium'
                  color={theme.palette.text.primary}>
                  {employeeDetailsPageStrings.noSkillsTitle}
                </Typography>
                <Box height={employeeDetailsPageSize.spaceBetweenIconAndText} />
                <Typography variant='body2' color={theme.palette.grey[300]}>
                  {employeeDetailsPageStrings.noSkillsSubTitle}
                </Typography>
              </Stack>
            )}
            {skills.length > 0 && (
              <Stack
                direction='row'
                flexWrap='wrap'
                gap={employeeDetailsPageSize.gapBetweenSkills}>
                {skills.map((skill) => (
                  <Chip
                    key={skill.id + skill.name}
                    sx={{
                      color: theme.palette.secondary.dark,
                      backgroundColor:
                        theme.palette.secondary.states.outlinedHoverBackground,
                    }}
                    label={<Typography variant='chip'>{skill.name}</Typography>}
                  />
                ))}
              </Stack>
            )}
          </Box>
        )}
        {isSkillsLoading && (
          <Box
            display='flex'
            flex={1}
            alignItems='center'
            justifyContent='center'>
            <CircularProgressIndicator />
          </Box>
        )}
        {!isSkillsLoading && isError && (
          <Box
            display='flex'
            flex={1}
            alignItems='center'
            justifyContent='center'>
            <ApiErrorComponent onRefreshClick={() => fetchUserSkills()} />
          </Box>
        )}
      </Box>

      <AddSkillsModal
        openAddSkillsModal={openAddSkillsModal}
        setOpenAddSkillsModal={setOpenAddSkillsModal}
        defaultData={skills}
        setUserSkills={setSkills}
        userId={userId}
      />
    </div>
  );
}
SkillsComponent.propTypes = {
  userId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  employeeStatus: PropTypes.string.isRequired,
  setIs404Error: PropTypes.func.isRequired,
};
export default SkillsComponent;
