import {
  Alert,
  Box,
  Container,
  Modal,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { customModalPaddings } from '../utils/dimensions/paddings';
import { customModalSize } from '../utils/dimensions/size';
import CustomFilledIconButton from './CustomFilledIconButton';
import CustomOutlinedIconButton from './CustomOutlinedIconButton';
import { customModalString } from '../utils/strings';

export default function CustomModal({
  open,
  onClose,
  title,
  subtitle,
  children,
  failureButtonText,
  onFailureButtonClick,
  successButtonText,
  onSuccessButtonClick,
  successButtonLoading,
  successButtonDisabled,
  error,
  errorMessage,
  setErrorMessage,
  padding,
  minWidth,
  maxWidth,
  maxHeight,
  minHeight,
}) {
  const theme = useTheme();

  useEffect(() => {
    let timeout;
    if (errorMessage) {
      timeout = setTimeout(() => {
        setErrorMessage(null);
      }, 5000);
    }
    return () => clearTimeout(timeout);
  }, [errorMessage, setErrorMessage]);

  return (
    <Modal
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      open={open}
      onClose={onClose}>
      <Box
        sx={{
          display: 'flex',
          backgroundColor: theme.palette.background.paper,
          padding,
          minWidth,
          maxWidth,
          maxHeight,
          minHeight,
        }}>
        <Container disableGutters>
          <Stack>
            <Stack direction='row' sx={{ display: 'flex' }}>
              <Stack justifyContent='center'>
                <Typography variant='h5SemiBold'>{title}</Typography>

                {subtitle !== '' && (
                  <>
                    <Box
                      height={customModalSize.spaceBetweenTitleAndSubTitle}
                    />

                    <Typography>{subtitle}</Typography>
                  </>
                )}
              </Stack>
            </Stack>
            {error && (
              <Box>
                {errorMessage && (
                  <Alert
                    sx={{
                      backgroundColor:
                        theme.palette.error.states.outlinedHoverBackground,
                      border: `1px solid ${theme.palette.error.light}`,
                      color: theme.palette.error.dark,
                      '& .MuiAlert-icon': {
                        color: theme.palette.error.dark,
                      },
                    }}
                    severity='error'>
                    <Typography variant='body2SemiBold'>
                      {errorMessage}
                    </Typography>
                  </Alert>
                )}
              </Box>
            )}
            <Box
              sx={{
                overflow: 'scroll',
                padding: customModalPaddings.childrenPadding,
                maxHeight: customModalSize.childrenMaxHeight,
                // Hide Scrollbar for Edge and IE Browser
                msOverflowStyle: 'none',
                // Hide Scrollbar for Other Browsers
                '&::-webkit-scrollbar': {
                  width: 0,
                },
                '&::-webkit-scrollbar-corner': {
                  background: 'transparent',
                },
              }}>
              {children}
            </Box>
            <Container
              disableGutters
              sx={{
                display: 'flex',
                justifyContent: 'end',
              }}>
              <Stack direction='row'>
                {failureButtonText && (
                  <CustomOutlinedIconButton onClick={onFailureButtonClick}>
                    {failureButtonText}
                  </CustomOutlinedIconButton>
                )}
                {failureButtonText && (
                  <Box width={customModalSize.spaceBetweenBottomButtons} />
                )}
                {successButtonText && (
                  <CustomFilledIconButton
                    disabled={successButtonDisabled}
                    text={successButtonText}
                    onClick={onSuccessButtonClick}
                    isLoading={successButtonLoading}
                  />
                )}
              </Stack>
            </Container>
          </Stack>
        </Container>
      </Box>
    </Modal>
  );
}

CustomModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onSuccessButtonClick: PropTypes.func,
  successButtonText: PropTypes.string,
  failureButtonText: PropTypes.string,
  onFailureButtonClick: PropTypes.func,
  children: PropTypes.element.isRequired,
  subtitle: PropTypes.string,
  title: PropTypes.string,
  onClose: PropTypes.func,
  errorMessage: PropTypes.string,
  error: PropTypes.bool,
  successButtonLoading: PropTypes.bool,
  successButtonDisabled: PropTypes.bool,
  setErrorMessage: PropTypes.func,
  padding: PropTypes.string,
  minWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  maxWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  minHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  maxHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

CustomModal.defaultProps = {
  error: false,
  successButtonLoading: false,
  errorMessage: customModalString.defaultErroreMessage,
  successButtonText: null,
  failureButtonText: null,
  onSuccessButtonClick: null,
  onFailureButtonClick: null,
  successButtonDisabled: false,
  subtitle: null,
  title: null,
  setErrorMessage: () => {},
  padding: customModalPaddings.modalPadding,
  onClose: () => {},
  minWidth: customModalSize.minWidth,
  maxWidth: customModalSize.maxWidth,
  minHeight: customModalSize.minHeight,
  maxHeight: undefined,
};
