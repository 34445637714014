import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { navBarPaddings } from '../../utils/dimensions/paddings';
import { navBarSize } from '../../utils/dimensions/size';
import NavBarAppBar from './NavBarAppBar';
import NavBarProfileSection from './NavBarProfileSection';
import NavBarStack from './NavBarStack';
import NavBarTabsSection from './NavBarTabSection';
import LogoSection from '../LogoSection';
import getLoggedInUser from '../../utils/getLoggedInUser';

function Navbar({ tabValue, setTabValue, tabsRouterPaths }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [user, setUser] = useState(null);

  const trimmedPath = location.pathname.split('/')[1]; // Get the first segment of the path
  useEffect(() => {
    const userDetails = getLoggedInUser(navigate, location);
    setUser(userDetails);
    setTabValue(`/${trimmedPath}`);
  }, [navigate, setUser, location, setTabValue, trimmedPath]);

  if (user) {
    const { name, email, picture } = user;
    return (
      <div>
        {user && (
          <NavBarAppBar elevation={0}>
            <NavBarStack
              justifyContent='start'
              padding={navBarPaddings.navBarPadding}>
              <LogoSection setTabValue={setTabValue} />
              <Box width={navBarSize.spaceBetweenLogoAndTabs} />
              {/* //takes array of paths as input */}
              <NavBarTabsSection
                tabValue={tabValue}
                setTabValue={setTabValue}
                paths={tabsRouterPaths}
              />
              {/* //takes name and imageURL as input */}
              <NavBarProfileSection
                name={name}
                email={email}
                picture={picture}
              />
            </NavBarStack>
          </NavBarAppBar>
        )}
      </div>
    );
  }
}

Navbar.propTypes = {
  tabValue: PropTypes.string.isRequired,
  setTabValue: PropTypes.func.isRequired,
  tabsRouterPaths: PropTypes.PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      component: PropTypes.element.isRequired,
    })
  ).isRequired,
};

export default Navbar;
