const { addEmployeeStrings } = require('../../utils/strings');

const basicDetailsInitialState = {
  company_email: {
    value: '',
    error: false,
    errorMessage: addEmployeeStrings.errorMessages.invalidEmail,
  },
  first_name: {
    value: '',
    error: false,
    errorMessage: addEmployeeStrings.errorMessages.required,
  },
  last_name: {
    value: '',
    error: false,
    errorMessage: addEmployeeStrings.errorMessages.required,
  },
  country_code: {
    value: '',
    error: false,
    errorMessage: addEmployeeStrings.errorMessages.required,
  },
  phone_number: {
    value: '',
    error: false,
    errorMessage: addEmployeeStrings.errorMessages.required,
  },
  personal_email: {
    value: '',
    error: false,
    errorMessage: addEmployeeStrings.errorMessages.required,
  },
  address: {
    value: '',
    error: false,
    errorMessage: addEmployeeStrings.errorMessages.required,
  },
  city: {
    value: '',
    error: false,
    errorMessage: addEmployeeStrings.errorMessages.required,
  },
  state: {
    value: '',
    error: false,
    errorMessage: addEmployeeStrings.errorMessages.required,
  },
  zipcode: {
    value: '',
    error: false,
    errorMessage: addEmployeeStrings.errorMessages.required,
  },
};

const jobDetailsInitialState = {
  location: {
    value: '',
    error: false,
    errorMessage: addEmployeeStrings.errorMessages.required,
  },
  employee_id: {
    value: '',
    error: false,
    errorMessage: addEmployeeStrings.errorMessages.invalidId,
    disabled: false,
  },
  job_title: {
    value: '',
    error: false,
    errorMessage: addEmployeeStrings.errorMessages.required,
  },
  employment_type: {
    value: '',
    error: false,
    errorMessage: addEmployeeStrings.errorMessages.required,
  },
  isAgency: { value: false },
  agency_name: {
    value: '',
    error: false,
    errorMessage: addEmployeeStrings.errorMessages.required,
  },
  agency_email: {
    value: '',
    error: false,
    errorMessage: addEmployeeStrings.errorMessages.required,
  },
  agency_phone_number: {
    value: '',
    error: false,
    errorMessage: addEmployeeStrings.errorMessages.required,
  },
  join_date: {
    value: new Date(),
    error: false,
    errorMessage: addEmployeeStrings.errorMessages.required,
  },
  capacity: {
    value: '',
  },
  description: {
    value: '',
  },
  branch_id: {
    value: 0,
  },
  prefix: {
    value: '',
  },
};

module.exports = { basicDetailsInitialState, jobDetailsInitialState };
