import {
  MaterialDesignContent,
  SnackbarProvider,
  closeSnackbar,
} from 'notistack';
import { IconButton, styled, useTheme } from '@mui/material';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { BrowserRouter } from 'react-router-dom';
import './App.css';
import CloseIcon from '@mui/icons-material/Close';
import Router from './Router/Router';
import { appSize } from './utils/dimensions/size';
import { snackbarStrings } from './utils/strings';
import ErrorBoundary from './Router/ErrorBoundary';
import ErrorMessagePage from './pages/ErrorMessageScreen/ErrorMessagePage';

const handleSnackbarAction = (snackbarId) => (
  <IconButton onClick={() => closeSnackbar(snackbarId)}>
    <CloseIcon />
  </IconButton>
);
function App() {
  const theme = useTheme();

  const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
    '&.notistack-MuiContent-success': {
      backgroundColor: theme.palette.success.lighter,

      color: theme.palette.success.darker,
      svg: {
        color: theme.palette.success.dark,
      },
    },
    '&.notistack-MuiContent-error': {
      backgroundColor: theme.palette.error.lighter,
      color: theme.palette.error.darker,
      svg: {
        color: theme.palette.error.dark,
      },
    },
    '&.notistack-MuiContent-warning': {
      backgroundColor: theme.palette.warning.lighter,
      color: theme.palette.warning.darker,
      svg: {
        color: theme.palette.warning.dark,
      },
    },
  }));
  return (
    <div
      className='App'
      style={{
        background: theme.palette.grey[50],
        minHeight: appSize.minHeight,
      }}>
      <ErrorBoundary fallback={<ErrorMessagePage />}>
        <GoogleOAuthProvider
          clientId={process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID}>
          <SnackbarProvider
            Components={{
              success: StyledMaterialDesignContent,
              error: StyledMaterialDesignContent,
              warning: StyledMaterialDesignContent,
            }}
            maxSnack={4}
            autoHideDuration={3000}
            anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
            variant={snackbarStrings.variant.success}
            action={handleSnackbarAction}>
            <BrowserRouter>
              <Router />
            </BrowserRouter>
          </SnackbarProvider>
        </GoogleOAuthProvider>
      </ErrorBoundary>
    </div>
  );
}

export default App;
