import { tokenStrings } from '../networks/networkStrings';
import { decryptMessage } from './encyption/encryptionMethods';
import { handle403Error } from './errorHandler';

// eslint-disable-next-line
const getLoggedInUser = (navigate, location) => {
  try {
    const userObj = decryptMessage(localStorage.getItem(tokenStrings.user));
    if (!userObj) {
      handle403Error(navigate, location);
    } else return JSON.parse(userObj);
  } catch (err) {
    handle403Error(navigate, location);
  }
};

export default getLoggedInUser;
