//  This file contains constant padding values for Custom Components

const customDrawerPaddings = {
  titleSectionPadding: '32px',
  childrensPadding: '10px 0px',
  buttonSectionPadding: '10px 32px',
};

const customFilterPadding = {
  RightSpaceFromRadioButton: '15px',
  menuListPaddingBottom: '0px',
  leftPaddingForSearchBar: '12px',
  clientNotFoundTopPadding: '10px',
};

const customModalPaddings = {
  modalPadding: '40px',
  childrenPadding: '10px 0px',
};
const addSkillsPaddings = {
  contentPadding: '15px',
  skillsTextPadding: '12px',
  searchIconPadding: '6px 2px',
  searchbarPadding: '5px 0px',
  noSkillsFoundPadding: '20px',
};
const ownerRoleChangePaddings = {
  modalPadding: '30px',
};
const navBarPaddings = {
  navBarPadding: '0px 92px',
  tabsPadding: '0px',
};
const customSearchBarPaddings = {
  padding: '12px 16px',
};

const customTablePaddings = {
  noDataMessagePadding: '20px',
  multipleDataCellPadding: '3px 16px',
  dropdownTableCellPadding: '10px 15px',
  managerMenuPaddings: '16px',
};

const commonPagePaddings = {
  pagePadding: '32px 92px',
  ApprovalPagePading: '32px 40px',
};

const employeeDetailsPagePaddings = {
  pagePadding: '32px 92px',
  customDetailsBoxPadding: '24px',
  asideTitlePadding: '12px 20px',
  asidePadding: '12px 20px 12px 20px',
  historyContentPadding: '25px 20px 0px 20px',
  highlightedComponentPadding: '3px 10px',
  rowDetailsPadding: '10px 0px',
  historyComponentStepperIconPadding: '15px',
  historyComponentStepperIconPaddingTop: '5px',
  historyDetailsComponentPadding: '20px',
  softwareTabRefreshButtonPadding: '10px',
};

const detailsCardPadding = {
  titlePaddingLeft: '17px',
  titlePaddingY: '20px',
  childrenPaddingX: '24px',
  childrenPAddingBottom: '32px',
  editDrawerContentPadding: '20px',
};

const detailsPagePadding = {
  detailsCardPadding: '35px 30px',
};
const employeeSoftwareDetailsPadding = {
  iconPadding: '12px',
};
const addSoftwareDetailsPadding = {
  iconPadding: '12px',
};
const deleteSoftwareDetailsPadding = {
  iconPadding: '12px',
};
const revokeSoftwareDetailsPadding = {
  iconPadding: '12px',
};

const completeAssignmentSoftwareDetailsPadding = {
  iconPadding: '12px',
};
const softwareDetailsPadding = {
  modalPadding: '25px ',
};

const antSwitchPadding = {
  switchPadding: 0,
  switchBasePadding: 2,
};

const approveEmployeePaddings = {
  boxPadding: '20px',
};
const customListExpandPadding = {
  listPadding: '0px',
};
const customListExpandElementPaddings = {
  listItemTextPaddingLeft: '15px',
  listItemTextPaddingRight: '20px',
  collapsePaddingX: '10px',
};
const softwareNotesDrawerPaddings = {
  iconPadding: '8px',
  userOuterPaddingX: '4px',
  userDetailsPaddingX: '25px',
  notesBoxPadding: '24px 16px',
  userDetailsPaddingY: '30px',
};
const profileMenuPaddings = {
  modalPadding: '30px',
};

const filterDropDownPadding = {
  menuListPaddingBottom: 0,
  menuStackLeftPadding: 2,
  menuStackRightPadding: 2,
  collapseListRightPadding: '15px',
  collapseListLeftPadding: 2,
  sliderPaddingLeft: 4,
};

const historyDetailsPaddings = {
  drawerPaddingTop: '92px',
  descriptionPaddingTop: '32px',
};

const offBoardingDaysToGoPadding = {
  editButtonPadding: '2px 7px',
  editButtonBorder: '1px solid',
};

const historyDrawerPaddings = {
  paddingX: ' 20px',
};

const assignSoftwareDrawerPaddings = {
  errorBox: '200px',
};
const CustomFilledIconButtonPadding = {
  boxPadding: '6px 16px',
};

const addEmployeePaddings = {
  branchPadding: '25px',
};
const softwareManagerCellPaddings = {
  manageNumberPaddingY: '5px',
};

export {
  assignSoftwareDrawerPaddings,
  customDrawerPaddings,
  customFilterPadding,
  antSwitchPadding,
  addSoftwareDetailsPadding,
  deleteSoftwareDetailsPadding,
  employeeDetailsPagePaddings,
  customTablePaddings,
  navBarPaddings,
  customSearchBarPaddings,
  customModalPaddings,
  commonPagePaddings,
  detailsPagePadding,
  employeeSoftwareDetailsPadding,
  revokeSoftwareDetailsPadding,
  detailsCardPadding,
  approveEmployeePaddings,
  softwareNotesDrawerPaddings,
  customListExpandPadding,
  customListExpandElementPaddings,
  addSkillsPaddings,
  profileMenuPaddings,
  filterDropDownPadding,
  historyDetailsPaddings,
  offBoardingDaysToGoPadding,
  historyDrawerPaddings,
  softwareDetailsPadding,
  completeAssignmentSoftwareDetailsPadding,
  CustomFilledIconButtonPadding,
  addEmployeePaddings,
  ownerRoleChangePaddings,
  softwareManagerCellPaddings,
};
