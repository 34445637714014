import PropTypes from 'prop-types';
import { Box, Stack, useTheme, Typography } from '@mui/material';
import { useState, useEffect, useCallback, useContext } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import CustomTabs from '../../components/CustomTabs';
import { commonPagePaddings } from '../../utils/dimensions/paddings';
import {
  employeeDetailsPageSize,
  approveEmployeeSize,
} from '../../utils/dimensions/size';
import {
  employeeDetailsPageStrings,
  onOffBoardButtonTextStrings,
  employeeStatusStrings,
  approveEmployeeStrings,
  snackbarStrings,
} from '../../utils/strings';
import CustomEmployeeDetailsCard from './CustomEmployeeDetailsCard';
import HistoryComponent from './HistoryComponent';
import ProfileChecklist from './ProfileChecklist';
import ProfileTab from './ProfileTab';
import SkillsComponent from './SkillsComponent';
import SoftwareTab from './SoftwareTab';
import networkClient from '../../networks/networkClient';
import { methodTypes, networkEndpoints } from '../../networks/networkStrings';
import endpointParamsSetter from '../../utils/endpointParamsSetter';
import { handle403Error } from '../../utils/errorHandler';
import ApiErrorComponent from '../../components/ApiErrorComponent';
import OffBoardEmployeeDrawer from '../../components/EmployeeSoftwareActions/OffBoardEmployeeDrawer';
import EmployeeOffBoardingPopup from '../../components/EmployeeOffBoardingPopup/EmployeeOffBoardingPopup';
import EmployeeOffBoardingDaysToGo from '../../components/EmployeeOffBoardingPopup/EmployeeOffBoardingDaysToGo';

import CustomModal from '../../components/CustomModal';
import { ReactComponent as SuccessSVG } from '../../utils/assets/ok.svg';
import { snackbarContext } from '../../utils/context';
import makeFirstLetterCapital from '../../utils/makeFirstLetterCapital';
import getLoggedInUser from '../../utils/getLoggedInUser';
import ErrorMessagePage from '../ErrorMessageScreen/ErrorMessagePage';
// below code is commented for hiding hardware and projects.
// import HardwareTab from './HardwareTab';
// import ProjectsTab from './ProjectsTab';
function TabPanel({ children, value, index }) {
  return (
    <div
      style={{
        maxHeight: employeeDetailsPageSize.tabMaxHeight,
        height: employeeDetailsPageSize.tabMaxHeight,
      }}
      hidden={value !== index}>
      {value === index && <Box height='100%'>{children}</Box>}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.element.isRequired,
  value: PropTypes.string.isRequired,
  index: PropTypes.string.isRequired,
};

function EmployeeDetailsPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const { showSnackbar } = useContext(snackbarContext);
  const params = useParams();
  const { employeeId } = params;

  const loggedInUser = getLoggedInUser(navigate, location);

  const theme = useTheme();
  const [employeeDetails, setEmployeeDetails] = useState({});
  const [isLoading, setisLoading] = useState(true);
  const [isError, setisError] = useState(false);
  const [openSuccesModal, setOpenSuccesModal] = useState(false);
  const [toggleDrawer, setToggleDrawer] = useState(false);
  const [openDiscontinuePopup, setOpenDiscontinuePopup] = useState(false);
  const [approveButtonLoading, setApproveButtonLoading] = useState(false);
  const [openApproveModal, setOpenApproveModal] = useState(false);
  const historyDataInitialState = null;
  const [historyData, setHistoryData] = useState(historyDataInitialState);
  const [isHistoryLoading, setIsHistoryLoading] = useState(true);
  const [isHistoryError, setIsHistoryError] = useState(false);
  const [isChecklistChecked, setIsChecklistChecked] = useState(null);
  const [isChecklistError, setIsChecklistError] = useState(false);
  const [isChecklistLoading, setIsChecklistLoading] = useState(true);
  const [checkListItems, setCheckListItems] = useState([]);
  const [value, setValue] = useState(
    Object.values(employeeDetailsPageStrings.pageTabs).includes(
      location.hash.slice(1)
    )
      ? location.hash.slice(1)
      : employeeDetailsPageStrings.pageTabs.profile
  );
  const [is404Error, setIs404Error] = useState(false);

  const approveEmployeeButtonClick = async () => {
    setApproveButtonLoading(true);
    const response = await networkClient({
      method: methodTypes.patch,
      endpoint: endpointParamsSetter({
        path: networkEndpoints.user.approve,
        params: employeeId,
      }),
    });
    if (response.error) {
      if (response.errors[0].code === 403) {
        handle403Error(navigate, location);
      }
      showSnackbar(
        approveEmployeeStrings.errorMessage,
        snackbarStrings.variant.error
      );
      setApproveButtonLoading(false);
    } else {
      setOpenApproveModal(true);
      setApproveButtonLoading(false);
    }
    const userSoftwareDataResponse = await networkClient({
      method: methodTypes.get,
      endpoint: endpointParamsSetter({
        path: networkEndpoints.user.userSoftwareDetails,
        params: employeeId,
      }),
    });
    const selectedSoftwares = userSoftwareDataResponse.data.map(
      (software) => software.id
    );
    if (selectedSoftwares.length) {
      await networkClient({
        method: methodTypes.post,
        endpoint: networkEndpoints.userSoftware.assignEmail,
        requestBody: { userSoftwareIds: selectedSoftwares },
      });
    }
  };

  const setonOffBoardButton = (status) => {
    switch (status) {
      case employeeStatusStrings.pending:
        return {
          text: onOffBoardButtonTextStrings.approve,
          onClick: approveEmployeeButtonClick,
        };

      case employeeStatusStrings.active:
        return {
          text: onOffBoardButtonTextStrings.offBoard,
          onClick: () => {
            setOpenDiscontinuePopup(true);
          },
        };
      case employeeStatusStrings.OffBoarding:
        return {
          text: onOffBoardButtonTextStrings.completeOffBoarding,
          onClick: () => {
            setToggleDrawer(true);
          },
        };
      default:
        return null;
    }
  };

  const handleChange = (event, newValue) => {
    window.history.replaceState('', '', `${location.pathname}#${newValue}`);
    setValue(newValue);
  };

  const handleModalSuccessButtonClick = () => {
    setOpenSuccesModal(false);
    setEmployeeDetails({
      ...employeeDetails,
      status: employeeStatusStrings.OffBoarded,
    });
    historyData.unshift({
      description: `Off-boarding completed for ${employeeDetails.first_name} ${employeeDetails.last_name} by ${loggedInUser.name}`,
      timestamp: Date.now(),
    });
    setHistoryData([...historyData]);
  };
  const handleApproveModalSuccessButtonClick = () => {
    setOpenApproveModal(false);

    setEmployeeDetails({
      ...employeeDetails,
      status: employeeStatusStrings.active,
    });
    historyData.unshift({
      description: `${loggedInUser.name} approved ${employeeDetails.first_name} ${employeeDetails.last_name}'s profile`,
      timestamp: Date.now(),
    });
    setHistoryData([...historyData]);
  };

  const updateUserStatus = async ({ endDate, status }) => {
    let requestBody = { status };
    if (endDate !== null) {
      requestBody = { ...requestBody, end_date: endDate };
    } else {
      requestBody = { ...requestBody, end_date: null }; // set empty end_date
    }

    const response = await networkClient({
      method: methodTypes.patch,
      endpoint: endpointParamsSetter({
        path: networkEndpoints.user.users,
        params: employeeId,
      }),
      requestBody,
    });
    if (!response.error) {
      showSnackbar(
        employeeDetailsPageStrings.responseMessage.userUpdateSuccess
      );
      if (
        makeFirstLetterCapital(status) === employeeStatusStrings.OffBoarding &&
        employeeDetails.status === employeeStatusStrings.active
      ) {
        setIsChecklistChecked(null);
      }
    }
    if (response.error) {
      if (response.errors[0].code === 403) {
        handle403Error(navigate, location);
      }
    }

    return response;
  };

  const fetchData = useCallback(async () => {
    setisLoading(true);
    // get employee details
    const response = await networkClient({
      method: methodTypes.get,
      endpoint: endpointParamsSetter({
        path: networkEndpoints.user.getUserById,
        params: employeeId,
      }),
    });

    setisLoading(false);
    if (!response.error) {
      setisError(false);

      // make first letter capital and for offboard make O and F capital
      response.data.status = makeFirstLetterCapital(response.data.status);
      // set employee details
      setEmployeeDetails(response.data);
    } else {
      setisError(true);
      if (response.errors[0].code === 403) {
        handle403Error(navigate, location);
      } else if (
        response.errors[0].code === 404 ||
        response.errors[0].code === 400
      ) {
        setIs404Error(true);
      }
    }
  }, [employeeId, navigate, location]);

  const fetchHistory = useCallback(
    async (limit) => {
      const data = await networkClient({
        method: methodTypes.get,
        endpoint: endpointParamsSetter({
          path: networkEndpoints.user.getUserHistory,
          params: employeeId,
        }),
        queryParams: {
          limit,
        },
      });
      setIsHistoryLoading(false);

      if (data.error) {
        setIsHistoryError(true);
        if (data.errors[0].code === 403) {
          handle403Error(navigate, location);
        } else if (data.errors[0].code === 404) {
          setIs404Error(true);
        }
      }
      // sorting data based on timestamp latest first
      else {
        setIsHistoryError(false);
        setHistoryData(
          data?.data?.sort(
            (a, b) => new Date(b.timestamp) - new Date(a.timestamp)
          )
        );
      }
    },
    [employeeId, navigate, location]
  );

  const fetchCheckListValues = useCallback(async () => {
    setIsChecklistLoading(true);
    setIsChecklistError(false);
    const checklistData = await networkClient({
      method: methodTypes.get,
      endpoint: endpointParamsSetter({
        path: networkEndpoints.user.checklist,
        params: employeeId,
      }),
    });
    if (checklistData.error) {
      if (checklistData.errors[0].code === 403) {
        handle403Error(navigate, location);
      } else if (checklistData.errors[0].code === 404) {
        setIs404Error(true);
      }
      setIsChecklistChecked(null);
      setIsChecklistLoading(false);
      setIsChecklistError(true);
    } else {
      setCheckListItems(
        checklistData.data.CheckLists.map((checkListItem) => checkListItem)
      );
      setIsChecklistChecked(
        checklistData.data.CheckLists.reduce(
          (acc, curr) => ({
            ...acc,
            [curr.id]: curr.UserCheckLists.checklist_value,
          }),
          {}
        )
      );
      setIsChecklistLoading(false);
      setIsChecklistError(false);
    }
  }, [employeeId, navigate, location]);

  useEffect(() => {
    if (isChecklistChecked === null) fetchCheckListValues();
  }, [fetchCheckListValues, isChecklistChecked]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    fetchHistory(4);
  }, [fetchHistory]);

  if (!is404Error) {
    return (
      <div>
        {!isLoading && (
          <EmployeeOffBoardingDaysToGo
            setOpenDiscontinuePopup={setOpenDiscontinuePopup}
            lastDate={employeeDetails.end_date}
            status={employeeDetails.status}
          />
        )}
        <Box
          padding={commonPagePaddings.pagePadding}
          height={employeeDetailsPageSize.detailsHeight}
          overflow='scroll'>
          <Stack flexDirection='row' display='flex' height='100%'>
            <Box flex='4'>
              <Stack display='flex' height='100%'>
                {isError ? (
                  <Box bgcolor={theme.palette.background.paper} flex={1}>
                    <Box
                      display='flex'
                      flex={1}
                      alignItems='center'
                      justifyContent='center'
                      height={215}>
                      <ApiErrorComponent onRefreshClick={() => fetchData()} />
                    </Box>
                  </Box>
                ) : (
                  <CustomEmployeeDetailsCard
                    userImageUrl={employeeDetails.userImageUrl}
                    firstname={employeeDetails.first_name}
                    lastname={employeeDetails.last_name}
                    jobTitle={employeeDetails.job_title}
                    email={employeeDetails?.company_email}
                    location={employeeDetails.Branch?.name}
                    capacity={employeeDetails.capacity}
                    status={employeeDetails.status}
                    id={employeeDetails.id}
                    roleId={employeeDetails.role_id}
                    onOffBoardButton={setonOffBoardButton(
                      employeeDetails.status
                    )}
                    onButtonLoading={approveButtonLoading}
                    isLoading={isLoading}
                    loggedInUser={loggedInUser}
                  />
                )}
                <Box height={employeeDetailsPageSize.spaceBetweenCards} />
                <Box flex={1} maxHeight={employeeDetailsPageSize.tabBoxHeight}>
                  <Box bgcolor={theme.palette.grey[25]}>
                    <CustomTabs
                      tabValue={value}
                      handleTabChange={handleChange}
                      tabs={Object.values(employeeDetailsPageStrings.pageTabs)}
                    />
                  </Box>

                  <TabPanel
                    value={value}
                    index={employeeDetailsPageStrings.pageTabs.profile}>
                    {isError ? (
                      <Box
                        display='flex'
                        flex={1}
                        alignItems='center'
                        justifyContent='center'
                        height={employeeDetailsPageSize.tabErrorSize}>
                        <ApiErrorComponent onRefreshClick={() => fetchData()} />
                      </Box>
                    ) : (
                      <Box sx={{ backgroundColor: theme.palette.common.white }}>
                        <ProfileTab
                          employeeDetails={employeeDetails}
                          isLoading={isLoading}
                          setEmployeeDetails={setEmployeeDetails}
                          setHistoryData={setHistoryData}
                        />
                      </Box>
                    )}
                  </TabPanel>

                  {/* 
                //below code is commented for hiding hardware.

                <TabPanel
                  value={value}
                  index={employeeDetailsPageStrings.pageTabs.hardware}>
                  <HardwareTab />
                </TabPanel> */}
                  <TabPanel
                    value={value}
                    index={employeeDetailsPageStrings.pageTabs.software}>
                    <SoftwareTab
                      employeeId={employeeId}
                      navigate={navigate}
                      employeeStatus={employeeDetails.status || ''}
                      employeeDetails={employeeDetails}
                      setHistoryData={setHistoryData}
                      isLoading={isHistoryLoading}
                      isError={isHistoryError}
                      setIs404Error={setIs404Error}
                      // fetchHistory={fetchHistory}
                    />
                  </TabPanel>
                  {/* 
                // below code is commented for hiding projects.

                <TabPanel
                  value={value}
                  index={employeeDetailsPageStrings.pageTabs.projects}>
                  <ProjectsTab />
                </TabPanel> */}
                </Box>
              </Stack>
            </Box>
            <Box width={employeeDetailsPageSize.spaceBetweenCards} />
            <Box flex='1' display='flex'>
              <Stack flex='1' display='flex'>
                <ProfileChecklist
                  key='ProfileChecklist'
                  userId={employeeId}
                  isChecklistChecked={isChecklistChecked}
                  setIsChecklistChecked={setIsChecklistChecked}
                  isChecklistLoading={isChecklistLoading}
                  isChecklistError={isChecklistError}
                  checkListItems={checkListItems}
                  fetchCheckListValues={fetchCheckListValues}
                />

                <Box height={employeeDetailsPageSize.spaceBetweenCards} />
                <SkillsComponent
                  userId={employeeId}
                  employeeStatus={employeeDetails.status || ''}
                  setIs404Error={setIs404Error}
                />
                <Box height={employeeDetailsPageSize.spaceBetweenCards} />
                <HistoryComponent
                  historyData={historyData}
                  fetchHistory={fetchHistory}
                  isError={isHistoryError}
                  isLoading={isHistoryLoading}
                />
              </Stack>
            </Box>
          </Stack>
        </Box>
        {employeeDetails.status === employeeStatusStrings.OffBoarding && (
          <OffBoardEmployeeDrawer
            toggleDrawer={toggleDrawer}
            setToggleDrawer={setToggleDrawer}
            updateUserStatus={updateUserStatus}
            userId={employeeId}
            setOpenSuccesModal={setOpenSuccesModal}
            navigate={navigate}
            location={location}
            historyData={historyData}
            setHistoryData={setHistoryData}
          />
        )}

        <EmployeeOffBoardingPopup
          openDiscontinuePopup={openDiscontinuePopup}
          setOpenDiscontinuePopup={setOpenDiscontinuePopup}
          lastDate={employeeDetails.end_date}
          setEmployeeDetails={setEmployeeDetails}
          employeeDetails={employeeDetails}
          updateUserStatus={updateUserStatus}
          setHistoryData={setHistoryData}
        />
        <CustomModal
          open={openSuccesModal}
          successButtonText={approveEmployeeStrings.openModal.buttonText}
          onSuccessButtonClick={handleModalSuccessButtonClick}>
          <Stack justifyContent='center' alignItems='center'>
            <SuccessSVG />
            <Box height={approveEmployeeSize.spaceBelowSVG} />
            <Typography variant='h5SemiBold'>
              {approveEmployeeStrings.openModal.offboardSuccessMessage}
            </Typography>
          </Stack>
        </CustomModal>
        <CustomModal
          open={openApproveModal}
          successButtonText={approveEmployeeStrings.openModal.buttonText}
          onSuccessButtonClick={handleApproveModalSuccessButtonClick}>
          <Stack justifyContent='center' alignItems='center'>
            <SuccessSVG />
            <Box height={approveEmployeeSize.spaceBelowSVG} />
            <Typography variant='h5'>
              {approveEmployeeStrings.openModal.successMessage}
            </Typography>
          </Stack>
        </CustomModal>
      </div>
    );
  }

  if (is404Error) {
    return (
      <ErrorMessagePage
        title={employeeDetailsPageStrings.error404Text.title}
        subtitle={employeeDetailsPageStrings.error404Text.subtitle}
      />
    );
  }
}

export default EmployeeDetailsPage;
