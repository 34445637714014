import { List, useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import { customListExpandPadding } from '../utils/dimensions/paddings';
import { customListExpandSize } from '../utils/dimensions/size';
import CustomListExpandElement from './CustomListExpandElement';

export default function CustomListExpand({
  tableData,
  setIsError,
  setTableData,
  setHistoryData,
}) {
  const theme = useTheme();
  return (
    <List
      sx={{
        width: customListExpandSize.listWidth,
        padding: customListExpandPadding.listPadding,
        bgcolor: theme.palette.common.white,
      }}>
      {tableData.map((row, index) => (
        <CustomListExpandElement
          key={row.id}
          index={index}
          row={row}
          tableData={tableData}
          setTableData={setTableData}
          setIsError={setIsError}
          setHistoryData={setHistoryData}
        />
      ))}
    </List>
  );
}

CustomListExpand.propTypes = {
  tableData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setIsError: PropTypes.func.isRequired,
  setTableData: PropTypes.func.isRequired,
  setHistoryData: PropTypes.func.isRequired,
};
