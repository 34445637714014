import {
  TableBody,
  TableCell,
  TableRow,
  Typography,
  Container,
} from '@mui/material';
import PropTypes from 'prop-types';
import { customTableSize } from '../../utils/dimensions/size';
import { customTablePaddings } from '../../utils/dimensions/paddings';
import { customTableStrings } from '../../utils/strings';
import CircularProgressIndicator from '../CircularProgressIndicator';
import ApiErrorComponent from '../ApiErrorComponent';

export default function CustomTableErrorMessage({
  message,
  isLoading,
  isError,
  onTryAgainClick,
}) {
  return (
    <TableBody
      sx={{
        display: 'flex',
        overflowY: 'scroll',
        maxHeight: customTableSize.bodyMaxHeight,
      }}>
      <TableRow
        sx={{
          display: 'flex',
          flex: 1,
          justifyContent: 'center',
        }}>
        <TableCell sx={{ borderBottom: 'none' }}>
          <Container
            sx={{
              height: customTableSize.noDataHeight,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: customTablePaddings.noDataMessagePadding,
            }}>
            {isLoading && <CircularProgressIndicator />}
            {!isLoading && isError && (
              <ApiErrorComponent onRefreshClick={onTryAgainClick} />
            )}
            {!isLoading && !isError && (
              <Typography
                variant='h6'
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}>
                {message}
              </Typography>
            )}
          </Container>
        </TableCell>
      </TableRow>
    </TableBody>
  );
}

CustomTableErrorMessage.propTypes = {
  message: PropTypes.string,
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
  onTryAgainClick: PropTypes.func,
};

CustomTableErrorMessage.defaultProps = {
  message: customTableStrings.noDataMessage,
  isLoading: false,
  isError: false,
  onTryAgainClick: () => {},
};
