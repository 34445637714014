import { HistoryToggleOff } from '@mui/icons-material';
import { Box, Container, Stack, Typography, useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import CustomTextButton from '../../components/CustomTextButton';
import {
  employeeDetailsPageSize,
  historyComponentSize,
} from '../../utils/dimensions/size';
import {
  employeeDetailsPageStrings,
  historyComponentStrings,
} from '../../utils/strings';
import HistoryDetailsComponent from './HistoryDetailsComponent';
import { employeeDetailsPagePaddings } from '../../utils/dimensions/paddings';
import EmployeeHistoryDrawer from '../../components/EmployeeProfileActions/EmployeeHistoryDrawer';
import CircularProgressIndicator from '../../components/CircularProgressIndicator';
import ApiErrorComponent from '../../components/ApiErrorComponent';

function HistoryComponent({ fetchHistory, historyData, isError, isLoading }) {
  const theme = useTheme();
  const [historyToggleDrawer, setHistoryToggleDrawer] = useState(false);
  const [isHistoryDataFetched, setHistoryFetched] = useState(false);

  useEffect(() => {
    if (historyToggleDrawer && !isHistoryDataFetched) {
      setHistoryFetched(true);
      fetchHistory();
    }
  }, [fetchHistory, historyToggleDrawer, isHistoryDataFetched]);
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: theme.palette.background.paper,
        minHeight: employeeDetailsPageSize.historyMinHeight,
      }}>
      <Stack
        padding={employeeDetailsPagePaddings.asideTitlePadding}
        flexDirection='row'
        justifyContent='space-between'
        bgcolor={theme.palette.grey[25]}>
        <Stack
          flexDirection='row'
          gap={historyComponentSize.titleObjectsGap}
          alignItems='center'>
          <HistoryToggleOff />
          <Typography variant='h6' color={theme.palette.grey[1000]}>
            {employeeDetailsPageStrings.historyCardTitle}
          </Typography>
        </Stack>
        <CustomTextButton
          onClick={() => {
            setHistoryToggleDrawer(true);
          }}>
          {employeeDetailsPageStrings.historyCardSeeAllButtonText}
        </CustomTextButton>
      </Stack>
      <Box display='flex' overflow='hidden' flex={1}>
        {isLoading && (
          <Container
            sx={{
              minHeight: employeeDetailsPageSize.historyMinHeight,
              display: 'flex',
              flex: 1,
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            <CircularProgressIndicator />
          </Container>
        )}
        {isError && !isLoading && (
          <Box
            minHeight={employeeDetailsPageSize.historyMinHeight}
            display='flex'
            flex={1}
            alignItems='center'
            justifyContent='center'>
            <ApiErrorComponent onRefreshClick={() => fetchHistory()} />
          </Box>
        )}
        {!isError &&
          !isLoading &&
          (historyData === null || historyData?.length === 0 ? (
            <Typography
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flex: 1,
              }}>
              No History
            </Typography>
          ) : (
            <HistoryDetailsComponent
              dataLength={historyData.length - 1}
              data={historyData.slice(0, 3)}
            />
          ))}
      </Box>
      {historyData && (
        <EmployeeHistoryDrawer
          data={historyData}
          toggleDrawer={historyToggleDrawer}
          setToggleDrawer={setHistoryToggleDrawer}
          title={historyComponentStrings.drawerTitle}
          refetchFunction={fetchHistory}
          isError={isError}
        />
      )}
    </Box>
  );
}

HistoryComponent.propTypes = {
  historyData: PropTypes.arrayOf(PropTypes.shape({})),
  fetchHistory: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
};
HistoryComponent.defaultProps = {
  isLoading: false,
  historyData: null,
  isError: false,
};

export default HistoryComponent;
