const networkEndpoints = {
  authentication: {
    refreshToken: '/refresh-token',
  },
  user: {
    getUserById: '/users/{userId}',
    getAllSkills: 'skills',
    postSkills: 'users/{userID}/skills',
    getUserSkills: 'users/{userID}/skills',
    deleteUserSkills: 'users/{userID}/skills',
    user: '/users/',
    users: '/users/{userId}',
    userSoftwareDetails: '/users/{userId}/softwares',
    checklist: '/users/{userId}/checklist',
    requestsoftware: '/softwares',
    approve: '/users/{userId}/approve',
    login: '/login',
    getUsers: '/users',
    getUserSoftwareById: '/users/{userId}/softwares',
    getUserHistory: 'users/{userID}/history',
    logout: '/logout',
  },
  software: {
    getSoftwares: '/softwares',
    getActiveSoftwares: '/softwares?status=active',
    updateSoftware: '/softwares/{softwareId}',
    addSoftware: '/softwares',
    editSoftware: '/softwares/{softwareId}',
    getSoftwareDetail: '/softwares/{softwareId}',
  },
  userSoftware: {
    userSoftwareById: '/users/software/{userSoftwareId}',
    updateUserSoftware: '/users/software/',
    usersFormSoftwareId: '/softwares/{softwareId}/users',
    assignEmail: '/users/software/assign-email',
    revokeEmail: '/users/software/revoke-email',
    sendMultipleAssignEmail: '/users/software/multiple-assign-email',
    sendMultipleRevokeEmail: '/users/software/multiple-revoke-email',
    deleteSoftware: '/users-softwares',
  },
  clients: {
    getAllClients: '/clients',
  },
  branches: {
    getAllBranches: '/branches',
  },
};

const tokenStrings = {
  accessToken: 'accessToken',
  refreshToken: 'refreshToken',
  user: 'user',
  expiresAt: 'expiresAt',
};

const headersStrings = {
  bearer: 'Bearer',
  applicationJSON: 'application/json',
  contentType: 'Content-Type',
};

const methodTypes = {
  get: 'get',
  post: 'post',
  patch: 'patch',
  delete: 'delete',
};

const errorCodes = {
  badRequestError: 'ERR_BAD_REQUEST',
  badResponseError: 'ERR_BAD_RESPONSE',
};

export {
  networkEndpoints,
  tokenStrings,
  headersStrings,
  methodTypes,
  errorCodes,
};
