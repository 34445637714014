import axios from 'axios';
import { headersStrings, tokenStrings } from './networkStrings';

const baseURL = `${process.env.REACT_APP_BASE_URL}/v1`;

const axiosInstance = () => {
  const axiosPrivate = axios.create({
    baseURL,
  });
  axiosPrivate.interceptors.request.use(
    (config) => {
      const accessToken = localStorage.getItem(tokenStrings.accessToken);
      if (!config.headers.Authorization) {
        // eslint-disable-next-line
        config.headers.Authorization = `${headersStrings.bearer} ${accessToken}`;
      }
      if (!config.headers[headersStrings.contentType]) {
        // eslint-disable-next-line
        config.headers[headersStrings.contentType] =
          headersStrings.applicationJSON;
      }
      return config;
    },
    (error) => {
      Promise.reject(error);
    }
  );

  return axiosPrivate;
};

export default axiosInstance;
