// headCells has data for Table Head data.
const headCells = [
  {
    id: 'id',
    dataType: 'skip',
  },

  {
    id: 'image',
    dataType: 'image',
    label: '',
    flex: 1,
    sorting: false,
  },
  {
    id: 'name',
    dataType: 'name',
    label: 'Name',
    flex: 4,
    sorting: true,
  },
  {
    id: 'managers',
    dataType: 'managers',
    label: 'Manager',
    flex: 6,
    sorting: true,
  },

  {
    id: 'client',
    dataType: 'text',
    label: 'Clients',
    flex: 4,
    sorting: true,
  },
  {
    id: 'clientId',
    dataType: 'skip',
  },
  {
    id: 'allocation',
    dataType: 'allocation',
    label: 'Allocation',
    flex: 4,
    sorting: true,
  },
  {
    id: 'status',
    dataType: 'status',
    label: 'Status',
    flex: 4,
    sorting: true,
  },
];

export default headCells;
