import PropTypes from 'prop-types';
import { Box, Stack, Typography, useTheme } from '@mui/material';
import { detailsPageSize } from '../../utils/dimensions/size';
import { hardwareDetailsPageStrings } from '../../utils/strings';
import ShowDetailsColumn from '../../components/ShowDetailsColumn';

function HardwareDetailContent({ hardwareDetails }) {
  const theme = useTheme();
  return (
    <div>
      <Stack>
        <Box height={detailsPageSize.spaceBetweenDetailsContent} />
        <ShowDetailsColumn
          label={hardwareDetailsPageStrings.detailsCard.categoryLabel}>
          <Typography varient='body2' color={theme.palette.grey[1100]}>
            {hardwareDetails.category}
          </Typography>
        </ShowDetailsColumn>
        <Box height={detailsPageSize.spaceBetweenDetailsContent} />
        <ShowDetailsColumn
          label={hardwareDetailsPageStrings.detailsCard.serialKeyLabel}>
          <Typography varient='body2' color={theme.palette.grey[1100]}>
            {hardwareDetails.serialKey}
          </Typography>
        </ShowDetailsColumn>
        <Box height={detailsPageSize.spaceBetweenDetailsContent} />
        <ShowDetailsColumn
          label={hardwareDetailsPageStrings.detailsCard.modelNoLabel}>
          <Typography varient='body2' color={theme.palette.grey[1100]}>
            {hardwareDetails.modelNo}
          </Typography>
        </ShowDetailsColumn>
        <Box height={detailsPageSize.spaceBetweenDetailsContent} />
        <ShowDetailsColumn
          label={hardwareDetailsPageStrings.detailsCard.dateOfPurchaseLabel}>
          <Typography varient='body2' color={theme.palette.grey[1100]}>
            {hardwareDetails.dateOfPurchase}
          </Typography>
        </ShowDetailsColumn>
        <Box height={detailsPageSize.spaceBetweenDetailsContent} />
        <ShowDetailsColumn
          label={hardwareDetailsPageStrings.detailsCard.locationLabel}>
          <Typography varient='body2' color={theme.palette.grey[1100]}>
            {hardwareDetails.location}
          </Typography>
        </ShowDetailsColumn>
        <Box height={detailsPageSize.spaceBetweenDetailsContent} />
        <ShowDetailsColumn
          label={hardwareDetailsPageStrings.detailsCard.venderNameLabel}>
          <Typography varient='body2' color={theme.palette.grey[1100]}>
            {hardwareDetails.vendorName}
          </Typography>
        </ShowDetailsColumn>
        <Box height={detailsPageSize.spaceBetweenDetailsContent} />
        <ShowDetailsColumn
          label={hardwareDetailsPageStrings.detailsCard.descriptionLabel}>
          <Typography varient='body2' color={theme.palette.grey[1100]}>
            {hardwareDetails.description}
          </Typography>
        </ShowDetailsColumn>
        <Box height={detailsPageSize.spaceBetweenDetailsContent} />
      </Stack>
    </div>
  );
}

HardwareDetailContent.propTypes = {
  hardwareDetails: PropTypes.shape({
    category: PropTypes.string.isRequired,
    serialKey: PropTypes.string.isRequired,
    modelNo: PropTypes.string.isRequired,
    dateOfPurchase: PropTypes.string.isRequired,
    location: PropTypes.string.isRequired,
    vendorName: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
  }).isRequired,
};
export default HardwareDetailContent;
