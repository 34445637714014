import { Box, Divider, Stack, Typography, useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import { employeeDetailsPagePaddings } from '../utils/dimensions/paddings';
import { employeeDetailsPageSize } from '../utils/dimensions/size';

function CustomDetailsBox({ title, titleTailElement, children }) {
  const theme = useTheme();
  return (
    <Box
      sx={{
        backgroundColor: theme.palette.background.paper,
        padding: employeeDetailsPagePaddings.customDetailsBoxPadding,
      }}>
      <Stack flexDirection='row' justifyContent='space-between'>
        <Typography variant='h6'>{title}</Typography>
        <Box>{titleTailElement}</Box>
      </Stack>
      <Box height={employeeDetailsPageSize.horizontalCommonSpace} />
      <Divider />
      <Box>{children}</Box>
    </Box>
  );
}

CustomDetailsBox.propTypes = {
  title: PropTypes.string.isRequired,
  titleTailElement: PropTypes.element,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
  ]).isRequired,
};

CustomDetailsBox.defaultProps = {
  titleTailElement: null,
};

export default CustomDetailsBox;
