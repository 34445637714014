// headCells has data for Table Head data.
const headCells = [
  {
    id: 'userId',
    dataType: 'skip',
  },
  {
    id: 'employeeName',
    dataType: 'employeeName',
    label: 'Name',
    flex: 2,
    sorting: true,
  },
  {
    id: 'id',
    dataType: 'employeeId',
    label: 'Employee Id',
    flex: 1,
    sorting: true,
  },
  {
    id: 'status',
    dataType: 'status',
    label: 'Status',
    flex: 1,
    sorting: true,
  },
  {
    id: 'role',
    dataType: 'dropdown',
    label: 'Role',
    flex: 1,
    sorting: true,
  },
];

export default headCells;
