import PropTypes from 'prop-types';
import { Circle } from '@mui/icons-material';
import {
  Avatar,
  Box,
  Chip,
  Divider,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import CustomFilledIconButton from '../../components/CustomFilledIconButton';
import { employeeDetailsPagePaddings } from '../../utils/dimensions/paddings';
import { employeeDetailsPageSize } from '../../utils/dimensions/size';
import {
  employeeDetailsPageStrings,
  employeeStatusStrings,
  onOffBoardButtonTextStrings,
  tableCellStrings,
  userRoles,
} from '../../utils/strings';
import ShowDetailsColumn from '../../components/ShowDetailsColumn';
import { getStatusColor } from '../../components/Table/CustomTableCell';
import { employeeDetailsPageMargins } from '../../utils/dimensions/margins';
import CircularProgressIndicator from '../../components/CircularProgressIndicator';

import randomColor from '../../utils/randomColorGenerator';

export default function CustomEmployeeDetailsCard({
  userImageUrl,
  firstname,
  lastname,
  jobTitle,
  email,
  location,
  capacity,
  status,
  onOffBoardButton,
  isLoading,
  loggedInUser,
  onButtonLoading,
  roleId,
}) {
  const theme = useTheme();
  if (isLoading) {
    return (
      <Box bgcolor={theme.palette.background.paper} flex='inherit'>
        <Box
          display='flex'
          flex={1}
          alignItems='center'
          justifyContent='center'
          height={215}>
          <CircularProgressIndicator />
        </Box>
      </Box>
    );
  }
  return (
    <div>
      <Box
        sx={{
          backgroundColor: theme.palette.primary.contrastText,
          padding: employeeDetailsPagePaddings.customDetailsBoxPadding,
        }}>
        <Stack
          flexDirection='row'
          justifyContent='space-between'
          alignItems='flex-start'>
          <Stack>
            <Stack direction='row'>
              <Avatar
                src={userImageUrl}
                sx={{
                  width: employeeDetailsPageSize.profileAvatarSize,
                  height: employeeDetailsPageSize.profileAvatarSize,
                  bgcolor: randomColor(`${firstname} ${lastname}`),
                }}>
                <Typography variant='h5'>
                  {`${firstname.slice(0, 1)}${lastname.slice(
                    0,
                    1
                  )}`.toUpperCase()}
                </Typography>
              </Avatar>
              <Box width={employeeDetailsPageSize.horizontalCommonSpace} />
              <Stack>
                <Typography variant='h6' color={theme.palette.grey[1100]}>
                  {`${firstname} ${lastname}`}
                </Typography>
                <Stack direction='row'>
                  <Typography
                    variant='body2'
                    color={theme.palette.secondary.main}
                    display='flex'
                    alignItems='center'>
                    {jobTitle}
                  </Typography>

                  {email && (
                    <Circle
                      sx={{
                        width: employeeDetailsPageSize.bulletSize,
                        marginX: employeeDetailsPageMargins.bulletsMarginX,
                        color: theme.palette.secondary.main,
                      }}
                    />
                  )}
                  {email && (
                    <Typography
                      variant='body2'
                      color={theme.palette.secondary.dark}
                      display='flex'
                      alignItems='center'>
                      {email}
                    </Typography>
                  )}
                </Stack>
              </Stack>
            </Stack>

            <Stack>
              <Box width={employeeDetailsPageSize.horizontalCommonSpace} />
            </Stack>
            <Box height={employeeDetailsPageSize.verticalCommonSpace} />

            <Stack flexDirection='row' alignItems='center'>
              <Box width={employeeDetailsPageSize.spaceBetweenIconAndText} />
            </Stack>
          </Stack>
          {status !== employeeStatusStrings.OffBoarded &&
          roleId !== userRoles.ownerRole &&
          loggedInUser.email !== email ? (
            <CustomFilledIconButton
              isLoading={onButtonLoading}
              text={onOffBoardButton.text}
              onClick={onOffBoardButton.onClick}
              disabled={
                onOffBoardButton.text === onOffBoardButtonTextStrings.approve &&
                loggedInUser.role !== 1
              }
            />
          ) : (
            <Box />
          )}
        </Stack>

        <Divider sx={{ marginY: employeeDetailsPageMargins.dividerMarginY }} />
        <Stack flexDirection='row'>
          <ShowDetailsColumn
            flex={1}
            label={
              employeeDetailsPageStrings.employeeDetailsCard.locationLabel
            }>
            <Typography variant='subtitle2' color={theme.palette.grey[1100]}>
              {location}
            </Typography>
          </ShowDetailsColumn>
          {/* <ShowDetailsColumn
            flex={2}
            label={employeeDetailsPageStrings.employeeDetailsCard.managerLabel}>
            <Stack direction='row' alignItems='center' spacing={1}>
              <Avatar
                src={managerImageUrl}
                sx={{
                  width: employeeDetailsPageSize.mediumSizeProfileSize,
                  height: employeeDetailsPageSize.mediumSizeProfileSize,
                }}
              />
              <Typography variant='subtitle2' color={theme.palette.grey[1100]}>
                {manager}
              </Typography>
            </Stack>
          </ShowDetailsColumn> */}
          <ShowDetailsColumn
            flex={1}
            label={
              employeeDetailsPageStrings.employeeDetailsCard.workAllocation
            }>
            <Stack direction='row' alignItems='center'>
              {capacity !== null ? (
                <>
                  <Typography variant='body2SemiBold'>
                    {`${capacity} ${tableCellStrings.capacityHoursPostfix}`}
                  </Typography>
                  <Typography variant='body2'>
                    &nbsp;{tableCellStrings.capacityweekPostfix}
                  </Typography>
                </>
              ) : (
                <Typography variant='body2'>{tableCellStrings.dash}</Typography>
              )}
            </Stack>
          </ShowDetailsColumn>
          <ShowDetailsColumn
            flex={1}
            label={employeeDetailsPageStrings.employeeDetailsCard.status}>
            <Box>
              <Chip label={status} sx={getStatusColor(status)} />
            </Box>
          </ShowDetailsColumn>
        </Stack>
      </Box>
    </div>
  );
}

CustomEmployeeDetailsCard.propTypes = {
  userImageUrl: PropTypes.string,
  firstname: PropTypes.string,
  lastname: PropTypes.string,
  jobTitle: PropTypes.string,
  email: PropTypes.string,
  location: PropTypes.string,
  capacity: PropTypes.number,
  status: PropTypes.string,
  onOffBoardButton: PropTypes.shape({
    text: PropTypes.string,
    onClick: PropTypes.func,
  }),
  isLoading: PropTypes.bool,
  loggedInUser: PropTypes.shape().isRequired,
  onButtonLoading: PropTypes.bool,
  roleId: PropTypes.number,
};

CustomEmployeeDetailsCard.defaultProps = {
  userImageUrl: '',
  isLoading: false,
  firstname: '',
  lastname: '',
  jobTitle: '',
  email: '',
  location: '',
  capacity: 0,
  status: '',
  onOffBoardButton: {
    text: '',
    onClick: () => {},
  },
  roleId: null,
  onButtonLoading: false,
};
