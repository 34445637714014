import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  useTheme,
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import CustomTableCell from '../../components/Table/CustomTableCell';
import { customTableStrings } from '../../utils/strings';
import {
  addSoftwareSize,
  approveEmployeeSize,
} from '../../utils/dimensions/size';

function RequestedSoftwares({
  userSoftwares,
  requestedSoftware,
  setRequestedSoftware,
}) {
  const theme = useTheme();
  const tableBodyRef = useRef();
  const [tablebodyMaxHeight, setTablebodyMaxHeight] = useState(
    approveEmployeeSize.requestSoftwareHeight
  );
  const isSelected = (id) => requestedSoftware.indexOf(id) !== -1;

  const handleClick = (id) => {
    const selectedIndex = requestedSoftware.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(requestedSoftware, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(requestedSoftware.slice(1));
    } else if (selectedIndex === requestedSoftware.length - 1) {
      newSelected = newSelected.concat(requestedSoftware.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        requestedSoftware.slice(0, selectedIndex),
        requestedSoftware.slice(selectedIndex + 1)
      );
    }

    setRequestedSoftware(newSelected);
  };
  const updateTableBodyMaxHeight = () => {
    const tableRowHeight =
      tableBodyRef?.current?.querySelector('tbody tr')?.offsetHeight;
    setTablebodyMaxHeight(
      Math.floor((window.innerHeight * 0.7) / tableRowHeight) * tableRowHeight
    );
  };

  useEffect(() => {
    updateTableBodyMaxHeight();
    window.addEventListener('resize', updateTableBodyMaxHeight);
    return () => {
      window.removeEventListener('resize', updateTableBodyMaxHeight);
    };
  });

  return (
    <TableContainer>
      <Table
        sx={{
          backgroundColor: theme.palette.primary.contrastText,
        }}>
        <TableBody
          ref={tableBodyRef}
          sx={{
            display: 'block',
            maxHeight: tablebodyMaxHeight,
            overflow: 'auto',
            '&::-webkit-scrollbar': {
              width: addSoftwareSize.managersDropdownScrollbarWidth,
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: theme.palette.grey[300],
              borderRadius: addSoftwareSize.managersDropdownScrollbarRadius,
              opacity: 0.7,
            },
          }}>
          {userSoftwares.map((software) => {
            const isItemSelected = isSelected(software.id);
            return (
              <TableRow
                sx={{ display: 'flex' }}
                key={software.id}
                onClick={() => handleClick(software.id)}>
                <TableCell>
                  <Checkbox checked={isItemSelected} />
                </TableCell>
                <TableCell sx={{ flex: 1 }}>
                  <CustomTableCell
                    data={{
                      icon: software.Software.icon,
                      name: software.Software.name,
                      client: software.Software.Client.name,
                    }}
                    dataType={
                      customTableStrings.tableCellDataTypes.softwareNameWithIcon
                    }
                  />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default RequestedSoftwares;

RequestedSoftwares.propTypes = {
  userSoftwares: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  requestedSoftware: PropTypes.arrayOf(PropTypes.number).isRequired,
  setRequestedSoftware: PropTypes.func.isRequired,
};
