import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { Box, Container, Tab, Tabs, Typography, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { navBarMargins } from '../../utils/dimensions/margins';
import { navBarPaddings } from '../../utils/dimensions/paddings';
import { navBarSize } from '../../utils/dimensions/size';
import pathStrings from '../../utils/pathStrings';

function CustomContainer(props) {
  // eslint-disable-next-line
  const { fullWidth, selectionFollowsFocus, textColor, indicator, ...rest } =
    props;
  return <Container {...rest} />;
}

function NavBarTabsSection({ setTabValue, tabValue, paths }) {
  const theme = useTheme();
  const onTabValueChanged = (_event, newTabValue) => {
    setTabValue(newTabValue);
  };
  return (
    <Box
      sx={{
        flex: 1,
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'end',
      }}>
      <Tabs
        centered
        onChange={onTabValueChanged}
        value={tabValue}
        sx={{
          display: 'flex',
          flex: 1,
          justifyContent: 'start',
          '& .MuiTab-root.Mui-selected': {
            color: theme.palette.grey[50],
          },
          '& .MuiTabs-flexContainer': {
            height: navBarSize.navBarHeight,
          },
        }}
        TabIndicatorProps={{
          sx: {
            top: '0px',
            height: navBarSize.tabsIndicatorHeight,
            backgroundColor: theme.palette.grey[50],
          },
        }}>
        {paths.map((routeObject) => (
          <Tab
            value={routeObject.path}
            key={routeObject.name}
            disableRipple
            component={Link}
            to={routeObject.path}
            sx={{
              color: theme.palette.grey[300],
              padding: navBarPaddings.tabsPadding,
              margin: navBarMargins.tabsMargin,
            }}
            label={
              <Typography variant='buttonMedium'>{routeObject.name}</Typography>
            }
          />
        ))}
        {/* Custom Container is used to take all remaining space in tabs section */}
        <CustomContainer />

        <Tab
          value={pathStrings.systemUsersPagePath}
          key={pathStrings.systemUsersPagePath}
          disableRipple
          component={Link}
          to={pathStrings.systemUsersPagePath}
          sx={{
            color: theme.palette.grey[300],
            padding: navBarPaddings.tabsPadding,
            margin: navBarMargins.tabsMargin,
            minWidth: '0px',
          }}
          label={<ManageAccountsIcon sx={{ fontSize: '27px' }} />}
        />
      </Tabs>
    </Box>
  );
}

NavBarTabsSection.propTypes = {
  setTabValue: PropTypes.func.isRequired,
  tabValue: PropTypes.string.isRequired,
  paths: PropTypes.PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      component: PropTypes.element.isRequired,
    })
  ).isRequired,
};

export default NavBarTabsSection;
