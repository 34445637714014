const pathStrings = {
  employeePagePath: '/employees',
  softwarePagePath: '/softwares',
  projectsPagePath: '/projects',
  hardwaresPagePath: '/hardwares',
  systemUsersPagePath: '/system-users',
  employeeDetailsPagePath: '/employees/:employeeId',
  hardwareDetailsPagePath: '/hardwares/:hardwareId',
  softwareDetailsPagePath: '/softwares/:softwareId',
  projectDetailsPagePath: '/projects/:projectId',
  homePagePath: '/',
  signInPagePath: '/signin',
  notDefinedRoutePath: '/*',
  ApproveEmployeePagePath: '/employees/:employeeId/approve',
  employeeDetailsPageRedirectPath: '/employees/{userId}',
};

export default pathStrings;
