import { useLocation, useNavigate } from 'react-router-dom';
import { FilterAlt } from '@mui/icons-material';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Badge, Typography, useTheme, Box, Stack } from '@mui/material';
import CustomOutlinedIconButton from '../../components/CustomOutlinedIconButton';
import CustomSearchBar from '../../components/Inputs/CustomSearchBar';
import CustomTable from '../../components/Table/CustomTable';
import { commonPagePaddings } from '../../utils/dimensions/paddings';
import { userSettingsPageSize } from '../../utils/dimensions/size';
import endpointParamsSetter from '../../utils/endpointParamsSetter';
import {
  customTableStrings,
  paginationStrings,
  snackbarStrings,
  userSettingsFilterDropdownStrings,
  userSettingsString,
} from '../../utils/strings';
import headCells from './SystemUserTableData';
import { getComparator, stableSort } from '../../utils/sortingHelpers';
import CustomTableRow from '../../components/Table/CustomTableRow';
import UserSettingsFilter from '../../components/Filter/UserSettingsFilter';
import pathStrings from '../../utils/pathStrings';
import { methodTypes, networkEndpoints } from '../../networks/networkStrings';
import networkClient from '../../networks/networkClient';
import { handle403Error } from '../../utils/errorHandler';
import { paginationContext, snackbarContext } from '../../utils/context';
import makeFirstLetterCapital from '../../utils/makeFirstLetterCapital';
import getLoggedInUser from '../../utils/getLoggedInUser';
import { paramsToObject, updateURL } from '../../utils/urlParser';

function SystemUserPage() {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();

  const initialFilterData = useMemo(
    () => ({
      role: [],
      status: [],
    }),
    []
  );
  const initialSortData = useMemo(
    () => ({
      sort: customTableStrings.sortOrder.decending,
      sortBy: headCells[0].id,
    }),
    []
  );

  const initialFilterState = useMemo(
    () => paramsToObject(location.search.slice(1), initialFilterData),
    [location, initialFilterData]
  );

  const initialSortState = useMemo(
    () => paramsToObject(location.search.slice(1), initialSortData),
    [location, initialSortData]
  );

  const initialSearchState = useMemo(
    () => paramsToObject(location.search.slice(1), { search: '' }),
    [location]
  );

  const { getRowsPerPage, updateRowsPerPage, getPageNo, updatePageNo } =
    useContext(paginationContext);

  const rowsPerPage = getRowsPerPage(paginationStrings.systemUsersListTable);
  const setRowsPerPage = (value) => {
    updateRowsPerPage(paginationStrings.systemUsersListTable, value);
  };
  const page = getPageNo(paginationStrings.systemUsersListTable);
  const setPage = (value) => {
    updatePageNo(paginationStrings.systemUsersListTable, value);
  };

  const [sortOrderBy, setSortOrderBy] = useState(initialSortState.sortBy);
  const [sortOrder, setSortOrder] = useState(initialSortState.sort);
  const [filterState, setFilterState] = useState(initialFilterState);
  const [activeFilters, setActiveFiltersCount] = useState(0);
  const [anchorElement, setanchorElement] = useState(null);
  const [userData, setUserData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const { showSnackbar } = useContext(snackbarContext);
  const [searchBarText, setSearchBarText] = useState(initialSearchState.search);
  const [filterLoading, setFilterLoading] = useState(false);

  useEffect(() => {
    updateURL(
      filterState,
      sortOrder,
      sortOrderBy,
      searchBarText,
      initialSortData,
      location
    );
  }, [
    filterState,
    location,
    sortOrder,
    sortOrderBy,
    searchBarText,
    initialSortData,
  ]);

  const filterData = useCallback(() => {
    let count = 0;
    Object.values(filterState).forEach((value) => {
      if (Array.isArray(value) && value.length > 0) {
        count += 1;
      } else if (typeof value === 'number' && value !== 0) {
        count += 1;
      }
    });
    setActiveFiltersCount(count);
    const filteredRows = userData.filter((row) => {
      const { status, role } = filterState;
      if (count !== 0) {
        if (status.length > 0 && !status.includes(row.status)) {
          return false;
        }
        const modifiedRole =
          row.role.roleName ===
          userSettingsFilterDropdownStrings.RoleList[1].toLowerCase()
            ? userSettingsFilterDropdownStrings.RoleList[1]
            : makeFirstLetterCapital(row.role.roleName);

        if (role.length > 0 && !role.includes(modifiedRole)) {
          return false;
        }
      }
      return (
        (row.id &&
          row.id.toUpperCase().includes(searchBarText.trim().toUpperCase())) ||
        row.nameData.name
          .toUpperCase()
          .includes(searchBarText.trim().toUpperCase()) ||
        row.nameData.position
          ?.toUpperCase()
          .includes(searchBarText.trim().toUpperCase())
      );
    });
    setTableData(filteredRows);
  }, [filterState, userData, searchBarText]);

  const onRowClickFunction = (index) => {
    navigate(
      endpointParamsSetter({
        path: pathStrings.employeeDetailsPagePath,
        params: index,
      })
    );
  };

  const handleClick = (event) => {
    setanchorElement(event.currentTarget);
  };
  const handleMenuClose = () => {
    setanchorElement(null);
  };

  const updateRole = useCallback(
    async ({ userId, role, roleId, setRoleValue }) => {
      let oldvalue;
      setRoleValue((old) => {
        oldvalue = old;
        return { id: roleId, role: role.toLowerCase() };
      });

      const userList = await networkClient({
        method: methodTypes.patch,
        endpoint: `${networkEndpoints.user.user}${userId}`,
        requestBody: {
          role_id: roleId,
        },
      });
      if (userList.error) {
        setRoleValue(oldvalue);
        showSnackbar(
          snackbarStrings.messages.error.roleError,
          snackbarStrings.variant.error
        );
      } else {
        showSnackbar(
          snackbarStrings.messages.success.roleUpdatedSuccessFully,
          snackbarStrings.variant.success
        );
      }
    },
    [showSnackbar]
  );
  const fetchUsers = useCallback(async () => {
    const userList = await networkClient({
      method: methodTypes.get,
      endpoint: networkEndpoints.user.getUsers,
    });
    if (userList.error) {
      if (userList.errors[0].code === 403) {
        handle403Error(navigate, location);
      }
      setUserData([]);
      setTableData([]);
      setIsLoading(false);
      setIsError(true);
    } else {
      const user = getLoggedInUser(navigate, location);
      const { email } = user;

      const isOwner = userList.data.some(
        (userObj) =>
          userObj.company_email === email &&
          userObj.UserRole.role ===
            customTableStrings.roleValues[0].role.toLowerCase() &&
          userObj.status !== userSettingsString.offBoardedStatus
      );
      const employeeData = userList.data.map((data) => ({
        userId: data.id,
        nameData: {
          imageUrl: '',
          name: `${data.first_name} ${data.last_name}`,
          position: data.job_title,
        },
        id: data.employee_id,
        status: makeFirstLetterCapital(data.status),
        role: {
          userId: data.id,
          roleId: data.UserRole.id,
          roleName: data.UserRole.role,
          onChangeFunction: updateRole,
          isOwner,
          name: `${data.first_name} ${data.last_name}`,
          status: makeFirstLetterCapital(data.status),
        },
      }));
      setUserData(employeeData);
      if (location.search.slice(1) === '') {
        setTableData(employeeData);
        setIsLoading(false);
        setIsError(false);
      } else setFilterLoading(true);
    }
  }, [navigate, location, updateRole]);

  useEffect(() => {
    if (filterLoading) {
      setFilterLoading(false);
      setIsLoading(false);
      setIsError(false);
    }
  }, [tableData, filterLoading]);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);
  useEffect(() => {
    filterData();
  }, [filterData]);
  return (
    <Box
      sx={{
        height: userSettingsPageSize.rootBoxHeight,
      }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        }}>
        <Stack
          padding={commonPagePaddings.pagePadding}
          direction='row'
          sx={{
            backgroundColor: theme.palette.common.white,
          }}
          justifyContent='space-between'>
          <Stack>
            <Typography variant='h4' color={theme.palette.grey[1100]}>
              {userSettingsString.title}
            </Typography>
            <Box height={userSettingsPageSize.spaceBetweenTitleAndSubtitle} />
            <Typography variant='body1' color={theme.palette.grey[300]}>
              {userSettingsString.subtitle}
            </Typography>
          </Stack>
        </Stack>
        <Box
          display='flex'
          flexDirection='column'
          maxHeight={userSettingsPageSize.tableBoxHeight}
          padding={commonPagePaddings.pagePadding}>
          <Stack
            marginBottom={userSettingsPageSize.spaceBetweenSearchbarAndTable}
            direction='row'
            justifyContent='space-between'>
            <CustomSearchBar
              initialValue={searchBarText}
              minWidth={userSettingsPageSize.searchbarMinWidth}
              onChange={(e) => setSearchBarText(e.target.value)}
              placeholder={userSettingsString.searchBarPlaceholder}
            />
            <Badge color='primary' badgeContent={activeFilters}>
              <CustomOutlinedIconButton
                icon={<FilterAlt />}
                onClick={handleClick}
                textColor={
                  activeFilters > 0
                    ? theme.palette.primary.main
                    : theme.palette.secondary.main
                }>
                {userSettingsString.filterButtonText}
              </CustomOutlinedIconButton>
            </Badge>
          </Stack>

          <CustomTable
            page={page}
            setPage={setPage}
            sortOrderBy={sortOrderBy}
            setSortOrderBy={setSortOrderBy}
            sortOrder={sortOrder}
            setSortOrder={setSortOrder}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            tableData={tableData}
            onTryAgainClick={fetchUsers}
            isError={isError}
            isLoading={isLoading}
            tableHeadData={headCells}>
            {stableSort(
              tableData,
              getComparator(sortOrder, sortOrderBy),
              sortOrderBy
            )
              .slice(
                rowsPerPage < 0 ? 0 : page * rowsPerPage,
                rowsPerPage < 0 ? undefined : page * rowsPerPage + rowsPerPage
              )
              .map((row) => (
                <CustomTableRow
                  key={JSON.stringify(row)}
                  row={row}
                  onRowClick={() => onRowClickFunction(row.userId)}
                  tableHeadData={headCells}
                />
              ))}
          </CustomTable>
        </Box>
      </Box>
      <UserSettingsFilter
        anchorElement={anchorElement}
        onClose={handleMenuClose}
        filterState={filterState}
        setFilterState={setFilterState}
        initialFilterState={initialFilterData}
      />
    </Box>
  );
}

export default SystemUserPage;
