// headCells has data for Table Head data.
const headCells = [
  {
    id: 'userId',
    dataType: 'skip',
  },
  {
    id: 'id',
    dataType: 'employeeId',
    label: 'Employee Id',
    flex: 3,
    sorting: true,
  },

  {
    id: 'employeeName',
    dataType: 'employeeName',
    label: 'Name',
    flex: 5,
    sorting: true,
  },

  {
    id: 'location',
    dataType: 'text',
    label: 'Branch',
    flex: 3,
    sorting: true,
  },
  {
    id: 'capacity',
    dataType: 'capacityText',
    label: 'Capacity',
    flex: 3,
    sorting: true,
  },
  {
    id: 'skills',
    dataType: 'multiple',
    label: 'Skills',
    flex: 5,
    sorting: true,
  },
  {
    id: 'status',
    dataType: 'status',
    label: 'Status',
    flex: 3,
    sorting: true,
  },
];

export default headCells;
