import PropTypes from 'prop-types';
import { Error, Refresh } from '@mui/icons-material';
import { Stack, Typography, useTheme } from '@mui/material';
import CustomTextButton from './CustomTextButton';
import { apiErrorComponentString } from '../utils/strings';
import { apiErrorComponentSize } from '../utils/dimensions/size';

function ApiErrorComponent({ errorMessage, onRefreshClick }) {
  const theme = useTheme();
  return (
    <Stack
      direction='column'
      gap={apiErrorComponentSize.gapBetweenComponents}
      alignItems='center'>
      <Stack
        direction='row'
        gap={apiErrorComponentSize.gapBetweenComponents}
        alignItems='center'>
        <Error
          sx={{
            color: theme.palette.grey[500],
          }}
        />

        <Typography variant='body1Medium' color={theme.palette.grey[500]}>
          {errorMessage}
        </Typography>
      </Stack>
      <CustomTextButton
        onClick={onRefreshClick}
        icon={
          <Refresh
            sx={{
              height: apiErrorComponentSize.refreshButtonSize,
              width: apiErrorComponentSize.refreshButtonSize,
            }}
          />
        }>
        {apiErrorComponentString.refreshButtonText}
      </CustomTextButton>
    </Stack>
  );
}

ApiErrorComponent.propTypes = {
  errorMessage: PropTypes.string,
  onRefreshClick: PropTypes.func.isRequired,
};
ApiErrorComponent.defaultProps = {
  errorMessage: apiErrorComponentString.defaultErroreMessage,
};
export default ApiErrorComponent;
