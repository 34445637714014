import { Box, Container, Stack, Typography, useTheme } from '@mui/material';
import PropTypes from 'prop-types';

import CustomFilledIconButton from '../../components/CustomFilledIconButton';
import { ReactComponent as ErrorMessageSVG } from '../../utils/assets/ErrorMessageSVG.svg';
import { ErrorMessagePageSize } from '../../utils/dimensions/size';
import { errorMessagePageStrings } from '../../utils/strings';
import pathStrings from '../../utils/pathStrings';

function ErrorMessagePage({ title, subtitle }) {
  const theme = useTheme();

  return (
    <Container
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: ErrorMessagePageSize.pageHeight,
      }}>
      <Stack justifyContent='center' alignItems='center'>
        <ErrorMessageSVG />
        <Box
          bgcolor={theme.palette.secondary.main}
          width={ErrorMessagePageSize.dividerWidth}
          height={ErrorMessagePageSize.dividerHeight}
        />
        <Box height={ErrorMessagePageSize.spaceBetweenObjects} />
        <Typography variant='h3' color={theme.palette.secondary.main}>
          {title}
        </Typography>
        <Box height={ErrorMessagePageSize.spaceBetweenObjects} />
        <Typography variant='h6' color={theme.palette.grey[500]}>
          {subtitle}
        </Typography>
        <Box height={ErrorMessagePageSize.spaceBetweenObjects} />
        <CustomFilledIconButton
          text={errorMessagePageStrings.buttonText}
          onClick={() => {
            window.location.href = pathStrings.homePagePath;
          }}
        />
      </Stack>
    </Container>
  );
}

ErrorMessagePage.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

ErrorMessagePage.defaultProps = {
  title: errorMessagePageStrings.pageTitle,
  subtitle: errorMessagePageStrings.pageSubtitle,
};

export default ErrorMessagePage;
