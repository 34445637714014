import { Box, Button, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { CustomFilledIconButtonString } from '../utils/strings';
import { CustomFilledIconButtonSize } from '../utils/dimensions/size';
import CircularProgressIndicator from './CircularProgressIndicator';
import theme from '../styles/theme';

export default function CustomFilledIconButton({
  onClick,
  icon,
  text,
  color,
  width,
  height,
  disabled,
  isLoading,
}) {
  return isLoading ? (
    <Box
      display='flex'
      alignItems='center'
      height={CustomFilledIconButtonSize.loaderBoxHeight}
      minWidth={CustomFilledIconButtonSize.loaderBoxMinWidth}>
      <CircularProgressIndicator />
    </Box>
  ) : (
    <Button
      variant={CustomFilledIconButtonString.variant}
      disableRipple={disabled}
      onClick={disabled ? undefined : onClick}
      disableElevation
      startIcon={icon}
      sx={{
        cursor: disabled ? 'not-allowed' : 'pointer',
        backgroundColor: color,
        opacity: disabled
          ? CustomFilledIconButtonString.disabledOpacity
          : CustomFilledIconButtonString.defaultOpacity,
        borderRadius: theme.shape.borderRadius,
        height:
          height == null ? CustomFilledIconButtonSize.buttonHeight : height,
        textTransform: CustomFilledIconButtonString.textTransform,
        minWidth:
          width == null ? width : CustomFilledIconButtonSize.buttonWidth,
        '&:hover': {
          opacity: disabled
            ? CustomFilledIconButtonString.disabledOpacity
            : CustomFilledIconButtonString.defaultOpacity,
          backgroundColor: disabled ? color : 'default',
        },
      }}>
      <Typography variant='buttonLarge'>{text}</Typography>
    </Button>
  );
}

CustomFilledIconButton.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  icon: PropTypes.element,
  color: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
};

CustomFilledIconButton.defaultProps = {
  icon: null,
  color: theme.palette.primary.main,
  width: null,
  height: null,
  disabled: false,
  isLoading: false,
};
