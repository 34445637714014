import PropTypes from 'prop-types';
import {
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
  useTheme,
} from '@mui/material';
import { customTableStrings } from '../../utils/strings';

export default function CustomTableHead({
  sortOrder,
  sortOrderBy,
  handleSortRequest,
  tableHeadData,
  expandable,
}) {
  const theme = useTheme();

  // function for handling sort event.
  const createSortHandler = (property) => (event) => {
    handleSortRequest(event, property);
  };

  return (
    <TableHead>
      <TableRow
        sx={{
          backgroundColor: theme.palette.grey[25],
          display: 'flex',
        }}>
        {tableHeadData.map((headCell) => {
          if (headCell.dataType !== 'skip')
            return (
              <TableCell
                sx={{
                  flex: headCell.flex,
                }}
                key={headCell.id}
                sortDirection={sortOrderBy === headCell.id ? sortOrder : false}>
                <TableSortLabel
                  sx={{
                    cursor: headCell.sorting ? 'pointer' : 'default',
                    '& .MuiTableSortLabel-icon': {
                      display:
                        sortOrderBy !== null && headCell.sorting
                          ? 'block'
                          : 'none',
                    },
                  }}
                  active={sortOrderBy === headCell.id}
                  direction={
                    sortOrderBy === headCell.id
                      ? sortOrder
                      : customTableStrings.sortOrder.ascending
                  }
                  onClick={createSortHandler(headCell.id)}>
                  <Typography
                    varient='tableHeader'
                    color={theme.palette.grey[400]}>
                    {headCell.label}
                  </Typography>
                </TableSortLabel>
              </TableCell>
            );
          return null;
        })}
        {expandable && (
          <TableCell
            sx={{
              flex: 1,
            }}
            key='expandable'
          />
        )}
      </TableRow>
    </TableHead>
  );
}

CustomTableHead.propTypes = {
  handleSortRequest: PropTypes.func.isRequired,
  sortOrder: PropTypes.oneOf([
    customTableStrings.sortOrder.ascending,
    customTableStrings.sortOrder.decending,
  ]).isRequired,
  sortOrderBy: PropTypes.string,
  tableHeadData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      dataType: PropTypes.string,
      label: PropTypes.string,
      flex: PropTypes.number,
      sortingNeeded: PropTypes.bool,
    })
  ).isRequired,
  expandable: PropTypes.bool,
};

CustomTableHead.defaultProps = {
  expandable: false,
  sortOrderBy: '',
};
