export default function RandomColor(name) {
  let hash = 0;
  for (let i = 0; i < name?.length; i += 1) {
    hash = name.charCodeAt(i) + (hash * 32 - hash);
  }

  const h = hash % 360;

  return `hsl(${h}, ${65}%, ${40}%)`;
}
