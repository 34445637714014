const skillsData = [
  { name: 'Dev Ops' },
  { name: 'Backend' },
  { name: 'Full Stack' },
  { name: 'NodeJS' },
  { name: 'Frontend' },
  { name: 'HTML' },
  { name: 'Coding' },
  { name: 'Native' },
];
const sliderMarksData = [
  {
    value: 0,
    label: 'Bench',
  },
  {
    value: 20,
  },
  {
    value: 35,
  },
  {
    value: 40,
    label: '40 Hrs',
  },
];

module.exports = { skillsData, sliderMarksData };
