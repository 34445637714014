import { Box, IconButton, Stack } from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { navbarProfileMenuSize, navBarSize } from '../../utils/dimensions/size';
import NavbarProfileMenu from './NavbarProfileMenu';
import CustomAvatar from '../CustomAvatar';

function NavBarProfileSection({ name, email, picture }) {
  const [anchorEl, setAnchorEl] = useState(null);

  return (
    <Box
      sx={{
        height: navBarSize.profileSectionHeight,
        display: 'flex',
        justifyContent: 'center',
      }}>
      <Stack direction='row' alignItems='center'>
        <Box width={navBarSize.spaceBetweenAvatarAndSettingGear} />
        <IconButton
          onClick={(e) => {
            setAnchorEl(e.currentTarget);
          }}>
          <CustomAvatar
            name={name}
            src={picture}
            height={navbarProfileMenuSize.avatarHeight}
            width={navbarProfileMenuSize.avatarWidth}
          />
        </IconButton>
        <NavbarProfileMenu
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
          picture={picture}
          name={name}
          email={email}
        />
      </Stack>
    </Box>
  );
}
NavBarProfileSection.propTypes = {
  name: PropTypes.string.isRequired,
  picture: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
};

export default NavBarProfileSection;
