import { useGoogleLogin } from '@react-oauth/google';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Button, Stack, Typography, useTheme } from '@mui/material';
import { signinStrings, snackbarStrings } from '../../utils/strings';
import redirectPaths from '../../Router/RedirectPaths';
import { signinMargins } from '../../utils/dimensions/margins';
import { signinSize } from '../../utils/dimensions/size';
import Background from '../../utils/assets/signinbg.png';
import { ReactComponent as SigninImage } from '../../utils/assets/signinImage.svg';
import { ReactComponent as GoogleLogo } from '../../utils/assets/GoogleLogo.svg';
import { ReactComponent as RedCarpetLogo } from '../../utils/assets/RedCarpetLogoWithTitle.svg';
import axios from '../../networks/axios';
import { encryptMessage } from '../../utils/encyption/encryptionMethods';
import { networkEndpoints, tokenStrings } from '../../networks/networkStrings';
import CustomSnackBar from '../../components/CustomSnackBar';
import CircularProgressIndicator from '../../components/CircularProgressIndicator';

function SignIn() {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const from = location.state?.from?.pathname || redirectPaths.homePath.to;

  // below code will be needed when using google SSO with live backend.

  const loginSuccess = async (res) => {
    try {
      // below code will be needed when using google SSO with live backend.
      const body = { code: res.code };
      const { data } = await axios.post(networkEndpoints.user.login, body);

      localStorage.setItem(
        tokenStrings.user,
        encryptMessage(JSON.stringify(data.data.user))
      );
      localStorage.setItem(tokenStrings.accessToken, data.data.accessToken);
      localStorage.setItem(
        tokenStrings.expiresAt,
        encryptMessage(data.data.expiresAt)
      );
      setIsLoading(false);
      navigate(from, { replace: true });
    } catch (err) {
      setIsLoading(false);
      if (err.response && err.response.status === 401) {
        setOpenSnackBar(true);
        setSnackBarMessage(snackbarStrings.messages.error.userUnAuthorized);
        return;
      }
      if (err.response && err.response.status === 403) {
        setOpenSnackBar(true);
        setSnackBarMessage(snackbarStrings.messages.error.userNotFound);
        return;
      }
      setOpenSnackBar(true);
      setSnackBarMessage(signinStrings.loginFailedMessage);
    }
  };

  // for login failure
  const loginfailure = () => {
    setIsLoading(false);
    setOpenSnackBar(true);
    setSnackBarMessage(signinStrings.loginFailedMessage);
  };

  const googleLogin = useGoogleLogin({
    onSuccess: loginSuccess,
    onFailure: loginfailure,
    flow: 'auth-code',
  });

  return (
    <Box
      sx={{
        backgroundImage: `url(${Background})`,
        backgroundSize: 'cover',
        display: 'flex',
        justifyContent: 'center',
        height: '100vh',
      }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: theme.palette.common.white,
          justifyContent: 'center',
          alignItems: 'center',
          width: signinSize.boxWidth,
          height: '100%',
        }}>
        <Box
          sx={{
            display: 'flex',
            flex: 3,
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <Stack direction='row' alignItems='center'>
            <RedCarpetLogo />
          </Stack>
          <Box height={signinSize.spaceBetweenLogoAndTitle} />
          <Typography
            color={theme.palette.grey[900]}
            align='center'
            variant='h4'>
            {signinStrings.signIn}
          </Typography>
          <Box height={signinSize.spaceBetweenTitleAndSubtitle} />
          <Typography
            color={theme.palette.grey[600]}
            variant='body1'
            sx={{
              marginTop: signinMargins.subtitleMarginTop,
              marginBottom: signinMargins.subtitleMarginBottom,
            }}>
            {signinStrings.SignInText}
          </Typography>

          {isLoading ? (
            <CircularProgressIndicator />
          ) : (
            <Button
              variant='outlined'
              disableElevation
              onClick={() => {
                setIsLoading(true);
                googleLogin();
                setTimeout(() => {
                  setIsLoading(false);
                }, 30000);
              }}
              startIcon={<GoogleLogo />}
              sx={{
                borderColor:
                  theme.palette.secondary.states.outlinedRestingBorder,
                backgroundColor: theme.palette.background.paper,
                color: theme.palette.secondary.main,
                borderRadius: theme.shape.borderRadius,
                height: signinSize.signInButtonHeight,
                width: signinSize.signInButtonWidth,
              }}>
              <Typography variant='buttonLarge'>
                {signinStrings.SignInButtonText}
              </Typography>
            </Button>
          )}
        </Box>
        <Box flex={1}>
          <SigninImage />
        </Box>
      </Box>
      {/* To display error on login failed */}

      <CustomSnackBar
        alertMessage={snackBarMessage}
        open={openSnackBar}
        setOpen={setOpenSnackBar}
        severity={snackbarStrings.variant.error}
      />
    </Box>
  );
}

export default SignIn;
