// this function return js object of parameters provided.
function createData(allocation, date, status, notes) {
  return { allocation, date, status, notes };
}

// rows has the softwares data
export const rows = [
  createData(
    {
      imageUrl:
        'https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=774&q=80',
      name: 'Robert Fox',
      postion: 'Software Engineer',
    },
    'Jul 11, 2015',
    'Assigned',
    'He is an intern'
  ),
  createData(
    {
      imageUrl:
        'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS_bv-bbQ5fudQZFzSsYMyDyuVmzEshkfS9-Iu2bIPnUBQFKjoqFzRLeXuQJsrLWRTxzj0&usqp=CAU',
      name: 'Darell Steward',
      postion: 'Software Engineer',
    },
    'Jul 11, 2015',
    'Issue Logged',
    'He is an intern'
  ),
  createData(
    {
      imageUrl:
        'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTxQUZDn3SdaD55Kk5N5-OQwzG6vhK58Oep8Q&usqp=CAU',
      name: 'Cody Fisher',
      postion: 'Software Engineer',
    },
    'Jul 11, 2015',
    'Revoked',
    ''
  ),
];

export const hardwareDetail = {
  category: 'Laptop',
  serialKey: 'skjdfhds3bwibd9393jkdo3',
  modelNo: 'MAC9099333',
  dateOfPurchase: '20/12/2022',
  location: 'Ahmedabad, India',
  vendorName: 'I Venus',
  description:
    '12-core CPU with 8 performance cores and 4 efficiency cores \n30-core GPU \n16-core Neural Engine\n400GB/s memory bandwidth',
};

// headCells has data for Table Head data.
export const headCells = [
  {
    id: 'name',
    dataType: 'employeeName',
    label: 'Allocation',
    flex: 3,
    sortingNeeded: false,
  },

  {
    id: 'date',
    dataType: 'text',
    label: 'Date',
    flex: 2,
  },
  {
    id: 'status',
    dataType: 'status',
    label: 'Status',
    flex: 2,
  },
  {
    id: 'note',
    dataType: 'note',
    label: 'Note',
    flex: 5,
  },
];
