//  This file contains constant margin values for Custom Components

// constant margin values for customDrawer
const navBarMargins = {
  tabsMargin: '0px 16px 0px 16px',
};

const customTableMargins = {
  employeeNameCellMarginLeft: '12px',
  managerNameCellMarginLeft: '8px',
  resourceSelectMarginsLeft: '24px',
  resourceSelectMainMarginLeft: '-10px',
};

const basicInformationMargins = {
  marginX: '32px',
  marginY: '23px',
};

const customDropDownMargins = {
  margin: '10px 10px 10px 0',
};

const customTextFieldMargins = {
  margin: '10px 10px 10px 0',
};

const signinMargins = {
  subtitleMarginTop: '8px',
  subtitleMarginBottom: '32px',
};

const joiningInformationMargins = {
  marginX: '32px',
  marginY: '14px',
  startDateMarginTop: '10px',
  startDateMarginRigth: '10px',
  selectDeviceMarginTop: '20px',
};
const resourceSelectMargins = {
  marginX: '32px',
};

const employeeDetailsPageMargins = {
  tabsMargin: '10px 0px',
  bulletsMarginX: '8px',
  dividerMarginY: '20px',
};

const deviceCategoryMarings = {
  marginBottom: '14px',
  marginTop: '16px',
  marginXBetweenSingleDevice: '8px',
  imageMarginTop: '16px',
  iconMarginTop: '20px',
  TextmarginTop: '18px',
  TextmarginBottom: '20px',
};
const addHardwareMargins = {
  marginX: '32px',
  marginY: '40px',
  DateOfPurchaseMarginTop: '10px',
  DateOfPurchaseMarginRigth: '10px',
};

const hardwarePageMargins = {
  categoriesIconMarginX: '12px',
};
const assignHardwareDrawerMargins = {
  marginX: '32px',
  marginY: '25px',
  marginForSearchBarDiv: '20px 16px 16px 16px',
};
const deleteHardwareMargins = {
  margin: '32px',
  subtitlemarginTop: '8px',
  subtitleMarginBottom: '31px',
};
const approveEmployeeMargins = {
  marginRight: '32px',
  marginTop: '10px',
  titleMarginLeft: '25px',
  stackMargin: '10px 10px 10px 10px',
  labelBoxMargin: '10px',
  divideMarginX: '20px',
  gapBetweenButtons: '30px',
  RequiredSoftwareTitle: '15px 15px 15px 15px',
  boxmarginLeft: '0px',
  boxMargin: '32px 32px 32px 0px',
  BoxToButton: '12px 32px 12px 0px',
  BoxToSoftawre: '15px 10px 15px 10px',
};
const employeeSoftwareDetailsMargin = {
  titleMarginLeft: '11px',
  dividerMarginY: '30px',
  tabelMarginX: '32px',
  ownerMarginRight: '34px',
};
const addEmployeeMargins = {
  stepperMarginY: '27px',
};
const assignSoftwareDrawerMargins = {
  marginX: '32px',
  marginY: '25px',
};

const assignProjectMargins = {
  marginX: '32px',
  marginY: '23px',
};

const revokeHardwareMargins = {
  marginX: '32px',
  marginY: '23px',
};
const revokeProjectMargins = {
  marginX: '32px',
  marginY: '23px',
};

const revokeSoftwareMargins = {
  marginX: '32px',
  marginY: '23px',
};

const completeAssignSoftwareMargins = {
  marginX: '32px',
  marginY: '23px',
};

const addProjectMargins = {
  marginX: '32px',
  marginY: '23px',
};

const addSoftwareMargins = {
  marginX: '32px',
  marginY: '23px',
};
const softwareDetailsMargins = {
  timeMarginright: '10px',
};

const deleteSoftwareMargins = {
  marginX: '32px',
  marginY: '23px',
};

const detailsCardMargins = {
  buttonMarginTop: '30px',
};

const profileChecklistMargins = {
  marginBetweenText: '0px',
};

const offBoardEmployeeDrawerMargins = {
  marginX: '32px',
  marginTop: '23px',
  marginBottom: '16px',
  captionMarginTop: '10px',
  marginLeft: '15px',
  marginRight: '25px',
};
const customListExpandElement = {
  marginTextFeild: '0px 16px',
  boxMarginBottom: '10px',
  boxMarginTop: '8px',
  boxMarginRight: '10px',
};

const filterDropDownMargins = {
  skillFilterChipMarginRight: 1,
  skillFilterChipMarginTop: 1,
  spacingToLabelChip: 1,
  menuBoxMarginTop: '8px',
  menuBoxMarginLeft: '-75px',
};
const historyDetailsMargins = {
  dateMarginY: '30px',
  dateLabelMarginRightTab: '30px',
  descriptionMarginTop: '-7px',
  dateLabelMarginBottomDrawer: '40px',
  dateLabelMarginBottomTab: '32px',
};

const offBoardEmployeePopupMargins = {
  textFieldDateTopMargin: '10px',
  textFieldDateRightMargin: '10px',
};

const navbarProfileMenuMargins = {
  menuBoxMarginTop: '13px',
  menuBoxMarginLeft: '-65px',
};
export {
  navBarMargins,
  customTableMargins,
  basicInformationMargins,
  resourceSelectMargins,
  customDropDownMargins,
  customTextFieldMargins,
  signinMargins,
  joiningInformationMargins,
  employeeDetailsPageMargins,
  detailsCardMargins,
  deviceCategoryMarings,
  addHardwareMargins,
  assignHardwareDrawerMargins,
  deleteHardwareMargins,
  approveEmployeeMargins,
  employeeSoftwareDetailsMargin,
  assignProjectMargins,
  revokeHardwareMargins,
  revokeProjectMargins,
  revokeSoftwareMargins,
  addProjectMargins,
  addSoftwareMargins,
  softwareDetailsMargins,
  deleteSoftwareMargins,
  assignSoftwareDrawerMargins,
  hardwarePageMargins,
  addEmployeeMargins,
  profileChecklistMargins,
  offBoardEmployeeDrawerMargins,
  customListExpandElement,
  filterDropDownMargins,
  offBoardEmployeePopupMargins,
  historyDetailsMargins,
  completeAssignSoftwareMargins,
  navbarProfileMenuMargins,
};
