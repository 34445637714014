import { urlKeys } from './strings';
import { isOnlyNumbers } from './validators';

const objectToParams = (object) =>
  Object.entries(object)
    .map(([key, value]) => {
      if (Array.isArray(value)) {
        if (value.length > 0)
          return `${encodeURIComponent(key)}=${encodeURIComponent(
            value.toString()
          )}`;
      } else if (value !== 0)
        return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
      return '';
    })
    .filter((param) => param !== '')
    .join('&');

const paramsToObject = (params, initialState) => {
  const decodedParams = decodeURIComponent(params);
  const newParams = decodedParams.split('&');
  const result = { ...initialState };

  if (params !== '') {
    newParams.forEach((param) => {
      const [key, value] = param.split('=');
      if (key in initialState && value !== '') {
        if (key === urlKeys.search) result[key] = value;
        else if (isOnlyNumbers(value)) result[key] = Number(value);
        else if (value.includes(',')) result[key] = value.split(/,(?=\S)/g);
        else if (Array.isArray(initialState[key])) result[key] = [value];
        else result[key] = value;
      }
    });
  }
  return result;
};

const updateURL = (
  filterState,
  sortOrder,
  sortOrderBy,
  searchBarText,
  initialSortData,
  location
) => {
  const filterParams = objectToParams(filterState);
  let sortParams = '';

  if (
    sortOrder !== null &&
    sortOrderBy !== null &&
    (sortOrder !== initialSortData.sort ||
      sortOrderBy !== initialSortData.sortBy)
  ) {
    sortParams = objectToParams({
      sort: sortOrder,
      sortBy: sortOrderBy,
    });
  }
  let searchParams = '';
  if (searchBarText !== '') {
    searchParams = `${urlKeys.search}=${searchBarText}`;
  }

  const finalParams = [filterParams, sortParams, searchParams]
    .filter((value) => value !== '')
    .join('&');

  window.history.replaceState(
    null,
    null,
    finalParams === ''
      ? `${location.pathname}`
      : `${location.pathname}?${finalParams}`
  );
};

export { objectToParams, paramsToObject, updateURL };
