import PropTypes from 'prop-types';
import { Avatar, Box, Typography, useTheme } from '@mui/material';
import { detailsPageSize } from '../../utils/dimensions/size';
import { projectDetailsPageStrings } from '../../utils/strings';
import ShowDetailsColumn from '../../components/ShowDetailsColumn';

function ProjectDetailContent({ projectDetails }) {
  const theme = useTheme();
  return (
    <div>
      <Box>
        <Box height={detailsPageSize.spaceBetweenDetailsContent} />
        <ShowDetailsColumn
          label={projectDetailsPageStrings.detailsCard.noOfEmployeesLabel}>
          <Typography varient='body2' color={theme.palette.grey[1100]}>
            {projectDetails.noOfEmployees}
          </Typography>
        </ShowDetailsColumn>
        <Box height={detailsPageSize.spaceBetweenDetailsContent} />
        <ShowDetailsColumn
          label={projectDetailsPageStrings.detailsCard.managerLabel}>
          <Box display='flex' alignItems='center'>
            <Avatar
              alt={projectDetails.manager.name}
              src={projectDetails.manager.imageUrl}
              sx={{
                height: detailsPageSize.profileImageSize,
                width: detailsPageSize.profileImageSize,
                border: `2px solid ${theme.palette.grey[200]}`,
              }}
            />
            <Box width={detailsPageSize.widthBetweenImageAndName} />
            <Typography variant='body2' color={theme.palette.grey[1100]}>
              {projectDetails.manager.name}
            </Typography>
          </Box>
        </ShowDetailsColumn>
        <Box height={detailsPageSize.spaceBetweenDetailsContent} />
        <ShowDetailsColumn
          label={projectDetailsPageStrings.detailsCard.leadLabel}>
          <Box display='flex' alignItems='center'>
            <Avatar
              alt={projectDetails.lead.name}
              src={projectDetails.lead.imageUrl}
              sx={{
                height: detailsPageSize.profileImageSize,
                width: detailsPageSize.profileImageSize,
                border: `2px solid ${theme.palette.grey[200]}`,
              }}
            />
            <Box width={detailsPageSize.widthBetweenImageAndName} />
            <Typography variant='body2' color={theme.palette.grey[1100]}>
              {projectDetails.lead.name}
            </Typography>
          </Box>
        </ShowDetailsColumn>
        <Box height={detailsPageSize.spaceBetweenDetailsContent} />
        <ShowDetailsColumn
          label={projectDetailsPageStrings.detailsCard.durationLabel}>
          <Typography varient='body2' color={theme.palette.grey[1100]}>
            {projectDetails.duration}
          </Typography>
        </ShowDetailsColumn>
        <Box height={detailsPageSize.spaceBetweenDetailsContent} />
        <ShowDetailsColumn
          label={projectDetailsPageStrings.detailsCard.typeLabel}>
          <Typography varient='body2' color={theme.palette.grey[1100]}>
            {projectDetails.type}
          </Typography>
        </ShowDetailsColumn>
        <Box height={detailsPageSize.spaceBetweenDetailsContent} />
        <ShowDetailsColumn
          label={projectDetailsPageStrings.detailsCard.descriptionLabel}>
          <Typography varient='body2' color={theme.palette.grey[1100]}>
            {projectDetails.description}
          </Typography>
        </ShowDetailsColumn>
        <Box height={detailsPageSize.spaceBetweenDetailsContent} />
      </Box>
    </div>
  );
}

ProjectDetailContent.propTypes = {
  projectDetails: PropTypes.shape({
    noOfEmployees: PropTypes.string.isRequired,
    manager: PropTypes.string.isRequired,
    lead: PropTypes.string.isRequired,
    duration: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
  }).isRequired,
};
export default ProjectDetailContent;
