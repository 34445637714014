import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Stack, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import CustomTextButton from '../../components/CustomTextButton';
import { employeeDetailsPagePaddings } from '../../utils/dimensions/paddings';
import {
  employeeDetailsPageStrings,
  tableCellStrings,
} from '../../utils/strings';
import CustomDetailsBox from '../../components/CustomDetailsBox';
import ShowDetailsColumn from '../../components/ShowDetailsColumn';
import EditEmployeeBasicDetails from '../../components/EmployeeProfileActions/EditEmployeeBasicDetails';
import { employeeDetailsPageSize } from '../../utils/dimensions/size';
import getLoggedInUser from '../../utils/getLoggedInUser';

function BasicDetailsCard({
  title,
  employeeBasicDetails,
  setEmployeeDetails,
  setHistoryData,
}) {
  const [toggleDrawer, setToggleDrawer] = useState(false);
  const theme = useTheme();
  const [user, setUser] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    setUser(getLoggedInUser(navigate, location));
  }, [navigate, setUser, location]);

  let role;
  if (user) {
    role = user.role;
  }
  return (
    <div>
      <CustomDetailsBox
        title={title}
        titleTailElement={
          <CustomTextButton
            disabled={role === 4 || role === 5}
            icon={
              <EditOutlinedIcon
                color={theme.palette.grey[1100]}
                fontSize={employeeDetailsPageSize.asideIconSize}
              />
            }
            onClick={() => {
              setToggleDrawer(true);
            }}>
            {employeeDetailsPageStrings.profileTab.editButtonText}
          </CustomTextButton>
        }>
        <Stack
          padding={employeeDetailsPagePaddings.rowDetailsPadding}
          display='flex'
          flexDirection='row'>
          <ShowDetailsColumn
            flex='1'
            label={employeeDetailsPageStrings.profileTab.firstNameLabel}>
            {employeeBasicDetails.first_name || tableCellStrings.dash}
          </ShowDetailsColumn>
          <ShowDetailsColumn
            flex='1'
            label={employeeDetailsPageStrings.profileTab.lastNameLabel}>
            {employeeBasicDetails.last_name || tableCellStrings.dash}
          </ShowDetailsColumn>
          <ShowDetailsColumn
            flex='1'
            label={employeeDetailsPageStrings.profileTab.phoneNoLabel}>
            {employeeBasicDetails.country_code ||
            employeeBasicDetails.phone_number
              ? `${
                  employeeBasicDetails.country_code
                    ? `${employeeBasicDetails.country_code}`
                    : ''
                }
            ${
              employeeBasicDetails.phone_number
                ? `${employeeBasicDetails.phone_number}`
                : ''
            }`
              : tableCellStrings.dash}
          </ShowDetailsColumn>
        </Stack>
        <Stack
          padding={employeeDetailsPagePaddings.rowDetailsPadding}
          display='flex'
          flexDirection='row'>
          <ShowDetailsColumn
            flex='1'
            label={employeeDetailsPageStrings.profileTab.personalEmailLabel}>
            {employeeBasicDetails.personal_email || tableCellStrings.dash}
          </ShowDetailsColumn>
          <ShowDetailsColumn
            flex='2'
            label={employeeDetailsPageStrings.profileTab.addressLabel}>
            {employeeBasicDetails.address ||
            employeeBasicDetails.city ||
            employeeBasicDetails.state ||
            employeeBasicDetails.zipcode
              ? `${
                  employeeBasicDetails.address
                    ? `${employeeBasicDetails.address}  `
                    : ''
                }
  ${employeeBasicDetails.city ? `${employeeBasicDetails.city}  ` : ''}
  ${employeeBasicDetails.state ? `${employeeBasicDetails.state} ` : ''}
  ${employeeBasicDetails.zipcode ? `${employeeBasicDetails.zipcode}` : ''}`
              : tableCellStrings.dash}
          </ShowDetailsColumn>
        </Stack>

        <EditEmployeeBasicDetails
          title={
            employeeDetailsPageStrings.employeeDetailsCard.editEmplployeeTitle
          }
          successButtonText={
            employeeDetailsPageStrings.employeeDetailsCard.successButtonText
          }
          failureButtonText={
            employeeDetailsPageStrings.employeeDetailsCard.failureButtonText
          }
          toggleDrawer={toggleDrawer}
          setToggleDrawer={setToggleDrawer}
          data={employeeBasicDetails}
          setEmployeeDetails={setEmployeeDetails}
          setHistoryData={setHistoryData}
        />
      </CustomDetailsBox>
    </div>
  );
}

BasicDetailsCard.propTypes = {
  title: PropTypes.string.isRequired,
  employeeBasicDetails: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    phone_number: PropTypes.string,
    personal_email: PropTypes.string,
    company_email: PropTypes.string,
    address: PropTypes.string,
    country_code: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zipcode: PropTypes.string,
    id: PropTypes.number,
  }),
  setEmployeeDetails: PropTypes.func.isRequired,
  setHistoryData: PropTypes.func.isRequired,
};
BasicDetailsCard.defaultProps = {
  employeeBasicDetails: {
    first_name: '',
    last_name: '',
    phone_number: '',
    personal_email: '',
    company_email: '',
    address: '',
    country_code: '',
    city: '',
    state: '',
    zipcode: '',
    id: 0,
  },
};
export default BasicDetailsCard;
