import { TextField, useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import { customSearchBarPaddings } from '../../utils/dimensions/paddings';
import { customSearchBarStrings } from '../../utils/strings';

export default function CustomSearchBar({
  placeholder,
  onChange,
  initialValue,
  minWidth,
  paddingLeft,
}) {
  const theme = useTheme();
  return (
    <TextField
      defaultValue={initialValue}
      sx={{
        minWidth,
        paddingLeft,
        width: Math.max(minWidth, 100 + placeholder.length * 9),
        '& .MuiOutlinedInput-input': {
          backgroundColor: theme.palette.background.paper,
          padding: customSearchBarPaddings.padding,
        },
      }}
      placeholder={placeholder}
      onChange={onChange}
    />
  );
}

CustomSearchBar.propTypes = {
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  initialValue: PropTypes.string,
  minWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  paddingLeft: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

CustomSearchBar.defaultProps = {
  placeholder: customSearchBarStrings.placeholder,
  minWidth: 0,
  paddingLeft: 0,
  initialValue: '',
};
