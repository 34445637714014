import { useCallback, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Step, StepLabel, Stepper } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import {
  addEmployeeStrings,
  basicInformationStrings,
  employeeDetailsPageStrings,
  employeeStatusStrings,
  employeesPageStrings,
  joiningInformationStrings,
  offBoardEmployeeStrings,
  branchString,
  locations,
  branches,
} from '../../utils/strings';
import BasicInformationComponent from './BasicInformationComponent';
import CustomDrawer from '../CustomDrawer';
import JoiningDataComponent from './JoiningInformationComponent';
import { addEmployeeMargins } from '../../utils/dimensions/margins';
import ResourceSelectComponent from './ResourceSelectComponent';
import { methodTypes, networkEndpoints } from '../../networks/networkStrings';
import networkClient from '../../networks/networkClient';
import { isValidId } from '../../utils/validators';
import { handle403Error } from '../../utils/errorHandler';
import { snackbarContext } from '../../utils/context';
import endpointParamsSetter from '../../utils/endpointParamsSetter';
import {
  basicDetailsInitialState,
  jobDetailsInitialState,
} from './employeeDetailsInitStates';

function AddEmployeeDrawer({
  toggleDrawer,
  setToggleDrawer,
  allEmployeeData,
  setAllEmployeeData,
}) {
  // const [selectedHardwareNumber, setSelectedHardwareNumber] = useState(0);
  const location = useLocation();
  const navigate = useNavigate();
  const { showSnackbar } = useContext(snackbarContext);
  const [activeStep, setActiveStep] = useState(0);
  const [isBranchLoading, setIsBranchLoading] = useState();

  const softwareDetailsInitialState = null;
  const initialBranchState = null;

  const [basicDetails, setBasicDetails] = useState(basicDetailsInitialState);
  const [jobDetails, setJobDetails] = useState(jobDetailsInitialState);
  const [softwareFetchError, setSoftwareFetchError] = useState(false);
  const [softwareDetails, setSoftwareDetails] = useState(
    softwareDetailsInitialState
  );
  const [branchItems, setBranchItems] = useState(initialBranchState);
  const [branchError, setBranchError] = useState(false);
  const [successButtonLoading, setsuccessButtonLoading] = useState(false);
  const handleSelection = (id) => {
    setSoftwareDetails((prevSoftwareDetails) =>
      prevSoftwareDetails.map((software) =>
        software.id === id
          ? { ...software, isSelected: !software.isSelected }
          : software
      )
    );
  };

  function joiningDataHasError() {
    if (
      basicDetails.company_email.error ||
      basicDetails.first_name.error ||
      basicDetails.last_name.error ||
      basicDetails.country_code.error ||
      basicDetails.city.error ||
      basicDetails.zipcode.error ||
      basicDetails.state.error ||
      basicDetails.address.error ||
      basicDetails.phone_number.error ||
      basicDetails.personal_email.error ||
      basicDetails.first_name.value === '' ||
      basicDetails.last_name.value === '' ||
      basicDetails.country_code.value === '' ||
      basicDetails.phone_number.value === '' ||
      basicDetails.personal_email.value === ''
    ) {
      return true;
    }
    return false;
  }

  function jobDetailsHasError() {
    if (
      jobDetails.location.error ||
      (!jobDetails.employee_id.disabled && jobDetails.employee_id.error) ||
      jobDetails.job_title.error ||
      jobDetails.employment_type.error ||
      jobDetails.join_date.error ||
      jobDetails.capacity.error ||
      jobDetails.location.value === '' ||
      (!jobDetails.employee_id.disabled &&
        jobDetails.employee_id.value !== '' &&
        jobDetails.location.value !== '' &&
        jobDetails.location.value !== locations.remoteIndia &&
        jobDetails.location.value !== locations.remoteMexico &&
        jobDetails.location.value !== locations.remoteUs &&
        !isValidId(jobDetails.employee_id.value, jobDetails.prefix.value)) ||
      jobDetails.job_title.value === '' ||
      jobDetails.employment_type.value === '' ||
      (jobDetails.isAgency.value && jobDetails.agency_email.value === '')
    ) {
      return true;
    }
    if (jobDetails.isAgency.value) {
      if (
        jobDetails.agency_name.error ||
        jobDetails.agency_email.error ||
        jobDetails.agency_phone_number.error
      ) {
        return true;
      }
    }

    return false;
  }
  // fetching softwares
  const fetchSoftwares = useCallback(async () => {
    const softwaresData = await networkClient({
      method: methodTypes.get,
      endpoint: networkEndpoints.software.getActiveSoftwares,
    });
    if (softwaresData.error) {
      setSoftwareDetails([]);
      setSoftwareFetchError(true);
      if (softwaresData.errors[0].code === 403) {
        handle403Error(navigate, location);
      }
    } else {
      const softwareDetailsWithClient = softwaresData.data.map((software) => {
        const client = software.Client || { id: null, name: null };
        return {
          ...software,
          clientName: client.name,
          clientId: client.id,
          // if the client name is Techholding and mathes the software name from preselected softwares
          isSelected:
            client.name === offBoardEmployeeStrings.clientNameTechholding &&
            addEmployeeStrings.preSelectedSoftwares.some(
              (preSelectedSoftware) =>
                preSelectedSoftware.toLowerCase() ===
                software.name.toLowerCase()
            ),
        };
      });
      setSoftwareDetails(
        softwareDetailsWithClient.sort((a, b) => {
          if (a.isSelected && !b.isSelected) {
            return -1;
          }
          if (!a.isSelected && b.isSelected) {
            return 1;
          }
          return 0;
        })
      );
      setSoftwareFetchError(false);
    }
  }, [navigate, location]);

  const fetchbranches = useCallback(async () => {
    setIsBranchLoading(true);
    const response = await networkClient({
      method: methodTypes.get,
      endpoint: branchString.getAllBranch,
    });
    setIsBranchLoading(false);
    if (response.error) {
      setBranchError(true);
    } else {
      setBranchError(false);
      setBranchItems(response.data);
    }
  }, []);

  useEffect(() => {
    if (toggleDrawer && softwareDetails === softwareDetailsInitialState)
      fetchSoftwares();
  }, [
    fetchSoftwares,
    toggleDrawer,
    softwareDetailsInitialState,
    softwareDetails,
  ]);

  const updateSoftwareSelection = useCallback(() => {
    const { clientNameTechholding } = offBoardEmployeeStrings;
    const { value: locationValue } = jobDetails.location;
    const preSelectedSoftwares = basicInformationStrings.preSelectedSoftwares
      .slice(0, 2) // Select only the values at indices 0 and 1
      .map((software) => software.toLowerCase());

    setSoftwareDetails((prevSoftwareDetails) =>
      prevSoftwareDetails.map((software) => {
        const client = software.Client || { id: null, name: null };
        const { name } = software;

        if (
          client.name === clientNameTechholding &&
          locationValue === branches.remote &&
          preSelectedSoftwares.includes(name.toLowerCase())
        ) {
          return { ...software, isSelected: false };
        }

        if (
          client.name === clientNameTechholding &&
          ((locationValue.includes(branches.india) &&
            preSelectedSoftwares.includes(name.toLowerCase())) ||
            ((locationValue.includes(branches.usa) ||
              locationValue.includes(branches.maxico)) &&
              name.toLowerCase() === preSelectedSoftwares[0]))
        ) {
          return { ...software, isSelected: true };
        }

        return software;
      })
    );
  }, [jobDetails]);

  useEffect(() => {
    if (jobDetails.location.value !== '') updateSoftwareSelection();
  }, [updateSoftwareSelection, jobDetails]);

  useEffect(() => {
    if (toggleDrawer && branchItems === initialBranchState) fetchbranches();
  }, [fetchbranches, branchItems, toggleDrawer]);

  const [error, setError] = useState(false);
  const addEmployeeSteps = [
    {
      label: addEmployeeStrings.stepOneLabel,
      element: (
        <BasicInformationComponent
          basicDetails={basicDetails}
          setBasicDetails={setBasicDetails}
        />
      ),
      active: false,
    },

    {
      label: addEmployeeStrings.stepTwoLabel,
      element: (
        <JoiningDataComponent
          jobDetails={jobDetails}
          setJobDetails={setJobDetails}
          branchItems={branchItems}
          branchError={branchError}
          fetchbranches={fetchbranches}
          isBranchLoading={isBranchLoading}
        />
      ),
      active: true,
    },
    {
      label: addEmployeeStrings.stepThreeLabel,
      element: (
        <ResourceSelectComponent
          // selectedHardwareNumber={selectedHardwareNumber}
          // setSelectedHardwareNumber={setSelectedHardwareNumber}
          fetchSoftwares={() => fetchSoftwares()}
          softwareDetails={softwareDetails || []}
          softwareFetchError={softwareFetchError}
          handleSelections={handleSelection}
          location={jobDetails.location.value}
          applyLocationFilter
        />
      ),
      active: false,
    },
  ];
  let successButtonDisabled = false;
  if (activeStep === 0) {
    successButtonDisabled = joiningDataHasError();
  } else if (activeStep === 1) {
    successButtonDisabled = jobDetailsHasError();
  }

  function addSelectedSoftware() {
    const selectSoftware = [];
    softwareDetails.forEach((data) => {
      if (
        data.isSelected === true &&
        ((jobDetails.location.value.includes(branches.india) &&
          data.name.toLowerCase() !==
            basicInformationStrings.preSelectedSoftwares[0].toLowerCase()) ||
          (!jobDetails.location.value.includes(branches.india) &&
            data.name.toLowerCase() !==
              basicInformationStrings.preSelectedSoftwares[1].toLowerCase()))
      ) {
        selectSoftware.push(data.id);
      }
    });
    return selectSoftware;
  }
  function filterPayload() {
    const payload = {
      employee_id:
        jobDetails.employee_id.value === '' || jobDetails.employee_id.disabled
          ? null
          : jobDetails.employee_id.value,
      first_name: basicDetails.first_name.value,
      last_name: basicDetails.last_name.value,
      personal_email: basicDetails.personal_email.value,
      country_code: basicDetails.country_code.value,
      phone_number: basicDetails.phone_number.value,
      address: basicDetails.address.value,
      city: basicDetails.city.value,
      state: basicDetails.state.value,
      zipcode: basicDetails.zipcode.value,
      branch_id: jobDetails.branch_id.value,
      join_date: moment(jobDetails.join_date.value)
        .utc()
        .format('YYYY-MM-DD HH:mm:ssZZ'),
      employment_type: jobDetails.employment_type.value.toLowerCase(),
      job_title: jobDetails.job_title.value,
      status: employeeStatusStrings.pending.toLowerCase(),
      note: jobDetails.description.value,
    };
    // checking if agecy swith is enable or not if not then email and name will be excluded from payload
    if (jobDetails.isAgency.value) {
      if (jobDetails.agency_name.value !== '') {
        payload.agency_name = jobDetails.agency_name.value;
      }
      if (jobDetails.agency_email.value !== '') {
        payload.agency_email = jobDetails.agency_email.value.toLowerCase();
      }
      if (jobDetails.agency_phone_number !== '') {
        payload.agency_phone_number = jobDetails.agency_phone_number.value;
      }
    }
    if (jobDetails.capacity.value !== '') {
      payload.capacity = jobDetails.capacity.value;
    }
    if (basicDetails.company_email.value !== '') {
      payload.company_email = basicDetails.company_email.value.toLowerCase();
    }
    return payload;
  }

  const setErrorMessage = ({ errors }) => {
    let redirectStepper = 2;
    errors.forEach((err) => {
      if (
        Object.keys(basicInformationStrings.placeHolder).includes(err.field)
      ) {
        setBasicDetails((prevBasicDetails) => ({
          ...prevBasicDetails,
          [err.field]: {
            ...prevBasicDetails[err.field],
            error: true,
            errorMessage: err.message,
          },
        }));
        redirectStepper = Math.min(redirectStepper, 0);
      } else if (
        Object.keys(joiningInformationStrings.placeHolder).includes(err.field)
      ) {
        setJobDetails((prevJobDetails) => ({
          ...prevJobDetails,
          [err.field]: {
            ...prevJobDetails[err.field],
            error: true,
            errorMessage: err.message,
          },
        }));
        redirectStepper = Math.min(redirectStepper, 1);
      } else {
        redirectStepper = Math.min(redirectStepper, 2);
      }
    });
    return redirectStepper;
  };

  const handleStepperClick = (index) => {
    if (activeStep === 2) {
      setActiveStep(index);
    } else if (
      activeStep === 1 &&
      ((index === 2 && !jobDetailsHasError()) || index === 0)
    ) {
      setActiveStep(index);
    } else if (
      activeStep === 0 &&
      ((index === 1 && !joiningDataHasError()) ||
        (index === 2 && !jobDetailsHasError() && !joiningDataHasError()))
    ) {
      setActiveStep(index);
    }
  };

  const onSuccessButtonClick = () => {
    if (activeStep <= 1) setActiveStep((oldActiveStep) => oldActiveStep + 1);
    if (activeStep === 2) {
      setError(null);
      setsuccessButtonLoading(true);
      const filteredPayload = filterPayload();
      networkClient({
        method: methodTypes.post,
        endpoint: networkEndpoints.user.user,
        requestBody: filteredPayload,
      }).then((response) => {
        setsuccessButtonLoading(false);
        if (response.error) {
          if (response.errors[0].code === 400) {
            const redirectStepper = setErrorMessage({
              errors: response.errors,
            });
            if (redirectStepper === 2) {
              setError(true);
            } else {
              setError(false);
            }
            setActiveStep(redirectStepper);
          } else {
            setError(true);
          }
          if (response.errors[0].code === 403) {
            handle403Error(navigate, location);
          }
          setTimeout(() => setError(false), 10000);
        }
        const selectedSoftware = addSelectedSoftware();
        if (response.code === 201) {
          setError(null);
          allEmployeeData.unshift({
            userId: response.data.id,
            id: response.data.employee_id,
            nameData: {
              imageUrl: '',
              name: `${response.data.first_name} ${response.data.last_name}`,
              position: response.data.job_title,
            },
            location: jobDetails.location.value,
            capacity: response.data.capacity,
            skills: [],
            status: employeeDetailsPageStrings.employeeStatus.pending,
          });
          setToggleDrawer(false);
          if (selectedSoftware.length === 0) {
            setToggleDrawer(false);
            setSoftwareDetails(softwareDetailsInitialState);
            setsuccessButtonLoading(false);
            setActiveStep(0);
          }
          setAllEmployeeData([...allEmployeeData]);
          showSnackbar(employeesPageStrings.employeeAdded);
          setBasicDetails(basicDetailsInitialState);
          setJobDetails(jobDetailsInitialState);

          if (selectedSoftware.length) {
            networkClient({
              method: methodTypes.post,
              endpoint: endpointParamsSetter({
                path: networkEndpoints.user.userSoftwareDetails,
                params: response.data.id,
              }),
              requestBody: {
                softwareList: selectedSoftware.map((softwareId) => ({
                  software_id: softwareId,
                })),
              },
            }).then((softwareResposne) => {
              if (softwareResposne.error) {
                setError(true);
                if (softwareResposne.errors[0].code === 403) {
                  handle403Error(navigate, location);
                }
                setTimeout(() => setError(false), 10000);
              } else {
                setError(false);
                setSoftwareDetails(softwareDetailsInitialState);
                setToggleDrawer(false);
                setActiveStep(0);
              }
            });
          }
        }
      });
    }
  };
  return (
    <CustomDrawer
      open={toggleDrawer}
      onFailureButtonClick={() => {
        setToggleDrawer(false);
        setBasicDetails(basicDetailsInitialState);
        setJobDetails(jobDetailsInitialState);
        setSoftwareDetails(softwareDetailsInitialState);
        setActiveStep(0);
      }}
      onBackButtonClick={() => {
        if (activeStep === 0) {
          setToggleDrawer(false);
          setBasicDetails(basicDetailsInitialState);
          setJobDetails(jobDetailsInitialState);
          setSoftwareDetails(softwareDetailsInitialState);
        } else {
          setActiveStep((oldActiveStep) => oldActiveStep - 1);
        }
      }}
      successButtonLoading={successButtonLoading}
      successButtonDisabled={successButtonDisabled}
      onSuccessButtonClick={onSuccessButtonClick}
      error={error && activeStep === 2}
      title={addEmployeeStrings.title}
      successButtonText={
        activeStep <= 1
          ? addEmployeeStrings.basicInfoPageSuccessButtonText
          : addEmployeeStrings.jobInfoPageSuccessButtonText
      }
      failureButtonText={addEmployeeStrings.basicInfoPageFailureButtonText}
      isCancelRequired={activeStep !== 0}>
      <>
        <Stepper
          sx={{
            marginY: addEmployeeMargins.stepperMarginY,
          }}
          activeStep={activeStep}
          alternativeLabel>
          {addEmployeeSteps.map((step, index) => (
            <Step key={step.label}>
              <StepLabel
                onClick={() => {
                  handleStepperClick(index);
                }}>
                {step.label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
        {addEmployeeSteps[activeStep].element}
      </>
    </CustomDrawer>
  );
}

AddEmployeeDrawer.propTypes = {
  toggleDrawer: PropTypes.bool.isRequired,
  setToggleDrawer: PropTypes.func.isRequired,
  allEmployeeData: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  setAllEmployeeData: PropTypes.func.isRequired,
};

export default AddEmployeeDrawer;
