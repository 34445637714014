import { Close } from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';
import PropTypes from 'prop-types';
import HistoryDetailsComponent from '../../pages/EmployeeDetailsPage/HistoryDetailsComponent';
import { historyDrawerPaddings } from '../../utils/dimensions/paddings';
import { historyDrawerSizes } from '../../utils/dimensions/size';
import ApiErrorComponent from '../ApiErrorComponent';
import CustomDrawer from '../CustomDrawer';

function EmployeeHistoryDrawer({
  title,
  toggleDrawer,
  setToggleDrawer,
  data,
  refetchFunction,
  isError,
}) {
  return (
    <CustomDrawer
      open={toggleDrawer}
      title={title}
      headerTailElement={
        <IconButton disableRipple onClick={() => setToggleDrawer(false)}>
          <Close />
        </IconButton>
      }
      onClose={() => setToggleDrawer(false)}>
      <Box
        paddingX={historyDrawerPaddings.paddingX}
        display='flex'
        minHeight={historyDrawerSizes.minHeight}>
        {isError ? (
          <Box
            alignItems='center'
            justifyContent='center'
            display='flex'
            flex={1}>
            <ApiErrorComponent
              onRefreshClick={() => {
                refetchFunction();
              }}
            />
          </Box>
        ) : (
          <HistoryDetailsComponent
            data={data}
            isDrawer
            dataLength={data.length - 1}
          />
        )}
      </Box>
    </CustomDrawer>
  );
}

EmployeeHistoryDrawer.propTypes = {
  toggleDrawer: PropTypes.bool.isRequired,
  setToggleDrawer: PropTypes.func.isRequired,
  refetchFunction: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
  isError: PropTypes.bool.isRequired,
};

export default EmployeeHistoryDrawer;
