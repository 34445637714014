import { Box, Stack, Typography, useTheme } from '@mui/material';
import moment from 'moment';
import PropTypes from 'prop-types';
import { softwareNotesDrawerPaddings } from '../../utils/dimensions/paddings';
import { softwareDetailsNotesSize } from '../../utils/dimensions/size';
import makeFirstLetterCapital from '../../utils/makeFirstLetterCapital';
import { softwareNotesDrawerStrings } from '../../utils/strings';
import CustomAvatar from '../CustomAvatar';
import CustomDrawer from '../CustomDrawer';
import DefaultSoftwareFavicon from '../../utils/assets/DefaultSoftwareFavicon.svg';

function SoftwareNotesDrawer({ toggleDrawer, setToggleDrawer, data }) {
  const theme = useTheme();
  return (
    <CustomDrawer
      open={toggleDrawer}
      title={softwareNotesDrawerStrings.title}
      failureButtonText='cancel'
      onFailureButtonClick={() => {
        setToggleDrawer(false);
      }}
      onClose={() => {
        setToggleDrawer(false);
      }}
      headerTailElement={
        <Stack direction='row' alignItems='center' spacing={1}>
          <Box
            sx={{
              padding: softwareNotesDrawerPaddings.iconPadding,
            }}>
            {data?.imageUrl ? (
              <img
                width={softwareDetailsNotesSize.iconWidth}
                height={softwareDetailsNotesSize.iconHeight}
                style={{ objectFit: 'contain' }}
                src={data?.imageUrl}
                alt={data?.imageUrl}
                onError={(e) => {
                  e.target.src = DefaultSoftwareFavicon;
                  e.onerror = null;
                }}
              />
            ) : (
              <img
                width={softwareDetailsNotesSize.iconWidth}
                height={softwareDetailsNotesSize.iconHeight}
                src={DefaultSoftwareFavicon}
                style={{ objectFit: 'contain' }}
                alt={data?.imageUrl}
              />
            )}
          </Box>

          <Typography variant='h6' color={theme.palette.grey[1100]}>
            {data?.name}
          </Typography>
        </Stack>
      }>
      <Box paddingX={softwareNotesDrawerPaddings.userOuterPaddingX}>
        <Stack
          justifyContent='space-between'
          direction='row'
          sx={{
            backgroundColor: theme.palette.grey[25],
            paddingX: softwareNotesDrawerPaddings.userDetailsPaddingX,
            paddingY: softwareNotesDrawerPaddings.userDetailsPaddingY,
          }}>
          <Stack direction='row' spacing={1} alignItems='center'>
            <CustomAvatar name={data?.userName} />
            <Stack>
              <Typography
                variant='body2SemiBold'
                color={theme.palette.grey[1000]}>
                {data?.userName}
              </Typography>
              <Typography variant='tooltip' color={theme.palette.grey[300]}>
                {data?.userJobTitle}
              </Typography>
            </Stack>
          </Stack>
          {data?.assignDate !== '' && (
            <Stack>
              <Typography variant='caption' color={theme.palette.grey[300]}>
                {softwareNotesDrawerStrings.assignDateLabel}
              </Typography>
              <Typography variant='body2'>{data?.assignDate}</Typography>
            </Stack>
          )}
        </Stack>

        <Stack
          padding={softwareNotesDrawerPaddings.userDetailsPaddingX}
          spacing={3}
          overflow='scroll'>
          {data?.notes.map((note) => (
            <Stack
              spacing={1.5}
              key={note.timestamp}
              sx={{
                padding: softwareNotesDrawerPaddings.notesBoxPadding,
                border: `1px solid ${theme.palette.info.states.outlinedHoverBackground}`,
              }}>
              <Typography variant='body2SemiBold'>
                {makeFirstLetterCapital(note.action)}
              </Typography>
              <Typography
                variant='inputText'
                color={theme.palette.common.black}
                width={softwareDetailsNotesSize.notesBoxWidth}>
                {note.note}
              </Typography>
              <Typography variant='body2' color={theme.palette.grey[300]}>
                {moment(note.timestamp).format('MMMM D, YYYY')}
              </Typography>
            </Stack>
          ))}
        </Stack>
      </Box>
    </CustomDrawer>
  );
}

SoftwareNotesDrawer.propTypes = {
  toggleDrawer: PropTypes.bool.isRequired,
  setToggleDrawer: PropTypes.func.isRequired,
  data: PropTypes.shape({
    name: PropTypes.string.isRequired,
    imageUrl: PropTypes.string,
    userName: PropTypes.string.isRequired,
    userJobTitle: PropTypes.string.isRequired,
    assignDate: PropTypes.string.isRequired,
    notes: PropTypes.arrayOf(
      PropTypes.shape({
        action: PropTypes.string.isRequired,
        note: PropTypes.string.isRequired,
        timestamp: PropTypes.string.isRequired,
      })
    ),
  }),
};

SoftwareNotesDrawer.defaultProps = {
  data: {
    name: '',
    imageUrl: '',
  },
};

export default SoftwareNotesDrawer;
