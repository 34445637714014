import ListItemButton from '@mui/material/ListItemButton';
import PropTypes from 'prop-types';
import { Stack, Typography, useTheme } from '@mui/material';
import ListItemText from '@mui/material/ListItemText';
import Chip from '@mui/material/Chip';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { filterDropDownMargins } from '../../utils/dimensions/margins';

export default function CustomListItem({
  handleListItemClick,
  listIndex,
  countFilter,
  openCollapse,
  itemListName,
  itemListType,
}) {
  const theme = useTheme();
  return (
    <ListItemButton
      onClick={() => handleListItemClick(listIndex)}
      sx={{
        justifyContent: 'space-between',
      }}>
      <Stack
        direction='row'
        spacing={filterDropDownMargins.spacingToLabelChip}
        alignItems='center'>
        <ListItemText
          primary={
            <Typography variant='body1Medium'>{itemListName}</Typography>
          }
        />
        <Chip
          label={countFilter(itemListType)}
          style={{
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
          }}
          variant='filled'
          size='small'
        />
      </Stack>
      {openCollapse === listIndex ? <ExpandLess /> : <ExpandMore />}
    </ListItemButton>
  );
}

CustomListItem.propTypes = {
  handleListItemClick: PropTypes.func.isRequired,
  listIndex: PropTypes.number.isRequired,
  countFilter: PropTypes.func.isRequired,
  openCollapse: PropTypes.number,
  itemListName: PropTypes.string.isRequired,
  itemListType: PropTypes.string.isRequired,
};
CustomListItem.defaultProps = {
  openCollapse: null,
};
