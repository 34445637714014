import {
  Box,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
  useTheme,
} from '@mui/material';
import PropTypes from 'prop-types';
import { useRef } from 'react';
import { customTableStrings } from '../../utils/strings';
import CustomTableHead from './CustomTableHead';
import { customTableSize } from '../../utils/dimensions/size';
import CustomTableErrorMessage from './CustomTableErrorMessage';

export default function CustomTable({
  tableHeadData,
  tableData,
  expandable,
  rowsPerPage,
  setRowsPerPage,
  children,
  page,
  setPage,
  sortOrderBy,
  setSortOrderBy,
  sortOrder,
  setSortOrder,
  isError,
  isLoading,
  onTryAgainClick,
  emptyTableMessage,
  flex,
}) {
  const theme = useTheme();
  const tableBodyRef = useRef();

  const totalPages = Math.ceil(tableData.length / rowsPerPage);
  if (page >= totalPages && page !== 0 && totalPages !== 0) {
    setPage(0);
  }

  const handleSortRequest = (_event, property) => {
    const isAsc =
      sortOrderBy === property &&
      sortOrder === customTableStrings.sortOrder.ascending;
    setSortOrder(
      isAsc
        ? customTableStrings.sortOrder.decending
        : customTableStrings.sortOrder.ascending
    );
    setSortOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (tableBodyRef.current) {
      tableBodyRef.current.scrollTop = 0;
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    if (tableBodyRef.current) {
      tableBodyRef.current.scrollTop = 0;
    }
  };

  return (
    <Box maxHeight flex={flex}>
      <TableContainer
        sx={{
          height:
            page !== null && tableData.length > 0
              ? customTableSize.tableContainerHeight
              : '100%',
        }}>
        <Table
          sx={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: theme.palette.primary.contrastText,
          }}>
          <CustomTableHead
            sortOrder={sortOrder}
            sortOrderBy={sortOrderBy}
            handleSortRequest={handleSortRequest}
            tableHeadData={tableHeadData}
            expandable={expandable}
          />

          {tableData.length <= 0 ? (
            <CustomTableErrorMessage
              isLoading={isLoading}
              isError={isError}
              onTryAgainClick={onTryAgainClick}
              message={emptyTableMessage}
            />
          ) : (
            <TableBody
              ref={tableBodyRef}
              sx={{
                display: 'block',
                maxHeight: customTableSize.tabBodyMaxHeight,
                overflowY: 'scroll',
                '&::-webkit-scrollbar': {
                  width: customTableSize.scrollbarWidth,
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: theme.palette.grey[300],
                  borderRadius: customTableSize.scrollbarRadius,
                  opacity: 0.7,
                },
              }}>
              {children}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      {page !== null && tableData.length > 0 && (
        <TablePagination
          rowsPerPageOptions={customTableStrings.rowsPerPageOptions}
          component='div'
          count={tableData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            backgroundColor: theme.palette.common.white,
            '& .MuiTablePagination-select': {
              backgroundColor: theme.palette.grey[50],
            },
          }}
        />
      )}
    </Box>
  );
}

CustomTable.propTypes = {
  tableHeadData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      dataType: PropTypes.string,
      label: PropTypes.string,
      flex: PropTypes.number,
      sorting: PropTypes.bool,
    })
  ).isRequired,
  tableData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  expandable: PropTypes.bool,
  isError: PropTypes.bool,
  isLoading: PropTypes.bool,
  children: PropTypes.arrayOf(PropTypes.element),
  rowsPerPage: PropTypes.number,
  flex: PropTypes.number,
  setRowsPerPage: PropTypes.func,
  page: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([null])]),
  setPage: PropTypes.func,
  sortOrderBy: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  setSortOrderBy: PropTypes.func,
  sortOrder: PropTypes.oneOf([
    customTableStrings.sortOrder.ascending,
    customTableStrings.sortOrder.decending,
  ]),
  setSortOrder: PropTypes.func,
  emptyTableMessage: PropTypes.string,
  onTryAgainClick: PropTypes.func,
};

CustomTable.defaultProps = {
  page: null,
  children: null,
  sortOrderBy: '',
  expandable: false,
  isError: false,
  isLoading: false,
  flex: 1,
  setPage: () => {},
  setSortOrderBy: () => {},
  setSortOrder: () => {},
  onTryAgainClick: () => {},
  rowsPerPage: 10,
  setRowsPerPage: () => {},
  emptyTableMessage: customTableStrings.noDataMessage,
  sortOrder: customTableStrings.sortOrder.ascending,
};
