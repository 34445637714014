import { Box, Stack, useTheme, Typography } from '@mui/material';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import CustomDetailsBox from '../../components/CustomDetailsBox';
import CustomFilledIconButton from '../../components/CustomFilledIconButton';
import CustomOutlinedIconButton from '../../components/CustomOutlinedIconButton';
import BasicDetails from '../../components/EmployeeActions/ApproveEmployee/BasicDetails';
import JobDetails from '../../components/EmployeeActions/ApproveEmployee/JobDetails';
import { approveEmployeeMargins } from '../../utils/dimensions/margins';
import { commonPagePaddings } from '../../utils/dimensions/paddings';
import { approveEmployeeSize } from '../../utils/dimensions/size';
import redirectPaths from '../../Router/RedirectPaths';
import {
  approveEmployeeStrings,
  employeeAprrovalString,
  employeeDetailsPageStrings,
  snackbarStrings,
} from '../../utils/strings';
import networkClient from '../../networks/networkClient';
import endpointParamsSetter from '../../utils/endpointParamsSetter';
import { methodTypes, networkEndpoints } from '../../networks/networkStrings';
import CircularProgressIndicator from '../../components/CircularProgressIndicator';
import ApiErrorComponent from '../../components/ApiErrorComponent';
import CustomModal from '../../components/CustomModal';
import { ReactComponent as SuccessSVG } from '../../utils/assets/ok.svg';
import { handle403Error } from '../../utils/errorHandler';
import { snackbarContext } from '../../utils/context';
import RequestedSoftwares from './RequestedSoftwares';
import getLoggedInUser from '../../utils/getLoggedInUser';
import ErrorMessagePage from '../ErrorMessageScreen/ErrorMessagePage';

function ApproveEmployee() {
  const { showSnackbar } = useContext(snackbarContext);
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();

  const [userData, setUserData] = useState(null);
  const [userSoftwareData, setUserSoftwareData] = useState(null);
  const [requestedSoftware, setRequestedSoftware] = useState([]);

  const [isUserDataLoading, setUserDataLoading] = useState(true);
  const [openSuccesModal, setOpenSuccesModal] = useState(false);
  const [isError, setIsError] = useState(true);
  const [onSuccessButtonLoading, setOpenSuccessButtonLoading] = useState(false);
  const [is404Error, setIs404Error] = useState(false);
  const backToEmployee = () => {
    navigate(redirectPaths.homePath.to, { replace: true });
  };
  const params = useParams();
  const userId = params.employeeId;

  const handleApproveButtonClick = async () => {
    setOpenSuccessButtonLoading(true);
    const deleteSoftwares = userSoftwareData
      .filter((userSoftware) => !requestedSoftware.includes(userSoftware.id))
      .map((userSoftware) => userSoftware.id);

    if (requestedSoftware.length) {
      await networkClient({
        method: methodTypes.post,
        endpoint: networkEndpoints.userSoftware.sendMultipleAssignEmail,
        requestBody: { userSoftwareIds: requestedSoftware },
      });
    }
    if (deleteSoftwares.length) {
      await networkClient({
        method: methodTypes.delete,
        endpoint: networkEndpoints.userSoftware.deleteSoftware,
        requestBody: { userSoftwareIds: deleteSoftwares },
      });
    }

    const response = await networkClient({
      method: methodTypes.patch,
      endpoint: endpointParamsSetter({
        path: networkEndpoints.user.approve,
        params: userId,
      }),
    });

    if (response.error) {
      if (response.errors[0].code === 403) {
        handle403Error(navigate, location);
      }
      showSnackbar(
        approveEmployeeStrings.errorMessage,
        snackbarStrings.variant.error
      );
      setOpenSuccessButtonLoading(false);
    } else {
      setOpenSuccesModal(true);
      setOpenSuccessButtonLoading(false);
    }
    // comment below code for checking On-boarding Employee Request page of any user with any status
  };
  const fetchUserData = useCallback(async () => {
    // check if logged in user is owner
    // if not then redirect to employee details page with snackbar error message
    const loggedInUser = getLoggedInUser(navigate, location);

    if (loggedInUser.role !== 1) {
      showSnackbar(
        approveEmployeeStrings.notAuthorized,
        snackbarStrings.variant.error
      );
      navigate(
        endpointParamsSetter({
          path: redirectPaths.employeeDetailsPath.to,
          params: userId,
        }),
        { replace: true }
      );
      return;
    }

    const userDataResponse = await networkClient({
      method: methodTypes.get,
      endpoint: endpointParamsSetter({
        path: networkEndpoints.user.users,
        params: userId,
      }),
    });
    const userSoftwareDataResponse = await networkClient({
      method: methodTypes.get,
      endpoint: endpointParamsSetter({
        path: networkEndpoints.user.userSoftwareDetails,
        params: userId,
      }),
    });
    setUserDataLoading(false);
    if (userDataResponse.error || userSoftwareDataResponse.error) {
      if (userDataResponse.error) {
        if (userDataResponse.errors[0].code === 403) {
          handle403Error(navigate, location);
        } else if (userDataResponse.errors[0].code === 404) {
          setIs404Error(true);
        }
      }
      if (userSoftwareDataResponse.error) {
        if (userSoftwareDataResponse.errors[0].code === 403) {
          handle403Error(navigate, location);
        } else if (userSoftwareDataResponse.errors[0].code === 404) {
          setIs404Error(true);
        }
      }
      setUserData(null);
      setUserSoftwareData(null);
      setIsError(true);
    } else {
      const selectedSoftwares = userSoftwareDataResponse.data.map(
        (software) => software.id
      );
      setUserData(userDataResponse.data);
      setUserSoftwareData(userSoftwareDataResponse.data);
      setRequestedSoftware(selectedSoftwares);
      setIsError(false);
    }
    // comment below code for checking On-boarding Employee Request page of any user with any status
    // if users status is active then it will be redirected to the employee detail page
    if (
      userDataResponse.data.status !== employeeAprrovalString.status.pending
    ) {
      navigate(
        endpointParamsSetter({
          path: redirectPaths.employeeDetailsPath.to,
          params: userId,
        }),
        { replace: true }
      );
      return;
    }

    //  if users status is OffBoarding then it will be redirected to the pagenot found
    if (
      userDataResponse.data.status === employeeAprrovalString.status.OffBoarding
    ) {
      navigate(redirectPaths.pageNotFoundPath.from, { replace: true });
    }
  }, [userId, navigate, location, showSnackbar]);

  useEffect(() => {
    fetchUserData();
  }, [fetchUserData]);
  if (!is404Error) {
    return (
      <>
        {isUserDataLoading && (
          <Box
            height='100vh'
            display='flex'
            flex={1}
            alignItems='center'
            justifyContent='center'>
            <CircularProgressIndicator />
          </Box>
        )}
        {!isUserDataLoading && isError && (
          <Box
            height='100vh'
            display='flex'
            flex={1}
            alignItems='center'
            justifyContent='center'>
            <ApiErrorComponent onRefreshClick={() => fetchUserData()} />
          </Box>
        )}
        {!isUserDataLoading && !isError && (
          <Box display='flex' flexDirection='row'>
            <Box flex={1} padding={commonPagePaddings.ApprovalPagePading}>
              <Box bgcolor={theme.palette.grey[25]}>
                <Stack
                  direction='row'
                  alignItems='center'
                  marginLeft={approveEmployeeMargins.titleMarginLeft}>
                  <Typography
                    variant='h5'
                    fontWeight='700'
                    color={theme.palette.grey[1100]}>
                    {approveEmployeeStrings.title}
                  </Typography>
                  <Stack
                    direction='row-reverse'
                    gap={approveEmployeeMargins.gapBetweenButtons}
                    margin={approveEmployeeMargins.BoxToButton}
                    flexGrow={1}>
                    <CustomFilledIconButton
                      text={approveEmployeeStrings.successButtonText}
                      onClick={handleApproveButtonClick}
                      isLoading={onSuccessButtonLoading}
                    />
                    <CustomOutlinedIconButton onClick={backToEmployee}>
                      {approveEmployeeStrings.backButtonText}
                    </CustomOutlinedIconButton>
                  </Stack>
                </Stack>
                <Box display='flex' flexDirection='column'>
                  <CustomDetailsBox
                    title={
                      employeeDetailsPageStrings.profileTab.basicDetailsTitle
                    }>
                    <BasicDetails data={userData} />
                  </CustomDetailsBox>
                  <CustomDetailsBox
                    title={
                      employeeDetailsPageStrings.profileTab.jobDetailstitle
                    }>
                    <JobDetails data={userData} />
                  </CustomDetailsBox>
                </Box>
              </Box>
            </Box>

            <Box
              margin={approveEmployeeMargins.boxMargin}
              height='fit-content'
              width={approveEmployeeSize.RequiresoftwareBoxWidth}
              flexDirection='column'
              sx={{ backgroundColor: theme.palette.background.paper }}>
              <Stack
                width={approveEmployeeSize.RequiresoftwareStackWidth}
                bgcolor={theme.palette.grey[25]}>
                <Typography
                  variant='h6'
                  fontWeight='700'
                  textAlign='left'
                  m={approveEmployeeMargins.RequiredSoftwareTitle}
                  color={theme.palette.grey[1000]}>
                  {approveEmployeeStrings.requireSoftwareText}
                </Typography>
              </Stack>

              {userSoftwareData.length === 0 && (
                <Box
                  height={
                    parseInt(approveEmployeeSize.RequiresoftwareBoxHeight, 10) -
                    parseInt(approveEmployeeSize.RequiresoftwareStackHeight, 10)
                  }
                  display='flex'
                  alignItems='center'
                  justifyContent='center'>
                  <Typography variant='body1' align='center'>
                    {approveEmployeeStrings.noSoftwareText}
                  </Typography>
                </Box>
              )}
              <RequestedSoftwares
                userSoftwares={userSoftwareData}
                requestedSoftware={requestedSoftware}
                setRequestedSoftware={setRequestedSoftware}
              />
            </Box>
          </Box>
        )}
        <CustomModal
          open={openSuccesModal}
          successButtonText={approveEmployeeStrings.openModal.buttonText}
          onSuccessButtonClick={() =>
            navigate(
              endpointParamsSetter({
                path: redirectPaths.employeeDetailsPath.to,
                params: userId,
              }),
              { replace: true }
            )
          }>
          <Stack justifyContent='center' alignItems='center'>
            <SuccessSVG />
            <Box height={approveEmployeeSize.spaceBelowSVG} />
            <Typography variant='h5'>
              {approveEmployeeStrings.openModal.successMessage}
            </Typography>
          </Stack>
        </CustomModal>
      </>
    );
  }
  if (is404Error) {
    return (
      <ErrorMessagePage
        title={approveEmployeeStrings.error404Text.title}
        subtitle={approveEmployeeStrings.error404Text.subtitle}
      />
    );
  }
}

export default ApproveEmployee;
