import { Logout } from '@mui/icons-material';
import {
  Box,
  Menu,
  MenuItem,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import networkClient from '../../networks/networkClient';
import {
  methodTypes,
  networkEndpoints,
  tokenStrings,
} from '../../networks/networkStrings';
import { navbarProfileMenuMargins } from '../../utils/dimensions/margins';
import { profileMenuPaddings } from '../../utils/dimensions/paddings';
import { navbarProfileMenuSize } from '../../utils/dimensions/size';
import pathStrings from '../../utils/pathStrings';
import {
  employeeDetailsPageStrings,
  navbarProfileMenuStrings,
} from '../../utils/strings';
import CustomAvatar from '../CustomAvatar';
import CustomFilledIconButton from '../CustomFilledIconButton';
import CustomModal from '../CustomModal';
import getLoggedInUser from '../../utils/getLoggedInUser';
import endpointParamsSetter from '../../utils/endpointParamsSetter';

function NavbarProfileMenu({ anchorEl, setAnchorEl, picture, email, name }) {
  const theme = useTheme();
  const [logoutModal, setLogoutModal] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);

  const { userId: loginUserId } = getLoggedInUser(navigate, location);

  const handleLogout = async () => {
    setIsLoading(true);
    await networkClient({
      method: methodTypes.delete,
      endpoint: networkEndpoints.user.logout,
    });
    setIsLoading(false);
    localStorage.removeItem(tokenStrings.accessToken);
    localStorage.removeItem(tokenStrings.user);
    localStorage.removeItem(tokenStrings.expiresAt);
    navigate(pathStrings.signInPagePath);
  };
  const handleclose = () => setAnchorEl(null);

  const showProfilePage = () => {
    handleclose();
    navigate(
      endpointParamsSetter({
        path: `${pathStrings.employeeDetailsPagePath}/#${employeeDetailsPageStrings.pageTabs.profile}`,
        params: loginUserId,
      })
    );
  };

  return (
    <Box>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleclose}
        style={{
          marginTop: navbarProfileMenuMargins.menuBoxMarginTop,
          marginLeft: navbarProfileMenuMargins.menuBoxMarginLeft,
        }}
        slotProps={{
          backdrop: {
            style: {
              background: 'transparent',
            },
          },
        }}>
        <Typography component='span' onClick={showProfilePage}>
          <MenuItem
            sx={{
              cursor: 'pointer',
            }}>
            <Stack direction='row' spacing={1}>
              <CustomAvatar
                name={name}
                src={picture}
                height={navbarProfileMenuSize.avatarHeight}
                width={navbarProfileMenuSize.avatarWidth}
              />
              <Stack>
                <Typography
                  variant='body1Meduim'
                  color={theme.palette.grey[1100]}>
                  {name}
                </Typography>
                <Typography variant='caption' color={theme.palette.grey[400]}>
                  {email}
                </Typography>
              </Stack>
            </Stack>
          </MenuItem>
        </Typography>
        <MenuItem
          disableRipple
          display='flex'
          sx={{
            justifyContent: 'center',
            '&:hover': {
              backgroundColor: theme.palette.common.white,
            },
          }}>
          <CustomFilledIconButton
            onClick={() => setLogoutModal(true)}
            text={navbarProfileMenuStrings.logoutButtonText}
            icon={<Logout />}
            width={navbarProfileMenuSize.logoutButtomWidth}
          />
        </MenuItem>
      </Menu>

      <CustomModal
        open={logoutModal}
        successButtonLoading={isLoading}
        onClose={() => setLogoutModal(false)}
        successButtonText={navbarProfileMenuStrings.logoutButtonText}
        failureButtonText={navbarProfileMenuStrings.cancelButtonText}
        onFailureButtonClick={() => setLogoutModal(false)}
        onSuccessButtonClick={() => handleLogout()}
        padding={profileMenuPaddings.modalPadding}>
        <Typography variant='h5' width={navbarProfileMenuSize.logoutTextWidth}>
          {navbarProfileMenuStrings.logoutText}
        </Typography>
      </CustomModal>
    </Box>
  );
}

NavbarProfileMenu.propTypes = {
  anchorEl: PropTypes.shape({}),
  setAnchorEl: PropTypes.func.isRequired,
  picture: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

NavbarProfileMenu.defaultProps = {
  anchorEl: null,
};

export default NavbarProfileMenu;
