import { Edit } from '@mui/icons-material';
import { Box, Stack, Typography, useTheme } from '@mui/material';
import { useState } from 'react';
import DetailsCard from '../../components/DetailsCard';
import AddProjectDrawer from '../../components/ProjectActions/AddProjectDrawer';
import CustomTable from '../../components/Table/CustomTable';
import { commonPagePaddings } from '../../utils/dimensions/paddings';
import { detailsPageSize } from '../../utils/dimensions/size';
import {
  addProjectStrings,
  projectDetailsPageStrings,
} from '../../utils/strings';
import ProjectDetailContent from './ProjectDetailContent';
import { headCells, rows, projectDetail } from './ProjectDetailsTableData';

// this file will be used for rendering project details.
function ProjectsDetailsPage() {
  const [toggleDrawer, setToggleDrawer] = useState(false);
  const theme = useTheme();

  return (
    <div>
      <Box>
        <Stack
          direction='row'
          justifyContent='space-between'
          padding={commonPagePaddings.pagePadding}
          sx={{
            backgroundColor: theme.palette.common.white,
          }}>
          <Stack>
            <Typography variant='h4' color={theme.palette.grey[1100]}>
              {projectDetailsPageStrings.pageTitleText}
            </Typography>
            <Box height={detailsPageSize.spaceBetweenTitleAndSubtitle} />
            <Typography variant='body1' color={theme.palette.grey[300]}>
              {projectDetailsPageStrings.pageSubtitleText}
            </Typography>
          </Stack>
        </Stack>

        <Box
          padding={commonPagePaddings.pagePadding}
          flexDirection='row'
          display='flex'>
          <Box flex={3}>
            <CustomTable
              tableData={rows}
              tableHeadData={headCells}
              pagination
            />
          </Box>
          <Box width={detailsPageSize.spaceBetweenCards} />
          <Box flex={1}>
            <DetailsCard
              icon={<Edit />}
              title={projectDetailsPageStrings.detailsTitleText}
              buttonText={projectDetailsPageStrings.editButtonText}
              onClick={() => {
                setToggleDrawer(true);
              }}>
              <ProjectDetailContent projectDetails={projectDetail} />
            </DetailsCard>
          </Box>
        </Box>
      </Box>
      <AddProjectDrawer
        toggleDrawer={toggleDrawer}
        setToggleDrawer={setToggleDrawer}
        isEdit
        title={addProjectStrings.editTitle}
      />
    </div>
  );
}

export default ProjectsDetailsPage;
