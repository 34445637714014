import PropTypes from 'prop-types';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Typography } from '@mui/material';
import FormGroup from '@mui/material/FormGroup';
import FormControl from '@mui/material/FormControl';

export default function CustomCheckBox({
  checkBoxOption,
  onChange,
  checkedValue,
}) {
  const handleChange = (event) => {
    if (onChange) {
      onChange(event.target.value);
    }
  };

  return (
    <FormControl component='fieldset' onChange={handleChange}>
      <FormGroup aria-label='position'>
        {checkBoxOption.map((LabelValue) => (
          <FormControlLabel
            key={LabelValue}
            value={LabelValue}
            control={
              <Checkbox
                checked={checkedValue.includes(LabelValue)}
                onChange={() => onChange(LabelValue)}
              />
            }
            label={
              <Typography variant='body2' fontWeight={400}>
                {LabelValue}
              </Typography>
            }
            labelPlacement='start'
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          />
        ))}
      </FormGroup>
    </FormControl>
  );
}

CustomCheckBox.propTypes = {
  checkBoxOption: PropTypes.arrayOf(PropTypes.string).isRequired,
  checkedValue: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
};
CustomCheckBox.defaultProps = {
  checkedValue: null,
  onChange: null,
};
