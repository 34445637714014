import { DeleteOutline, Edit } from '@mui/icons-material';
import { Box, Stack, Typography, useTheme } from '@mui/material';
import { useState } from 'react';
import CustomFilledIconButton from '../../components/CustomFilledIconButton';
import DetailsCard from '../../components/DetailsCard';
import AddHardwareDrawer from '../../components/HardwareActions/AddHardwareDrawer';
import DeleteHardwareDrawer from '../../components/HardwareActions/DeleteHardwareDrawer';
import CustomTable from '../../components/Table/CustomTable';
import { commonPagePaddings } from '../../utils/dimensions/paddings';
import { detailsPageSize } from '../../utils/dimensions/size';
import {
  addHardwareStrings,
  hardwareDetailsPageStrings,
} from '../../utils/strings';
import HardwareDetailContent from './HardwareDetailContent';
import { hardwareDetail, headCells, rows } from './HardwareDetailsTableData';

function HardwareDetailsPage() {
  const [deleteDrawerToggle, setDeleteDrawerToggle] = useState(false);
  const [toggleDrawer, setToggleDrawer] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const theme = useTheme();

  return (
    <div>
      <Box>
        <Stack
          direction='row'
          justifyContent='space-between'
          padding={commonPagePaddings.pagePadding}
          sx={{
            backgroundColor: theme.palette.common.white,
          }}>
          <Stack>
            <Typography variant='h4' color={theme.palette.grey[1100]}>
              {hardwareDetailsPageStrings.pageTitleText}
            </Typography>
            <Box height={detailsPageSize.spaceBetweenTitleAndSubtitle} />
            <Typography variant='body1' color={theme.palette.grey[300]}>
              {hardwareDetailsPageStrings.pageSubtitleText}
            </Typography>
          </Stack>
          <CustomFilledIconButton
            text={hardwareDetailsPageStrings.deleteButtonText}
            onClick={() => {
              setDeleteDrawerToggle(true);
            }}
            icon={<DeleteOutline />}
          />
        </Stack>

        <Box
          padding={commonPagePaddings.pagePadding}
          flexDirection='row'
          display='flex'>
          <Box flex={3}>
            <CustomTable
              tableData={rows}
              tableHeadData={headCells}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              pagination
            />
          </Box>
          <Box width={detailsPageSize.spaceBetweenCards} />
          <Box flex={1}>
            <DetailsCard
              title={hardwareDetailsPageStrings.detailsTitleText}
              buttonText={hardwareDetailsPageStrings.editButtonText}
              icon={<Edit />}
              onClick={() => {
                setToggleDrawer(true);
              }}>
              <HardwareDetailContent hardwareDetails={hardwareDetail} />
            </DetailsCard>
          </Box>
        </Box>
      </Box>
      <AddHardwareDrawer
        title={addHardwareStrings.editTitle}
        // send data if edit hardware
        data={addHardwareStrings.sampleData}
        toggleDrawer={toggleDrawer}
        setToggleDrawer={setToggleDrawer}
        isEdit
      />
      <DeleteHardwareDrawer
        toggleDrawer={deleteDrawerToggle}
        setToggleDrawer={setDeleteDrawerToggle}
      />
    </div>
  );
}

export default HardwareDetailsPage;
