import { Box, TextField, Stack } from '@mui/material';
import PropTypes from 'prop-types';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useState } from 'react';
import { addHardwareMargins } from '../../utils/dimensions/margins';
import { addHardwareStrings } from '../../utils/strings';
import CustomDrawer from '../CustomDrawer';
import CustomTextField from '../Inputs/CustomTextField';

function AddHardwareDrawer({
  title,
  toggleDrawer,
  setToggleDrawer,
  isEdit,

  data,
}) {
  const [category, setCategory] = useState();
  const [dateOfPurchase, setDateOfPurchase] = useState(new Date());
  const hanldeValuechange = (e, setFunction) => {
    setFunction(e.target.value);
  };

  return (
    <CustomDrawer
      title={title}
      open={toggleDrawer}
      onFailureButtonClick={() => {
        setToggleDrawer(false);
      }}
      onSuccessButtonClick={() => {}}
      onClose={() => {
        setToggleDrawer(false);
      }}
      successButtonText={addHardwareStrings.successButtonText}>
      <Box marginX={addHardwareMargins.marginX}>
        <Stack marginY={addHardwareMargins.marginY}>
          <CustomTextField
            placeHolder={addHardwareStrings.placeHolder.category}
            onChange={(e) => hanldeValuechange(e, setCategory)}
            select
            value={category}
            menuItem={addHardwareStrings.deviceCategoryMenuItem}
          />
          <Stack direction='row'>
            <CustomTextField
              onChange={() => {}}
              value={data.serialKey}
              placeHolder={addHardwareStrings.placeHolder.serialKey}
              disabled={isEdit}
            />

            <CustomTextField
              onChange={() => {}}
              value={data.modelNo}
              placeHolder={addHardwareStrings.placeHolder.modelNo}
            />
          </Stack>
          <CustomTextField
            onChange={() => {}}
            value={data.name}
            placeHolder={addHardwareStrings.placeHolder.name}
          />
          <CustomTextField
            onChange={() => {}}
            value={data.vendorName}
            placeHolder={addHardwareStrings.placeHolder.vendorName}
          />
          <Stack direction='row'>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                label={addHardwareStrings.placeHolder.dateOfPurchase}
                value={dateOfPurchase}
                onChange={(newValue) => setDateOfPurchase(newValue)}
                renderInput={(params) => (
                  <TextField
                    sx={{
                      marginTop: addHardwareMargins.DateOfPurchaseMarginTop,
                      marginRight: addHardwareMargins.DateOfPurchaseMarginRigth,
                    }}
                    {...params}
                  />
                )}
              />
            </LocalizationProvider>
            <CustomTextField
              onChange={() => {}}
              value={data.location}
              placeHolder={addHardwareStrings.placeHolder.location}
            />
          </Stack>
          <CustomTextField
            onChange={() => {}}
            value={data.description}
            placeHolder={addHardwareStrings.placeHolder.description}
            multiline
            rows={4}
          />
        </Stack>
      </Box>
    </CustomDrawer>
  );
}

AddHardwareDrawer.propTypes = {
  title: PropTypes.string.isRequired,
  isEdit: PropTypes.bool,
  toggleDrawer: PropTypes.bool.isRequired,
  setToggleDrawer: PropTypes.func.isRequired,
  data: PropTypes.shape({
    category: PropTypes.string,
    serialKey: PropTypes.string,
    modelNo: PropTypes.string,
    name: PropTypes.string,
    vendorName: PropTypes.string,
    dateOfPurchase: PropTypes.string,
    location: PropTypes.string,
    description: PropTypes.string,
  }),
};

AddHardwareDrawer.defaultProps = {
  data: addHardwareStrings.intialData,
  isEdit: false,
};

export default AddHardwareDrawer;
