import moment from 'moment';
import axiosInstance from './axiosInstance';
import { errorCodes, methodTypes, tokenStrings } from './networkStrings';
import { decryptMessage } from '../utils/encyption/encryptionMethods';
import refreshToken from './refreshToken';

const axios = axiosInstance();

// use this to make every request
export default async function networkClient({
  method,
  endpoint,
  requestBody,
  queryParams,
}) {
  let response = { data: {} };
  try {
    const expiresAt = decryptMessage(
      localStorage.getItem(tokenStrings.expiresAt)
    );
    if (
      localStorage.getItem(tokenStrings.accessToken) &&
      expiresAt &&
      !moment().isBefore(moment(expiresAt))
    ) {
      await refreshToken();
    }

    switch (method) {
      case methodTypes.get: {
        response = await axios.get(endpoint, { params: queryParams });
        break;
      }
      case methodTypes.post: {
        response = await axios.post(endpoint, requestBody);
        break;
      }
      case methodTypes.patch: {
        response = await axios.patch(endpoint, requestBody);
        break;
      }
      case methodTypes.delete: {
        response = await axios.delete(endpoint, {
          params: queryParams,
          data: requestBody,
        });
        break;
      }
      default: {
        response = {
          data: {
            error: true,
            errors: [
              {
                code: 400,
                type: 'Bad Request',
                message: `Invalid method '${method}'`,
                field: 'method',
              },
            ],
          },
        };
      }
    }
  } catch (err) {
    try {
      if (!err.response.data.errors) {
        if (err.code === errorCodes.badRequestError) {
          return {
            error: true,
            errors: [
              {
                code: 400,
                type: 'BadRequestError',
                message: `${method.toUpperCase()} '${endpoint}' not found.`,
                field: 'Endpoint',
              },
            ],
          };
        }
        if (err.code === errorCodes.badResponseError) {
          return {
            error: true,
            errors: [
              {
                code: 500,
                type: 'InternalServerError',
                message: `Something went wrong.`,
                field: null,
              },
            ],
          };
        }
      }
      return {
        error: true,
        errors: err.response.data.errors,
      };
    } catch (error) {
      // invalid parameters passed to networkClient
      return {
        error: true,
        errors: [
          {
            code: 500,
            type: 'AxiosError',
            message: `Something Went Wrong.`,
          },
        ],
      };
    }
  }
  // return response
  return response.data;
}
