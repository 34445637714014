import {
  Box,
  Table,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useTheme,
  Stack,
} from '@mui/material';
import PropTypes from 'prop-types';
import CustomListExpand from './CustomListExpand';
import { employeeDetailsPageStrings } from '../utils/strings';
import CustomTableErrorMessage from './Table/CustomTableErrorMessage';

export default function CustomListExpandContainer({
  tableData,
  tableHeadData,
  setIsError,
  setTableData,
  isSoftwaresLoading,
  fetchAllSoftwares,
  setHistoryData,
}) {
  const theme = useTheme();

  return (
    <Box>
      <Table
        sx={{
          borderTop: `1px solid ${theme.palette.grey[50]}`,
          borderLeft: `1px solid ${theme.palette.grey[50]}`,
          borderRight: `1px solid ${theme.palette.grey[50]}`,
          borderBottom:
            !tableData || tableData.length <= 0
              ? `1px solid ${theme.palette.grey[50]}`
              : 'none',
        }}>
        <TableHead>
          <TableRow
            sx={{
              backgroundColor: theme.palette.grey[25],
              display: 'flex',
            }}>
            {tableHeadData.map((data) => (
              <TableCell
                sx={{
                  flex: data.flex,
                }}
                key={data.id}>
                <Typography
                  color={theme.palette.grey[400]}
                  variant='tableHeader'>
                  {data.label}
                </Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        {(!tableData || tableData.length <= 0) && (
          <CustomTableErrorMessage
            message={
              employeeDetailsPageStrings.softwareTabStrings.emptyTableMessage
            }
            isLoading={isSoftwaresLoading}
            isError={!tableData}
            onTryAgainClick={fetchAllSoftwares}
          />
        )}
      </Table>

      {tableData && tableData.length > 0 && (
        <Stack
          sx={{
            borderBottom: `1px solid ${theme.palette.grey[50]}`,
            borderLeft: `1px solid ${theme.palette.grey[50]}`,
            borderRight: `1px solid ${theme.palette.grey[50]}`,
          }}>
          <CustomListExpand
            tableData={tableData}
            setIsError={setIsError}
            setTableData={setTableData}
            setHistoryData={setHistoryData}
          />
        </Stack>
      )}
    </Box>
  );
}

CustomListExpandContainer.propTypes = {
  tableHeadData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      dataType: PropTypes.string,
      label: PropTypes.string,
      flex: PropTypes.number,
      sortingNeeded: PropTypes.bool,
    })
  ).isRequired,
  tableData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      image: PropTypes.string,
      name: PropTypes.string,
      client: PropTypes.string,
      status: PropTypes.string,
    })
  ),
  setIsError: PropTypes.func.isRequired,
  setTableData: PropTypes.func.isRequired,
  fetchAllSoftwares: PropTypes.func.isRequired,
  isSoftwaresLoading: PropTypes.bool.isRequired,
  setHistoryData: PropTypes.func.isRequired,
};

CustomListExpandContainer.defaultProps = {
  tableData: [],
};
