import { Navigate, Route, Routes } from 'react-router-dom';
import navbarTabsRouterPaths from './NavbarTabsRouterPaths';
import AppLayout from '../pages/AppLayout';
import { routerPaths, detailsPagesPaths } from './RoutesPath';
import redirectPaths from './RedirectPaths';

function Router() {
  return (
    <div>
      <Routes>
        {/* Nested Routing is used here, AppLayout is root component for this nested routing */}
        <Route path='/' element={<AppLayout />}>
          {/* uses at / path will get redirected tp /employee page */}
          <Route
            path={redirectPaths.homePath.from}
            element={<Navigate to={redirectPaths.homePath.to} />}
          />

          {
            // maps each path from tabsRouterPaths to Router.
            navbarTabsRouterPaths.map((routeObject) => (
              <Route
                key={routeObject.path}
                path={routeObject.path}
                element={routeObject.component}
              />
            ))
          }
          {
            // maps each path from tabsRouterPaths to Router.
            detailsPagesPaths.map((routeObject) => (
              <Route
                key={routeObject.path}
                path={routeObject.path}
                element={routeObject.component}
              />
            ))
          }
          <Route
            path={routerPaths.userSettingsPath.path}
            element={routerPaths.userSettingsPath.component}
          />
          <Route
            path={routerPaths.ApproveEmployeePagePath.path}
            element={routerPaths.ApproveEmployeePagePath.component}
          />
        </Route>

        <Route
          path={routerPaths.signInPagePath.path}
          element={routerPaths.signInPagePath.component}
        />

        {/* routes not defined in router will get redirect to component given below */}
        <Route
          path={redirectPaths.pageNotFoundPath.from}
          element={routerPaths.pageNotFoundPath.component}
        />
      </Routes>
    </div>
  );
}

export default Router;
