import { useContext } from 'react';
import PropTypes from 'prop-types';
import { PlaylistAddCheck } from '@mui/icons-material';
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Stack,
  Typography,
  useTheme,
  Container,
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  employeeDetailsPageStrings,
  snackbarStrings,
} from '../../utils/strings';
import { employeeDetailsPagePaddings } from '../../utils/dimensions/paddings';
import { employeeDetailsPageSize } from '../../utils/dimensions/size';
import { profileChecklistMargins } from '../../utils/dimensions/margins';
import networkClient from '../../networks/networkClient';
import { methodTypes, networkEndpoints } from '../../networks/networkStrings';
import ApiErrorComponent from '../../components/ApiErrorComponent';
import CircularProgressIndicator from '../../components/CircularProgressIndicator';
import { handle403Error } from '../../utils/errorHandler';
import endpointParamsSetter from '../../utils/endpointParamsSetter';
import { snackbarContext } from '../../utils/context';

function ProfileChecklist({
  userId,
  checkListItems,
  isChecklistError,
  isChecklistLoading,
  isChecklistChecked,
  setIsChecklistChecked,
  fetchCheckListValues,
}) {
  const theme = useTheme();

  const location = useLocation();
  const navigate = useNavigate();
  const { showSnackbar } = useContext(snackbarContext);

  const apiHandler = async (userCheckListsId, event, oldState) => {
    const response = await networkClient({
      method: methodTypes.patch,
      endpoint: endpointParamsSetter({
        path: networkEndpoints.user.checklist,
        params: userId,
      }),
      requestBody: {
        checklistId: userCheckListsId,
        checklistValue: event,
      },
    });

    if (response.error) {
      if (response.errors[0].code === 403) {
        handle403Error(navigate, location);
      }
      setIsChecklistChecked(oldState);
      showSnackbar(
        employeeDetailsPageStrings.checkListUpdateFailMessage,
        snackbarStrings.variant.error
      );
    }
  };

  const handleCheckboxChange =
    (checkListItemId, userCheckListsId) => (event) => {
      const oldState = { ...isChecklistChecked };
      const updatedState = { ...isChecklistChecked };
      updatedState[checkListItemId] = event.target.checked;
      setIsChecklistChecked(updatedState);
      apiHandler(userCheckListsId, event.target.checked, oldState);
    };

  return (
    <Box
      display='flex'
      flexDirection='column'
      sx={{
        backgroundColor: theme.palette.background.paper,
      }}>
      <Stack
        padding={employeeDetailsPagePaddings.asidePadding}
        flexDirection='row'
        alignItems='center'
        gap={employeeDetailsPageSize.verticalCommonSpace}
        bgcolor={theme.palette.grey[25]}>
        <PlaylistAddCheck />
        <Typography variant='h6' color={theme.palette.grey[1000]}>
          {employeeDetailsPageStrings.checklistCardTitle}
        </Typography>
      </Stack>

      <Box padding={employeeDetailsPagePaddings.asidePadding}>
        {!isChecklistLoading && !isChecklistError && isChecklistChecked && (
          <FormControl component='fieldset'>
            <FormGroup aria-label='position' column='true'>
              {checkListItems.length === 0 ? (
                <Container
                  sx={{
                    display: 'flex',
                    flex: 1,
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}>
                  <Typography
                    sx={{
                      textAlign: 'center',
                    }}>
                    No CheckList
                  </Typography>
                </Container>
              ) : (
                checkListItems
                  .sort((a, b) => a.id - b.id)
                  .map((checkListItem) => (
                    <div
                      key={checkListItem.id}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        cursor: 'default',
                      }}>
                      <FormControlLabel
                        key={checkListItem.id}
                        value='end'
                        sx={{ pointerEvents: 'none' }}
                        control={
                          <Checkbox
                            checked={isChecklistChecked[checkListItem.id]}
                            onChange={(event) => {
                              handleCheckboxChange(
                                checkListItem.id,
                                checkListItem.UserCheckLists.id
                              )(event);
                            }}
                            color='secondary'
                            sx={{
                              color: theme.palette.grey[1100],
                              cursor: 'pointer',
                              pointerEvents: 'auto',
                            }}
                          />
                        }
                        labelPlacement='end'
                        label={
                          <Stack>
                            <Typography
                              marginTop={
                                profileChecklistMargins.marginBetweenText
                              }
                              style={
                                isChecklistChecked[checkListItem.id]
                                  ? { textDecoration: 'line-through' }
                                  : null
                              }>
                              <span
                                style={{
                                  cursor: 'pointer',
                                  pointerEvents: 'auto',
                                }}>
                                {checkListItem.checklist_title
                                  .split('(')[0]
                                  .trim()}
                              </span>
                            </Typography>

                            {checkListItem.checklist_title.includes('(') && (
                              <Typography
                                variant='caption'
                                color={theme.palette.grey[300]}>
                                {checkListItem.checklist_title
                                  .split('(')[1]
                                  .replace(')', '')
                                  .trim()}
                              </Typography>
                            )}
                          </Stack>
                        }
                      />
                    </div>
                  ))
              )}
            </FormGroup>
          </FormControl>
        )}
        {isChecklistLoading && (
          <Box
            display='flex'
            flex={1}
            alignItems='center'
            justifyContent='center'>
            <CircularProgressIndicator />
          </Box>
        )}
        {isChecklistError && (
          <Box
            display='flex'
            flex={1}
            alignItems='center'
            justifyContent='center'>
            <ApiErrorComponent onRefreshClick={() => fetchCheckListValues()} />
          </Box>
        )}
      </Box>
    </Box>
  );
}
ProfileChecklist.propTypes = {
  userId: PropTypes.string.isRequired,
  isChecklistChecked: PropTypes.shape({}),
  isChecklistError: PropTypes.bool.isRequired,
  isChecklistLoading: PropTypes.bool.isRequired,
  setIsChecklistChecked: PropTypes.func.isRequired,
  checkListItems: PropTypes.arrayOf(PropTypes.shape).isRequired,
  fetchCheckListValues: PropTypes.func.isRequired,
};

ProfileChecklist.defaultProps = {
  isChecklistChecked: null,
};

export default ProfileChecklist;
