import PropTypes from 'prop-types';
import { useState } from 'react';
import { Box, Stack, Typography, useTheme } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import CustomTextField from '../Inputs/CustomTextField';
import CustomDrawer from '../CustomDrawer';
import { deleteSoftwareMargins } from '../../utils/dimensions/margins';
import {
  deleteSoftwareStrings,
  softwareDetailsPageStrings,
} from '../../utils/strings';
import { deleteSoftwareSize } from '../../utils/dimensions/size';
import { deleteSoftwareDetailsPadding } from '../../utils/dimensions/paddings';
import networkClient from '../../networks/networkClient';
import { methodTypes, networkEndpoints } from '../../networks/networkStrings';
import endpointParamsSetter from '../../utils/endpointParamsSetter';
import { handle403Error } from '../../utils/errorHandler';

export default function DeleteSoftwareDrawer({
  toggleDeleteDrawer,
  setToggleDeleteDrawer,
  defaultData,
}) {
  const theme = useTheme();
  const descriptionInitialState = '';
  const [description, setDescription] = useState(descriptionInitialState);
  const [isError, setIsError] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const softwareId = 1;
  const status = 'active';
  const updatedStatus =
    status === softwareDetailsPageStrings.activeStatus
      ? softwareDetailsPageStrings.inactiveStatus
      : softwareDetailsPageStrings.activeStatus;
  const handleDeleteSoftware = async () => {
    const data = await networkClient({
      method: methodTypes.patch,
      endpoint: endpointParamsSetter({
        path: networkEndpoints.software.updateSoftware,
        params: softwareId,
      }),
      requestBody: {
        status: updatedStatus,
      },
    });

    if (data.error) {
      if (data.errors[0].code === 403) {
        handle403Error(navigate, location);
      }
      setIsError(true);
    } else {
      setToggleDeleteDrawer(false);
      setDescription(descriptionInitialState);
    }
  };
  return (
    <div>
      <CustomDrawer
        open={toggleDeleteDrawer}
        title={deleteSoftwareStrings.deleteTitle}
        failureButtonText={deleteSoftwareStrings.cancelButtonText}
        onFailureButtonClick={() => {
          setToggleDeleteDrawer(false);
          setDescription(descriptionInitialState);
        }}
        onSuccessButtonClick={handleDeleteSoftware}
        error={isError}
        headerTailElement={
          <Stack direction='row' alignItems='center' spacing={1}>
            <Box
              sx={{
                padding: deleteSoftwareDetailsPadding.iconPadding,
                border: `1px solid ${theme.palette.grey[100]}`,
              }}>
              <img
                src={defaultData.softwareImageUrl}
                alt={defaultData.softwareImageUrl}
                width={deleteSoftwareSize.softwareImageSize}
                height={deleteSoftwareSize.softwareImageSize}
              />
            </Box>
            <Stack>
              <Typography variant='h5SemiBold' color={theme.palette.grey[1100]}>
                {defaultData.softwareName}
              </Typography>
              <Typography variant='body2' color={theme.palette.text.primary}>
                {defaultData.companyName}
              </Typography>
            </Stack>
          </Stack>
        }
        successButtonText={deleteSoftwareStrings.deleteButtonText}
        cancel={deleteSoftwareStrings.deleteButtonText}>
        <Box
          marginX={deleteSoftwareMargins.marginX}
          display='flex'
          flexDirection='column'>
          <Box height={deleteSoftwareSize.spaceBetweenTitleAndTextField} />

          <Stack>
            <CustomTextField
              onChange={(e) => {
                setDescription(e.target.value);
              }}
              value={description}
              placeHolder={deleteSoftwareStrings.placeHolder.note}
              multiline
              rows={3}
            />
          </Stack>
        </Box>
      </CustomDrawer>
    </div>
  );
}

DeleteSoftwareDrawer.propTypes = {
  toggleDeleteDrawer: PropTypes.bool.isRequired,
  setToggleDeleteDrawer: PropTypes.func.isRequired,
  defaultData: PropTypes.shape({
    softwareName: PropTypes.string,
    companyName: PropTypes.string,
    softwareImageUrl: PropTypes.string,
  }).isRequired,
};
