import { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Stack } from '@mui/material';
import { addProjectStrings } from '../../utils/strings';
import CustomDrawer from '../CustomDrawer';
import { addProjectMargins } from '../../utils/dimensions/margins';
import { addProjectSize } from '../../utils/dimensions/size';
import CustomTextField from '../Inputs/CustomTextField';

export default function AddProjectDrawer({
  toggleDrawer,
  setToggleDrawer,
  isEdit,
}) {
  const [manager, setManager] = useState();
  const [lead, setLead] = useState();
  const [type, setType] = useState();
  const hanldeValuechange = (e, setFunction) => {
    setFunction(e.target.value);
  };

  return (
    <CustomDrawer
      title={isEdit ? addProjectStrings.editTitle : addProjectStrings.addTitle}
      open={toggleDrawer}
      onFailureButtonClick={() => {
        setToggleDrawer(false);
      }}
      onSuccessButtonClick={() => {
        setToggleDrawer(false);
      }}
      onClose={() => {
        setToggleDrawer(false);
      }}
      successButtonText={
        isEdit
          ? addProjectStrings.editButtonText
          : addProjectStrings.submitButtonText
      }>
      <Box
        marginX={addProjectMargins.marginX}
        display='flex'
        flexDirection='column'>
        <Box height={addProjectSize.spaceBetweenTitleAndTextField} />

        <CustomTextField
          onChange={() => {}}
          placeHolder={addProjectStrings.placeHolder.name}
        />
        <Stack direction='row'>
          <CustomTextField
            endAdornment={addProjectStrings.durationTextFieldEndAdornment}
            onChange={() => {}}
            placeHolder={addProjectStrings.placeHolder.duration}
          />
          <CustomTextField
            value={type}
            select
            onChange={(e) => hanldeValuechange(e, setType)}
            menuItem={addProjectStrings.menuItem.type}
            placeHolder={addProjectStrings.placeHolder.type}
          />
        </Stack>
        <Stack direction='row'>
          <CustomTextField
            value={manager}
            select
            placeHolder={addProjectStrings.placeHolder.manager}
            menuItem={addProjectStrings.menuItem.manager}
            onChange={(e) => hanldeValuechange(e, setManager)}
          />
          <CustomTextField
            value={lead}
            select
            placeHolder={addProjectStrings.placeHolder.lead}
            menuItem={addProjectStrings.menuItem.lead}
            onChange={(e) => hanldeValuechange(e, setLead)}
          />
        </Stack>
        <CustomTextField
          placeHolder={addProjectStrings.placeHolder.description}
          multiline
          rows={5}
          onChange={() => {}}
        />
      </Box>
    </CustomDrawer>
  );
}

AddProjectDrawer.propTypes = {
  toggleDrawer: PropTypes.bool.isRequired,
  setToggleDrawer: PropTypes.func.isRequired,
  isEdit: PropTypes.bool,
};

AddProjectDrawer.defaultProps = {
  isEdit: false,
};
