import PropTypes from 'prop-types';
import { Stack, Typography, useTheme } from '@mui/material';

function ShowDetailsColumn({ flex, label, children }) {
  const theme = useTheme();
  return (
    <Stack flex={flex}>
      <Typography variant='caption' color={theme.palette.grey[300]}>
        {label}
      </Typography>
      {children}
    </Stack>
  );
}

ShowDetailsColumn.propTypes = {
  flex: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  label: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.shape(),
    PropTypes.number,
  ]).isRequired,
};

ShowDetailsColumn.defaultProps = {
  flex: '1',
};
export default ShowDetailsColumn;
