import EmployeeDetailsPage from '../pages/EmployeeDetailsPage/EmployeeDetailsPage';
import HardwareDetailsPage from '../pages/HardwareDetailsPage/HardwareDetailsPage';
import PageNotFoundPage from '../pages/ErrorMessageScreen/PageNotFoundPage';
import ProjectsDetailsPage from '../pages/ProjectsDetailsPage/ProjectsDetailsPage';
import SignIn from '../pages/SignIn/SignIn';
import SoftwareDetailsPage from '../pages/SoftwareDetailsPage/SoftwareDetailsPage';
import SystemUserPage from '../pages/SystemUserPage/SystemUserPage';
import ApproveEmployee from '../pages/Employees/ApproveEmployee';
import pathStrings from '../utils/pathStrings';

// list of routers
const routerPaths = {
  signInPagePath: {
    path: pathStrings.signInPagePath,
    component: <SignIn />,
  },
  userSettingsPath: {
    path: pathStrings.systemUsersPagePath,
    component: <SystemUserPage />,
  },
  pageNotFoundPath: {
    component: <PageNotFoundPage />,
  },
  ApproveEmployeePagePath: {
    path: pathStrings.ApproveEmployeePagePath,
    component: <ApproveEmployee />,
  },
};

const detailsPagesPaths = [
  {
    path: pathStrings.employeeDetailsPagePath,
    component: <EmployeeDetailsPage />,
  },
  {
    path: pathStrings.hardwareDetailsPagePath,
    component: <HardwareDetailsPage />,
  },
  {
    path: pathStrings.softwareDetailsPagePath,
    component: <SoftwareDetailsPage />,
  },
  {
    path: pathStrings.projectDetailsPagePath,
    component: <ProjectsDetailsPage />,
  },
];

export { routerPaths, detailsPagesPaths };
