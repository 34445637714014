import PropTypes from 'prop-types';
import { Circle } from '@mui/icons-material';
import { Box, Chip, Divider, Stack, Typography, useTheme } from '@mui/material';
import {
  employeeDetailsPagePaddings,
  historyDetailsPaddings,
} from '../../utils/dimensions/paddings';
import {
  employeePageSize,
  historyComponentSize,
} from '../../utils/dimensions/size';
import { historyDetailsMargins } from '../../utils/dimensions/margins';
import formatTime from '../../utils/formatDate';

function HistoryDetailsComponent({ data, isDrawer, dataLength }) {
  const theme = useTheme();
  function hasSameDate(timestamp1, timestamp2) {
    if (!timestamp1 || !timestamp2) return true;
    const firstDate = new Date(timestamp1);
    const secondDate = new Date(timestamp2);

    const formattedFirstDate = firstDate.toLocaleDateString('en-US');
    const formattedSecondDate = secondDate.toLocaleDateString('en-US');

    return formattedFirstDate === formattedSecondDate;
  }

  return (
    <Box
      padding={employeeDetailsPagePaddings.historyContentPadding}
      sx={{
        overflowY: 'scroll',
        '&::-webkit-scrollbar': {
          width: employeePageSize.historyScrollBarWidth,
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: theme.palette.grey[300],
          borderRadius: employeePageSize.historyScrollRadius,
          opacity: 0.7,
        },
      }}>
      {data.length === 0 ? (
        <Typography
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flex: 1,
          }}>
          No History{' '}
        </Typography>
      ) : (
        [...data]?.map((historyDetails, index) => (
          <Stack direction='row' key={JSON.stringify(historyDetails + index)}>
            <Box display='flex' flexDirection='column' alignItems='center'>
              <Circle
                sx={{
                  position: 'relative',
                  paddingTop:
                    isDrawer &&
                    index === 0 &&
                    historyDetailsPaddings.drawerPaddingTop,
                  height: historyComponentSize.customStepIconSize,
                  Width: historyComponentSize.customStepIconSize,
                  color: theme.palette.primary.main,
                }}
              />

              <Box
                flex='1'
                display='flex'
                paddingX={
                  employeeDetailsPagePaddings.historyComponentStepperIconPadding
                }>
                {index !== dataLength && (
                  <Divider
                    sx={{
                      height: historyComponentSize.dividerHeight,
                    }}
                    orientation='vertical'
                    flexItem
                  />
                )}
              </Box>
            </Box>
            <Stack direction='column' flex={1}>
              {isDrawer && index === 0 && (
                <Box
                  display='flex'
                  justifyContent='center'
                  marginY={historyDetailsMargins.dateMarginY}>
                  <Chip
                    label={
                      <Typography variant='tooltip' display='flex'>
                        {formatTime(historyDetails.timestamp)}
                      </Typography>
                    }
                  />
                </Box>
              )}
              <Box
                display='flex'
                flexDirection='column'
                width={isDrawer ? historyComponentSize.drawerWidth : 'auto'}
                paddingBottom={historyDetailsPaddings.descriptionPaddingTop}>
                <Typography
                  variant='body1'
                  marginTop={historyDetailsMargins.descriptionMarginTop} // to align text with dot
                  color={theme.palette.grey[1100]}>
                  {historyDetails.description}
                </Typography>

                <Typography
                  variant='badgeLabel'
                  color={theme.palette.grey[400]}
                  textTransform='none'>
                  {formatTime(historyDetails.timestamp, true)}
                </Typography>
              </Box>

              {!hasSameDate(
                data[index + 1]?.timestamp,
                historyDetails.timestamp
              ) && (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                  marginBottom={
                    isDrawer
                      ? historyDetailsMargins.dateLabelMarginBottomDrawer
                      : historyDetailsMargins.dateLabelMarginBottomTab
                  }
                  marginRight={
                    isDrawer
                      ? 'none'
                      : historyDetailsMargins.dateLabelMarginRightTab
                  }>
                  <Chip
                    label={
                      <Typography variant='tooltip' display='flex'>
                        {formatTime(data[index + 1]?.timestamp)}
                      </Typography>
                    }
                  />
                </Box>
              )}
            </Stack>
          </Stack>
        ))
      )}
    </Box>
  );
}

HistoryDetailsComponent.propTypes = {
  isDrawer: PropTypes.bool,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string.isRequired,
      timestamp: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
    })
  ).isRequired,
  dataLength: PropTypes.number.isRequired,
};

HistoryDetailsComponent.defaultProps = {
  isDrawer: false,
};

export default HistoryDetailsComponent;
