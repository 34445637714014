//  This file contains constant size values for Custom Components

// constant size values for customDrawer
const customDrawerSize = {
  drawerHeight: '100%',
  buttonSectionHeight: '10vh',
  titleAndChildrenHeight: '90vh',
  drawerMinWidth: '550px',
  drawerMaxWidth: '50vw',
  fullHeight: '100vh',
  spaceBetweenTitleAndSubTitle: '5px',
  spaceBetweenBottomButtons: '24px',
};
const SoftwareFilterSize = {
  listWidth: '100%',
  maxwidth: 300,
  minWidth: '55px',
  SubHeaderHeight: '55px',
};

const appSize = {
  minHeight: '100vh',
};
const customModalSize = {
  minWidth: '10vw',
  maxWidth: '60vw',
  maxHeight: '60vh',
  minHeight: '10vh',
  childrenMaxHeight: '500px',
  iconHeight: '50px',
  spaceBetweenIconAndTitle: '30px',
  iconWidth: '50px',
  spaceBetweenTitleAndSubTitle: '5px',
  spaceBetweenBottomButtons: '24px',
};

const navBarSize = {
  navBarHeight: '72px',

  profileSectionHeight: '100%',
  spaceBetweenLogoAndTabs: '20px',
  tabsIndicatorHeight: '2px',
  spaceBetweenAvatarAndSettingGear: '20px',
};

const logoSectionSize = {
  logoHeight: '40px',
  logoWidth: '40px',
  spaceBetweenLogoAndText: '10px',
  logoSectionHeight: '100%',
};

const customTableSize = {
  scrollbarWidth: '6px',
  scrollbarRadius: '3px',
  tableContainerHeight: 'calc(100% - 52px)',
  tabBodyMaxHeight: `calc(100% - 58px)`,
  noManagerHeight: '33.5px',
  imageDataCellHeight: '36px',
  imageDataCellWidth: '36px',
  nameWithImgSize: '24px',
  avatarHeight: '32px',
  avatarWidth: '32px',
  managerListIconButtonSize: {
    width: '37px',
    borderRadius: '16px',
  },
  hyphenLeftSpaccing: '10px',
  noDataHeight: '30vh',
  bodyMaxHeight: '48.5vh',
  managerMenuWidth: '225px',
  managerMenuMaxHeight: '180px',
  managersScrollbarWidth: '6px',
  managersScrollbarRadius: '3px',
};
const CustomFilledIconButtonSize = {
  buttonHeight: '48px',
  buttonWidth: '220px',
  loaderBoxMinWidth: '48px',
  loaderBoxHeight: '48px',
};
const CustomOutlinedIconButtonSize = {
  buttonHeight: '48px',
  buttonWidth: '368px',
};

const CustomSearchBarSize = {
  height: '44px',
};

const joiningInformationSize = {
  idWidth: '120px',
  jobTitleWidth: '352px',
  commonWidth: '236px',
  notesWidth: '496px',
  width: '525px',
};

const employeePageSize = {
  rootBoxHeight: 'calc( 100vh - 72px )',
  tableBoxHeight: 'calc( 100% - 270px)',
  historyScrollBarWidth: '6px',
  historyScrollRadius: '3px',
  searchbarMinWidth: '480px',
  spaceBetweenTitleAndSubtitle: '5px',
  spaceBetweenSubtitleAndSearchbar: '24px',
  spaceBetweenSearchbarAndTable: '30px',
};
const hardwarePageSize = {
  searchbarMinWidth: '480px',
  spaceBetweenTitleAndSubtitle: '5px',
  spaceBetweenSubtitleAndSearchbar: '24px',
  spaceBetweenSearchbarAndTable: '40px',
  spaceBetweenCategoryAndTable: '24px',
};
const softwarePageSize = {
  rootBoxHeight: 'calc( 100vh - 72px )',
  tableBoxHeight: 'calc( 100% - 270px)',
  searchbarMinWidth: '480px',
  spaceBetweenTitleAndSubtitle: '5px',
  spaceBetweenSubtitleAndSearchbar: '24px',
  spaceBetweenSearchbarAndTable: '30px',
};
const addSoftwareSize = {
  spaceBetweenTitleAndTextField: '24px',
  softwareImageSize: '24px',
  autoCompleteWidth: '220px',
  autoCompleteRadius: '4px',
  spaceBetweenButtonAndIcon: '11px',
  managersDropdownMaxHeight: '140px',
  managersDropdownScrollbarWidth: '6px',
  managersDropdownScrollbarRadius: '3px',
};
const deleteSoftwareSize = {
  softwareImageSize: '24px',
  spaceBetweenTitleAndTextField: '24px',
};
const projectPageSize = {
  searchbarMinWidth: '480px',
  spaceBetweenTitleAndSubtitle: '5px',
  spaceBetweenSubtitleAndSearchbar: '24px',
  spaceBetweenSearchbarAndTable: '40px',
};
const userSettingsPageSize = {
  rootBoxHeight: 'calc( 100vh - 72px )',
  tableBoxHeight: 'calc( 100% - 270px)',
  spaceBetweenTitleAndSubtitle: '5px',
  spaceBetweenSubtitleAndSearchbar: '24px',
  spaceBetweenSearchbarAndTable: '30px',
  searchbarMinWidth: '480px',
  rowHeight: '53px',
};

const basicInformationSize = {
  fullTextFieldWidth: '496px',
  twoTextFieldWidth: '236px',
  preMobileWidth: '88px',
  mobileNumberWidth: '384px',
  cityTextFieldWidth: '180px',
  stateTextFieldWidth: '88px',
  zipcodeTextFieldWidth: '180px',
  width: '525px',
};

const resourceSelectSize = {
  logoHeight: '24px',
  logoWidth: '30px',
  width: '525px',
};

const signinSize = {
  pageHeight: '100%',
  boxWidth: '592px',
  boxHeight: '820px',
  signInButtonWidth: '100%',
  signInButtonHeight: '48px',
  spaceBetweenLogoAndProjectTitle: '21px',
  spaceBetweenLogoAndTitle: '57.75px',
  spaceBetweenTitleAndSubtitle: '8px',
  spaceBetweenSubtitleAndButton: '32px',
};

const employeeDetailsPageSize = {
  tabMaxHeight: 'calc(100% - 72px)',
  detailsHeight: 'calc(100vh - 140px)',
  tabBoxHeight: 'calc(100% - 250px)',
  mediumSizeProfileSize: '22px',
  bulletSize: '6px',
  profileAvatarSize: '64px',
  spaceBetweenCards: '24px',
  verticalCommonSpace: '10px',
  horizontalCommonSpace: '16px',
  spaceBetweenButtons: '10px',
  gapBetweenSkills: '9px',
  iconSize: '16px',
  skillCloseButtonSize: '5px',
  spaceBetweenIconAndText: '5px',
  skillMinHeight: '280px',
  historyMinHeight: '250px',
  historyMaxHeight: '350px',
  gapBetweenIconAndTitle: '10px',
  skillImagwWidth: '200px',
  asideIconSize: '12px',
  searchbarMinWidth: '400px',
  tabErrorSize: '500px',
  profileTabMinHeight: '500px',
  softwareTabMinHeight: '500px',
  skillBoxMaxHeight: '203px',
  capacityBoxWidth: '2px',
  spaceAfterProfileDetailsTable: '24px',
  spaceAfterSoftwareTable: '70px',
};

const softwareDetailsPageSize = {
  rootBoxHeight: 'calc( 100vh - 72px )',
  tableBoxHeight: 'calc( 100% - 200px)',
  modalTextWidth: '300px',
  detailsBoxMaxWidth: '300px',
};

const addSkillsModalSizes = {
  sizeBetweenChips: '12px',
  spaceBelowSearchbar: '10px',
  skillsListHeight: '250px',
  contentWidth: '400px',
  skillChipRadius: '2px',
  skillsListWidth: '420px',
  searchBarMaxHeight: '120px',
};

const historyComponentSize = {
  customStepIconSize: '10px',
  customStepConnectorSize: '100px',
  minHeight: '300px',
  titleObjectsGap: '10px',
  dividerHeight: '100%',
  firstDivideHeight: '104%',
  historyDataBoxSize: '330px',
  drawerWidth: '450px',
};

const deviceCategorySize = {
  maxWidth: '540px',
  singleDeviceWidth: '96px',
  singleDeviceHeight: '96px',
  imageWidth: '36px',
  imageHeight: '36px',
};
const addHardwareSize = {
  fullWidth: '496px',
  commonWidth: '236px',
};
const detailsPageSize = {
  spaceBetweenTitleAndSubtitle: '5px',
  spaceBetweenCards: '30px',
  spaceBetweenDetailsContent: '15px',
  iconSize: '32px',
  profileImageSize: '32px',
  widthBetweenImageAndName: '8px',
  managerListMaxHeight: '150px',
};
const approveEmployeeSize = {
  width: '1024px',
  spaceBetweenDetails: '15px',
  logoHeight: '24px',
  logoWidth: '30px',
  boxBorder: '2px',
  spaceBelowSVG: '16px',
  sectionSpaceHeight: '30px',
  RequiresoftwareBoxWidth: '300px',
  RequiresoftwareBoxHeight: '370px',
  RequiresoftwareStackWidth: '300px',
  RequiresoftwareStackHeight: '60px',
  stackHeight: '270px',
  requestSoftwareHeight: '31vh',
};

const employeeSoftwareDetailsSize = {
  imageWidth: '24px',
  imageHeight: '24px',
};
const employeeSoftwareRevokeSize = {
  imageWidth: '24px',
  imageHeight: '24px',
};

const employeeSoftwareCompleteAssignmentSize = {
  imageWidth: '24px',
  imageHeight: '24px',
};

const deleteHardwareSize = {
  commonVerticalSpace: '12px',
};
const assignProjectDrawerSize = {
  spaceBetweenTextFields: '15px',
  spaceBetweenTitleAndTextField: '24px',
};

const revokeHardwareDrawerSize = {
  spaceBetweenTitleAndTextField: '24px',
  widthOfTextBox: '496px',
};

const revokeProjectDrawerSize = {
  spaceBetweenTextFields: '15px',
  spaceBetweenTitleAndTextField: '24px',
};

const revokeSoftwareDrawerSize = {
  spaceBetweenTitleAndTextField: '24px',
  widthOfTextBox: '496px',
};

const completeAssignSoftwareDrawerSize = {
  spaceBetweenTitleAndTextField: '24px',
  widthOfTextBox: '496px',
};

const assignHardwareDrawerSize = {
  searchBarWidth: '100%',
  spaceBetweenComponents: '10px',
  spaceBetweenTitleAndSearchBar: '13px',
  spaceBetweenTitleAndAvilable: '10px',
};

const antSwitchSize = {
  switchWidth: 28,
  switchHeight: 16,
  thumbSize: 12,
};
const customTextButtonSize = {
  spaceBetweenIconAndText: '5px',
};

const addProjectSize = {
  spaceBetweenTitleAndTextField: '24px',
};

const noDataEmployeeSize = {
  heightOfBox: '100vh',
  spaceBetweenSubTitleAndButton: '32px',
};

const pageNotFoundPageSize = {
  pageHeight: '100vh',
  spaceBetweenObjects: '20px',
};
const ErrorMessagePageSize = {
  pageHeight: '100vh',
  spaceBetweenObjects: '20px',
  dividerHeight: '1px',
  dividerWidth: '80%',
};

const softwareDetailsNotesSize = {
  iconWidth: '30px',
  iconHeight: '30px',
  avatarWidth: '24px',
  avatarHeight: '24px',
  notesBoxHeight: '440px',
  notesBoxWidth: '464px',
};
const customListExpandSize = {
  listWidth: '100%',
};
const customListExpandElementSize = {
  listButtonWidth: '100%',
  listIconWidth: '30px',
  listIconHeight: '24px',
  listTextWidth: '245px',
  spaceBetweenListAndCollapse: '10px',
  spaceBetweenIconAndTypography: '13px',
  messageWidth: '380px',
  spaceBetweenTypographyAndTextfeild: '100%',
  spaceBetweenButton: '16px',
  infoIconHeight: '19px',
  infoIconWidth: '19px',
};
const apiErrorComponentSize = {
  refreshButtonSize: '20px',
  gapBetweenComponents: '5px',
};

const circularProgressSize = {
  defaultSize: 30,
  defaultThickness: 4,
  defaultBgValue: 100,
};

const navbarProfileMenuSize = {
  menuWidth: '264px',
  logoutButtomWidth: '100%',
  logoutTextWidth: '330px',
  avatarHeight: '40px',
  avatarWidth: '40px',
};
const filterDropDownSize = {
  menuPaperPropsWidth: '300px',
  menuListWidth: '100%',
  menuStackHeight: '55px',
  sliderBoxWidth: '93%',
  ListItemTextWidth: '500',
  listMaxHeight: '160px',
};

const offBoardingDaysToGoSize = {
  DaysToGoBoxWidth: '100%',
  DaysToGoBoxHeight: '54px',
  EditButtonFontSize: '14px',
};

const offBoardEmployeePopupSizes = {
  contentBoxHeight: '330px',
  contentBoxWidth: '456px',
  contentBoxTextPaddingHeight: '16px',
  contentBoxDatePaddingHeight: '24px',
};

const historyDrawerSizes = {
  maxWidth: ' 440px',
  minHeight: '70vh',
};

const customAvatarSizes = {
  fontSize: '15px',
};

const offBoardEmployeeDrawerSizes = {
  allRevokeRequestButtonHeight: '36px',
  managerChipWidth: '75px',
  managerChipHeight: '30px',
  spaceAfterSoftware: '5px',
};
const ownerRoleChangeSizes = {
  confirmTextWidth: '360px',
};
const softwareManagerCellsizes = {
  managerCountFontSize: '13px',
};

export {
  circularProgressSize,
  apiErrorComponentSize,
  noDataEmployeeSize,
  SoftwareFilterSize,
  pageNotFoundPageSize,
  antSwitchSize,
  customTextButtonSize,
  customDrawerSize,
  CustomFilledIconButtonSize,
  CustomOutlinedIconButtonSize,
  CustomSearchBarSize,
  customModalSize,
  navBarSize,
  deleteHardwareSize,
  employeePageSize,
  customTableSize,
  hardwarePageSize,
  softwarePageSize,
  projectPageSize,
  basicInformationSize,
  resourceSelectSize,
  signinSize,
  logoSectionSize,
  joiningInformationSize,
  employeeDetailsPageSize,
  deviceCategorySize,
  appSize,
  addHardwareSize,
  addSoftwareSize,
  detailsPageSize,
  userSettingsPageSize,
  assignHardwareDrawerSize,
  approveEmployeeSize,
  employeeSoftwareDetailsSize,
  assignProjectDrawerSize,
  revokeHardwareDrawerSize,
  revokeProjectDrawerSize,
  revokeSoftwareDrawerSize,
  addProjectSize,
  deleteSoftwareSize,
  employeeSoftwareRevokeSize,
  historyComponentSize,
  softwareDetailsNotesSize,
  customListExpandSize,
  customListExpandElementSize,
  addSkillsModalSizes,
  navbarProfileMenuSize,
  filterDropDownSize,
  offBoardingDaysToGoSize,
  offBoardEmployeePopupSizes,
  historyDrawerSizes,
  softwareDetailsPageSize,
  employeeSoftwareCompleteAssignmentSize,
  completeAssignSoftwareDrawerSize,
  customAvatarSizes,
  offBoardEmployeeDrawerSizes,
  ErrorMessagePageSize,
  ownerRoleChangeSizes,
  softwareManagerCellsizes,
};
