// import { CheckCircle, CircleOutlined } from '@mui/icons-material';
import {
  Avatar,
  Box,
  Checkbox,
  Chip,
  Container,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import theme from '../../styles/theme';
import {
  customTableMargins,
  filterDropDownMargins,
} from '../../utils/dimensions/margins';
import {
  customTablePaddings,
  ownerRoleChangePaddings,
  softwareManagerCellPaddings,
} from '../../utils/dimensions/paddings';
import {
  customTableSize,
  resourceSelectSize,
  userSettingsPageSize,
  ownerRoleChangeSizes,
  softwareManagerCellsizes,
} from '../../utils/dimensions/size';
import {
  customTableStrings,
  employeeDetailsPageStrings,
  ownerRoleChangeString,
  tableCellStrings,
  userRoles,
  allocationUser,
  softwareStatusStrings,
  softwareStatusActionMessage,
} from '../../utils/strings';
import CustomTextButton from '../CustomTextButton';
import CustomAvatar from '../CustomAvatar';
import DefaultSoftwareFavicon from '../../utils/assets/DefaultSoftwareFavicon.svg';
import CustomModal from '../CustomModal';

function trimURL(url) {
  // Remove the protocol
  let newUrl = url.replace(/^https?:\/\//i, '');
  // Remove www.
  newUrl = newUrl.replace(/^www\./i, '');
  // Remove trailing slashes
  newUrl = newUrl.replace(/\/+$/, '');
  // Return the trimmed URL
  return newUrl;
}

export const getStatusColor = (data) => {
  if (customTableStrings.tableCellStatusValues.activeArray.includes(data))
    return {
      backgroundColor: theme.palette.success.states.outlinedHoverBackground,
      color: theme.palette.success.dark,
    };
  if (customTableStrings.tableCellStatusValues.pendingArray.includes(data))
    return {
      backgroundColor: theme.palette.info.states.outlinedHoverBackground,
      color: theme.palette.info.dark,
    };
  if (customTableStrings.tableCellStatusValues.OffBoardedArray.includes(data))
    return {
      backgroundColor: theme.palette.error.states.outlinedHoverBackground,
      color: theme.palette.error.dark,
    };
  if (customTableStrings.tableCellStatusValues.OffBoardingArray.includes(data))
    return {
      backgroundColor: theme.palette.warning.states.outlinedHoverBackground,
      color:
        data === 'Issue Logged'
          ? theme.palette.primary.main
          : theme.palette.warning.dark,
    };
  if (customTableStrings.tableCellStatusValues.OffBoardingArray.includes(data))
    return {
      backgroundColor: theme.palette.warning.states.outlinedHoverBackground,
      color:
        data === 'Issue Logged'
          ? theme.palette.primary.main
          : theme.palette.warning.dark,
    };
  return {
    backgroundColor: theme.palette.secondary.states.outlinedHoverBackground,
    color: theme.palette.secondary.dark,
  };
};

function TableCellMultipleDataComponent(data) {
  /* eslint-disable */
  if (data.length === 0) {
    return (
      <Box
        paddingLeft={customTableSize.hyphenLeftSpaccing}
        display='flex'
        alignItems='center'>
        -
      </Box>
    );
  }
  const boxes = data;
  const [_maxBoxWidth, setMaxBoxWidth] = useState(0);
  const [maxBoxes1, setMaxBoxes] = useState(0);
  const [remainingBoxes, setRemainingBoxes] = useState(0);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const stackRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      const stack = stackRef.current;
      const stackWidth = stack.offsetWidth - 30;
      let totalBoxWidth = 0;
      let maxBoxes = 0;

      for (let i = 0; i < boxes.length; i += 1) {
        const box = boxes[i];
        const boxWidth = box.length * 8 + 24;
        if (totalBoxWidth + boxWidth <= stackWidth) {
          totalBoxWidth += boxWidth;
          maxBoxes += 1;
        } else {
          break;
        }
      }

      setMaxBoxWidth(totalBoxWidth / maxBoxes);
      setMaxBoxes(maxBoxes);
      setRemainingBoxes(Math.max(0, boxes.length - maxBoxes));
    };
    handleResize();
    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [boxes, windowWidth]);

  const visibleBoxes = boxes.slice(0, maxBoxes1);
  return (
    <Container
      disableGutters
      sx={{
        height: '100%',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
      }}>
      <Box
        sx={{
          width: '-webkit-fill-available',
        }}>
        <Stack
          alignItems='center'
          direction='row'
          spacing={1}
          overflow='hidden'
          ref={stackRef}>
          {visibleBoxes.map((skill) => (
            <Chip
              key={JSON.stringify(skill)}
              label={
                <Typography color={theme.palette.secondary.dark} variant='chip'>
                  {skill}
                </Typography>
              }
            />
          ))}
          {remainingBoxes > 0 && (
            <Tooltip
              placement='top'
              title={data.slice(visibleBoxes.length).join(', ')}
              sx={{ cursor: 'pointer' }}>
              <Typography variant='body2SemiBold'>{`${remainingBoxes}+`}</Typography>
            </Tooltip>
          )}
        </Stack>
      </Box>
    </Container>
  );
}

function TableCellUrlDataComponent(href) {
  return (
    <div>
      <Typography
        varient='body2'
        component='a'
        href={href}
        target='_blank'
        color={theme.palette.text.primary}>
        {trimURL(href)}
      </Typography>
    </div>
  );
}

function TableCellStatusDataComponent(data) {
  return (
    <div>
      <Chip sx={getStatusColor(data)} label={data} />
    </div>
  );
}
const statusData = function TableCellStatusDataComponent(data) {
  return statusData;
};

function TableCellDefaultComponent(data) {
  if (!data || data === '' || data === '-')
    return (
      <Box
        paddingLeft={customTableSize.hyphenLeftSpaccing}
        display='flex'
        alignItems='center'>
        -
      </Box>
    );
  return (
    <div>
      <Typography varient='body2' color={theme.palette.text.primary}>
        {data}
      </Typography>
    </div>
  );
}
function TableCellDropDownComponent(celldata) {
  const { userId, roleId, roleName, onChangeFunction, isOwner, status, name } =
    celldata;
  const [roleValue, setRoleValue] = useState({ id: roleId, role: roleName });
  const [roleModalOpen, setRoleModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  return (
    <>
      <TextField
        select
        fullWidth
        disabled={
          !isOwner ||
          (isOwner &&
            roleValue.role ===
              customTableStrings.roleValues[0].role.toLowerCase()) ||
          status ===
            customTableStrings.tableCellStatusValues.OffBoardedArray[0] ||
          status === customTableStrings.tableCellStatusValues.pendingArray[0]
        }
        value={roleValue.id}
        sx={{
          height: userSettingsPageSize.rowHeight,
          '& .MuiOutlinedInput-root': {
            backgroundColor: theme.palette.grey[50],
            borderRadius: theme.shape.textFieldBorderRadius,
          },
          '& fieldset': { border: 'none' },

          '& .MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input': {
            padding: customTablePaddings.dropdownTableCellPadding,
          },
        }}
        onChange={(e) => {
          if (e.target.value !== userRoles.ownerRole) {
            onChangeFunction({
              userId,
              roleId: e.target.value,
              role: customTableStrings.roleValues[e.target.value - 1].role,
              setRoleValue,
            });
          } else {
            setRoleModalOpen(true);
          }
        }}>
        {customTableStrings.roleValues.map((menu) => (
          <MenuItem key={menu.id} value={menu.id}>
            {menu.role}
          </MenuItem>
        ))}
      </TextField>

      <CustomModal
        open={roleModalOpen}
        successButtonLoading={isLoading}
        onClose={() => setRoleModalOpen(false)}
        successButtonText={ownerRoleChangeString.confirmButtonText}
        failureButtonText={ownerRoleChangeString.cancelButtonText}
        onFailureButtonClick={() => setRoleModalOpen(false)}
        onSuccessButtonClick={() => {
          setIsLoading(true);
          onChangeFunction({
            userId,
            roleId: userRoles.ownerRole,
            role: customTableStrings.roleValues[0].role,
            setRoleValue,
          });
          setIsLoading(false);
          setRoleModalOpen(false);
        }}
        padding={ownerRoleChangePaddings.modalPadding}>
        <Typography variant='h5' width={ownerRoleChangeSizes.confirmTextWidth}>
          {`${ownerRoleChangeString.confirmTextFirst} ${name}${ownerRoleChangeString.confirmTextSecond}`}
        </Typography>
      </CustomModal>
    </>
  );
}

function TableCellImageDataComponent(imageUrl) {
  return (
    <Box flex={1} display='flex' alignItems='center' justifyContent='center'>
      {imageUrl ? (
        <img
          height={resourceSelectSize.logoHeight}
          width={resourceSelectSize.logoWidth}
          style={{ objectFit: 'contain' }}
          src={imageUrl}
          alt={imageUrl}
          onError={(e) => {
            e.target.src = DefaultSoftwareFavicon;
            e.onerror = null;
          }}
        />
      ) : (
        <img
          height={resourceSelectSize.logoHeight}
          width={resourceSelectSize.logoWidth}
          src={DefaultSoftwareFavicon}
          style={{ objectFit: 'contain' }}
          alt={imageUrl}
        />
      )}
    </Box>
  );
}
function TableCellAvatarComponent(imageUrl) {
  return (
    <Avatar
      src={imageUrl}
      sx={{
        height: customTableSize.imageDataCellHeight,
        width: customTableSize.imageDataCellWidth,
      }}
    />
  );
}

function TableCellSelectComponent(_data) {
  return <Checkbox />;
}

function TableCellEmployeeName(data) {
  const { imageUrl, name, position } = data;
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <CustomAvatar
        name={name}
        src={imageUrl}
        width={customTableSize.avatarWidth}
        height={customTableSize.avatarHeight}
      />
      <Stack sx={{ marginLeft: customTableMargins.employeeNameCellMarginLeft }}>
        <Typography variant='body2SemiBold' color={theme.palette.grey[1000]}>
          {name}
        </Typography>
        <Typography
          variant='tooltip'
          color={theme.palette.secondary.states.outlinedRestingBorder}>
          {position}
        </Typography>
      </Stack>
    </Box>
  );
}
function TableCellEmployeeId(data) {
  if (!data) {
    return tableCellStrings.dash;
  }
  const digits = data.split('/').pop().length;
  const MAX_LENGTH = 13;

  const truncatedData =
    data.length > MAX_LENGTH ? data.substring(0, MAX_LENGTH) + '...' : data;

  if (digits > 4) {
    return (
      <Tooltip placement='top' title={data} sx={{ cursor: 'pointer' }}>
        <Typography variant='body2'>{truncatedData}</Typography>
      </Tooltip>
    );
  } else {
    return <Typography variant='body2'>{data}</Typography>;
  }
}

function TableCellText(data) {
  if (!data) {
    return tableCellStrings.dash;
  }
  const MAX_LENGTH = 12;

  const truncatedData =
    data.length > MAX_LENGTH ? data.substring(0, MAX_LENGTH) + '...' : data;

  if (data.length > MAX_LENGTH) {
    return (
      <Tooltip placement='top' title={data} sx={{ cursor: 'pointer' }}>
        <Typography variant='body2'>{truncatedData}</Typography>
      </Tooltip>
    );
  } else {
    return <Typography variant='body2'>{data}</Typography>;
  }
}

function TableCellSoftwareNameWithIcon(data) {
  const { icon, name, client } = data;
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        marginLeft: customTableMargins.resourceSelectMainMarginLeft,
      }}>
      {icon ? (
        <img
          height={resourceSelectSize.logoHeight}
          width={resourceSelectSize.logoWidth}
          style={{ objectFit: 'contain' }}
          src={icon}
          alt={icon}
          onError={(e) => {
            e.target.src = DefaultSoftwareFavicon;
            e.onerror = null;
          }}
        />
      ) : (
        <img
          height={resourceSelectSize.logoHeight}
          width={resourceSelectSize.logoWidth}
          src={DefaultSoftwareFavicon}
          style={{ objectFit: 'contain' }}
          alt={name}
        />
      )}
      <Stack sx={{ marginLeft: customTableMargins.resourceSelectMarginsLeft }}>
        <Typography variant='body2SemiBold' color={theme.palette.grey[1000]}>
          {name}
        </Typography>
        <Typography
          variant='tooltip'
          color={theme.palette.secondary.states.outlinedRestingBorder}>
          {client}
        </Typography>
      </Stack>
    </Box>
  );
}

function TableCellNameWithImage(data) {
  const { imageUrl, name } = data;
  return (
    <Box display='flex' alignItems='center'>
      <Avatar
        alt={name}
        src={imageUrl}
        sx={{
          height: customTableSize.nameWithImgSize,
          width: customTableSize.nameWithImgSize,
          border: `2px solid ${theme.palette.grey[200]}`,
        }}
      />
      <Stack sx={{ marginLeft: customTableMargins.employeeNameCellMarginLeft }}>
        <Typography variant='body2' color={theme.palette.grey[1000]}>
          {name}
        </Typography>
      </Stack>
    </Box>
  );
}

function TableCellManagerComponent(data) {
  if (!data || !data.length)
    return (
      <Typography height={customTableSize.noManagerHeight}>
        {tableCellStrings.dash}
      </Typography>
    );

  const [firstManager, ...managers] = [...data];
  const [anchorElement, setanchorElement] = useState(null);

  const handleClick = (event) => {
    event.stopPropagation();
    setanchorElement(event.currentTarget);
  };
  const handleClose = (event, reason) => {
    event.stopPropagation();
    setanchorElement(null);
  };

  return (
    <Container
      disableGutters
      sx={{
        display: 'flex',
        alignItems: 'center',
      }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <CustomAvatar
          name={firstManager?.name}
          src={firstManager?.imageUrl}
          width={customTableSize.avatarWidth}
          height={customTableSize.avatarHeight}
        />
        <Stack
          sx={{ marginLeft: customTableMargins.managerNameCellMarginLeft }}>
          <Typography variant='body2' color={theme.palette.grey[1100]}>
            {firstManager?.name}
          </Typography>
          <Typography
            variant='tooltip'
            color={theme.palette.secondary.states.outlinedRestingBorder}>
            {firstManager?.position}
          </Typography>
        </Stack>
        {managers.length > 0 && (
          <>
            <Box
              sx={{ marginLeft: customTableMargins.managerNameCellMarginLeft }}>
              <IconButton
                aria-controls='managers-list'
                onClick={handleClick}
                sx={{
                  backgroundColor:
                    theme.palette.secondary.states.outlinedHoverBackground,
                  width: customTableSize.managerListIconButtonSize.width,
                  borderRadius:
                    customTableSize.managerListIconButtonSize.borderRadius,
                  paddingY: softwareManagerCellPaddings.manageNumberPaddingY,
                }}>
                <Typography
                  variant='body2SemiBold'
                  fontSize={softwareManagerCellsizes.managerCountFontSize}
                  sx={{
                    color: '#0C3C61',
                    // 0c3c61color is hardcoded here as we dont have this color in theme file
                  }}>{`${managers.length}+`}</Typography>
              </IconButton>
            </Box>
            <Menu
              anchorEl={anchorElement}
              onClick={(e) => {
                e.stopPropagation();
              }}
              open={Boolean(anchorElement)}
              onClose={handleClose}
              MenuListProps={{ disablePadding: true }}
              slotProps={{
                backdrop: {
                  style: {
                    background: 'transparent',
                  },
                },
              }}
              PaperProps={{
                sx: {
                  maxHeight: customTableSize.managerMenuMaxHeight,
                  overflow: 'auto',
                  '&::-webkit-scrollbar': {
                    width: customTableSize.managersScrollbarWidth,
                  },
                  '&::-webkit-scrollbar-thumb': {
                    backgroundColor: theme.palette.grey[300],
                    borderRadius: customTableSize.managersScrollbarWidth,
                    opacity: 0.7,
                  },
                },
                style: {
                  padding: customTablePaddings.managerMenuPaddings,
                  width: customTableSize.managerMenuWidth,
                  border: '1px solid',
                  backgroundColor: theme.palette.primary.contrastText,
                  borderColor: theme.palette.grey[100],
                  boxShadow: theme.shadows[7],
                  marginTop: filterDropDownMargins.menuBoxMarginTop,
                },
              }}>
              {managers.map((manager, index) => {
                return (
                  <Container disableGutters key={JSON.stringify(index)}>
                    <MenuItem
                      disableRipple
                      sx={{
                        cursor: 'default',
                        '&:hover': {
                          backgroundColor: theme.palette.common.white,
                        },
                      }}>
                      {TableCellEmployeeName(manager)}
                    </MenuItem>
                    {index !== managers.length - 1 && (
                      <Divider
                        sx={{
                          color: theme.palette.grey[50],
                          border: '1px solid',
                        }}
                      />
                    )}
                  </Container>
                );
              })}
            </Menu>
          </>
        )}
      </Box>
    </Container>
  );
}

function TableCellName(data) {
  return (
    <Typography variant='body2SemiBold' color={theme.palette.text.primary}>
      {data}
    </Typography>
  );
}

function TableCellHardwareAllocation(data) {
  if (data === 'Available')
    return (
      <Chip
        sx={getStatusColor(data)}
        label={<Typography variant='chip'>{data}</Typography>}
      />
    );
  const { name, imageUrl } = data;
  return (
    <Chip
      avatar={<Avatar scr={imageUrl} alt='name' />}
      label={<Typography variant='chip'>{name}</Typography>}
    />
  );
}
function TableCellNote(data) {
  if (!data)
    return (
      <Box
        paddingLeft={customTableSize.hyphenLeftSpaccing}
        display='flex'
        alignItems='center'>
        -
      </Box>
    );

  return (
    <Typography
      variant='body2'
      sx={{
        color: theme.palette.text.primary,
      }}>
      {data}
    </Typography>
  );
}
function TableCellIcon(data) {
  return (
    <Box
      sx={{
        color: theme.palette.primary.dark,
      }}>
      {data}
    </Box>
  );
}
function TableCellNotesLink(celldata) {
  const { buttonText, onButtonClick, data } = celldata;

  if (!data.notes || !data.notes.length)
    return (
      <Box
        paddingLeft={customTableSize.hyphenLeftSpaccing}
        display='flex'
        alignItems='center'>
        {tableCellStrings.dash}
      </Box>
    );

  return (
    <Typography
      onClick={() => {
        onButtonClick(data);
      }}
      variant='body2SemiBold'
      sx={{
        color: theme.palette.info.main,
        textDecoration: 'underline',
        cursor: 'pointer',
      }}>
      {buttonText}
    </Typography>
  );
}

function TableCellActionButton(celldata) {
  const { buttonText, onButtonClick, data, disabled } = celldata;
  return (
    <CustomTextButton onClick={() => onButtonClick(data)} disabled={disabled}>
      {!disabled ? (
        <Typography variant='buttonSmall' color={theme.palette.primary.main}>
          {buttonText}
        </Typography>
      ) : (
        <Typography variant='buttonSmall' color={theme.palette.grey[300]}>
          {buttonText}
        </Typography>
      )}
    </CustomTextButton>
  );
}

function TableCellDate(data) {
  return (
    <div>
      <Typography varient='body2' color={theme.palette.text.primary}>
        {data ? moment(data).format('MMM D, YYYY') : tableCellStrings.dash}
      </Typography>
    </div>
  );
}

function TableCellCapacity(data) {
  if (data === null)
    return (
      <Box
        paddingLeft={customTableSize.hyphenLeftSpaccing}
        display='flex'
        alignItems='center'>
        -
      </Box>
    );
  return (
    <Typography variant='body2SemiBold' color={theme.palette.text.primary}>
      {`${data} ${tableCellStrings.capacityHoursPostfix}${tableCellStrings.capacityweekPostfix}`}
    </Typography>
  );
}
function TableCellAllocation(data) {
  if (parseInt(data) === 0)
    return (
      <Typography varient='body2' color={theme.palette.text.primary}>
        {allocationUser.noUser}
      </Typography>
    );

  return (
    <Typography varient='body2' color={theme.palette.text.primary}>
      {`${data} ${parseInt(data) === 1 ? 'User' : 'Users'}`}
    </Typography>
  );
}

function TableCellSoftwareNameWithManager(data) {
  const { softwareName, isManager, actionBy, status, fullName } = data;
  let actionMessage;

  if (status === softwareStatusStrings.pending) {
    actionMessage = `${softwareStatusActionMessage.pending}: ${
      actionBy ?? ' - '
    }`;
  } else if (status === softwareStatusStrings.active) {
    actionMessage = `${softwareStatusActionMessage.active}: ${
      actionBy ?? ' - '
    }`;
  } else if (status === softwareStatusStrings.revoked) {
    actionMessage = `${softwareStatusActionMessage.revoke}: ${
      actionBy ?? ' - '
    }`;
  }
  const MAX_LENGTH = 10;

  const truncatedName =
    softwareName.length > MAX_LENGTH
      ? softwareName.substring(0, MAX_LENGTH) + '...'
      : softwareName;

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Stack>
        <Typography
          variant='body2SemiBold'
          color={theme.palette.text.primary}
          title={truncatedName === softwareName ? '' : softwareName}>
          {truncatedName}
        </Typography>
        {isManager && (
          <Typography variant='tooltip' color={theme.palette.success.dark}>
            {employeeDetailsPageStrings.employeeDetailsCard.managerLabel}
          </Typography>
        )}
        <Typography
          variant='tooltip'
          color={theme.palette.grey[400]}
          title={fullName}>
          {actionMessage}
        </Typography>
      </Stack>
    </Box>
  );
}

export default function CustomTableCell({ data, dataType }) {
  // based on datatypes, diffrent type of component is returned.
  if (dataType === customTableStrings.tableCellDataTypes.capacityText) {
    return TableCellCapacity(data);
  }
  if (dataType === customTableStrings.tableCellDataTypes.text) {
    return TableCellText(data);
  }
  if (dataType === customTableStrings.tableCellDataTypes.employeeId) {
    return TableCellEmployeeId(data);
  }
  if (dataType === customTableStrings.tableCellDataTypes.manager) {
    return TableCellManagerComponent(data);
  }
  if (dataType === customTableStrings.tableCellDataTypes.multiple) {
    return TableCellMultipleDataComponent(data);
  }
  if (dataType === customTableStrings.tableCellDataTypes.image) {
    return TableCellImageDataComponent(data);
  }
  if (dataType === customTableStrings.tableCellDataTypes.avatar) {
    return TableCellAvatarComponent(data);
  }
  if (dataType === customTableStrings.tableCellDataTypes.url) {
    return TableCellUrlDataComponent(data);
  }
  if (dataType === customTableStrings.tableCellDataTypes.status) {
    return TableCellStatusDataComponent(data);
  }
  if (dataType === customTableStrings.tableCellDataTypes.dropdown) {
    return TableCellDropDownComponent(data);
  }
  if (dataType === customTableStrings.tableCellDataTypes.select) {
    return TableCellSelectComponent(data);
  }
  if (dataType === customTableStrings.tableCellDataTypes.employeeName) {
    return TableCellEmployeeName(data);
  }
  if (dataType === customTableStrings.tableCellDataTypes.name) {
    return TableCellName(data);
  }
  if (dataType === customTableStrings.tableCellDataTypes.nameWithImg) {
    return TableCellNameWithImage(data);
  }
  if (dataType === customTableStrings.tableCellDataTypes.hardwareAllocation) {
    return TableCellHardwareAllocation(data);
  }
  if (dataType === customTableStrings.tableCellDataTypes.note) {
    return TableCellNote(data);
  }
  if (dataType === customTableStrings.tableCellDataTypes.icon) {
    return TableCellIcon(data);
  }
  if (dataType === customTableStrings.tableCellDataTypes.notesLink) {
    return TableCellNotesLink(data);
  }
  if (dataType === customTableStrings.tableCellDataTypes.date) {
    return TableCellDate(data);
  }
  if (dataType === customTableStrings.tableCellDataTypes.actionButton) {
    return TableCellActionButton(data);
  }
  if (dataType === customTableStrings.tableCellDataTypes.allocation) {
    return TableCellAllocation(data);
  }
  if (dataType === customTableStrings.tableCellDataTypes.softwareNameWithIcon) {
    return TableCellSoftwareNameWithIcon(data);
  }
  if (dataType === customTableStrings.tableCellDataTypes.nameWithManager) {
    return TableCellSoftwareNameWithManager(data);
  }

  return TableCellDefaultComponent(data);
}

CustomTableCell.propTypes = {
  dataType: PropTypes.oneOf(
    Object.values(customTableStrings.tableCellDataTypes)
  ),
  data: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.shape({}),
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.shape()),
  ]),
};
CustomTableCell.defaultProps = {
  dataType: null,
  data: null,
};
